import React from 'react'
import Button from 'antd/lib/button'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const AddIconButton = (props) => {
  return (
    <Link to={props.uri}>
      <AddButtonIconStyle disabled={props.disabled} block icon='plus' {...props}>
        {props.text}
      </AddButtonIconStyle>
    </Link>
  )
}

const AddButtonIconStyle = styled(Button)`
  background-color: var(--color-secondary);
  color: var(--color-font-white);
  box-shadow: 0 4px 25px 0 rgba(0,0,0,0.1);
  border-radius: .5rem;
  font-weight: 300;
  font-size: 25px;
  text-transform: uppercase;
  height: 4.5rem;
  border: 0px;

  @media (max-width:1024px){
    height: 40px;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: normal;
  }
  
   @media (max-width:767px){
    height: 50px;
    font-size: 18px;
  }
  :focus {
    background-color: var(--color-font-white) !important;
    color: var(--color-secondary);
   

  }

  :hover {
    background-color: var(--color-font-white) !important;
    color: var(--color-secondary) 
  }

`

export default AddIconButton
