import { GET_TYPES_SUCCESS, GET_TYPES_FAILURE } from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_TYPES_SUCCESS: {
      return {
        ...state,
        types: action.payload.types,
      }
    }

    case GET_TYPES_FAILURE: {
      return {
        ...state,
        getTypesError: action.payload.error,
      }
    }

    default:
      return state
  }
}
