import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const DistributorsTable = () => {
  return (
    <div>

<TableHolder>
<View componentName='distributors' config={config.distributors} exportConfig={config.exportData}/>

</TableHolder>
    </div>
  )
}

export default DistributorsTable


const TableHolder  = styled.div`

@media (max-width:767px){
  td:nth-child(7),td:nth-child(8), td:nth-child(9) {
    display: inline-block;
    text-align: center;
  }
}

`