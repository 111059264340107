import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_BUYERS,
  DELETE_BUYER,
  ADD_BUYER,
  UPDATE_BUYER,
  UPDATE_BUYER_HANDSHAKE,
} from '../../../../config/constants/ActionTypes'
import { getBuyers, getBuyersSuccess, getBuyersFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'
import { path } from 'ramda'

const getBuyersRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'buyers',
    params: { ...query, isDeleted: false },
    headers: { token: `${localStorage.getItem('token')}` }
  })
}

function* getBuyersSaga({ payload }) {
  try {
    const response = yield call(getBuyersRequest, payload)
    yield put(getBuyersSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getBuyersFailure(error))
    payload.onFailure(error)
  }
}

const deleteBuyerRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'buyers',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

const deleteBuyerHandshakeRequest = async ({ _id, distributorId }) => {
  return await axios({
    method: 'put',
    url: config.url + 'distributors/remove-buyer',
    params: {
      _id: distributorId,
    },
    headers: { token: `${localStorage.getItem('token')}` },
    data: { element: { _id } },
  })
}

function* deleteBuyerSaga({ payload }) {
  try {
    let response // eslint-disable-line
    if (['distributor', 'company'].includes(payload.staffType)) response = yield call(deleteBuyerHandshakeRequest, payload)
    else response = yield call(deleteBuyerRequest, payload)
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addBuyerRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'buyers',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addBuyerSaga({ payload }) {
  try {
    const response = yield call(addBuyerRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateBuyerRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'buyers',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateBuyerSaga({ payload }) {
  try {
    const response = yield call(updateBuyerRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getBuyers(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateBuyerHandshakeRequest = async ({ query, body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'distributors/buyerCategory',
    params: {
      _id: query._id,
    },
    headers: { token: `${localStorage.getItem('token')}` },
    data: body,
  })
}

function* updateBuyerHandshakeSaga({ payload }) {
  try {
    const response = yield call(updateBuyerHandshakeRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.query?.buyer?._id)
    else {
      delete payload.body
      yield put(getBuyers(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_BUYERS, getBuyersSaga)],
    [yield takeEvery(DELETE_BUYER, deleteBuyerSaga)],
    [yield takeEvery(ADD_BUYER, addBuyerSaga)],
    [yield takeEvery(UPDATE_BUYER, updateBuyerSaga)],
    [yield takeEvery(UPDATE_BUYER_HANDSHAKE, updateBuyerHandshakeSaga)],
  )
}
