import {
  getStatusFromStatusNumber,
} from '../../../services/commonServices';
import arabic_labels from '../../../config/constants/arabic_labels.json';
import { pathOr } from 'ramda'

export const superAdminDownloadableSections = ['companies', 'distributors', 'reps', 'orders']
export const companyAdmindownloadableSections = ['distributors', 'reps', 'orders']

export function formatRecord(recordArray, componentName) {
  return recordArray?.map((row) =>
    rowSelector(row, componentName)
  )
}

export const formatBuyerCategories = (categories) => {
  let returnedCategories = ''
  categories.map((category, index) => {
    returnedCategories += `${index}) قطاع --> ${pathOr('-', ['buyerCategory', 'name', 'ar'], category)}: \n وحدة البيع الرئيسيه= ${pathOr('-', ['saleUnit', 'package', 'name'], category)}, \n العدد= ${pathOr(0, ['saleUnit', 'packageQuantity'], category)}, \n السعر= ${pathOr(0, ['saleUnit', 'price'], category)}. \n`
  })
  return returnedCategories
}

export const rowSelector = (row, componentName) => {
  switch (componentName) {
    case 'companies':
      return {
        'إسم الشركه': pathOr('-', ['name', 'ar'], row),
        'رقم الموبايل': pathOr('-', ['phone'], row),
        'الصناعات': pathOr([], ['section'], row).map(element => element.name.ar).toString(),
        'البريد الإلكتروني': pathOr('-', ['email'], row),
        'المدينه': pathOr('-', ['address', 0, 'city', 'name'], row),
        'الحي': pathOr('-', ['address', 0, 'district', 'name', 'ar'], row),
        'المنطقه': pathOr('-', ['address', 0, 'zone', 'name', 'ar'], row),
        'الاشتراكات': pathOr('-', ['subscription', 'name', 'ar'], row),
      }
    case 'distributors':
      return {
        'إسم الموزع': pathOr('-', ['name', 'ar'], row),
        'إسم الشركه': pathOr('-', ['company', 'name', 'ar'], row),
        'رقم الموبايل': pathOr('-', ['phone'], row),
        'البريد الإلكتروني': pathOr('-', ['email'], row),
        'المناطق': pathOr([], ['zones', 'zone'], row).map(element => element.name?.ar || '-').toString(),
        'المدينه': pathOr([], ['zones', 'city'], row).map(element => element.name).toString(),
        'العنوان': `${pathOr([], ['address', 0, 'buildingNumber'], row)}, ${pathOr([], ['address', 0, 'street'], row)}`,
        'فرع': row.isBranch ? 'نعم' : 'لا',
        'أعداد المشترين': (row.buyers).length,
        'عدد المشترين قيد الانتظار': (row.pendingBuyers).length,
      }
    case 'reps':
      return {
        'المناديب': pathOr('-', ['name', 'ar'], row),
        'رقم الموبايل': pathOr('-', ['accountPhoneNumber'], row),
        'انضم يوم': new Date(`${pathOr('-', ['created_at'], row)}`).toLocaleDateString(),
        'إسم القطاع ': pathOr('-', ['buyerCategory', 'name', 'ar'], row),
        'الموزع': pathOr('-', ['distributor', 'name', 'ar'], row),
        ' البريد الإلكتروني للموزع': pathOr('-', ['distributor', 'email'], row),
        'المناطق': pathOr([], ['zones'], row).map(element => element.name?.ar || '-').toString(),
        ' العنوان الموزع': `${pathOr([], ['distributor', 'address', 0, 'buildingNumber'], row)}, ${pathOr([], ['distributor', 'address', 0, 'street'], row)}`,
      }
    case 'orders':
      return {
        'المنطقه': pathOr('_', ['zone', 'name'], row),
        'تاريخ الطلب': new Date(row.createdAt).toLocaleDateString() || '_',
        'تاريخ التوصيل': row.deliveryDate ? new Date(row.deliveryDate).toLocaleDateString() : '_',
        'الحاله':
          arabic_labels[
          pathOr('-', ['arabicStatus'], getStatusFromStatusNumber(row.status))
          ] || '_',
        'رقم الطلب': row.orderId || '_',
        'الاجمالي': row.total || 0,
        'الخصم': row.discount || 0,
        'الاجمالي بعد الخصم': row.discountedTotal || row.total,
        'هاتف المندوب': pathOr('_', ['rep', 'phone'], row),
        'إسم المندوب': pathOr('_', ['rep', 'name'], row),
        'البائع': pathOr('_', ['seller', 'name'], row),
        'المشتري': pathOr('_', ['user', 'name'], row),
        'رقم محمول المشتري': pathOr('_', ['user', 'phone'], row),
        'قيمة الطلب بالنقاط': row.orderWorthInPoints || 0,
        'طريقة الدفع': row.paymentMethod || 'COD',
        'النقاط المستخدمة': row.usedPoints || 0,
      }
    case 'companyProducts':
      return {
        'إسم الصنف': pathOr('_', ['name', 'ar'], row),
        'الشركه': pathOr('_', ['company', 'name', 'ar'], row),
        'اي ار بي كود': pathOr('_', ['erpCode'], row),
        'الأوصاف': pathOr('_', ['tags', 0, 'name', 'ar'], row),
        'منتج مميز': row.featured ? 'نعم' : 'لا',
        'عدد النقاط': pathOr(0, ['points'], row),
        'الماركه': pathOr('-', ['brand', 'name', 'ar'], row),
        'القطاعات': formatBuyerCategories(pathOr([], ['buyerCategories'], row)),
        'القسم': pathOr('-', ['category', 'name', 'ar'], row),
        'وحدة البيع الفرعيه': pathOr('-', ['packageUnit', 'name'], row),
        'الأحجام': `${pathOr('-', ['sizes', 'size', 'name', 'ar'], row)} --> الوزن= ${pathOr(0, ['sizes', 'quantity'], row)}`,
        'الصناعه': pathOr('-', ['section', 'name', 'ar'], row),
        'القسم الفرعي': pathOr('-', ['subCategory', 'name', 'ar'], row),
        'المجموعه الرئيسيه': pathOr('-', ['type', 'name', 'ar'], row),
        'المجموعه الفرعية': pathOr('-', ['subType', 'name', 'ar'], row),
      }
    default:
      return []
  }
}

export function canDownloadContent(user, componentName) {
    if (user?.isSuperAdmin || user?.sellerCategory === 'Company') {
      return (user.isSuperAdmin && superAdminDownloadableSections.includes(componentName)) ||
        (user.sellerCategory === 'Company' && companyAdmindownloadableSections.includes(componentName))
    }
    return false
}