/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import { uploadImages } from '../../../../config/Client/actions/Upload'

const EditBuyerForm = ({ dispatch }) => {
  const { buyers } = config
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(
      config.buyers.edit.get({
        query: { _id: id },
        onSuccess: onFetchSuccess,
        onFailure: onFetchFailure,
      }),
    )
  }, [])

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(uploadImages({
      values,
      imageArray: values.images,
      moduleName: 'buyers',
      _id: id,
      onSuccess: onUploadSuccess,
      onFailure,
    }))
  }

  const onUploadSuccess = (imageLinkArray, values) => {
    values.images = imageLinkArray
    dispatch(buyers.edit.update({ body: { ...values, _id: id }, onSuccess, onFailure }))
  }

  const onSuccess = () => {
    setSubmitting(false)
    message.success('تم التعديل بنجاح')
    history.push(`/buyers${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  const onFetchSuccess = (records) => {
    const fetchedRecord = records.data[Object.keys(records.data)[0]]
    const record = {
      ...fetchedRecord,
      phone: fetchedRecord?.phone[0],
      address: {
        ...fetchedRecord?.address,
        country: fetchedRecord?.address?.country?._id,
        city: fetchedRecord?.address?.city?._id,
        district: fetchedRecord?.address?.district?._id,
        zone: fetchedRecord?.address?.zone?._id,
      },
    }
    setLoading(false)
    setData(record)
  }

  const onFetchFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }
  return (
    <CrudForm
      title='تعديل المشتري'
      loading={loading}
      fields={config.buyers.edit.fields}
      onSubmit={onSubmit}
      buttonText='تعديل'
      initialValues={data}
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(EditBuyerForm)
