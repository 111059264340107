import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'

const PendingBuyersTable = () => {
  return (
    <div>
      <View componentName='pendingBuyers' config={config.pendingBuyers} />
    </div>
  )
}

export default PendingBuyersTable
