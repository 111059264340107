import React, { forwardRef, useState, useEffect, Fragment, useCallback } from 'react'
import { equals } from 'ramda'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'

const TextInput = forwardRef(({
  value,
  dataIndex,
  title,
  readOnly,
  onChange,
  onMounted,
  helperText,
}, ref) => {
  const [state, setState] = useState('')

  useEffect(() => {
    if (typeof value !== 'string') applyChange('')
    else if (!equals(value, state)) applyChange(value)
  }, [value]) // eslint-disable-line

  useEffect(() => {
    if (onMounted) onMounted(dataIndex)
  }, []) // eslint-disable-line

  const applyChange = useCallback((val) => {
    setState(val)
    if (onChange) onChange(val, dataIndex)
  }, [onChange, dataIndex])

  const onChildChange = useCallback((e) => {
    applyChange(e.target.value)
  }, [applyChange])

  return (
    <Fragment>
      <FormControl fullWidth margin='dense'>
        <InputLabel>{title}</InputLabel>
        <Input
          multiline
          style={{
           
          }}
          placeholder={title}
          inputProps={{ 'aria-label': title, name: dataIndex, style: {minHeight: '28px'} }}
          disabled={readOnly}
          ref={ref}
          value={state}
          onChange={onChildChange}
          fullWidth
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Fragment>
  )
})

export default TextInput
