import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  GET_SETTING,
  UPDATE_SETTING,
} from "../../../../config/constants/ActionTypes";
import { getSetting, getSettingsFailure, getSettingsSuccess } from "../actions";
import config from "../../../../config/Client/sagas/config";
import axios from "axios";

const getSettingRequest = async ({ query }) => {
  return await axios({
    method: "get",
    url: config.url + "settings",
    params: {
      ...query,
      token: localStorage.getItem("token"),
    },
  });
};

function* getSettingSaga({ payload }) {
  try {
    const response = yield call(getSettingRequest, payload);
    yield put(getSettingsSuccess(response.data));
    payload.onSuccess(response.data);
  } catch (error) {
    yield put(getSettingsFailure(error));
    payload.onFailure(error);
  }
}

const updateSettingRequest = async ({ body }) => {
  return await axios({
    method: "put",
    url: config.url + "settings",
    params: {
      _id: body._id,
      token: localStorage.getItem("token"),
    },
    data: body,
  });
};

function* updateSettingSaga({ payload }) {
  try {
    const response = yield call(updateSettingRequest, payload); // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id);
    else {
      delete payload.body;
      yield put(getSetting(payload));
    }
  } catch (error) {
    payload.onFailure(error);
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_SETTING, getSettingSaga)],
    [yield takeEvery(UPDATE_SETTING, updateSettingSaga)]
  );
}
