import {
    UPLOAD_IMAGES,
    UPLOAD_IMAGES_SUCCESS,
    UPLOAD_IMAGES_FAILURE,
} from '../../constants/ActionTypes'

//Upload Images Request
export const uploadImages = (payload) => {
    return {
        type: UPLOAD_IMAGES,
        payload,
    }
}

//Upload Images Success
export const uploadImagesSuccess = (links) => {
    return {
        type: UPLOAD_IMAGES_SUCCESS,
        payload: { links },
    }
}

//Upload Images Failure
export const uploadImagesFailure = (error) => {
    return {
        type: UPLOAD_IMAGES_FAILURE,
        payload: { error },
    }
}