import { all, call, put, takeEvery } from 'redux-saga/effects'
import { GET_ORDERS_REPORT, GET_ORDERS_CONSTANTS, GET_UNITS_REPORT } from '../../../../config/constants/ActionTypes'
import {
  getOrdersReportSuccess,
  getOrdersReportFailure,
  getOrdersConstantsSuccess,
  getOrdersConstantsFailure,
  getUnitsReportSuccess,
  getUnitsReportFailure,
} from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getOrdersReportRequest = async ({ query }) => {
  return await axios({
    method: 'post',
    url: config.url + 'orders/ordersReport',
    params: Object.assign({}, query),
    headers: { token: `${localStorage.getItem('token')}` },
  })
}
const getOrdersConstantsRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'orders/ordersConstants',
    params: Object.assign({}, query),
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getOrdersReportSaga({ payload }) {
  try {
    const response = yield call(getOrdersReportRequest, payload)
    yield put(getOrdersReportSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getOrdersReportFailure(error))
    payload.onFailure(error)
  }
}
function* getOrdersConstantsSaga({ payload }) {
  try {
    const response = yield call(getOrdersConstantsRequest, payload)
    yield put(getOrdersConstantsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getOrdersConstantsFailure(error))
    payload.onFailure(error)
  }
}

const getUnitsReportRequest = async ({ query }) => {
  delete query.limit
  delete query.skip
  return await axios({
    method: 'get',
    url: config.url + 'orders/unitsReport',
    params: Object.assign({}, query),
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getUnitsReportSaga({ payload }) {
  try {
    const response = yield call(getUnitsReportRequest, payload)
    yield put(getUnitsReportSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getUnitsReportFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_ORDERS_REPORT, getOrdersReportSaga)],
    [yield takeEvery(GET_ORDERS_CONSTANTS, getOrdersConstantsSaga)],
    [yield takeEvery(GET_UNITS_REPORT, getUnitsReportSaga)]
  )
}
