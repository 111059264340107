import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { pathOr, path, isEmpty } from 'ramda'
import { message } from 'antd'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import { getOrder } from '../../store/actions'
import arabic_labels from '../../../../config/constants/arabic_labels.json'
import { getStatusFromStatusNumber, getStaffType } from '../../../../services/commonServices'
import Table from '../../../View/components/Table'
import UpdateStatus from '../UpdateStatus'
import { config } from '../../../../config/Structure/index'

const OrderPage = ({ dispatch, state }) => {
  const { id } = useParams()
  const history = useHistory()
  const [currentActive, setCurrentActive] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    setLoading(true)
    getRecords(id)
  }, []) // eslint-disable-line

  const getRecords = (orderId) => {
    dispatch(getOrder({ query: { _id: orderId }, onSuccess, onFailure }))
  }

  const onSuccess = (data) => {
    setLoading(false)
    setData(data)
    console.log(data)

    if (path(['auth', 'user', 'staff'], state)) {
      if (getStaffType(path(['auth', 'user', 'staff'], state)) === 'rep' && path(['auth', 'user', 'staff', '_id'], state) !== path(['rep', 'id'], data)) {
        history.push(`/reps/view/${path(['auth', 'user', 'staff', '_id'], state)}`)
      }
      if (getStaffType(path(['auth', 'user', 'staff'], state)) === 'distributor' && path(['auth', 'user', 'staff', 'sellerRef', '_id'], state) !== path(['seller', 'id'], data)) {
        history.push(`/distributors/view/${path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)}`)
      }
      if (getStaffType(path(['auth', 'user', 'staff'], state)) === 'company') {
        // i am a company trying to view a distributor's order
        // fetch the distributor to check if its one of mines or not
        dispatch(
          config.distributors.edit.get({
            query: { _id: path(['seller', 'id'], data) },
            onSuccess: onFetchSuccess,
            onFailure: onFetchFailure,
          }),
        )
      }
    }
  }

  const onFetchSuccess = (distributorData) => {
    if (path(['data', '0', 'company', '_id'], distributorData) !== path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)) {
      history.push(`/companies/view/${path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)}`)
      
    }
  }

  const onFetchFailure = () => {
    history.push(`/companies/view/${path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)}`)
  }

  const onFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }


  const columns = [
    {
      title: '',
      dataIndex: 'product.images.0',
      key: 'image',
      type: 'image',
    },
    {
      title: arabic_labels['form.quantity'],
      dataIndex: 'quantity',
      key: 'quantity',
      type: 'text',
      selector: (quantityArray) => {
        if (Array.isArray(quantityArray)) {
          return `
            ${arabic_labels['form.productPackage']}: ${path(['0', 'price'], quantityArray) ? Number(path(['0', 'price'], quantityArray)).toFixed(0) : '-'} * ${pathOr('0', ['0', 'quantity'], quantityArray)}
            ${arabic_labels['form.productUnit']}: ${path(['1', 'price'], quantityArray) ? Number(path(['1', 'price'], quantityArray)).toFixed(2) : '-'} * ${pathOr('0', ['1', 'quantity'], quantityArray)}
          `
        }
      }
    },
    {
      title: arabic_labels['form.name'],
      dataIndex: 'product.name',
      key: 'name',
    },
    {
      title: arabic_labels['form.total'],
      dataIndex: 'total',
      key: 'total',
    },
  ]

  if (loading) return <LinearProgress />


  return (
    <div>
      <div className='row'>
        <div className='col-12 col-9-md'>
          <MyTabs className='row'>
            <Tab className={`${currentActive === 1 && 'active'} col-4 is-vertical-align is-horizontal-align`} onClick={() => setCurrentActive(1)}>{arabic_labels['notification.info']} </Tab>
            <Tab className={`${currentActive === 2 && 'active'} col-4 is-vertical-align is-horizontal-align`} onClick={() => setCurrentActive(2)}>{arabic_labels['form.address']} </Tab>
            <Tab className={`${currentActive === 3 && 'active'} col-4 is-vertical-align is-horizontal-align`} onClick={() => setCurrentActive(3)}>{arabic_labels['form.buyer'] + ' / ' + arabic_labels['form.sellerRef']}</Tab>
          </MyTabs>
          <TabContent className={`${currentActive !== 1 && 'is-hidden'}`}>
            <div className='row'>
              <div className='col-12 col-4-md part'>
                <p className='title'>{arabic_labels['form.deliveryDate']}</p>
                <p className='value'>
                  {
                    path(['deliveryDate'], data)
                      ? new Date(path(['deliveryDate'], data)).toLocaleString('ar-eg', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
                      : '-'
                  }
                </p>
              </div>
              <br />
              <div className='col-12 col-4-md part'>
                <p className='title'>{'إسم ' + arabic_labels['form.rep']}</p>
                {pathOr('-', ['rep', 'name'], data)}
              </div>
              <br />
              <br />
              <div className='col-12 col-4-md part'>
                <p className='title'>{arabic_labels['appModule.phone'] + ' ' + arabic_labels['form.rep']}</p>
                <p className='value'>
                  {pathOr(' - ', ['rep', 'phone'], data)}
                </p>
              </div>
              <br />
            </div>
            <br />
            <div className='row'>
              <div className='col-12 col-4-md part'>
                <p className='title'>{arabic_labels['sidebar.pickers.dateTimePickers']}</p>
                <p className='value'>
                  {
                    path(['createdAt'], data)
                      ? new Date(path(['createdAt'], data)).toLocaleString('ar-eg', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
                      : '-'
                  }
                </p>
              </div>
              <br />
              <div className='col-12 col-4-md part'>
                <p className='title'>{arabic_labels['form.zone']}</p>
                <p className='value'>{pathOr('-', ['zone', 'name'], data)}</p>
              </div>
              <br />
              <div className='col-12 col-4-md part'>
                <p className='title'>{arabic_labels['form.buyerCategory']}</p>
                <p className='value'>{!isEmpty(path(['buyerCategory', 'name'], data)) ? pathOr('-', ['buyerCategory', 'name'], data) : '-'}</p>
              </div>
            </div>
          </TabContent>
          <TabContent className={`${currentActive !== 2 && 'is-hidden'}`}>
            <div className='row'>
              <div className='col-12 col-12-md part'>
                <p className='title'>{arabic_labels['form.shipping']}</p>
                <p className='value'>
                  <strong className='title'>{arabic_labels['form.address1'] + ' '}</strong> {pathOr(' - ', ['user', 'shipping', 'address1'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.address2'] + ' '}</strong> {pathOr(' - ', ['user', 'shipping', 'address2'], data) + ' '}
                  <br />
                  <br />
                  <strong className='title'>{arabic_labels['form.address.district'] + ' '}</strong> {pathOr(' - ', ['user', 'shipping', 'district', 'name', 'ar'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.city'] + ' '}</strong> {pathOr(' - ', ['user', 'shipping', 'city', 'name'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.addressCountry'] + ' '}</strong> {pathOr(' - ', ['user', 'shipping', 'country', 'name'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.addressPhone'] + ' '}</strong> {pathOr(' - ', ['user', 'shipping', 'phone'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.addressPhone'] + ' '}</strong>{pathOr(' - ', ['user', 'shipping', 'additionalPhone'], data) + ' '}
                </p>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12 part'>
                <p className='title'>{arabic_labels['form.billing']}</p>
                <p className='value'>
                  <strong className='title'>{arabic_labels['form.address1'] + ' '}</strong> {pathOr(' - ', ['user', 'billing', 'address1'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.address2'] + ' '}</strong> {pathOr(' - ', ['user', 'billing', 'address2'], data) + ' '}
                  <br />
                  <br />
                  <strong className='title'>{arabic_labels['form.address.district'] + ' '}</strong> {pathOr(' - ', ['user', 'billing', 'district', 'name', 'ar'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.city'] + ' '}</strong> {pathOr(' - ', ['user', 'billing', 'city', 'name'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.addressCountry'] + ' '}</strong> {pathOr(' - ', ['user', 'billing', 'country', 'name'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.addressPhone'] + ' '}</strong> {pathOr(' - ', ['user', 'billing', 'phone'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.addressPhone'] + ' '}</strong>{pathOr(' - ', ['user', 'billing', 'additionalPhone'], data) + ' '}
                </p>
              </div>
            </div>
          </TabContent>
          <TabContent className={`${currentActive !== 3 && 'is-hidden'}`}>
            <div className='row'>
              <div className='col-12 col-12-md part'>
                <p className='title'>{arabic_labels['form.buyer']}</p>
                <p className='value'>
                  <strong className='title'>{arabic_labels['form.name'] + ' '}</strong> {pathOr(' - ', ['user', 'name'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.phoneNumber'] + ' '}</strong> {pathOr(' - ', ['user', 'phone'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.email'] + ' '}</strong> {pathOr(' - ', ['user', 'email'], data) + ' '}
                </p>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12 part'>
                <p className='title'>{arabic_labels['form.sellerRef']}</p>
                <p className='value'>
                  <strong className='title'>{arabic_labels['form.name'] + ' '}</strong> {pathOr(' - ', ['seller', 'name'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.phoneNumber'] + ' '}</strong> {pathOr(' - ', ['seller', 'phone'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.email'] + ' '}</strong> {pathOr(' - ', ['seller', 'email'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.sellerType'] + ' '}</strong> {pathOr(' - ', ['seller', 'type'], data) + ' '}
                </p>
              </div>
            </div>
          </TabContent>
        </div>
        <div className='col-12 col-3-md'>
          <div className='row'>
            <div className='col-12'>
              <UpdateStatus height='4.75rem' record={data} onChange={() => getRecords(id)} />
            </div>
          </div>
          <TabContent>
            <div className='row'>
              <div className='col-12 part'>
                <p className='title'>{arabic_labels['form.orderId']}</p>
                <p className='value'>
                  {pathOr('-', ['orderId'], data)}
                </p>
                <p className='title'>{arabic_labels['form.status']}</p>
                <p className='value'>
                  <span>{arabic_labels[pathOr('-', ['arabicStatus'], getStatusFromStatusNumber(pathOr('-', ['status'], data)))]}</span>
                </p>
                <p className='title'>{arabic_labels['form.total']}</p>
                <p className='value'>
                  <span>{pathOr('-', ['total'], data)}</span>
                </p>
                <p className='title'>{arabic_labels['form.discountedTotal']}</p>
                <p className='value'>
                  <span>{pathOr('-', ['discountedTotal'], data)}</span>
                </p>
                <p className='title'>{arabic_labels['form.paymentMethod']}</p>
                <p className='value'>
                  <span>{path(['paymentMethod'], data) === 'online' ? arabic_labels['form.onlinePayment'] : arabic_labels['form.cash']}</span>
                </p>
              </div>
            </div>
          </TabContent>
        </div>
      </div>

      <TableStyle columnsCount={columns.length}>
        <Table data={path(['products'], data)} headers={columns} componentName={'order'} loading={false} />
      </TableStyle>

    </div>
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(OrderPage)


const MyTabs = styled.div`
  .active  {
    border-bottom: 0;
   color: var(--color-font-white) !important;
      background-color: var(--color-secondary) ;
    :hover  {
      color: var(--color-font-white) !important;
      background-color: var(--color-secondary) ;
    }

  }

  .false:hover {
    border-bottom: 0;
   color: var(--color-font-white) !important;
      background-color: var(--color-secondary) ;
  }
`

const Tab = styled.div`


  height: 4.75rem;
  background: var(--color-primary);
  cursor: pointer;
  border-radius: .5rem;
  border: 0;
  color: var(--color-font-secondary);
  text-transform: uppercase;
  font-size: 1.3rem;
  @media (max-width:767px){
    font-size: 16px;
    max-width: calc((100% / (12/12)) - var(--grid-gutter));
    height: 50px;

   
  }

  :hover {
    color: var(--color-secondary);
  }
`

const TabContent = styled.div`
  background: var(--color-primary);
  border-radius: .5rem;
  border: 0;
  color: var(--color-font-secondary);
  text-transform: uppercase;
  padding: 40px 30px;


  .part {
    margin-left: 10px;
    .title {
      color: var(--color-custom);
      font-size: 18px;
      margin-bottom: 10px;
      @media (max-width:767px){
        line-height: 32px;
      
      }
    }

    .value {
      color: var(--color-font-secondary);
      font-size: 17px;
      text-transform: lowercase;
      font-weight: 300;
      line-height: 1.2rem;
    }
  }

  hr {
    margin: .65rem 1rem;
    height: 1px;
    color: #82b3d626;
    background: #82b3d626;
  }
`

const TableStyle = styled.div`
  overflow-x: auto;
  th, td {
    min-width: 200px;
    width: ${props => 100 / props.columnsCount}vw;

    @media (max-width:768px){
      min-width: 100%;
       width: 100%;
  
    }
  }


  @media (max-width:767px){
    td:first-child::before {
      content: 'صورة المنتج';
  }  

  td:nth-child(2)::before {
    content: 'العدد';
}  

td:nth-child(3)::before {
  content: 'الأسم';
} 
td:nth-child(4)::before {
  content: 'الأجمالي';
} 

  }


`