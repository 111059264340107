import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const PriceOffersTable = () => {
  return (
    <div>
            <TableHolder>


      <View componentName='priceOffers' config={config.priceOffers} />
      </TableHolder>

    </div>
  )
}

export default PriceOffersTable



const TableHolder  = styled.div`
@media (max-width:767px){
  td:nth-child(7), td:nth-child(8)  {
    display: inline-block;
    text-align: center;
  }

}

`