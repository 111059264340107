/* eslint-disable no-unused-vars */
import { tableFields, fields, formFields } from '../schema'
import { getReps, deleteRep, addRep, updateRep } from '../../../modules/Rep/store/actions'
import ZonesTreeSelect from '../../../modules/Rep/components/ZonesTreeSelect'
import arabic_labels from '../../constants/arabic_labels.json'
import { getBuyerCategories } from '../../../modules/BuyerCategory/store/actions'
import { getDistributors } from '../../../modules/Distributor/store/actions'
import { path } from 'ramda'
const logo = require('../../../img/logo-new.png')

export const reps = {
  view: {
    get: getReps,
    delete: deleteRep,
    fields: [
      tableFields.image(arabic_labels['form.logo'], 'images[0]', (value) => value || logo),
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.phone'], 'accountPhoneNumber'),
      tableFields.text('الموزع', 'distributor.name.ar'),
      tableFields.text(arabic_labels['form.buyerCategory'], 'buyerCategory.name.ar'),
      tableFields.action.goTo(arabic_labels['form.details'], 'rep', '/reps/view/{key}'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addRep,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.text({
        title: arabic_labels['form.phone'],
        dataIndex: 'accountPhoneNumber',
        validate: (fieldValue) => (!fieldValue ? 'لا بد من إدخال رقم الموبايل' : true),
      }),
      fields.text({
        title: arabic_labels['form.password'],
        dataIndex: 'password',
        inputType: 'password',
        validate: (fieldValue) => (!fieldValue ? 'لا بد من إدخال كلمة السر' : true),
      }),
      formFields.select(
        arabic_labels['form.buyerCategory'],
        'buyerCategory',
        'default',
        { query: getBuyerCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'buyerCategory',
        },
      ),
      formFields.select(
        arabic_labels['form.distributor'],
        'distributor',
        'default',
        { query: getDistributors },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الموزع' }],
        null,
        null,
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الموزع': true),
      ),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال المنطقة' : true)
      }),
      fields.checkbox({ title: arabic_labels['form.status'], dataIndex: 'status' }),
    ],
  },
  edit: {
    update: updateRep,
    get: getReps,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.text({ title: arabic_labels['form.phone'], dataIndex: 'accountPhoneNumber' }),
      fields.object({
        title: arabic_labels['form.buyerCategory'],
        dataIndex: 'buyerCategory',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getBuyerCategories },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            {
              key: 'buyerCategory',
            },
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.distributor'],
        dataIndex: 'distributor',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getDistributors },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار الموزع' }],
            null,
            null,
            null,
            'distributor',
          ),
        ],
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال المنطقة' : true)
      }),

      fields.checkbox({ title: arabic_labels['form.status'], dataIndex: 'status' }),
    ],
  },
}
