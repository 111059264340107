import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
// import useAddForm from '../../../Form/components/useFormHelpers/useAddForm'

const AddTagForm = ({ dispatch }) => {
  const { tags } = config
  const location = useLocation()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(tags.add.create({ body: values, onSuccess, onFailure }))
  }

  const onSuccess = () => {
    setSubmitting(false)
    message.success('تمت الاضافة بنجاح')
    history.push(`/tags${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  return (
    <CrudForm
      title='أضف مجموعه رئيسيه جديده'
      loading={false}
      fields={config.tags.add.fields}
      onSubmit={onSubmit}
      buttonText='أضف'
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(AddTagForm)
