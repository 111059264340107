import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_BRAND,
  GET_BRANDS,
  DELETE_BRAND,
  ADD_BRAND,
  EDIT_BRAND,
} from '../../../../config/constants/ActionTypes'
import {
  getBrandsFailure,
  getBrandsSuccess,
  getBrandFailure,
  getBrandSuccess,
  addBrandSuccess,
  addBrandFailure,
  editBrandSuccess,
  editBrandFailure,
} from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getBrandsRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'brands/paginated',
    params: { ...query, isDeleted: false },
    headers: {
      lite,
      token: `${localStorage.getItem('token')}`,
    },
  })
}

function* getBrandsSaga({ payload }) {
  try {
    const response = yield call(getBrandsRequest, payload)
    yield put(getBrandsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getBrandsFailure(error))
    payload.onFailure(error)
  }
}

const getBrandRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'brands/brand',
    params: { _id: query._id },
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getBrandSaga({ payload }) {
  try {
    const response = yield call(getBrandRequest, payload)
    yield put(getBrandSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getBrandFailure(error))
    payload.onFailure(error)
  }
}

const deleteBrandRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'brands/',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteBrandSaga({ payload }) {
  try {
    yield call(deleteBrandRequest, payload)
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addBrandRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'brands/',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addBrandSaga({ payload }) {
  try {
    const response = yield call(addBrandRequest, payload)
    yield put(addBrandSuccess(response.data.brand))
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    yield put(addBrandFailure(error))
    payload.onFailure(error)
  }
}

const editBrandRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'brands/',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* editBrandSaga({ payload }) {
  try {
    const response = yield call(editBrandRequest, payload)
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      yield put(editBrandSuccess(response.data.brand))
      payload.onSuccess(response.data.brand)
    }
  } catch (error) {
    yield put(editBrandFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_BRAND, getBrandSaga)],
    [yield takeEvery(GET_BRANDS, getBrandsSaga)],
    [yield takeEvery(DELETE_BRAND, deleteBrandSaga)],
    [yield takeEvery(ADD_BRAND, addBrandSaga)],
    [yield takeEvery(EDIT_BRAND, editBrandSaga)],
  )
}
