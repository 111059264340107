import {
  GET_BUNDLE_OFFERS,
  GET_BUNDLE_OFFERS_SUCCESS,
  GET_BUNDLE_OFFERS_FAILURE,
  DELETE_BUNDLE_OFFER,
  ADD_BUNDLE_OFFER,
  UPDATE_BUNDLE_OFFER,
  GET_BUNDLE_OFFER,
} from '../../../../config/constants/ActionTypes'

//Get BundleOffers Request
export const getBundleOffers = (payload) => {
  return {
    type: GET_BUNDLE_OFFERS,
    payload,
  }
}

//Get BundleOffers Success
export const getBundleOffersSuccess = (bundleOffers) => {
  return {
    type: GET_BUNDLE_OFFERS_SUCCESS,
    payload: { bundleOffers },
  }
}

//Get BundleOffers Failure
export const getBundleOffersFailure = (error) => {
  return {
    type: GET_BUNDLE_OFFERS_FAILURE,
    payload: { error },
  }
}

//Get BundleOffer Request
export const getBundleOffer = (payload) => {
  return {
    type: GET_BUNDLE_OFFER,
    payload,
  }
}

//Delete BundleOffer
export const deleteBundleOffer = (payload) => {
  return {
    type: DELETE_BUNDLE_OFFER,
    payload,
  }
}

//Add BundleOffer
export const addBundleOffer = (payload) => {
  return {
    type: ADD_BUNDLE_OFFER,
    payload,
  }
}

//update BundleOffer
export const updateBundleOffer = (payload) => {
  return {
    type: UPDATE_BUNDLE_OFFER,
    payload,
  }
}
