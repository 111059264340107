/* eslint-disable no-sparse-arrays */
import { tableFields, fields, formFields } from '../schema'
import {
  getSubCategories,
  deleteSubCategory,
  addSubCategory,
  updateSubCategory,
} from '../../../modules/SubCategory/store/actions'
import { getCategories } from '../../../modules/Category/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const subCategories = {
  view: {
    get: getSubCategories,
    delete: deleteSubCategory,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.category'], 'category.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addSubCategory,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.textArea({
        title: arabic_labels['form.description'],
        dataIndex: 'description.ar',
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      formFields.select(
        'القسم',
        'category',
        'default',
        { query: getCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
      ),
    ],
  },
  edit: {
    update: updateSubCategory,
    get: getSubCategories,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      ,
      fields.object({
        title: arabic_labels['form.description'],
        dataIndex: 'description',
        fields: [fields.textArea({ title: '', dataIndex: 'ar' })],
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.object({
        title: arabic_labels['form.category'],
        dataIndex: 'category',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCategories },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
          ),
        ],
      }),
    ],
  },
}
