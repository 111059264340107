import {
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER,
  UPDATE_SELLER,
} from '../../../../config/constants/ActionTypes'
import { SIGNOUT_USER } from '../../../../config/constants/ActionTypes'
// import mapRoutes from '../../routes/mapRoutes'
// import { repRoutes } from '../../routes/repRoutes'

export const userSignIn = ({ email, password, type, accountPhoneNumber, onSuccess, onFailure }) => {
  return {
    type: SIGNIN_USER,
    payload: {
      email,
      password,
      type,
      accountPhoneNumber,
      onSuccess,
      onFailure,
    },
  }
}

export const userSignOut = () => {
  localStorage.removeItem('token')
  return {
    type: SIGNOUT_USER,
  }
}

export const userSignInSuccess = (authUser) => {
  // let role = {}
  // if (get(authUser, 'sellerRef')) {
  //   // staff
  //   role = get(authUser, 'sellerRef.roles', []).find((role) => {
  //     return get(role, '_id') === get(authUser, 'role')
  //   })
  // }
  // let routes = []
  // for (let index = 0; index < get(role, 'actions', []).length; index++) {
  //   const action = role.actions[index]
  //   let route = get(mapRoutes[action.name.ar], 'url')
  //   if (route) routes.push(route)
  // }
  // if (get(authUser, 'accountPhoneNumber')) {
  //   // rep
  //   routes = repRoutes
  //   role.actions = []
  // }
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: { user: authUser },
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  }
}

export const updateSeller = (user) => {
  return {
    type: UPDATE_SELLER,
    payload: user,
  }
}
