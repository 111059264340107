import { GET_STAFFS_SUCCESS, GET_STAFFS_FAILURE } from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_STAFFS_SUCCESS: {
      return {
        ...state,
        staffs: action.payload.staffs,
      }
    }

    case GET_STAFFS_FAILURE: {
      return {
        ...state,
        getStaffsError: action.payload.error,
      }
    }

    default:
      return state
  }
}
