import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { isEmpty } from 'ramda'
import get from 'lodash/get'
import { uploadImages } from '../../../../config/Client/actions/Upload'

const AddAdForm = (props) => {
  const history = useHistory()
  const location = useLocation()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState(false)
  const [fields, setFields] = useState(config.ads.add.fields)
  const query = queryString.parse(location.search)
  useEffect(() => {
    if (!get(props, 'state.auth.user.staff.isSuperAdmin', false))
      setFields(config.ads.add.fields.filter((field) => 
        (field.dataIndex !== 'isActive')))
    else 
      setFields(config.ads.add.fields)
  },[get(props, 'state.auth.user.staff.isSuperAdmin')])// eslint-disable-line
  
  useEffect(() => {
    setValues(isEmpty(query) ? undefined : query)
  }, []) // eslint-disable-line

  const onSubmit = async (values) => {
    setSubmitting(true)
    props.dispatch(config.ads.add.create({ body: values, onSuccess, onFailure }))
  }

  const onSuccess = (id, imageFiles) => {
    if (imageFiles) {
      props.dispatch(uploadImages({
        imageArray: imageFiles,
        moduleName: 'commercials',
        _id: id,
        onSuccess: onUploadSuccess,
        onFailure,
      }))
    } else {
      setSubmitting(false)
      message.success('تمت الاضافة بنجاح')
      history.push(`/ads${location.search}`)
    }
  }

  const onUploadSuccess = (imageLinkArray, values, id) => {
    props.dispatch(config.ads.edit.update({
      body: { image: imageLinkArray, _id: id },
      onSuccess: onEditSuccess,
      onFailure
    }))
  }

  const onEditSuccess = () => {
    setSubmitting(false)
    message.success('تمت الاضافة بنجاح')
    history.push(`/ads${location.search}`)
  }

  const onFailure = () => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  return (
    <CrudForm
      title='أضف اعلان جديد'
      loading={false}
      fields={fields}
      onSubmit={onSubmit}
      buttonText='أضف'
      submitting={submitting}
      initialValues={values}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(AddAdForm)