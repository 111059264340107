import {
  GET_PENDING_BUYERS,
  GET_PENDING_BUYERS_SUCCESS,
  GET_PENDING_BUYERS_FAILURE,
  ACCEPT_BUYER,
  REJECT_BUYER,
} from '../../../../config/constants/ActionTypes'

//Get Pending Buyers Request
export const getPendingBuyers = (payload) => {
  return {
    type: GET_PENDING_BUYERS,
    payload,
  }
}

//Get Pending Buyers Success
export const getPendingBuyersSuccess = (buyers) => {
  return {
    type: GET_PENDING_BUYERS_SUCCESS,
    payload: { buyers },
  }
}

//Get Pending Buyers Failure
export const getPendingBuyersFailure = (error) => {
  return {
    type: GET_PENDING_BUYERS_FAILURE,
    payload: { error },
  }
}

//accept Buyers
export const acceptBuyer = (payload) => {
  return {
    type: ACCEPT_BUYER,
    payload,
  }
}

//reject Buyers
export const rejectBuyer = (payload) => {
  return {
    type: REJECT_BUYER,
    payload,
  }
}
