import { tableFields, fields, formFields } from '../schema'
import {
  getSubTypes,
  deleteSubType,
  addSubType,
  updateSubType,
} from '../../../modules/SubType/store/actions'
import { getTypes } from '../../../modules/Type/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'
export const subTypes = {
  view: {
    get: getSubTypes,
    delete: deleteSubType,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.type'], 'type.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addSubType,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.textArea({
        title: arabic_labels['form.description'],
        dataIndex: 'description.ar',
      }),
      formFields.select(
        ' المجموعه الرئيسيه',
        'type',
        'default',
        { query: getTypes },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
      ),
    ],
  },
  edit: {
    update: updateSubType,
    get: getSubTypes,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.description'],
        dataIndex: 'description',
        fields: [fields.textArea({ title: '', dataIndex: 'ar' })],
      }),
      fields.object({
        title: arabic_labels['form.type'],
        dataIndex: 'type',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getTypes },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
          ),
        ],
      }),
    ],
  },
}
