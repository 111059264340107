/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { message } from 'antd'
import { useFormContext } from 'react-hook-form'
import { equals, filter } from 'ramda'
import { connect } from 'react-redux'
import { getCompanyProducts } from '../../../CompanyProduct/store/actions'
const DiscountLabel = ({ value, onChange, dataIndex, dispatch }) => {
  const [discount, setDiscount] = useState()
  const { getValues, watch } = useFormContext()
  const values = getValues()
  const previousValuesRef = useRef()
  useEffect(() => {
    previousValuesRef.current = values
  })
  useEffect(() => {
    if (!equals(value, discount)) applyChange(value)
  }, [value]) // eslint-disable-line
  useEffect(() => {
    if (watch('oldPrice') && watch('optional_2')) {
      // calculate
      let price =
        Number(values['oldPrice']) -
        Number(values['oldPrice']) * (Number(watch('optional_2')) / 100)
      applyChange(price)
    }
  }, [values, watch('oldPrice'), watch('optional_2')]) // eslint-disable-line
  const applyChange = (price) => {
    setDiscount(price)
    if (onChange) onChange(price, dataIndex)
  }
  return (
    <div>
      <label style={{ fontSize: '20px',  color: 'var(--color-form)' }}>{discount}</label>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state,
})
export default connect(mapStateToProps)(DiscountLabel)
