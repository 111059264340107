import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { message } from "antd";
import { config } from "../../../../config/Structure";
import queryString from "query-string";
import { isEmpty } from "ramda";
import CrudForm from "../../../Form";
import { connect } from "react-redux";

const AddPriceOfferForm = ({ dispatch, state }) => {
  const location = useLocation();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [values, setValues] = useState(false);
  const query = queryString.parse(location.search);

  useEffect(() => {
    setValues(isEmpty(query) ? undefined : query);
  }, []); // eslint-disable-line

  const onSubmit = async (values) => {
    setSubmitting(true);
    dispatch(
      config.priceOffers.add.create({ body: values, onSuccess, onFailure })
    );
  };

  const onSuccess = () => {
    setSubmitting(false);
    message.success("تم الإضافة بنجاح");
    history.push(`/priceOffers${location.search}`);
  };

  const onFailure = () => {
    setSubmitting(false);
    message.error("حدث خطأ");
  };

  return (
    <CrudForm
      title="أضف عرض سعر  "
      loading={false}
      fields={config.priceOffers.add.fields}
      onSubmit={onSubmit}
      buttonText="أضف"
      submitting={submitting}
      initialValues={values}
    />
  );
};

const mapStateToProps = (state) => ({
  state,
});

export default connect(mapStateToProps)(AddPriceOfferForm);
