import { tableFields } from '../schema'
import { pathOr } from 'ramda'
import arabic_labels from '../../constants/arabic_labels.json'
import { getStatusFromStatusNumber } from '../../../services/commonServices'
import { getOrdersReport } from '../../../modules/OrdersReportView/store/actions'

export const ordersReport = {
  NoAdd: true,
  NoSearch: true,
  view: {
    get: getOrdersReport,
    fields: [
      tableFields.text(arabic_labels['form.sellerRef'], 'seller.name'),
      tableFields.text(arabic_labels['form.buyer'], 'user.name'),
      tableFields.text(
        arabic_labels['form.status'],
        'status',
        (statusNumber) =>
          arabic_labels[pathOr('-', ['arabicStatus'], getStatusFromStatusNumber(statusNumber))]
      ),
      tableFields.text(arabic_labels['form.total'], 'total', (val) => !!val && parseFloat(val).toFixed(2)),
      tableFields.text(arabic_labels['form.rep'], 'rep.name'),
      tableFields.text(arabic_labels['table.orderDate'], 'createdAt', (val) =>
        val
          ? new Date(val).toLocaleString('ar-eg', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          : '-'
      )    ],
  },
}
