import {
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        subscriptions: action.payload.subscriptions,
      }
    }

    case GET_SUBSCRIPTIONS_FAILURE: {
      return {
        ...state,
        getSubscriptionsError: action.payload.error,
      }
    }

    default:
      return state
  }
}
