/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Col, Row, Form, message, Radio } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import LoginInput from '../LoginInput'
import EnterButton from '../../../Form/components/EnterButton'
import { GlobalContext } from '../../../../../src/GlobalState'

import logo from '../../../../img/logo-new.png'
import logo2 from '../../../../img/logo-new-dark.png'
const RadioGroup = Radio.Group

const LoginForm = (props) => {
  const { state, dispatch } = GlobalContext()

  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const loginSuccess = () => {
    setSubmitting(false)
    message.success('تم تسجيل الدخول بنجاح')
    history.push('/companies')
  }

  const loginFailure = () => {
    setSubmitting(false)
    message.error('أعد المحاولة')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    props.form.validateFields(async (err, values) => {
      if (err) return setSubmitting(false)
      props.onSubmit({
        email: values.email,
        password: values.password,
        type: values.type,
        accountPhoneNumber: values.accountPhoneNumber,
        onSuccess: loginSuccess,
        onFailure: loginFailure,
      })
    })
  }

  return (
    <LoginFormStyle>

      <Row>

        <Col lg={12} md={24} xs={24} >
          <Row className="height"
            type='flex'
            justify='center'
            align='middle'
            style={{ textAlign: 'center' }}
          >
            <Col  className="border-form" style={{ width: '100%'}}>

              <UserMenuItemName>

{state.selectedTheme === 'light' ?  <img src={logo} alt='logo' /> :  <img src={logo2} alt='logo' /> }
</UserMenuItemName>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={24} xs={24} >
        <Row className="height"
            type='flex'
            justify='center'
            align='middle'
            style={{ textAlign: 'center' }}
          >
            <Col>
              <h1>مرحبًا بك مجددًا في ريبزبون</h1>
              <Form onSubmit={onSubmit}>
                <Form.Item key='type'>
                  {props.form.getFieldDecorator('type', {
                    initialValue: 'superadmins',
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(
                    <RadioGroup>
                      <Radio value={'superadmins'}> سوبر ادمن</Radio>
                      <Radio label={'Staff'} value={'staffs'}>
                      شركات وموزعين
                      </Radio>
                      <Radio label={'Rep'} value={'reps'}>
                        مناديب
                      </Radio>
                    </RadioGroup>,
                  )}
                </Form.Item>
              
                <Form.Item className="flexDirection"
                  key={props.form.getFieldValue('type') === 'reps' ? 'accountPhoneNumber' : 'email'}
                  label={props.form.getFieldValue('type') === 'reps' ? 'الهاتف' : 'البريد الإلكتروني'}
                  labelCol={{ xl: 10 }}
                  wrapperCol={{ xl: 12 }}
                
                >
                  {props.form.getFieldDecorator(
                    props.form.getFieldValue('type') === 'reps' ? 'accountPhoneNumber' : 'email',
                    {
                      rules: [
                        {
                          required: true,
                          type: props.form.getFieldValue('type') === 'reps' ? 'string' : 'email',
                          message:
                            props.form.getFieldValue('type') === 'reps'
                              ? 'يرجي ادخال رقم الهاتف!'
                              : 'يرجي ادخال بريد الكتروني',
                        },
                      ],
                    },
                  )(<LoginInput readOnly={submitting} />)}
                </Form.Item>
                <Form.Item className="flexDirection"
                  key='password'
                  label='كلمه السر'
                  labelCol={{ xl: 10 }}
                  wrapperCol={{ xl: 12 }}
                >
                  {props.form.getFieldDecorator('password', {
                    rules: [{ required: true, message: 'كلمة المرور مطلوبة ' }],
                  })(<LoginInput type='password' readOnly={submitting} />)}
                </Form.Item>
                <Form.Item key='enter'>
                  <EnterButton type='submit' htmlType='submit' loading={submitting} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </LoginFormStyle>
  )
}

const LoginFormStyle = styled.div`

label.ant-radio-wrapper {
  float: right;
}
.border-form{
  border-right:1px solid #979797;
}
@media (max-width:767px){
  .border-form{
    border-right:0;
  }
.ant-form-item-label{

  margin-bottom: 5px !important;
  text-align: right;

}
}
padding:0 30px;
.flexDirection{
  display:flex;
  flexDirection:row;
  flexWrap:wrap-reverse;
  flex: 0 1 auto;

  @media (max-width:767px){
    display:inherit;
    flexDirection:inherit;
    flexWrap:inherit;
    flex: inherit;

  }

}



.height{
  height:100vh;
  @media (max-width:768px){
    height:inherit;
  }
}
.ant-form-explain {
  margin: 5px;
  text-align: right;
}
label {
  font-size: 25px !important;

  @media (max-width:767px){
    font-size: 20px !important;
  }
}

label.ant-form-item-required {
  font-size: 22px !important;

  @media (max-width:767px){
    font-size: 18px !important;

  }
}

.ant-radio{
  padding: 4px;

}

.ant-radio-inner {

  width: 20px;
  height: 20px;

}


.ant-radio-inner::after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  background: var(--color-secondary);
 
}


.ant-radio-checked .ant-radio-inner {
  background: var(--color-white);
  border-color: var(--color-secondary);

}
  label {
    color: var(--color-font-primary);
    font-size: 28px;
    text-transform: uppercase;
@media (max-width:767px){
  margin-bottom: 10px;

}
  }

  .ant-form {
    text-align: center;
  }

  img {
    width: 205px;
    margin: 0 auto;

    @media (max-width:767px){
      margin: 25px 0;
      width: 80px;

    }

    @media (max-width:360px){
      margin: 30px 0;
      width: 60px;

    }
  }

  h1 {
    font-size: 35px;
    font-weight: 500;
    color: var(--color-font-primary);
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    margin-bottom: 45px;

    @media (max-width:767px){

      font-size: 27px;
      margin-bottom: 15px;

    }

    @media (max-width:360px){

      font-size: 23px;

    }
  }

  h2 {
    font-size: 55px;
    font-weight: 300;
    color: var(--color-font-primary);
    text-transform: uppercase;
  }
`
const UserMenuItemName = styled.div`
 
`


const FormComponent = Form.create({ name: 'LoginForm' })(LoginForm)
export default FormComponent
