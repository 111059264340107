import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const ButtonInput = ({
  icon,
  title,
  uri,
  size,
  fullWidth,
  variant,
  loading,
  readOnly,
  children,
  style,
  width,
  margin,
  onClick,
}) => {
  const buttonClasses = useButtonStyles({ width, margin })
  const Icon = icon

  return (
    <Fragment>
      <Button
        classes={buttonClasses}
        disabled={false}
        startIcon={Icon ? <Icon style={{ fontSize: 'inherit' }}/> : null}
        disableRipple={false} 
        variant={variant}
        color='primary'
        fullWidth={fullWidth}
        size={size}
        component={uri ? Link : 'button'}
        to={uri}
        onClick={onClick}
        style={style}
      >
        {loading ? <CircularProgress color='inherit' /> : children}
      </Button>
    </Fragment>
  )
}

const useButtonStyles = makeStyles( theme => ({
  root: {
    borderRadius: 8,
    position: 'relative',
    padding: 0,
    height: '3.3rem',
    width: props => props.width,
    margin: props => props.margin,
    fontSize: '1.9rem',
    textTransform: 'uppercase',
    fontFamily: 'Cairo-Regular',
    fontWeight: 400,
    boxShadow: theme.shadows[25],
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  text: {
    boxShadow: theme.shadows[0],
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  sizeSmall: {
    height: '2.2rem',
    fontSize: '1rem',
    width: props => props.width,
    margin: props => props.margin,
    boxShadow: theme.shadows[3],
    '&$text': {
      boxShadow: theme.shadows[0],
    },
  },
  sizeLarge: {
    height: '4.5rem',
    fontSize: '1.8rem',

    width: props => props.width,
    margin: props => props.margin,
    boxShadow: theme.shadows[26],
    '&$text': {
      boxShadow: theme.shadows[0],
    },
  },
}))

export default ButtonInput
