import {
  GET_PRICE_OFFERS,
  GET_PRICE_OFFERS_SUCCESS,
  GET_PRICE_OFFERS_FAILURE,
  DELETE_PRICE_OFFER,
  ADD_PRICE_OFFER,
  UPDATE_PRICE_OFFER,
  GET_PRICE_OFFER,
} from '../../../../config/constants/ActionTypes'

//Get PriceOffers Request
export const getPriceOffers = (payload) => {
  return {
    type: GET_PRICE_OFFERS,
    payload,
  }
}

//Get PriceOffers Success
export const getPriceOffersSuccess = (priceOffers) => {
  return {
    type: GET_PRICE_OFFERS_SUCCESS,
    payload: { priceOffers },
  }
}

//Get PriceOffers Failure
export const getPriceOffersFailure = (error) => {
  return {
    type: GET_PRICE_OFFERS_FAILURE,
    payload: { error },
  }
}

//Get PriceOffer Request
export const getPriceOffer = (payload) => {
  return {
    type: GET_PRICE_OFFER,
    payload,
  }
}

//Delete PriceOffer
export const deletePriceOffer = (payload) => {
  return {
    type: DELETE_PRICE_OFFER,
    payload,
  }
}

//Add PriceOffer
export const addPriceOffer = (payload) => {
  return {
    type: ADD_PRICE_OFFER,
    payload,
  }
}

//update PriceOffer
export const updatePriceOffer = (payload) => {
  return {
    type: UPDATE_PRICE_OFFER,
    payload,
  }
}
