import React, {useEffect, useState, forwardRef, useCallback} from 'react'
import { equals } from 'ramda'
import Grid from '@material-ui/core/Grid'
import componentMapper from '../../../../config/Structure/componentMapper'

const TripleInput = forwardRef(({
  value,
  onChange,
  dataIndex,
  firstField,
  secondField,
  thirdField,
  readOnly
}, ref) => {
  const [state, setState] = useState({
    [firstField.dataIndex]: value ? value[firstField.dataIndex] : undefined,
    [secondField.dataIndex]: value ? value[secondField.dataIndex] : undefined,
    [thirdField.dataIndex]: value ? value[thirdField.dataIndex] : undefined,
  })

  useEffect(() => {
    if (value && !equals(value, state)) applyChange(value)
  }, [value]) // eslint-disable-line

  const applyChange = useCallback((val) => {
    setState(val)
    if (onChange) onChange(val, dataIndex)
  }, [onChange, dataIndex])

  const handleValue1Change = useCallback((val) => {
    applyChange({ ...state, [firstField.dataIndex]: val })
  }, [applyChange, state, firstField.dataIndex])

  const handleValue2Change = useCallback((val) => {
    applyChange({ ...state, [secondField.dataIndex]: val })
  }, [applyChange, state, secondField.dataIndex])


  const handleValue3Change = useCallback((val) => {
    applyChange({ ...state, [thirdField.dataIndex]: val })
  }, [applyChange, state, thirdField.dataIndex])

  const FirstField = componentMapper[firstField.type]
  const SecondField = componentMapper[secondField.type]
  const ThirdField = componentMapper[thirdField.type]

  return (
    <Grid container alignItems='center' ref={ref} spacing={1}>
      <Grid item xs={4}>
        <FirstField { ...firstField } readOnly={readOnly || firstField.readOnly} onChange={handleValue1Change} value={state[firstField.dataIndex]} />
      </Grid>
      <Grid item xs={4}>
        <SecondField { ...secondField } readOnly={readOnly || secondField.readOnly} onChange={handleValue2Change} value={state[secondField.dataIndex]} />
      </Grid>
      <Grid item xs={4}>
        <ThirdField { ...thirdField } readOnly={readOnly || thirdField.readOnly} onChange={handleValue3Change} value={state[thirdField.dataIndex]} />
      </Grid>
    </Grid>
  )
})

export default TripleInput
