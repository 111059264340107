import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_STAFFS,
  GET_STAFF,
  DELETE_STAFF,
  ADD_STAFF,
  UPDATE_STAFF,
} from '../../../../config/constants/ActionTypes'
import { getStaffs, getStaffsSuccess, getStaffsFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'
import { path } from 'ramda'

const getStaffsRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'staffs',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
  })
}

function* getStaffsSaga({ payload }) {
  try {
    if (payload.query?.sellerCategory){
      delete payload.query.sellerCategory
    } 
    if (payload.query?.seller){
      delete payload.query.seller
    }
    const response = yield call(getStaffsRequest, payload)
    yield put(getStaffsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getStaffsFailure(error))
    payload.onFailure(error)
  }
}

const getStaffRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'staffs/singleStaff',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
  })
}

function* getStaffSaga({ payload }) {
  try {
    const response = yield call(getStaffRequest, payload)
    yield put(getStaffsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getStaffsFailure(error))
    payload.onFailure(error)
  }
}

const deleteStaffRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'staffs',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteStaffSaga({ payload }) {
  try {
    const response = yield call(deleteStaffRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addStaffRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'staffs',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addStaffSaga({ payload }) {
  try {
    const response = yield call(addStaffRequest, payload)
    payload.onSuccess(path(['data', '_id'], response))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateStaffRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'staffs',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateStaffSaga({ payload }) {
  try {
    const response = yield call(updateStaffRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getStaffs(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_STAFFS, getStaffsSaga)],
    [yield takeEvery(DELETE_STAFF, deleteStaffSaga)],
    [yield takeEvery(ADD_STAFF, addStaffSaga)],
    [yield takeEvery(UPDATE_STAFF, updateStaffSaga)],
    [yield takeEvery(GET_STAFF, getStaffSaga)],
  )
}
