import React, { useState, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
import { useForm, FormContext } from 'react-hook-form'
import queryString from 'query-string'
import AddButton from '../../../Form/components/AddButton'
import Dialog from '../../../View/components/Dialog'
import { Icon } from 'antd'
import { FaCheck } from 'react-icons/fa'
import componentMapper from '../../../../config/Structure/componentMapper'
import arabic_labels from '../../../../config/constants/arabic_labels.json'
import { getBuyerCategories } from '../../../BuyerCategory/store/actions'
import { acceptBuyer } from '../../store/actions'

const AntdSelect = componentMapper['antdSelect']

const AcceptBuyer = ({ height, record, dispatch, onChange, text }) => {
  const location = useLocation()
  const { getValues, watch } = useForm()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const query = queryString.parse(location.search, { arrayFormat: 'comma' })
  const [value, setValue] = useState()


  const handleOnClick = () => {
    dispatch(acceptBuyer({
      query: {
        branches: query?.branches,
      },
      body: {
        _id: query?.branches,
        buyer: {
          _id: record?._id,
          address: { zone: { _id: record?.address?.zone?._id } },
          buyerCategory: value,
        }
      }, onSuccess, onFailure
    }))
  }

  const onSuccess = () => {
    setSubmitting(false)
    setDialogOpen(false)
    if (onChange) onChange()
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }


  return (
    <div>
      <Icon style={{ height }} onClick={() => setDialogOpen(true)}> <FaCheck /> </Icon>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} title={'إختر قطاع المشتري'} contentWidth={'50%'}>
        {<Fragment>
          <FormContext getValues={getValues} watch={watch}>
            <form>
              <AntdSelect
                childKeyPath={['_id']}
                mode={'default'}
                source={{ query: getBuyerCategories }}
                onChange={(e) => setValue(e)} />
              <AddButton style={{ marginTop: '20px', width: '27%' }} text={arabic_labels['status.accept']} loading={submitting} onClick={() => handleOnClick()} disabled={!value} />
            </form>
          </FormContext>
          <br />
        </Fragment>
        }
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(AcceptBuyer)

