import {
  GET_DISTRICTS,
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAILURE,
  DELETE_DISTRICT,
  ADD_DISTRICT,
  UPDATE_DISTRICT,
} from '../../../../config/constants/ActionTypes'

//Get DISTRICTS Request
export const getDistricts = (payload) => {
  return {
    type: GET_DISTRICTS,
    payload,
  }
}

//Get Districts Success
export const getDistrictsSuccess = (districts) => {
  return {
    type: GET_DISTRICTS_SUCCESS,
    payload: { districts },
  }
}

//Get Districts Failure
export const getDistrictsFailure = (error) => {
  return {
    type: GET_DISTRICTS_FAILURE,
    payload: { error },
  }
}

//Delete District
export const deleteDistrict = (payload) => {
  return {
    type: DELETE_DISTRICT,
    payload,
  }
}

//Add District
export const addDistrict = (payload) => {
  return {
    type: ADD_DISTRICT,
    payload,
  }
}

//update Districts
export const updateDistrict = (payload) => {
  return {
    type: UPDATE_DISTRICT,
    payload,
  }
}
