/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { path } from 'ramda'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import { uploadImages } from '../../../../config/Client/actions/Upload'
import queryString from 'query-string'

const EditAdForm = ({ dispatch, state }) => {
  const { ads } = config
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const query = queryString.parse(location.search)
  useEffect(() => {
      config.ads.edit.fields.map((field) => {
        if (field.dataIndex === 'isActive') field.readOnly = !get(state, 'auth.user.staff.isSuperAdmin', false)
        return field
      })
  }, [get(state, 'auth.user.staff.isSuperAdmin')])
  useEffect(() => {
    setLoading(true)
    dispatch(
      config.ads.edit.get({
        query: { _id: id },
        onSuccess: onFetchSuccess,
        onFailure: onFetchFailure,
      })
    )
  }, []) // eslint-disable-line

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(
      uploadImages({
        values,
        imageArray: values.image,
        moduleName: 'commercials',
        _id: id,
        onSuccess: onUploadSuccess,
        onFailure,
      })
    )
  }

  const onUploadSuccess = (imageLinkArray, values) => {
    values.image = imageLinkArray
    dispatch(ads.edit.update({ body: { ...values, _id: id }, onSuccess, onFailure }))
  }

  const onSuccess = () => {
    setSubmitting(false)
    message.success('تم التعديل بنجاح')
    history.push(`/ads${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  const onFetchSuccess = (records) => {
    setLoading(false)
    if (query['company'] && path(['company', '_id'], records.data[Object.keys(records.data)[0]]) !== query['company']) {
      return history.push('/companies')
    }
    setData(records.data[Object.keys(records.data)[0]])
  }

  const onFetchFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }

  return (
    <CrudForm
      title='تعديل الاعلان'
      loading={loading}
      fields={config.ads.edit.fields}
      onSubmit={onSubmit}
      buttonText='تعديل'
      initialValues={data}
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(EditAdForm)
