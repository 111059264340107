import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const Staffs = () => {
  return (
    <div>
      <TableHolder>
      <View componentName='staffs' config={config.staff} />

</TableHolder>
    </div>
  )
}

export default Staffs


const TableHolder  = styled.div`
@media (max-width:767px){
  td:nth-child(5), td:nth-child(6)  {
    display: inline-block;
    text-align: center;
  }

}

`