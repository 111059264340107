import { all } from 'redux-saga/effects'
import uploadSaga from './Upload'
import authSagas from '../../../modules/Auth/store/sagas'
import companySagas from '../../../modules/Company/store/sagas'
import brandSagas from '../../../modules/Brand/store/sagas'
import sectionSagas from '../../../modules/Section/store/sagas'
import categorySagas from '../../../modules/Category/store/sagas'
import subCategorySagas from '../../../modules/SubCategory/store/sagas'
import size from '../../../modules/Size/store/sagas'
import typeSagas from '../../../modules/Type/store/sagas'
import packageUnitSagas from '../../../modules/PackageUnit/store/sagas'
import tagSagas from '../../../modules/Tag/store/sagas'
import subTypeSagas from '../../../modules/SubType/store/sagas'
import citySagas from '../../../modules/City/store/sagas'
import districtSagas from '../../../modules/District/store/sagas'
import countrySagas from '../../../modules/Country/store/sagas'
import zoneSagas from '../../../modules/Zone/store/sagas'
import buyerCategorySagas from '../../../modules/BuyerCategory/store/sagas'
import subscriptionSagas from '../../../modules/Subscription/store/sagas'
import roleSagas from '../../../modules/Role/store/sagas'
import superadminSagas from '../../../modules/Superadmin/store/sagas'
import packageSagas from '../../../modules/Package/store/sagas'
import distributorSagas from '../../../modules/Distributor/store/sagas'
import buyerSagas from '../../../modules/Buyer/store/sagas'
import staffSagas from '../../../modules/Staff/store/sagas'
import repSagas from '../../../modules/Rep/store/sagas'
import orderSagas from '../../../modules/Order/store/sagas'
import companyProductSagas from '../../../modules/CompanyProduct/store/sagas'
import priceOfferSagas from '../../../modules/PriceOffer/store/sagas'
import quantityOfferSagas from '../../../modules/QuantityOffer/store/sagas'
import bundleOfferSagas from '../../../modules/BundleOffer/store/sagas'
import pendingBuyersSagas from '../../../modules/PendingBuyers/store/sagas'
import priceListsSaga from '../../../modules/PriceList/store/sagas'
import adSagas from '../../../modules/Ad/store/sagas'
import ordersReportSagas from '../../../modules/OrdersReportView/store/sagas'
import settingSagas from '../../../modules/Setting/store/sagas'
import exportDataSagas from '../../../modules/ExportData/store/sagas'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    companySagas(),
    brandSagas(),
    sectionSagas(),
    categorySagas(),
    subCategorySagas(),
    size(),
    typeSagas(),
    packageUnitSagas(),
    subTypeSagas(),
    citySagas(),
    districtSagas(),
    countrySagas(),
    zoneSagas(),
    buyerCategorySagas(),
    tagSagas(),
    subscriptionSagas(),
    roleSagas(),
    superadminSagas(),
    packageSagas(),
    distributorSagas(),
    buyerSagas(),
    staffSagas(),
    repSagas(),
    orderSagas(),
    companyProductSagas(),
    priceOfferSagas(),
    quantityOfferSagas(),
    bundleOfferSagas(),
    uploadSaga(),
    pendingBuyersSagas(),
    priceListsSaga(),
    adSagas(),
    ordersReportSagas(),
    settingSagas(),
    exportDataSagas()
  ])
}
