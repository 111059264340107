import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_PACKAGES,
  GET_ALL_PACKAGES_ID,
  DELETE_PACKAGE,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
} from '../../../../config/constants/ActionTypes'
import { getPackages, getPackagesSuccess, getPackagesFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getPackagesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'packages',
    params: { ...query, isDeleted: false },
    headers: {
      lite,
      token: `${localStorage.getItem('token')}`,
    },
  })
}

function* getPackagesSaga({ payload }) {
  try {
    const response = yield call(getPackagesRequest, payload)
    yield put(getPackagesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getPackagesFailure(error))
    payload.onFailure(error)
  }
}

const getAllPackagesIdRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'packages/allPackagesId',
    params: { ...query, isDeleted: false },
    headers: {
      lite,
      token: `${localStorage.getItem('token')}`,
    },
  })
}

function* getAllPackagesIdSaga({ payload }) {
  try {
    const response = yield call(getAllPackagesIdRequest, payload)
    yield put(getPackagesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getPackagesFailure(error))
    payload.onFailure(error)
  }
}

const deletePackageRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'packages',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deletePackageSaga({ payload }) {
  try {
    const response = yield call(deletePackageRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addPackageRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'packages',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addPackageSaga({ payload }) {
  try {
    const response = yield call(addPackageRequest, payload)
    payload.onSuccess(path(['data', '_id'], response))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updatePackageRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'packages',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updatePackageSaga({ payload }) {
  try {
    const response = yield call(updatePackageRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getPackages(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_PACKAGES, getPackagesSaga)],
    [yield takeEvery(GET_ALL_PACKAGES_ID, getAllPackagesIdSaga)],
    [yield takeEvery(DELETE_PACKAGE, deletePackageSaga)],
    [yield takeEvery(ADD_PACKAGE, addPackageSaga)],
    [yield takeEvery(UPDATE_PACKAGE, updatePackageSaga)],
  )
}
