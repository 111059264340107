import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_TYPES,
  DELETE_TYPE,
  ADD_TYPE,
  UPDATE_TYPE,
} from '../../../../config/constants/ActionTypes'
import { getTypes, getTypesSuccess, getTypesFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getTypesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'types/paginated',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
    headers: { lite },
  })
}

function* getTypesSaga({ payload }) {
  try {
    const response = yield call(getTypesRequest, payload)
    yield put(getTypesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getTypesFailure(error))
    payload.onFailure(error)
  }
}

const deleteTypeRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'types',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteTypeSaga({ payload }) {
  try {
    const response = yield call(deleteTypeRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addTypeRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'types',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addTypeSaga({ payload }) {
  try {
    const response = yield call(addTypeRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateTypeRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'types',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateTypeSaga({ payload }) {
  try {
    const response = yield call(updateTypeRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getTypes(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_TYPES, getTypesSaga)],
    [yield takeEvery(DELETE_TYPE, deleteTypeSaga)],
    [yield takeEvery(ADD_TYPE, addTypeSaga)],
    [yield takeEvery(UPDATE_TYPE, updateTypeSaga)],
  )
}
