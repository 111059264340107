import React, { Fragment } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useTheme, makeStyles } from '@material-ui/core/styles'

export default function DialogComponent(props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dialogClasses = useDialogStyles()
  const dialogTitleClasses = useDialogTitleStyles()
  const dialogTitleTypographyClasses = useDialogTitleTypographyStyles()
  const iconButtonClasses = useIconButtonStyles()

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={() => props.onClose()}
        fullWidth
        classes={dialogClasses}
        scroll='paper'
        disableBackdropClick={true}
      >
        <DialogTitle classes={dialogTitleClasses} style={{ textAlign: 'right' }}>
          <IconButton classes={iconButtonClasses} onClick={() => props.onClose()} style={{ left: 0, right: 'inherit' }}>
            <CloseIcon />
          </IconButton>
          <Typography classes={dialogTitleTypographyClasses} >{props.title}</Typography>
        </DialogTitle>

        <DialogContent style={{ width: props.contentWidth, height: props.contentHeight, paddingTop: props.contentTopSpacing, margin: '0 auto' }}>
          {props.children}
        </DialogContent>

        <DialogActions>
          {props.actions}
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

const useDialogStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    borderRadius: 8,
    background: theme.palette.background,
    fontFamily: 'inherit',
  },
}))

const useDialogTitleStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    textTransform: 'uppercase',
    fontFamily: 'Cairo-Regular',
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    letterSpacing: '0',
    backgroundColor: theme.palette.secondary.main,
  },
}))

const useDialogTitleTypographyStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    textTransform: 'uppercase',
    fontFamily: 'Cairo-Regular',
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    letterSpacing: '0',
  },
}))

const useIconButtonStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}))
