import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
// import useAddForm from '../../../Form/components/useFormHelpers/useAddForm'
import { uploadImages } from '../../../../config/Client/actions/Upload'

const AddBuyerCategoryForm = ({ dispatch }) => {
  const { buyerCategories } = config
  const location = useLocation()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(buyerCategories.add.create({ body: values, onSuccess, onFailure }))
  }

  const onSuccess = (id, imageFiles) => {
    if (imageFiles && imageFiles.length > 0) {
      dispatch(uploadImages({
        imageArray: imageFiles,
        moduleName: 'buyerCategories',
        _id: id,
        onSuccess: onUploadSuccess,
        onFailure,
      }))
    } else {
      setSubmitting(false)
      message.success('تمت الاضافة بنجاح')
      history.push(`/buyerCategories${location.search}`)
    }
  }

  const onUploadSuccess = (imageLinkArray, values, id) => {
    dispatch(buyerCategories.edit.update({
      body: { images: imageLinkArray, _id: id },
      onSuccess: onEditSuccess,
      onFailure
    }))
  }

  const onEditSuccess = () => {
    setSubmitting(false)
    message.success('تمت الاضافة بنجاح')
    history.push(`/buyerCategories${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  return (
    <CrudForm
      title='أضف قطاع جديد'
      loading={false}
      fields={config.buyerCategories.add.fields}
      onSubmit={onSubmit}
      buttonText='أضف'
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(AddBuyerCategoryForm)
