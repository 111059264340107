import { tableFields, fields, formFields } from '../schema'
import {
  getDistributors,
  deleteDistributor,
  addDistributor,
  updateDistributor,
} from '../../../modules/Distributor/store/actions'
import ZonesTreeSelect from '../../../modules/Distributor/components/ZonesTreeSelect'
import { getCities } from '../../../modules/City/store/actions'
import { getDistricts } from '../../../modules/District/store/actions'
import { getSubscriptions } from '../../../modules/Subscription/store/actions'
import { getCompanies } from '../../../modules/Company/store/actions'
import { getZones } from '../../../modules/Zone/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'
const logo = require('../../../img/logo-new.png')

export const distributors = {
  view: {
    get: getDistributors,
    delete: deleteDistributor,
    fields: [
      tableFields.image(
        arabic_labels['form.logo'],
        'images[0]',
        value => value || logo
      ),
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.company'], 'company.name.ar'),
      tableFields.text(arabic_labels['form.phone'], 'phone'),
      tableFields.text(arabic_labels['form.email'], 'email'),
      tableFields.text(
        arabic_labels['form.subscription'],
        'subscription.name.ar'
      ),
      tableFields.action.goTo(
        arabic_labels['form.details'],
        'distributor',
        '/distributors/view/{key}'
      ),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addDistributor,
    fields: [
      fields.checkbox({
        title: arabic_labels['form.isBranch'],
        dataIndex: 'isBranch',
      }),
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: fieldValue =>
          !path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true,
      }),
      fields.text({
        title: arabic_labels['form.email'],
        dataIndex: 'email',
        validate: fieldValue =>
          !fieldValue ? 'برجاء إدخال البريد الألكتروني' : true,
      }),
      fields.text({
        title: arabic_labels['form.phone'],
        dataIndex: 'phone',
        validate: fieldValue =>
          !fieldValue ? 'برجاء إدخال رقم المحمول' : true,
      }),
      fields.object({
        title: 'العنوان',
        dataIndex: 'address',
        fields: [
          fields.select({
            title: 'نوع العنوان',
            dataIndex: 'type',
            options: ['شركة', 'مخزن', 'مصنع', 'مكتب'],
          }),
          formFields.select(
            'المدينه',
            'city',
            'default',
            { query: getCities },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المدينه' }],
            null,
            null
          ),
          formFields.select(
            'الحي',
            'district',
            'default',
            { query: getDistricts },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار الحي' }],
            'address.city',
            'city'
          ),
          formFields.select(
            'المنطقه',
            'zone',
            'default',
            { query: getZones },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار المنطقه' }],
            'address.district',
            'district'
          ),
          fields.text({ title: 'الشارع', dataIndex: 'street' }),
          fields.text({ title: 'رقم المبني', dataIndex: 'buildingNumber' }),
          fields.text({
            title: 'تليفون العنوان',
            dataIndex: 'phone',
            validate: fieldValue =>
              !fieldValue ? 'برجاء إدخال تليفون العنوان' : true,
          }),
          fields.text({ title: 'خرائط جوجل', dataIndex: 'googleMapLocation' }),
        ],
        validate: fieldValue =>
          !path(['city'], fieldValue) ? 'لا بد من إختيارالمدينه' : true,
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      formFields.select(
        'الشركة',
        'company',
        'default',
        { query: getCompanies },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الشركة' }],
        null,
        null,
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الشركة' : true),
      ),

      //manate8 al bai3
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال المنطقة' : true),
      }),

      //---------------

      formFields.select(
        'الأشتراك',
        'subscription',
        'default',
        { query: getSubscriptions },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'subscription',
        }
      ),
      fields.checkbox({
        title: arabic_labels['form.status'],
        dataIndex: 'status',
      }),
    ],
  },
  edit: {
    update: updateDistributor,
    get: getDistributors,
    fields: [
      fields.checkbox({
        title: arabic_labels['form.isBranch'],
        dataIndex: 'isBranch',
      }),
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: fieldValue =>
          !path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true,
      }),
      fields.text({
        title: arabic_labels['form.email'],
        dataIndex: 'email',
        validate: fieldValue =>
          !fieldValue ? 'برجاء إدخال البريد الألكتروني' : true,
      }),
      fields.text({
        title: arabic_labels['form.phone'],
        dataIndex: 'phone',
        validate: fieldValue =>
          !fieldValue ? 'برجاء إدخال رقم المحمول' : true,
      }),
      fields.object({
        title: 'العنوان',
        dataIndex: 'address',
        fields: [
          fields.select({
            title: 'نوع العنوان',
            dataIndex: 'type',
            options: ['شركة', 'مخزن', 'مصنع', 'مكتب'],
          }),
          formFields.select(
            'المدينه',
            'city',
            'default',
            { query: getCities },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المدينه' }],
            null,
            null
          ),
          formFields.select(
            'الحي',
            'district',
            'default',
            { query: getDistricts },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار الحي' }],
            'address.city',
            'city'
          ),
          formFields.select(
            'المنطقه',
            'zone',
            'default',
            { query: getZones },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار المنطقه' }],
            'address.district',
            'district'
          ),
          fields.text({ title: 'الشارع', dataIndex: 'street' }),
          fields.text({ title: 'رقم المبني', dataIndex: 'buildingNumber' }),
          fields.text({ title: 'تليفون العنوان', dataIndex: 'phone' }),
          fields.text({ title: 'خرائط جوجل', dataIndex: 'googleMapLocation' }),
        ],
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.object({
        title: 'الشركة',
        dataIndex: 'company',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCompanies },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            'company'
          ),
        ],
      }),

      //manate8 al bai3

      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال المنطقة' : true),
      }),

      //----------------

      fields.object({
        title: 'الأشتراك',
        dataIndex: 'subscription',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getSubscriptions },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            {
              key: 'subscription',
            }
          ),
        ],
      }),
      fields.checkbox({
        title: arabic_labels['form.status'],
        dataIndex: 'status',
      }),
    ],
  },
}
