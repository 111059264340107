/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
import {
  FaArchive,
  FaFileInvoiceDollar,
  FaSms,
  FaShoppingBag,
  FaShoppingBasket,
  FaFileAlt,
  FaRegIdCard,
  FaBars,
  FaCoins,
  FaUsers,
  FaCodeBranch,
  FaSitemap,
  FaStore,
  FaLayerGroup,
  FaAddressCard,
  FaSlidersH,
  FaBuilding,
  FaBoxOpen,
  FaRulerCombined,
  FaAd,
  FaDollarSign,
} from 'react-icons/fa'

import { MdLocalOffer, MdBrandingWatermark,MdSettings } from 'react-icons/md'

import DescriptionIcon from '@material-ui/icons/Description'
import ListAltIcon from '@material-ui/icons/ListAlt'

export const sidebar = [
  {
    name: 'الشركات',
    icon: FaBuilding,
    link: '/companies',
    availableTo: ['superAdmin'],
  },
  {
    name: 'منتجات الشركة',
    icon: FaShoppingBasket,
    link: '/companyProducts',
    availableTo: ['superAdmin', 'company', 'distributor'],
  },
  {
    name: 'الموزعين',
    icon: FaCodeBranch,
    link: '/distributors',
    availableTo: ['superAdmin', 'company'],
  },
  {
    name: 'المناديب',
    icon: FaSms,
    link: '/reps',
    availableTo: ['superAdmin', 'distributor'],
  },
  {
    name: 'قوائم التسعير',
    icon: ListAltIcon,
    link: '/pricelists',
    availableTo: ['superAdmin', 'company', 'distributor'],
  },
  {
    name: 'عرض سعر',
    icon: MdLocalOffer,
    link: '/priceOffers',
    availableTo: ['superAdmin', 'company', 'distributor'],
  },
  {
    name: 'عرض كمية',
    icon: MdLocalOffer,
    link: '/quantityOffers',
    availableTo: ['superAdmin', 'company', 'distributor'],
  },
  {
    name: 'عرض هدية',
    icon: MdLocalOffer,
    link: '/bundleOffers',
    availableTo: ['superAdmin', 'company', 'distributor'],
  },
  {
    name: 'العملاء',
    icon: FaAddressCard,
    link: '/buyers',
    availableTo: ['superAdmin', 'distributor'],
  },
  {
    name: 'الطلبات',
    icon: DescriptionIcon,
    link: '/orders',
    availableTo: ['superAdmin', 'distributor'],
  },
  {
    name: 'ماركات',
    icon: MdBrandingWatermark,
    link: '/brands',
    availableTo: ['superAdmin', 'company'],
  },

  {
    name: 'الصناعات',
    icon: FaLayerGroup,
    link: '/sections',
    availableTo: ['superAdmin'],
  },
  {
    name: 'الأقسام',
    icon: FaSitemap,
    link: '/categories',
    availableTo: ['superAdmin'],
  },
  {
    name: 'القسم الفرعي',
    icon: FaBoxOpen,
    link: '/subCategories',
    availableTo: ['superAdmin'],
  },

  {
    name: 'المجموعة الرئيسية',
    icon: FaBars,
    link: '/types',
    availableTo: ['superAdmin'],
  },
  {
    name: 'المجموعة الفرعية',
    icon: FaCoins,
    link: '/subTypes',
    availableTo: ['superAdmin'],
  },
  {
    name: 'الأصناف وتوصيفها',
    icon: FaAddressCard,
    link: '/tags',
    availableTo: ['superAdmin'],
  },
  {
    name: 'الأحجام',
    icon: FaRulerCombined,
    link: '/sizes',
    availableTo: ['superAdmin'],
  },
  {
    name: 'وحدات البيع الفرعية',
    icon: FaSlidersH,
    link: '/packageUnits',
    availableTo: ['superAdmin'],
  },
  {
    name: 'وحدات البيع الرئيسية',
    icon: FaArchive,
    link: '/packages',
    availableTo: ['superAdmin'],
  },
  {
    name: 'المراكز',
    icon: FaStore,
    link: '/districts',
    availableTo: ['superAdmin'],
  },
  {
    name: 'المناطق',
    icon: FaFileInvoiceDollar,
    link: '/zones',
    availableTo: ['superAdmin'],
  },
  {
    name: 'القطاعات',
    icon: ListAltIcon,
    link: '/buyerCategories',
    availableTo: ['superAdmin'],
  },
  {
    name: 'الأشتراكات',
    icon: FaUsers,
    link: '/subscriptions',
    availableTo: ['superAdmin'],
  },
  {
    name: 'المهام',
    icon: DescriptionIcon,
    link: '/roles',
    availableTo: ['superAdmin'],
  },
  {
    name: 'سوبر ادمن',
    icon: FaRegIdCard,
    link: '/superadmins',
    availableTo: ['superAdmin'],
  },
  {
    name: 'طاقم العمل',
    icon: FaCodeBranch,
    link: '/staffs',
    availableTo: ['superAdmin', 'company', 'distributor'],
  },
  {
    name: 'home',
    icon: DescriptionIcon,
    link: '/whatever',
    availableTo: ['company', 'distributor', 'rep'],
  },
  {
    name: 'اعلانات',
    icon: FaAd,
    link: '/ads',
    availableTo: ['company', 'superAdmin'],
  },
  {
    name: 'تقرير الطلبات',
    icon: FaDollarSign,
    link: '/orders-report',
    availableTo: ['company','superAdmin'],
  },
  {
    name: 'تقرير الوحدات',
    icon: FaDollarSign,
    link: '/units-report',
    availableTo: ['company','superAdmin'],
  },
  {
    name: 'الإعدادات',
    icon: MdSettings,
    link: '/settings',
    availableTo: ['superAdmin'],
  },
]
