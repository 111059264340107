import {
  GET_ZONES,
  GET_ZONES_NAMES,
  GET_ZONES_SUCCESS,
  GET_ZONES_FAILURE,
  DELETE_ZONE,
  ADD_ZONE,
  UPDATE_ZONE,
  GET_DISTRIBUTOR_ZONES,
} from '../../../../config/constants/ActionTypes'

//Get Zones Request
export const getZones = (payload) => {
  return {
    type: GET_ZONES,
    payload,
  }
}

//Get Zones Names Request
export const getZonesNames = (payload) => {
  return {
    type: GET_ZONES_NAMES,
    payload,
  }
}

//Get Zones Success
export const getZonesSuccess = (zones) => {
  return {
    type: GET_ZONES_SUCCESS,
    payload: { zones },
  }
}

//Get Zones Failure
export const getZonesFailure = (error) => {
  return {
    type: GET_ZONES_FAILURE,
    payload: { error },
  }
}
export const getDistributorZones = (payload) => {
  return {
    type: GET_DISTRIBUTOR_ZONES,
    payload,
  }
}

//Delete Zone
export const deleteZone = (payload) => {
  return {
    type: DELETE_ZONE,
    payload,
  }
}

//Add Zone
export const addZone = (payload) => {
  return {
    type: ADD_ZONE,
    payload,
  }
}

//update Zones
export const updateZone = (payload) => {
  return {
    type: UPDATE_ZONE,
    payload,
  }
}
