import {
  GET_DISTRIBUTORS_SUCCESS,
  GET_DISTRIBUTORS_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_DISTRIBUTORS_SUCCESS: {
      return {
        ...state,
        distributors: action.payload.distributors,
      }
    }

    case GET_DISTRIBUTORS_FAILURE: {
      return {
        ...state,
        getDistributorsError: action.payload.error,
      }
    }
    default:
      return state
  }
}
