import { GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE } from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: action.payload.settings,
      }
    }

    case GET_SETTINGS_FAILURE: {
      return {
        ...state,
        getSettingsError: action.payload.error,
      }
    }

    default:
      return state
  }
}
