import React, { forwardRef, useState, useEffect, Fragment, useCallback } from 'react'
import { equals, reject, isNil } from 'ramda'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import ImageList from '../../../View/components/ImageList'
import { message } from 'antd'

const MyUpload = forwardRef((props, ref) => {
  const [state, setState] = useState(props.multiple ? [] : undefined)
  const [previews, setPreviews] = useState([])
  const [randomId, seRandomId] = useState()
  const classes = useStyles()

  useEffect(() => {
    if (props.value && (typeof props.value === 'string' || Array.isArray(props.value)) && !equals(props.value, state))
      onChange(props.value)
  }, [props.value]) // eslint-disable-line
  useEffect(() => {
    seRandomId(Math.random())
    if (props.onMounted) props.onMounted(props.dataIndex)
  }, []) // eslint-disable-line

  const onChange = (val) => {
    let value
    if (Array.isArray(val)) {
      if (!props.multiple) value = val.pop()
      else value = val
    } else {
      if (!props.multiple) {
        value = val
      } else value = [val]
    }
    setState(value)
    if (props.onChange) props.onChange(val, props.dataIndex)
  }

  const onFileAdded = async (fileList) => {
    if (props.multiple) {
      const files = []
      for (let file of fileList) {
        files.push(file)
      }
      onChange([...state, ...files])
    } else {
      if (props.dimensions) {
        let img = new Image()
        let width, height
        img.src = window.URL.createObjectURL(fileList[0])
        img.onload = () => {
          width = img.naturalWidth
          height = img.naturalHeight
          if (width / height === props.dimensions.aspectWidth / props.dimensions.aspectHeight) onChange(fileList[0])
          else {
            let aspectRatio = `${props.dimensions.aspectWidth} : ${props.dimensions.aspectHeight}`
            const msg1 = `  مقاسات الاعلان يجب ان تكون بنسبة  ` 

            message.error(`${msg1}${aspectRatio}`)
          }
        }
      } else onChange(fileList[0])
    }
  }
  const getBase64 = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const getPreviews = useCallback(
    async (list) => {
      const previewList = [...list]
      for (let i in previewList) {
        if (typeof previewList[i] === 'string') continue
        previewList[i] = await getBase64(previewList[i])
      }
      setPreviews([...previewList])
    },
    [getBase64, setPreviews]
  )

  useEffect(() => {
    if (props.multiple) getPreviews(state)
    else getPreviews(reject(isNil, [state]))
  }, [getPreviews, props.multiple, state])

  return (
    <Fragment>
      <input
        accept='image/*'
        className={classes.input}
        id={randomId}
        multiple={props.multiple}
        type='file'
        onChange={(e) => onFileAdded(e.target.files)}
      />
      <Grid container spacing={1}>
        <Grid item>
          <ImageList list={previews} onChange={onChange} />
        </Grid>
        <Grid item>
          <label htmlFor={randomId}>
            <Button
              variant='outlined'
              className={classes.button}
              color='primary'
              component='span'
              disabled={props.readOnly}
            >
              <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                <AddIcon />
                Upload
              </Box>
            </Button>
          </label>
        </Grid>
      </Grid>
    </Fragment>
  )
})

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  button: {
    width: 100,
    height: 100,
    borderRadius: 8,
  },
}))

export default MyUpload
