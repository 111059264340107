import {
  INIT_URL,
  USER_TOKEN_SET,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  UPDATE_SELLER,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload }
    }

    case SIGNOUT_USER: {
      return {
        ...state,
        token: null,
        user: null,
        initURL: '',
      }
    }

    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        token: action.payload.user.token,
        user: action.payload.user,
      }
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      }
    }
    case UPDATE_SELLER: {
      let user = JSON.parse(JSON.stringify(state.user))
      user.sellerRef = action.payload
      return {
        ...state,
        user,
      }
    }
    default:
      return state
  }
}
