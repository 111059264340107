import { companies } from './modules/companies'
import { brands } from './modules/brands'
import { sections } from './modules/sections'
import { categories } from './modules/categories'
import { subCategories } from './modules/subCategories'
import { sizes } from './modules/sizes'
import { types } from './modules/types'
import { packageUnits } from './modules/packageUnits'
import { districts } from './modules/districts'
import { zones } from './modules/zones'
import { buyerCategories } from './modules/buyerCategories'
import { subTypes } from './modules/subTypes'
import { tags } from './modules/tags'
import { subscriptions } from './modules/subscriptions'
import { roles } from './modules/roles'
import { superadmins } from './modules/superadmins'
import { packages } from './modules/packages'
import { distributors } from './modules/distributors'
import { buyers } from './modules/buyers'
import { staff } from './modules/staff'
import { reps } from './modules/reps'
import { orders } from './modules/orders'
import { companyProducts } from './modules/companyProducts'
import { priceOffers } from './modules/priceOffers'
import { quantityOffers } from './modules/quantityOffers'
import { bundleOffers } from './modules/bundleOffers'
import { pendingBuyers } from './modules/pendingBuyers'
import { pricelists } from './modules/pricelists'
import { ads } from './modules/ads'
import { ordersReport } from './modules/ordersReport'
import { unitsReport } from './modules/unitsReport'
import { settings } from './modules/settings'
import { exportData } from './modules/exportData'

export const config = {
  companies,
  brands,
  sections,
  categories,
  subCategories,
  sizes,
  types,
  packageUnits,
  districts,
  zones,
  buyerCategories,
  subTypes,
  tags,
  subscriptions,
  roles,
  superadmins,
  packages,
  distributors,
  buyers,
  staff,
  reps,
  orders,
  companyProducts,
  priceOffers,
  quantityOffers,
  bundleOffers,
  pendingBuyers,
  pricelists,
  ads,
  ordersReport,
  unitsReport,
  settings,
  exportData,
}
