/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react'
import { path } from 'ramda'
import { connect } from 'react-redux'
import { message } from 'antd'
import AddButton from '../../../Form/components/AddButton'
import Dialog from '../../../View/components/Dialog'
import componentMapper from '../../../../config/Structure/componentMapper'
import { getStatusFromStatusNumber } from '../../../../services/commonServices'
import { updateOrderStatus, updateOrder, rejectOrder, cancelOrder } from '../../store/actions'
import arabic_labels from '../../../../config/constants/arabic_labels.json'

const DatePicker = componentMapper['datePicker']
const today = new Date();
const UpdateStatus = ({ height, record, dispatch, onChange }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [date, setDate] = useState(today)
  const _id = path(['_id'], record)
  const currentStatus = path(['status'], record)

  const handleOnClick = (status) => {
    switch (status) {
      case 0: // pending -> reject / accept
        setDialogOpen(true)
        break
      case 1: // processing -> pack
        nextStep()
        break
      case 2: // ready to ship -> ship
        setDialogOpen(true)
        break
      case 3: // shipped -> deliver
        nextStep()
        break
      case 4: // delivered -> ??
        nextStep()
        break
      case 5: // cancelled -> ??
        break
      case 6: // rejected -> ??
        break
      case 7: // received -> ??
        break
      case 8: // started -> ??
        break
      default: // ??
        break
    }
  }

  const nextStep = () => {
    setSubmitting(true)
    dispatch(updateOrderStatus({ body: { _id }, onSuccess, onFailure }))
  }

  const reject = () => {
    setSubmitting(true)
    dispatch(rejectOrder({ body: { _id }, onSuccess, onFailure }))
  }

  const cancel = () => {
    setSubmitting(true)
    dispatch(cancelOrder({ body: { _id }, onSuccess, onFailure }))
  }

  const updateOrderDate = () => {
    setSubmitting(true)
    dispatch(updateOrder({ body: { _id, deliveryDate: date }, onSuccess: onUpdateOrderSuccess, onFailure }))
  }

  const onUpdateOrderSuccess = () => {
    nextStep()
  }

  const onSuccess = () => {
    setSubmitting(false)
    setDialogOpen(false)
    if (onChange) onChange()
  }

  const onFailure = () => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  let buttonDisabled = !currentStatus || isNaN(currentStatus) || Number(currentStatus) < 0
  if (!path(['btnAction'], getStatusFromStatusNumber(currentStatus))) buttonDisabled = true
  const buttonText = arabic_labels[path(['arabicBtnAction'],  getStatusFromStatusNumber(currentStatus))] ||  arabic_labels[path(['arabicStatus'],  getStatusFromStatusNumber(currentStatus))]

  return (
    <div>
      <AddButton type='button' style={{ height, width: '100%' }} text={buttonText} onClick={() => handleOnClick((Number(currentStatus)))} disabled={buttonDisabled} loading={submitting} />
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} title={buttonText}>
        {
          currentStatus === '0'
          ? <Fragment>
              <br />
              <AddButton disabled={buttonDisabled} text={arabic_labels[path(['arabicBtnAction2'],  getStatusFromStatusNumber(currentStatus))]} loading={submitting} onClick={reject} />
              <AddButton disabled={buttonDisabled} text={arabic_labels[path(['arabicBtnAction1'],  getStatusFromStatusNumber(currentStatus))]} loading={submitting} onClick={nextStep} style={{marginLeft: 20}} />

            </Fragment>
          : <Fragment>
              <DatePicker minDate={new Date()} locale='ar' onChange={(value) =>{ setDate(value)} } />
              <br />
              <br />
              <AddButton disabled={buttonDisabled} text={buttonText} loading={submitting} onClick={updateOrderDate} />
            </Fragment>
        }
      </Dialog>
    </div>
  )
}


const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(UpdateStatus)

