import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'ramda'
import { message } from 'antd'
import CrudForm from '../../index'
import moduleMapper from '../../../../config/Structure/moduleMapper'
import { uploadImages } from '../../../../config/Client/actions/Upload'
import queryString from 'query-string'

function AddNew(props) {
  const module = moduleMapper[props.module]
  const location = useLocation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [values, setValues] = useState(false)

  const query = queryString.parse(location.search)

  useEffect(() => {
    setValues(isEmpty(query) ? undefined : query)
  }, []) // eslint-disable-line

  const onSubmit = async (values) => {
    setIsSubmitting(true)
    const checkedValues = props.beforeSubmit ? props.beforeSubmit(values) : values
    if (!checkedValues) setIsSubmitting(false)
    else
      props.dispatch(
        module.add.create({
          body: values,
          onSuccess: values.images ? onSuccess : onEditSuccess,
          onFailure,
          OnFlyAddFlag: true,
        }),
      )
  }

  const onSuccess = (id, imageFiles) => {
    if (imageFiles && imageFiles.length > 0) {
      props.dispatch(
        uploadImages({
          imageArray: imageFiles,
          // maintaining a consistent naming and image file path in the s3 bucket
          moduleName:
            props.module.slice(-1).toLocaleLowerCase() === 'y'
              ? props.module.slice(0, -1) + 'ies'
              : props.module + 's',
          _id: id,
          onSuccess: onUploadSuccess,
          onFailure,
        }),
      )
    } else {
      setIsSubmitting(false)
      props.onAdd(id)
    }
  }

  const onUploadSuccess = (imageLinkArray, values, id) => {
    props.dispatch(
      module.edit.update({
        body: { images: imageLinkArray, _id: id },
        onSuccess: onEditSuccess,
        onFailure,
        OnFlyAddFlag: true,
      }),
    )
  }

  const onEditSuccess = (id) => {
    setIsSubmitting(false)
    props.onAdd(id)
  }

  const onFailure = () => {
    setIsSubmitting(false)
    message.error('حدث خطأ')
  }

  return (
    <Fragment>
      <CrudForm
        fields={module.add.fields}
        onSubmit={onSubmit}
        buttonText='أضف'
        submitting={isSubmitting}
        inModal={true}
        initialValues={values}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(AddNew)
