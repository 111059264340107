import {
  GET_ADS,
  GET_ADS_SUCCESS,
  GET_ADS_FAILURE,
  GET_AD,
  GET_AD_SUCCESS,
  GET_AD_FAILURE,
  DELETE_AD,
  ADD_AD,
  ADD_AD_SUCCESS,
  ADD_AD_FAILURE,
  EDIT_AD,
  EDIT_AD_SUCCESS,
  EDIT_AD_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Get Ads Request
export const getAds = (payload) => {
  return {
    type: GET_ADS,
    payload,
  }
}

//Get Ads Success
export const getAdsSuccess = (ads) => {
  return {
    type: GET_ADS_SUCCESS,
    payload: { ads },
  }
}

//Get Ads Failure
export const getAdsFailure = (error) => {
  return {
    type: GET_ADS_FAILURE,
    payload: { error },
  }
}

//Get Ad Request
export const getAd = (payload) => {
  return {
    type: GET_AD,
    payload,
  }
}

//Get Ad Success
export const getAdSuccess = (ad) => {
  return {
    type: GET_AD_SUCCESS,
    payload: { ad },
  }
}

//Get Ad Failure
export const getAdFailure = (error) => {
  return {
    type: GET_AD_FAILURE,
    payload: { error },
  }
}

//Add Ad Request
export const addAd = (payload) => {
  return {
    type: ADD_AD,
    payload,
  }
}

//Add Ad Success
export const addAdSuccess = (ad) => {
  return {
    type: ADD_AD_SUCCESS,
    payload: { ad },
  }
}

//Add Ad Failure
export const addAdFailure = (error) => {
  return {
    type: ADD_AD_FAILURE,
    payload: { error },
  }
}

//Edit Ad Request
export const editAd = (payload) => {
  return {
    type: EDIT_AD,
    payload,
  }
}

//Edit Ad Success
export const editAdSuccess = (ad) => {
  return {
    type: EDIT_AD_SUCCESS,
    payload: { ad },
  }
}

//Edit Ad Failure
export const editAdFailure = (error) => {
  return {
    type: EDIT_AD_FAILURE,
    payload: { error },
  }
}

//Delete Ads
export const deleteAd = (payload) => {
  return {
    type: DELETE_AD,
    payload,
  }
}
