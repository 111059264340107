import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_SECTIONS,
  GET_SECTION,
  DELETE_SECTION,
  ADD_SECTION,
  UPDATE_SECTION,
} from '../../../../config/constants/ActionTypes'
import { getSectionsFailure, getSectionsSuccess, getSections } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getSectionsRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'sections/paginated',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
    headers: { lite },
  })
}

function* getSectionsSaga({ payload }) {
  try {
    const response = yield call(getSectionsRequest, payload)
    yield put(getSectionsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getSectionsFailure(error))
    payload.onFailure(error)
  }
}

const deleteSectionRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'sections',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteSectionSaga({ payload }) {
  try {
    const response = yield call(deleteSectionRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addSectionRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'sections',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addSectionSaga({ payload }) {
  try {
    const response = yield call(addSectionRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateSectionRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'sections',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateSectionSaga({ payload }) {
  try {
    const response = yield call(updateSectionRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getSections(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

const getSectionRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'sections',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
  })
}

function* getSectionSaga({ payload }) {
  try {
    const response = yield call(getSectionRequest, payload)
    if (response.data) {
      payload.onSuccess(response.data[0])
    } else {
      payload.onSuccess({})
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_SECTIONS, getSectionsSaga)],
    [yield takeEvery(DELETE_SECTION, deleteSectionSaga)],
    [yield takeEvery(ADD_SECTION, addSectionSaga)],
    [yield takeEvery(UPDATE_SECTION, updateSectionSaga)],
    [yield takeEvery(GET_SECTION, getSectionSaga)],
  )
}
