import {
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER,
  REJECT_ORDER,
  CANCEL_ORDER,
} from '../../../../config/constants/ActionTypes'

//Get Orders Request
export const getOrders = (payload) => {
  return {
    type: GET_ORDERS,
    payload,
  }
}

//Get orders Success
export const getOrdersSuccess = (orders) => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: { orders },
  }
}

//Get orders Failure
export const getOrdersFailure = (error) => {
  return {
    type: GET_ORDERS_FAILURE,
    payload: { error },
  }
}

//Get Order Request
export const getOrder = (payload) => {
  return {
    type: GET_ORDER,
    payload,
  }
}

//Get order Success
export const getOrderSuccess = (order) => {
  return {
    type: GET_ORDER_SUCCESS,
    payload: { order },
  }
}

//Get order Failure
export const getOrderFailure = (error) => {
  return {
    type: GET_ORDER_FAILURE,
    payload: { error },
  }
}

//update order status
export const updateOrderStatus = (payload) => {
  return {
    type: UPDATE_ORDER_STATUS,
    payload,
  }
}

//update order
export const updateOrder = (payload) => {
  return {
    type: UPDATE_ORDER,
    payload,
  }
}

//update order
export const cancelOrder = (payload) => {
  return {
    type: CANCEL_ORDER,
    payload,
  }
}


//update order
export const rejectOrder = (payload) => {
  return {
    type: REJECT_ORDER,
    payload,
  }
}

