import {
  GET_BUYER_CATEGORIES,
  GET_BUYER_CATEGORIES_SUCCESS,
  GET_BUYER_CATEGORIES_FAILURE,
  DELETE_BUYER_CATEGORY,
  ADD_BUYER_CATEGORY,
  UPDATE_BUYER_CATEGORY,
  GET_PRODUCT_BUYER_CATEGORIES,
} from '../../../../config/constants/ActionTypes'


//Get BuyerCategories Request
export const getBuyerCategories = (payload) => {
  return {
    type: GET_BUYER_CATEGORIES,
    payload,
  }
}

//Get ProductBuyerCategories Request
export const getProductBuyerCategories = (payload) => {
  return {
    type: GET_PRODUCT_BUYER_CATEGORIES,
    payload,
  }
}

//Get BuyerCategories Success
export const getBuyerCategoriesSuccess = (buyerCategories) => {
  return {
    type: GET_BUYER_CATEGORIES_SUCCESS,
    payload: { buyerCategories },
  }
}

//Get BuyerCategories Failure
export const getBuyerCategoriesFailure = (error) => {
  return {
    type: GET_BUYER_CATEGORIES_FAILURE,
    payload: { error },
  }
}

//Delete BuyerCategory
export const deleteBuyerCategory = (payload) => {
  return {
    type: DELETE_BUYER_CATEGORY,
    payload,
  }
}

//Add BuyerCategory
export const addBuyerCategory = (payload) => {
  return {
    type: ADD_BUYER_CATEGORY,
    payload,
  }
}

//update BuyerCategory
export const updateBuyerCategory = (payload) => {
  return {
    type: UPDATE_BUYER_CATEGORY,
    payload,
  }
}
