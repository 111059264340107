import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  DELETE_CATEGORY,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
} from '../../../../config/constants/ActionTypes'

//Get Categories Request
export const getCategories = (payload) => {
  return {
    type: GET_CATEGORIES,
    payload,
  }
}

//Get Categories Success
export const getCategoriesSuccess = (categories) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: { categories },
  }
}

//Get Categories Failure
export const getCategoriesFailure = (error) => {
  return {
    type: GET_CATEGORIES_FAILURE,
    payload: { error },
  }
}

//Delete Category
export const deleteCategory = (payload) => {
  return {
    type: DELETE_CATEGORY,
    payload,
  }
}

//Add Category
export const addCategory = (payload) => {
  return {
    type: ADD_CATEGORY,
    payload,
  }
}

//update Category
export const updateCategory = (payload) => {
  return {
    type: UPDATE_CATEGORY,
    payload,
  }
}
