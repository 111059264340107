import React  from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
import queryString from 'query-string'
import { Popconfirm, Icon } from 'antd'
import { FaTimes } from 'react-icons/fa'
import { rejectBuyer } from '../../store/actions'



const RejectBuyer = ({ record, dispatch, onChange }) => {
  const location = useLocation()
  const query = queryString.parse(location.search, { arrayFormat: 'comma' })


  const handleOnClick = () => {
    dispatch(rejectBuyer({
      query: {
        branches: query?.branches,
      },
      body: {
        _id: query?.branches,
        buyer: {
          _id: record?._id,
        }
      }, onSuccess, onFailure
    }))
  }

  const onSuccess = () => {
    if (onChange) onChange()
  }

  const onFailure = (error) => {
    message.error('حدث خطأ')
  }

  return (
    <Popconfirm
      title='هل أنت متأكد؟'
      icon={<Icon type='exclamation-circle-o' style={{ color: 'red' }} />}
      onConfirm={() => handleOnClick()}
    >
      <Icon> <FaTimes /> </Icon>
    </Popconfirm>
  )
}


const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(RejectBuyer)

