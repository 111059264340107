import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_PRICE_OFFERS,
  DELETE_PRICE_OFFER,
  ADD_PRICE_OFFER,
  UPDATE_PRICE_OFFER,
  GET_PRICE_OFFER,
} from '../../../../config/constants/ActionTypes'
import { getPriceOffersFailure, getPriceOffersSuccess } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getPriceOffersRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'offers/allPriceOffers',
    params: { ...query, isDeleted: false },
  })
}

function* getPriceOffersSaga({ payload }) {
  try {
    const response = yield call(getPriceOffersRequest, payload)
    yield put(getPriceOffersSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getPriceOffersFailure(error))
    payload.onFailure(error)
  }
}

const getPriceOfferRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'offers/singlePriceOffer',
    params: { id: query._id },
  })
}

function* getPriceOfferSaga({ payload }) {
  try {
    const response = yield call(getPriceOfferRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

const deletePriceOfferRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'offers/deletePriceOffer',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { offer_id: _id },
  })
}

function* deletePriceOfferSaga({ payload }) {
  try {
    const response = yield call(deletePriceOfferRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addPriceOfferRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'offers/priceOffers',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addPriceOfferSaga({ payload }) {
  try {
    const response = yield call(addPriceOfferRequest, payload) // eslint-disable-line
    delete payload._id
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const updatePriceOfferRequest = async ({ body }) => {
  let offer_id = body._id
  delete body._id
  return await axios({
    method: 'put',
    url: config.url + 'offers/updatePriceOffers',
    params: {
      offer_id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updatePriceOfferSaga({ payload }) {
  try {
    const response = yield call(updatePriceOfferRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      payload.onSuccess()
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_PRICE_OFFERS, getPriceOffersSaga)],
    [yield takeEvery(DELETE_PRICE_OFFER, deletePriceOfferSaga)],
    [yield takeEvery(ADD_PRICE_OFFER, addPriceOfferSaga)],
    [yield takeEvery(UPDATE_PRICE_OFFER, updatePriceOfferSaga)],
    [yield takeEvery(GET_PRICE_OFFER, getPriceOfferSaga)],
  )
}
