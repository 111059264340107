/* eslint-disable no-unused-vars */
import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { equals } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'

const AutoCompleteInput = forwardRef((props, ref) => {
  const [state, setState] = useState([])
  const [options, setOptions] = useState([])
  // const { data: fetchedOptions, loading } = useQuery(props.query, { useCache: false, skipCache: true })
  const autoCompleteClasses = useAutoCompleteStyles()

  // const defaultDisplayFormatter = option => option?.name
  // const defaultValueFormatter = option => option?.value

  useEffect(() => {
    if (props.value && !equals(props.value, state)) {
      setState(props.value)
      if (props.onChange) props.onChange(props.value, props.dataIndex)
    }
  }, [props.value]) // eslint-disable-line

  useEffect(() => {
    if (props.onMounted) props.onMounted(props.dataIndex)
  }, []) // eslint-disable-line

  // useEffect(() => {
  //   if (fetchedOptions) {
  //     let fetchedOptionsList = Array.isArray(fetchedOptions[Object.keys(fetchedOptions)[0]])
  //       ? fetchedOptions[Object.keys(fetchedOptions)[0]]
  //       : fetchedOptions[Object.keys(fetchedOptions)[0]].items
  //     setOptions([...fetchedOptionsList])
  //   }
  // }, [fetchedOptions]) // eslint-disable-line

  const onChange = (val) => {
    setState(val)
    if (props.onChange) props.onChange(props.multiple ? val : val[0], props.dataIndex)
  }

  return (
    <Fragment>
      <Autocomplete
        multiple={true}
        freeSolo={true}
        size='small'
        title={props.title}
        // freeSolo={props.tags}
        classes={autoCompleteClasses}
        autoComplete
        autoHighlight
        ref={ref}
        options={options}
        // getOptionSelected={(option, value) => {
        //   return props.valueFormatter ? props.valueFormatter(option) === value : defaultValueFormatter(option) === value
        // }}
        // getOptionLabel={props.displayFormatter || defaultDisplayFormatter}
        // filterOptions={(options, state) => options }
        // filterOptions={(options, state) => {
        //   return options.filter((option) => props.displayFormatter
        //     ? props.displayFormatter(option).match(new RegExp(state.inputValue, 'ig'))
        //     : defaultDisplayFormatter(option).match(new RegExp(state.inputValue, 'ig')))
        // }}
        onChange={(e, val) => onChange(val)}
        value={state}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.title}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position='end' style={{ marginBottom: 8 }}>
                  {/* {loading && <CircularProgress size={20} />} */}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Fragment>
  )
})

const useAutoCompleteStyles = makeStyles((theme) => ({
  listbox: {
    background: theme.palette.secondary.main,
    '& > li': {
      margin: 0,
      padding: 0,
      height: '3em',
      fontSize: '1.3rem',
      textTransform: 'uppercase',
      fontFamily: 'Cairo-Regular',
      textAlign: 'center',
      display: 'box',
      boxPack: 'center',
      boxAlign: 'center',
      width: '100%',
      borderBottom: `1px solid ${theme.palette.divider}`,
      color: 'white',
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
  },
  noOptions: {
    textAlign: 'center',
    background: theme.palette.secondary.main,
  },
  loading: {
    textAlign: 'center',
    background: theme.palette.secondary.main,
  },
  tag: {
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
  },
}))

export default AutoCompleteInput
