import { all, call, put, takeEvery } from 'redux-saga/effects'
import { UPLOAD_IMAGES } from '../../constants/ActionTypes'
import { uploadImagesFailure, uploadImagesSuccess } from '../actions/Upload'
import { s3Configuration } from './config'
import { uploadFile } from 'react-s3'

const uploadImageRequest = async ({ imageArray, moduleName, _id }) => {
  if (!Array.isArray(imageArray)) {
    if (typeof imageArray === 'string') return imageArray
    const uploadResponse = await uploadFile(imageArray, s3Configuration(moduleName, _id))
    return uploadResponse.location
  } else {
    return imageArray
      ? await Promise.all(
          imageArray.map(async (image) => {
            if (typeof image === 'string') return image
            else {
              const uploadResponse = await uploadFile(image, s3Configuration(moduleName, _id))
              return uploadResponse.location
            }
          })
        )
      : []
  }
}

function* uploadImagesSaga({ payload }) {
  try {
    const response = yield call(uploadImageRequest, payload)
    yield put(uploadImagesSuccess(response))
    payload.onSuccess(response, payload.values, payload._id)
  } catch (error) {
    yield put(uploadImagesFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(UPLOAD_IMAGES, uploadImagesSaga)])
}
