import { all, call, put, takeEvery } from 'redux-saga/effects'
import { SIGNIN_USER } from '../../../../config/constants/ActionTypes'
import { showAuthMessage, userSignInSuccess } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'
import { push } from 'react-router-redux'

const signInUserWithEmailPasswordRequest = async ({
  email,
  password,
  type,
  accountPhoneNumber,
}) => {
  return await axios({
    method: 'post',
    url: config.url + type + '/login',
    data: { email, password, accountPhoneNumber },
  })
}

function* signInUserWithEmailPassword({ payload }) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload)
    localStorage.setItem('token', signInUser.data.token)
    payload.onSuccess()
    yield put(userSignInSuccess(signInUser.data))
    yield put(push('/companies'))
  } catch (error) {
    payload.onFailure()
    yield put(showAuthMessage(error))
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword)])
}
