import { GET_ROLES_SUCCESS, GET_ROLES_FAILURE } from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload.roles,
      }
    }

    case GET_ROLES_FAILURE: {
      return {
        ...state,
        getRolesError: action.payload.error,
      }
    }

    default:
      return state
  }
}
