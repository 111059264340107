import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import { GlobalStateProvider } from './GlobalState'
import configureStore from './config/Client/store'
import { GlobalTheme } from './GlobalStyle'

export const store = configureStore().store
export const persistor = configureStore().persistor

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <GlobalStateProvider>
        <GlobalTheme>
          <App />
        </GlobalTheme>
      </GlobalStateProvider>
    </Router>
  </Provider>,
  document.getElementById('root'),
)
