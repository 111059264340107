import { tableFields, fields } from '../schema'
import {
  getSubscriptions,
  deleteSubscription,
  addSubscription,
  updateSubscription,
} from '../../../modules/Subscription/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const subscriptions = {
  view: {
    get: getSubscriptions,
    delete: deleteSubscription,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addSubscription,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
    ],
  },
  edit: {
    update: updateSubscription,
    get: getSubscriptions,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
    ],
  },
}
