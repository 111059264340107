export const types = {
  text: 'text',
  textArea: 'textArea',
  checkbox: 'checkbox',
  antdSelect: 'antdSelect',
  select: 'select',
  selectAsync: 'selectAsync',
  object: 'object',
  list: 'list',
  images: 'images',
  double: 'double',
  triple: 'triple',
  editAction: 'editAction',
  deleteAction: 'deleteAction',
  detailsAction: 'detailsAction',
  goToAction: 'goToAction',
  custom: 'custom',
  dynamicKeyValue: 'dynamicKeyValue',
  datePicker: 'datePicker',
  autoComplete: 'autoComplete',
  number: 'number',
  verticalTabs: 'verticalTabs',
  tags: 'tags',
}

// validate (optional): must be a function that receives 2 parameter (fieldValue, formValue)
// returning a boolean true (can not be truthy value) for the passed validation, and an error message for the failed validation
export const fields = {
  text: ({ title, dataIndex, inputType, helperText, readOnly, validate }) => {
    return {
      type: types.text,
      title,
      dataIndex,
      key: dataIndex,
      inputType,
      helperText,
      readOnly,
      validate,
    }
  },
  number: ({ title, dataIndex, min, max, helperText, allowFractions, readOnly, validate,dependentField,anotherDependentField}) => {
    return {
      type: types.number,
      title,
      dataIndex,
      key: dataIndex,
      min,
      max,
      helperText,
      allowFractions,
      readOnly,
      validate,
      dependentField,
      anotherDependentField
    }
  },
  textArea: ({ title, dataIndex, helperText, readOnly, validate }) => {
    return {
      type: types.textArea,
      title,
      dataIndex,
      key: dataIndex,
      helperText,
      readOnly,
      validate,
    }
  },
  checkbox: ({ title, dataIndex, helperText, readOnly, validate }) => {
    return {
      type: types.checkbox,
      title,
      dataIndex,
      key: dataIndex,
      helperText,
      readOnly,
      validate,
    }
  },
  object: ({ title, dataIndex, fields, readOnly, validate, dependencyObject }) => {
    return {
      type: types.object,
      title,
      dataIndex,
      key: dataIndex,
      fields,
      readOnly,
      validate,
      dependencyObject,
    }
  },
  autoComplete: ({
    title,
    dataIndex,
    singleQuery,
    searchQuery,
    helperText,
    multiple,
    displayFormatter,
    valueFormatter,
    initialValueFormatter,
    module,
    onModuleSubmit,
    readOnly,
    validate,
  }) => {
    // providing a module name will open the add new tab
    // module name must be the same as in module mapper
    // onModuleSubmit is a function that accepts the form values as a parameter and returns a tuple [values, addMutationIndex]. used to perform custom checks before submitting
    // if it didn't return values, it will be considered as an error
    return {
      type: types.autoComplete,
      title,
      dataIndex,
      key: dataIndex,
      singleQuery,
      searchQuery,
      helperText,
      multiple,
      displayFormatter,
      valueFormatter,
      readOnly,
      selector: initialValueFormatter,
      module,
      onModuleSubmit,
      validate,
    }
  },
  select: ({ title, dataIndex, options, readOnly, validate }) => {
    return { type: types.select, title, dataIndex, key: dataIndex, options, readOnly, validate }
  },
  selectAsync: ({ title, dataIndex, optionsQuery, multiple, readOnly, validate }) => {
    return {
      type: types.selectAsync,
      title,
      dataIndex,
      key: dataIndex,
      optionsQuery,
      multiple,
      readOnly,
      validate,
    }
  },
  verticalTabs: ({ dataIndex, tabs }) => {
    return { type: types.verticalTabs, key: dataIndex, tabs }
  },
  list: ({ title, dataIndex, field, readOnly, validate }) => {
    return { type: types.list, title, dataIndex, key: dataIndex, field, readOnly, validate }
  },
  double: ({ title, dataIndex, firstField, secondField, dependent, readOnly, validate }) => {
    // dependent is only needed if the second field depends on the first field
    return {
      type: types.double,
      title,
      dataIndex,
      key: dataIndex,
      firstField,
      secondField,
      dependent,
      readOnly,
      validate,
    }
  },
  triple: ({ title, dataIndex, firstField, secondField, thirdField, readOnly, validate }) => {
    return {
      type: types.triple,
      title,
      dataIndex,
      key: dataIndex,
      firstField,
      secondField,
      thirdField,
      readOnly,
      validate,
    }
  },
  dynamicKeyValue: ({
    title = { key: 'Key', valueType: 'Value Type', value: 'Value' },
    dataIndex = { key: 'key', valueType: 'valueType', value: 'value' },
    initialValueFormatter,
    readOnly,
    validate,
  }) => {
    // initialValueFormatter should return an object with three keys: key, type, value
    return {
      type: types.dynamicKeyValue,
      title,
      dataIndex,
      key: dataIndex,
      selector: initialValueFormatter,
      readOnly,
      validate,
    }
  },
  images: ({ title, dataIndex, multiple, readOnly, validate, dimensions }) => {
    return { type: types.images, title, dataIndex, key: dataIndex, readOnly, validate, multiple, dimensions }
  },
  tags: ({ title, dataIndex, multiple, allowNew, query, readOnly, validate }) => {
    return {
      type: types.tags,
      title,
      dataIndex,
      key: dataIndex,
      readOnly,
      validate,
      multiple,
      allowNew,
      query,
    }
  },
  datePicker: ({ title, dataIndex, readOnly, validate }) => {
    return { type: types.datePicker, title, dataIndex, key: dataIndex, readOnly, validate }
  },

  custom: ({ title, dataIndex, field, uniqueKey, selector, readOnly, validate }) => {
    return {
      type: types.custom,
      title,
      dataIndex,
      key: dataIndex,
      field,
      uniqueKey,
      selector,
      readOnly,
      validate,
    }
  },
}

export const formFields = {
  text: (title, dataIndex, mode, min, max, readOnly, rules) => {
    // mode can either be omitted or 'text' for text input,'number' for number input, or 'email' well...
    // min and max are only required in number input, and only if you need a min and max...
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    return { type: types.text, title, dataIndex, key: dataIndex, mode, min, max, readOnly, rules }
  },
  textArea: (title, dataIndex, readOnly, rules) => {
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    return { type: types.textArea, title, dataIndex, key: dataIndex, readOnly, rules }
  },
  checkbox: (title, dataIndex, readOnly, rules) => {
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    return { type: types.checkbox, title, dataIndex, key: dataIndex, readOnly, rules }
  },
  select: (
    title,
    dataIndex,
    mode,
    source,
    selector,
    childKeyPath,
    displaySelector,
    dependency,
    filter,
    readOnly,
    rules,
    dependentField,
    dependentKey,
    module,
    anotherDataIndex,
    validate,
  ) => {
    // mode can either be 'default' for single select, or 'multiple' for, well, multiple...
    // source is the source of the select options, an object with keys: list (array for static data), query(for dynamic data)
    // selector is the helper function for setting the initial values, it needs to return a value in a way that is accepted by select
    // childKeyPath is the path to the 'key' in array style
    // displaySelector is a function that defines what is displayed for the options
    // dependency is string the dataIndex of field the select depends on
    // filter is a function to filter data by
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    // module is an object with keys:
    //  - key<required>(the name of the module according to componentMapper)
    //  - beforeSubmit<optional>(a function that receives the form values as a parameter and returns the form values that will be used in the dispatch)
    // anotherDataIndex, well how to explain this?... it is a field to used to disable the select input if the query has the same value
    // - for example if the query string has a key called company, and this field has the value of company, this select would be disabled
    // - why didn't i  use the dataIndex you say? because some selects have a dataIndex of _id
    return {
      type: types.antdSelect,
      title,
      dataIndex,
      key: dataIndex,
      mode,
      source,
      selector,
      childKeyPath: childKeyPath || ['key'],
      displaySelector,
      dependency,
      filter,
      readOnly,
      rules,
      dependentField,
      dependentKey,
      module,
      anotherDataIndex,
      validate,
    }
  },
  images: (title, dataIndex, selector, readOnly, rules) => {
    // selector is the helper function for setting the initial value, the input expects uploaded images to be an object with url attribute
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    return {
      type: types.images,
      title,
      dataIndex,
      key: dataIndex,
      selector,
      readOnly,
      rules,
      multiple: true,
    }
  },
  object: (title, dataIndex, fields, readOnly, rules) => {
    // fields is an array of fields
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    return { type: types.object, title, dataIndex, key: dataIndex, fields, readOnly, rules }
  },
  list: (title, dataIndex, field, readOnly, rules) => {
    // field is the field that is the list gonna consist of
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    return { type: types.list, title, dataIndex, key: dataIndex, field, readOnly, rules }
  },
  double: (title, dataIndex, firstField, secondField, dependent, readOnly, rules) => {
    // dependent is only needed if the second field depends on the first field
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    return {
      type: types.double,
      title,
      dataIndex,
      key: dataIndex,
      firstField,
      secondField,
      dependent,
      readOnly,
      rules,
    }
  },
  triple: (title, dataIndex, firstField, secondField, thirdField, readOnly, rules) => {
    // readOnly is a boolean that decides whether a text field's content can be edited (false by default)
    // rules is the ant design rules array
    return {
      type: types.triple,
      title,
      dataIndex,
      key: dataIndex,
      firstField,
      secondField,
      thirdField,
      readOnly,
      rules,
    }
  },
  custom: (title, dataIndex, field, uniqueKey, selector, readOnly, rules) => {
    return {
      type: types.custom,
      title,
      dataIndex,
      key: dataIndex,
      field,
      uniqueKey,
      selector,
      readOnly,
      rules,
    }
  },
  dynamicKeyValue: (
    title = { key: 'Key', valueType: 'Type', value: 'Value' },
    dataIndex = { key: 'key', valueType: 'type', value: 'value' },
    selector,
    readOnly,
  ) => {
    // selector should return an object with three keys: key, type, value
    return { type: types.dynamicKeyValue, title, dataIndex, key: dataIndex, selector, readOnly }
  },
}

export const tableFields = {
  text: (title, dataIndex, selector) => {
    // selector is a helper function for applying data in the desired way
    return { type: types.text, title, dataIndex, key: dataIndex, selector }
  },
  image: (title, dataIndex, selector) => {
    // selector is a helper function for applying data in the desired way
    return { type: 'image', title, dataIndex, key: dataIndex, selector }
  },
  action: {
    edit: (title, dataIndex, selector) => {
      // all parameters can be omitted for the default edit action
      // selector is a helper function for applying data in the desired way
      return {
        type: types.editAction,
        title: title || 'تعديل',
        dataIndex: dataIndex || 'edit',
        key: dataIndex || 'edit',
        selector,
      }
    },
    delete: (title, dataIndex, selector) => {
      // all parameters can be omitted for the default delete action
      // selector is a helper function for applying data in the desired way
      return {
        type: types.deleteAction,
        title: title || 'حذف',
        dataIndex: dataIndex || 'delete',
        key: dataIndex || 'delete',
        selector,
      }
    },
    details: (title, dataIndex, selector) => {
      // all parameters can be omitted for the default delete action
      // selector is a helper function for applying data in the desired way
      return {
        type: types.detailsAction,
        title: title || 'Details',
        dataIndex: dataIndex || 'details',
        key: dataIndex || 'details',
        selector,
      }
    },
    goTo: (title, dataIndex, to) => {
      // to is the url for the action, '{key}' will be replaced with the record key
      // ex: '/variations?product={key}
      return { type: types.goToAction, title: title, dataIndex: dataIndex, key: dataIndex, to }
    },
    custom: (title, dataIndex, component) => {
      return { type: types.custom, title, dataIndex, key: dataIndex, component }
    },
  },
}
