import {
    UPLOAD_IMAGES_SUCCESS,
    UPLOAD_IMAGES_FAILURE,
} from '../../constants/ActionTypes'

export default (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_IMAGES_SUCCESS: {
            return {
                ...state,
                links: action.payload.links,
            }
        }

        case UPLOAD_IMAGES_FAILURE: {
            return {
                ...state,
                getUploadImagesError: action.payload.error,
            }
        }

        default:
            return state
    }
}
