/* eslint-disable react-hooks/exhaustive-deps */
//new imports
import React, { useState, useEffect } from 'react'
import { message, TreeSelect, Icon } from 'antd'
import { connect } from 'react-redux'
import { getZonesNames } from '../../../Zone/store/actions'
import { get, findIndex } from 'lodash'
import styled from 'styled-components'

const SHOW_PARENT = TreeSelect.SHOW_PARENT

const TreeSelectForm = ({ value, dispatch, setTempFilters, tempFilters }) => {
  const [treeData, setTreeData] = useState([])
  const [selectedValue, setSelectedValue] = useState([])
  const [availableZones, setAvailableZones] = useState([])
  const [initialValue, setInitialValue] = useState(value)
  const [zonesLoading, setZonesLoading] = useState(false)
  const [availableZonesLoading, setAvailableZonesLoading] = useState(false)

  useEffect(() => {
    if (treeData.length) setAvailableZonesLoading(false)
  }, [treeData])

  useEffect(() => {
    setZonesLoading(true)
    setAvailableZonesLoading(true)
    dispatch(
      getZonesNames({
        onSuccess,
        onFailure,
      }),
    )
  }, [])

  useEffect(() => {
    if (treeData.length) setAvailableZonesLoading(false)
  }, [treeData])

  const onSuccess = (records) => {
    setZonesLoading(false)
    setAvailableZones(records.data)
    setInitialValue(value)
  }

  const onFailure = (error) => {
    message.error('حدث خطأ')
  }

  useEffect(() => {
    setInitialValue(value)
  }, [value])

  useEffect(() => {
    let zones = availableZones
    if (zones === null) zones = []
    let treeData = []
    for (let i = 0; i < zones.length; i++) {
      const currentZone = zones[i]
      const currentDistrict = currentZone.district
      const currentCity = currentZone.city
      const currentCountry = currentZone.country
      const countryIndex = findIndex(treeData, (td) => td.value === currentCountry._id)
      if (countryIndex === -1) {
        treeData.push({
          title: currentCountry.name,
          value: currentCountry._id,
          key: currentCountry._id,
          children: [
            {
              title: currentCity.name,
              value: currentCity._id,
              key: currentCity._id,
              children: [
                {
                  title: currentDistrict.name.ar,
                  value: currentDistrict._id,
                  key: currentDistrict._id,
                  children: [
                    {
                      title: get(currentZone, 'name.ar'),
                      value: get(currentZone, '_id'),
                      key: get(currentZone, '_id'),
                    },
                  ],
                },
              ],
            },
          ],
        })
      } else {
        const cityIndex = findIndex(treeData[countryIndex].children, (td) => td.value === currentCity._id)
        if (cityIndex === -1) {
          treeData[countryIndex].children.push({
            title: currentCity.name,
            value: currentCity._id,
            key: currentCity._id,
            children: [
              {
                title: currentDistrict.name.ar,
                value: currentDistrict._id,
                key: currentDistrict._id,
                children: [
                  {
                    title: get(currentZone, 'name.ar'),
                    value: get(currentZone, '_id'),
                    key: get(currentZone, '_id'),
                    children: [],
                  },
                ],
              },
            ],
          })
        } else {
          const districtIndex = findIndex(treeData[countryIndex].children[cityIndex].children, (td) => td.value === currentDistrict._id)
          if (districtIndex === -1) {
            treeData[countryIndex].children[cityIndex].children.push({
              title: currentDistrict.name.ar,
              value: currentDistrict._id,
              key: currentDistrict._id,
              children: [
                {
                  title: get(currentZone, 'name.ar'),
                  value: get(currentZone, '_id'),
                  key: get(currentZone, '_id'),
                  children: [],
                },
              ],
            })
          } else {
            treeData[countryIndex].children[cityIndex].children[districtIndex].children.push({
              title: get(currentZone, 'name.ar'),
              value: get(currentZone, '_id'),
              key: get(currentZone, '_id'),
              children: [],
            })
          }
        }
      }
    }
    setTreeData(treeData)
  }, [availableZones])

  const setZones = ({ selectedZones, selectedDistricts, selectedCities, selectedCountries }) => {
    let zones = {}
    zones.country = selectedCountries
    zones.city = selectedCities
    zones.district = selectedDistricts
    zones.zone = selectedZones.map((zone) => get(zone, '_id', zone))
    setSelectedValue(zones.zone)
    // applyChange(zones.zone)
  }

  const onSelect = (val) => {
    let filteredDistricts = []
    let filteredCities = []
    let filteredCountries = []
    let filteredZones = availableZones.filter((zone) => {
      for (let i = 0; i < val.length; i++) {
        const selectedId = val[i]
        if (get(zone, 'country._id') === selectedId || get(zone, 'city._id') === selectedId || get(zone, 'district._id') === selectedId) {
          return zone._id
        } else if (zone._id === selectedId) {
          filteredCountries.push(get(zone, 'country._id'))
          filteredCities.push(get(zone, 'city._id'))
          filteredDistricts.push(get(zone, 'district._id'))
          return zone._id
        }
      }
      return false
    })
    setZones({
      selectedZones: filteredZones.map((z) => z._id),
    })
    setTempFilters({ ...tempFilters, zones: filteredZones.map((z) => z._id) })
  }
  return !zonesLoading && !availableZonesLoading ? (
    <StyledTreeSelect
      treeData={treeData || []}
      value={selectedValue.length === 0 ? initialValue : selectedValue}
      onChange={(value, level, op) => {
        onSelect(value)
      }}
      treeCheckable={true}
      showCheckedStrategy={SHOW_PARENT}
      searchPlaceholder={'من فضلك اختر مناطقك'}
    />
  ) : (
    <Icon type='loading' style={{ fontSize: 50, color: 'var(--color-secondary' }} spin />
  )
}

const StyledTreeSelect = styled(TreeSelect)`
  .ant-select-selection--multiple > ul > {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    /* margin-bottom: 0 !important; */
    padding-bottom: 0 !important;
  }
`
const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(TreeSelectForm)
