import { all, call, put, takeEvery } from 'redux-saga/effects'
import { GET_PACKAGE_UNITS, DELETE_PACKAGE_UNIT, ADD_PACKAGE_UNIT, EDIT_PACKAGE_UNIT} from '../../../../config/constants/ActionTypes'
import { 
  getPackageUnitsFailure,
  getPackageUnitsSuccess, 
  addPackageUnitSuccess,
  addPackageUnitFailure,
  editPackageUnitSuccess,
  editPackageUnitFailure,
} from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getPackageUnitsRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'packageUnits/',
    params: { ...query, isDeleted: false },
    headers: {
      lite,
      token: `${localStorage.getItem('token')}`,
    },
  })
}

function* getPackageUnitsSaga({ payload }) {
  try {
    const response = yield call(getPackageUnitsRequest, payload)
    yield put(getPackageUnitsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getPackageUnitsFailure(error))
    payload.onFailure(error)
  }
}

// const getBrandRequest = async ({ query }) => {
//   return await axios({
//     method: 'get',
//     url: config.url + 'brands/brand',
//     params: { _id: query._id },
//     headers: { token: `${localStorage.getItem('token')}` } 
//   })
// }

// function* getBrandSaga({ payload }) {
//   try {
//     const response = yield call(getBrandRequest, payload)
//     yield put(getBrandSuccess(response.data))
//     payload.onSuccess(response.data)
//   } catch (error) {
//     yield put(getBrandFailure(error))
//     payload.onFailure(error)
//   }
// }

const deletePackageUnitRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'packageUnits/',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deletePackageUnitSaga({ payload }) {
  try {
    yield call(deletePackageUnitRequest, payload)
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addPackageUnitRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'packageUnits/',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addPackageUnitSaga({ payload }) {
  try {
    const response = yield call(addPackageUnitRequest, payload)
    yield put(addPackageUnitSuccess(response.data))
    payload.onSuccess(path(['data', '_id'], response))
  } catch (error) {
    yield put(addPackageUnitFailure(error))
    payload.onFailure(error)
  }
}

const editPackageUnitRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'packageUnits/',
    params: {
      _id: body._id,
    },
    headers: { token: `${localStorage.getItem('token')}` },
    data: body,
  })
}

function* editPackageUnitSaga({ payload }) {
  try {
    const response = yield call(editPackageUnitRequest, payload)
    yield put(editPackageUnitSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(editPackageUnitFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    // [yield takeEvery(GET_PACKAGE_UNIT, getBrandSaga)],
    [yield takeEvery(GET_PACKAGE_UNITS, getPackageUnitsSaga)],
    [yield takeEvery(DELETE_PACKAGE_UNIT, deletePackageUnitSaga)],
    [yield takeEvery(ADD_PACKAGE_UNIT, addPackageUnitSaga)],
    [yield takeEvery(EDIT_PACKAGE_UNIT, editPackageUnitSaga)],
  )
}
