import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { pluck, path } from 'ramda'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import { uploadImages } from '../../../../config/Client/actions/Upload'
import queryString from 'query-string'

const EditDistributorForm = ({ dispatch }) => {
  const { distributors } = config
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const query = queryString.parse(location.search)

  useEffect(() => {
    setLoading(true)
    dispatch(
      config.distributors.edit.get({
        query: { _id: id },
        onSuccess: onFetchSuccess,
        onFailure: onFetchFailure,
      }),
    )
  }, []) // eslint-disable-line

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(uploadImages({
      values,
      imageArray: values.images,
      moduleName: 'distributors',
      _id: id,
      onSuccess: onUploadSuccess,
      onFailure,
    }))
  }

  const onUploadSuccess = (imageLinkArray, values) => {
    values.images = imageLinkArray
    dispatch(distributors.edit.update({ body: { ...values, _id: id }, onSuccess, onFailure }))
  }

  const onSuccess = () => {
    setSubmitting(false)
    message.success('تم التعديل بنجاح')
    history.push(`/distributors${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  const onFetchSuccess = (records) => {
    const fetchedRecord = records.data[Object.keys(records.data)[0]]

    //to accommodate objects zones as defined prior to the BE refactoring
    const zones = Array.isArray(fetchedRecord.zones) ? fetchedRecord.zones[0] : fetchedRecord.zones

    const record = {
      ...fetchedRecord,
      address: fetchedRecord.address[0],
      phone: fetchedRecord.phone[0],
      zones: {
        zone: pluck('_id', zones.zone),
        city: pluck('_id', zones.city),
        country: pluck('_id', zones.country),
        district: pluck('_id', zones.district),
      },
    }
    if (query['distributor'] && path(['distributor', '_id'], record) !== query['distributor']) {
      return history.push('/companies')
    }
    setLoading(false)
    setData(record)
  }

  const onFetchFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }
  return (
    <CrudForm
      title='تعديل الموزع أو الفرع'
      loading={loading}
      fields={config.distributors.edit.fields}
      onSubmit={onSubmit}
      buttonText='تعديل'
      initialValues={data}
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(EditDistributorForm)
