import React, { useState, useEffect, forwardRef } from 'react'
import { Checkbox } from 'antd'
import { equals, isNil } from 'ramda'
import styled from 'styled-components'

const CheckboxInput = forwardRef((props, ref) => {
  const [state, setState] = useState(false)


  useEffect(() => {
    if (!equals(props.value, state)) onChange(!isNil(props.value))
  }, [props.value]) // eslint-disable-line

  const onChange = (val) => {
    setState(val)
    if (props.onChange) props.onChange(val, props.dataIndex)
  }

  return (
    <CheckboxInputStyle>
      <Checkbox checked={state} onChange={(e) => onChange(e.target.checked)} ref={ref} disabled={props.readOnly}>
        {props.title}
      </Checkbox>
    </CheckboxInputStyle>
  )
})

const CheckboxInputStyle = styled.section`
  .ant-checkbox-checked .ant-checkbox-inner::after {
    display: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--color-secondary);
    border-radius: 1px;
  }

  .ant-checkbox-disabled .ant-checkbox-inner,
  .ant-checkbox-inner {
    width: 10px;
    height: 10px;
    border-radius: 1px;
    border-color: var(--color-secondary) !important;
    transition: all 0.3s;
    margin: 6px;
    background: transparent;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--color-secondary);
  }

  .ant-checkbox {
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    border: solid 1.5px var(--color-secondary);
    border-radius: 3px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-secondary);
  }

  .ant-checkbox-checked::after {
    border: 1px solid #fcc001;
    opacity: 0 !important;
    display: none;
  }
`

export default CheckboxInput
