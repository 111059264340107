/* eslint-disable react-hooks/exhaustive-deps */
//new imports
import React, { useState, useEffect } from 'react'
import { message, TreeSelect, Icon } from 'antd'
import { connect } from 'react-redux'
import { getZonesNames } from '../../../Zone/store/actions'
import { getDistricts } from '../../../District/store/actions'
import { getCities } from '../../../City/store/actions'
import { getCountries } from '../../../Country/store/actions'
import { get, findIndex } from 'lodash'

const SHOW_PARENT = TreeSelect.SHOW_PARENT

const TreeSelectForm = ({ value, onChange, dataIndex, dispatch }) => {
  const [treeData, setTreeData] = useState([])
  const [selectedValue, setSelectedValue] = useState([])
  const [availableZones, setAvailableZones] = useState([])
  const [districts, setDistricts] = useState([])
  const [cities, setCities] = useState([])
  const [countries, setCountries] = useState([])
  const [initialValue, setInitialValue] = useState([])
  const [zonesLoading, setZonesLoading] = useState(false)
  const [availableZonesLoading, setAvailableZonesLoading] = useState(false)

  useEffect(() => {
    setZonesLoading(true)
    setAvailableZonesLoading(true)
    dispatch(
      getZonesNames({
        onSuccess,
        onFailure,
      }),
    )
  }, [])

  useEffect(() => {
    if (treeData.length) setAvailableZonesLoading(false)
  }, [treeData])

  const onDistrictsSuccess = (records) => {
    setDistricts(records.data)
    dispatch(
      getCities({
        onSuccess: onCitiesSuccess,
        onFailure,
      }),
    )
  }

  const onCitiesSuccess = (records) => {
    setCities(records)
    dispatch(
      getCountries({
        onSuccess: onCountriesSuccess,
        onFailure,
      }),
    )
  }

  const onCountriesSuccess = (records) => {
    setCountries(records)
  }

  const onSuccess = (records) => {
    setZonesLoading(false)
    setAvailableZones(records.data)
    dispatch(
      getDistricts({
        onSuccess: onDistrictsSuccess,
        onFailure,
      }),
    )
    if (value) setInitialValue(value)
  }

  const onFailure = (error) => {
    message.error('حدث خطأ')
  }

  useEffect(() => {
    if (value) setInitialValue(value.zone)
  }, [value])

  useEffect(() => {
    let zones = availableZones
    if (zones === null) zones = []
    let treeData = []
    for (let i = 0; i < zones.length; i++) {
      const currentZone = zones[i]
      const currentDistrict = currentZone.district
      const currentCity = currentZone.city
      const currentCountry = currentZone.country
      const countryIndex = findIndex(treeData, (td) => td.value === currentCountry._id)
      if (countryIndex === -1) {
        treeData.push({
          title: currentCountry.name,
          value: currentCountry._id,
          key: currentCountry._id,
          children: [
            {
              title: currentCity.name,
              value: currentCity._id,
              key: currentCity._id,
              children: [
                {
                  title: currentDistrict.name.ar,
                  value: currentDistrict._id,
                  key: currentDistrict._id,
                  children: [
                    {
                      title: get(currentZone, 'name.ar'),
                      value: get(currentZone, '_id'),
                      key: get(currentZone, '_id'),
                    },
                  ],
                },
              ],
            },
          ],
        })
      } else {
        const cityIndex = findIndex(treeData[countryIndex].children, (td) => td.value === currentCity._id)
        if (cityIndex === -1) {
          treeData[countryIndex].children.push({
            title: currentCity.name,
            value: currentCity._id,
            key: currentCity._id,
            children: [
              {
                title: currentDistrict.name.ar,
                value: currentDistrict._id,
                key: currentDistrict._id,
                children: [
                  {
                    title: get(currentZone, 'name.ar'),
                    value: get(currentZone, '_id'),
                    key: get(currentZone, '_id'),
                    children: [],
                  },
                ],
              },
            ],
          })
        } else {
          const districtIndex = findIndex(treeData[countryIndex].children[cityIndex].children, (td) => td.value === currentDistrict._id)
          if (districtIndex === -1) {
            treeData[countryIndex].children[cityIndex].children.push({
              title: currentDistrict.name.ar,
              value: currentDistrict._id,
              key: currentDistrict._id,
              children: [
                {
                  title: get(currentZone, 'name.ar'),
                  value: get(currentZone, '_id'),
                  key: get(currentZone, '_id'),
                  children: [],
                },
              ],
            })
          } else {
            treeData[countryIndex].children[cityIndex].children[districtIndex].children.push({
              title: get(currentZone, 'name.ar'),
              value: get(currentZone, '_id'),
              key: get(currentZone, '_id'),
              children: [],
            })
          }
        }
      }
    }
    setTreeData(treeData)
  }, [availableZones])

  const setZones = ({ selectedZones, selectedDistricts, selectedCities, selectedCountries }) => {
    let zones = {}
    zones.country = selectedCountries
    zones.city = selectedCities
    zones.district = selectedDistricts
    zones.zone = selectedZones.map((zone) => get(zone, '_id', zone))
    setSelectedValue(zones.zone)
    applyChange(zones)
  }
  const applyChange = (zones) => {
    if (onChange) onChange(zones, dataIndex)
  }

  const onSelect = (val) => {
    let filteredDistricts = []
    let filteredCities = []
    let filteredCountries = []
    let filteredZones = availableZones.filter((zone) => {
      for (let i = 0; i < val.length; i++) {
        const selectedId = val[i]
        if (get(zone, 'country._id') === selectedId || get(zone, 'city._id') === selectedId || get(zone, 'district._id') === selectedId) {
          return zone._id
        } else if (zone._id === selectedId) {
          filteredCountries.push(get(zone, 'country._id'))
          filteredCities.push(get(zone, 'city._id'))
          filteredDistricts.push(get(zone, 'district._id'))
          return zone._id
        }
      }
      return false
    })
    let selectedDistricts = districts.filter((district) => {
      for (let i = 0; i < val.length; i++) {
        const selectedId = val[i]
        if (get(district, 'city.country._id') === selectedId || get(district, 'city._id') === selectedId) {
          return district._id
        } else if (district._id === selectedId) {
          filteredCountries.push(get(district, 'city.country._id'))
          filteredCities.push(get(district, 'city._id'))
          return district._id
        }
      }
      return false
    })
    let selectedCities = cities.filter((city) => {
      for (let i = 0; i < val.length; i++) {
        const selectedId = val[i]
        if (get(city, 'country._id') === selectedId) {
          return city._id
        } else if (city._id === selectedId) {
          filteredCountries.push(get(city, 'country._id'))
          return city._id
        }
      }
      return false
    })
    let selectedCountries = countries.filter((country) => {
      for (let i = 0; i < val.length; i++) {
        const selectedId = val[i]
        if (country._id === selectedId) {
          return country._id
        }
      }
      return false
    })
    let appendedCountries = filteredCountries.concat(selectedCountries.map((z) => z._id))
    let appendedCities = filteredCities.concat(selectedCities.map((z) => z._id))
    let appendedDistricts = filteredDistricts.concat(selectedDistricts.map((z) => z._id))
    appendedCountries = [...new Set(appendedCountries)]
    appendedCities = [...new Set(appendedCities)]
    appendedDistricts = [...new Set(appendedDistricts)]
    setZones({
      selectedCities: appendedCities,
      selectedCountries: appendedCountries,
      selectedDistricts: appendedDistricts,
      selectedZones: filteredZones.map((z) => z._id),
    })
  }

  return !zonesLoading && !availableZonesLoading ? (
    <TreeSelect
      treeData={treeData || []}
      value={selectedValue.length === 0 ? initialValue : selectedValue}
      onChange={(value) => {
        onSelect(value)
      }}
      treeCheckable={true}
      showCheckedStrategy={SHOW_PARENT}
      searchPlaceholder={'من فضلك اختر مناطقك'}
      style={{ width: '75%' }}
    />
  ) : (
    <Icon type='loading' style={{ fontSize: 50, color: 'var(--color-secondary' }} spin />
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(TreeSelectForm)
