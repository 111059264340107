import { tableFields, fields } from '../schema'
import {
  getBuyerCategories,
  deleteBuyerCategory,
  addBuyerCategory,
  updateBuyerCategory,
} from '../../../modules/BuyerCategory/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const buyerCategories = {
  view: {
    get: getBuyerCategories,
    delete: deleteBuyerCategory,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addBuyerCategory,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.description'],
        dataIndex: 'description',
        fields: [
          fields.text({
            title: arabic_labels['form.description'],
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الوصف' : true),
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
    ],
  },
  edit: {
    update: updateBuyerCategory,
    get: getBuyerCategories,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.description'],
        dataIndex: 'description',
        fields: [fields.textArea({ title: '', dataIndex: 'ar' })],
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الوصف' : true),
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
    ],
  },
}
