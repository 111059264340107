import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  DELETE_SUBSCRIPTION,
  ADD_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from '../../../../config/constants/ActionTypes'

//Get Subscriptions Request
export const getSubscriptions = (payload) => {
  return {
    type: GET_SUBSCRIPTIONS,
    payload,
  }
}

//Get Subscriptions Success
export const getSubscriptionsSuccess = (subscriptions) => {
  return {
    type: GET_SUBSCRIPTIONS_SUCCESS,
    payload: { subscriptions },
  }
}

//Get Subscriptions Failure
export const getSubscriptionsFailure = (error) => {
  return {
    type: GET_SUBSCRIPTIONS_FAILURE,
    payload: { error },
  }
}

//Delete Subscription
export const deleteSubscription = (payload) => {
  return {
    type: DELETE_SUBSCRIPTION,
    payload,
  }
}

//Add Subscription
export const addSubscription = (payload) => {
  return {
    type: ADD_SUBSCRIPTION,
    payload,
  }
}

//update Subscription
export const updateSubscription = (payload) => {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload,
  }
}
