import {
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_CATEGORIES_SUCCESS: {
      return {
        ...state,
        subCategories: action.payload.subCategories,
      }
    }

    case GET_SUB_CATEGORIES_FAILURE: {
      return {
        ...state,
        getSubCategoriesError: action.payload.error,
      }
    }

    default:
      return state
  }
}
