import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  DELETE_ROLE,
  ADD_ROLE,
  UPDATE_ROLE,
} from '../../../../config/constants/ActionTypes'

//Get Roles Request
export const getRoles = (payload) => {
  return {
    type: GET_ROLES,
    payload,
  }
}

//Get Roles Success
export const getRolesSuccess = (roles) => {
  return {
    type: GET_ROLES_SUCCESS,
    payload: { roles },
  }
}

//Get Roles Failure
export const getRolesFailure = (error) => {
  return {
    type: GET_ROLES_FAILURE,
    payload: { error },
  }
}

//Delete Role
export const deleteRole = (payload) => {
  return {
    type: DELETE_ROLE,
    payload,
  }
}

//Add Role
export const addRole = (payload) => {
  return {
    type: ADD_ROLE,
    payload,
  }
}

//update Role
export const updateRole = (payload) => {
  return {
    type: UPDATE_ROLE,
    payload,
  }
}
