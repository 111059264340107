import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const BrandsTable = () => {  

  return(
    <div>
            <TableHolder>

      <View componentName='brands' config={config.brands} />
      </TableHolder>

    </div>
  )
}

export default BrandsTable


const TableHolder  = styled.div`
@media (max-width:767px){

  td:nth-child(3), td:nth-child(4) {
    display: inline-block;
    text-align: center;
  }
}

`