import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

const EnterButton = (props) => {
  return (
    <EnterButtonStyle {...props} size='large'>
      دخول
    </EnterButtonStyle>
  )
}

const EnterButtonStyle = styled(Button)`
  border-radius: 8px;
  height:50px;
  box-shadow: 0 4px 25px 0 rgba(0,0,0,0.1);
  border: 0px;
  background-color: var(--color-secondary);
  font-weight: 500;
  color: var(--color-font-white);
  font-size: 25px;
  text-transform: uppercase;
  padding: 0 30px;
  padding-bottom: 10px;
  margin-right: 40px;
@media (max-width:768px){
  padding: 0 60px;

}

  :hover, :focus {
    border-radius: 8px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    border: 0px;
    background-color: var(--color-font-white);
    font-weight: 500;
    color: var(--color-secondary);
    font-size: 25px;
    text-transform: uppercase;
    
  }

`


export default EnterButton
