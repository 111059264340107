/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Row, Col, Select, message } from 'antd'
import Button from '../../../Form/components/Button'
import { pathOr, prop } from 'ramda'
import componentMapper from '../../../../config/Structure/componentMapper'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'
import ZoneTreeSelect from '../ZoneTreeSelect'
import { getBuyerCategories } from '../../../BuyerCategory/store/actions'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'

const { Option } = Select
const OrdersTable = ({ dispatch }) => {
  const DatePicker = componentMapper['datePicker']
  const location = useLocation()
  const [children, setChildren] = useState([])
  const [fetchingColumns, setFetchingColumns] = useState(true)
  const [tempFilters, setTempFilters] = useState({})
  const [customParams, setCustomParams] = useState({})
  const query = queryString.parse(location.search, { arrayFormat: 'comma' })
  const orderStatuses = [
    { value: 0, label: 'قيد الانتظار' },
    { value: 1, label: 'قيد المعالجة' },
    { value: 2, label: 'جاهز للشحن' },
    { value: 3, label: 'تم الشحن' },
    { value: 4, label: 'تم التوصيل' },
    { value: 5, label: 'ملغي' },
    { value: 6, label: 'مرفوض' },
    { value: 7, label: 'تم الاستلام' },
    { value: 8, label: 'في الطريق' },
  ]
  const getBuyerCategoriesColumns = () => {
    dispatch(getBuyerCategories({ onSuccess, onFailure }))
  }
  useEffect(() => {
    getBuyerCategoriesColumns()
  }, [])
  const onSuccess = ({ data, count }) => {
    let result = data.filter((item) => item !== null)
    setChildren(
      result.map((datum) => (
        <Option key={datum?._id} value={`${datum?._id},${datum?.name?.ar}`}>
          {pathOr(prop('name', datum), ['name', 'ar'], datum)}
        </Option>
      )),
    )
    setFetchingColumns(false)
  }

  const onFailure = (error) => {
    setFetchingColumns(false)
    message.error('حدث خطأ')
  }

  const filter = () => {
    setCustomParams({ ...tempFilters })
    //I 'll leave this here in case we need to put filters values in URL
    // let queryObj = { ...query, ...tempFilters }
    // delete queryObj.zones
    // const search = queryString.stringify(queryObj, { arrayFormat: 'comma' })
    // history.push({
    //   pathname: '/orders',
    //   search,
    // })
  }
  return (
    <div>
      <TableHolder>
        <Row gutter={32}>
          <Col xl={8}>
            <SelectInputStyle>
              <Select
                optionFilterProp='children'
                showSearch={true}
                disabled={false}
                placeholder={'الحالة'}
                mode={'multiple'}
                dropdownMenuStyle={{
                  background: 'var(--color-primary)',
                  color: 'var(--color-font-secondary)',
                }}
                name={'الحالة'}
                filterOption={true}
                onChange={(_, values) => setTempFilters({ ...tempFilters, status: values.map((s) => s.key) })}
              >
                {orderStatuses.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </SelectInputStyle>
          </Col>
          <Col xl={8}>
            <SelectInputStyle>
              <Select
                showSearch={true}
                disabled={false}
                loading={fetchingColumns}
                placeholder={'القطاعات'}
                mode='multiple'
                dropdownMenuStyle={{
                  background: 'var(--color-primary)',
                  color: 'var(--color-font-secondary)',
                }}
                name={'القطاعات'}
                filterOption={true}
                onChange={(_, values) => setTempFilters({ ...tempFilters, buyerCategories: values.map((val) => val.key) })}
              >
                {children}
              </Select>
            </SelectInputStyle>
          </Col>
          <Col xl={8}>
            <SelectInputStyle>
              <ZoneTreeSelect setTempFilters={setTempFilters} tempFilters={tempFilters} value={query.zones} />
              {/* <Option >
                {/* </Option> */}
            </SelectInputStyle>
          </Col>
        </Row>
        <Row gutter={32} style={{ display: 'flex', direction: 'rtl' }}>
          <Col xl={8}>
            <DatePickerStyle>
              <DatePicker
                locale='ar'
                title='من تاريخ'
                onChange={(value) => {
                  if (!isNaN(value)) setTempFilters({ ...tempFilters, startDate: value })
                }}
              />
            </DatePickerStyle>
          </Col>
          <Col xl={8}>
            <DatePickerStyle>
              <DatePicker
                locale='ar'
                title='الي تاريخ'
                onChange={(value) => {
                  if (!isNaN(value)) setTempFilters({ ...tempFilters, endDate: value })
                }}
              />
            </DatePickerStyle>
          </Col>
          <Col xl={6}>
            <Button style={{ width: '50%' }} color='primary' variant='contained' onClick={() => filter()}>
              بحث
            </Button>
          </Col>
        </Row>
        <View componentName='orders' config={config.orders} customParams={customParams} exportConfig={config.exportData} />
      </TableHolder>
    </div>
  )
}

export default connect()(OrdersTable)

const TableHolder = styled.div``

const SelectInputStyle = styled.div`
  .ant-select-selection .ant-select-selection__choice {
    background: var(--color-primary);
    border: 0;
    border-radius: 2rem;
    font-size: 1rem;
    color: var(--color-font-secondary);
  }

  input.MuiInputBase-input.MuiInput-input,
  .ant-select-dropdown-menu-item {
    color: var(--color-form) !important;
  }

  .ant-select-remove-icon {
    color: var(--color-font-secondary);
  }

  .ant-select-selection {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #8dc3e72b;
    font-weight: 300;
    color: var(--color-font-primary);
    font-size: 1.25rem;
    text-transform: uppercase;
    border-radius: 0;
  }

  .ant-select-selection {
    min-height: 32px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1;
    width: 300px;
  }

  .ant-select-arrow .ant-select-arrow-icon svg {
    display: none;
  }
  i.anticon.anticon-down.ant-select-arrow-icon:before {
    content: '\f107';
    transition: 0.3s;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  i.anticon.anticon-down.ant-select-arrow-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon.anticon-down,
  .anticon-spin {
    font-size: 1.25rem;
    color: var(--color-secondary);
    position: relative;
    bottom: 0.5rem;
  }
`
const DatePickerStyle = styled.div`
  width: 78%;
`
