import { tableFields } from '../schema'
import arabic_labels from '../../constants/arabic_labels.json'
import { getUnitsReport } from '../../../modules/OrdersReportView/store/actions'

export const unitsReport = {
  NoAdd: true,
  NoSearch: true,
  //NoPagination: false,
  view: {
    get: getUnitsReport,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'product.name'),
      tableFields.text(arabic_labels['form.carton'], 'quantity[0].quantity'),
      tableFields.text(arabic_labels['form.can'], 'quantity[1].quantity'),
      tableFields.text(
        arabic_labels['form.total'],
        'total',
        (val) => !!val && parseFloat(val).toFixed(2)
      ),
      tableFields.text(arabic_labels['form.orderId'], 'orderId'),
      tableFields.text(arabic_labels['table.orderDate'], 'createdAt', (val) =>
        val
          ? new Date(val).toLocaleString('ar-eg', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          : '-'
      ),
    ],
  },
}
