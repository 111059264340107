import { tableFields, fields, formFields } from '../schema'
import {
  getDistricts,
  deleteDistrict,
  addDistrict,
  updateDistrict,
} from '../../../modules/District/store/actions'
import { getCities } from '../../../modules/City/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const districts = {
  view: {
    get: getDistricts,
    delete: deleteDistrict,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.city'], 'city.name'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addDistrict,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        'المدينة',
        'city',
        'default',
        { query: getCities },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
      ),
    ],
  },
  edit: {
    update: updateDistrict,
    get: getDistricts,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.city'],
        dataIndex: 'city',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCities },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
          ),
        ],
      }),
    ],
  },
}
