import { tableFields, fields, formFields } from '../schema'
import { getTags, deleteTag, addTag, updateTag } from '../../../modules/Tag/store/actions'
import { getSubTypes } from '../../../modules/SubType/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const tags = {
  view: {
    get: getTags,
    delete: deleteTag,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.subType'], 'subType.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addTag,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        arabic_labels['form.subType'],
        'subType',
        'default',
        { query: getSubTypes },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: ' لا بد من إختيار المجموعه الفرعيه ' }],
        null,
        null,
        {
          key: 'subType',
        },
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيارالمجموعه الفرعيه' : true),
      ),
    ],
  },
  edit: {
    update: updateTag,
    get: getTags,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.subType'],
        dataIndex: 'subType',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getSubTypes },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المجموعه الفرعيه' }],
            null,
            null,
            {
              key: 'subType',
            },
          ),
        ],
      }),
    ],
  },
}
