import {
  GET_DISTRIBUTORS,
  GET_DISTRIBUTORS_SUCCESS,
  GET_DISTRIBUTORS_FAILURE,
  DELETE_DISTRIBUTOR,
  ADD_DISTRIBUTOR,
  UPDATE_DISTRIBUTOR,
  GET_DISTRIBUTOR,
} from '../../../../config/constants/ActionTypes'

//Get DISTRIBUTORS Request
export const getDistributors = (payload) => {
  return {
    type: GET_DISTRIBUTORS,
    payload,
  }
}

//Get Distributors Success
export const getDistributorsSuccess = (distributors) => {
  return {
    type: GET_DISTRIBUTORS_SUCCESS,
    payload: { distributors },
  }
}

//Get Distributors Failure
export const getDistributorsFailure = (error) => {
  return {
    type: GET_DISTRIBUTORS_FAILURE,
    payload: { error },
  }
}

//Delete Distributor
export const deleteDistributor = (payload) => {
  return {
    type: DELETE_DISTRIBUTOR,
    payload,
  }
}

//get Distributor
export const getDistributor = (payload) => {
  return {
    type: GET_DISTRIBUTOR,
    payload,
  }
}

//Add Distributor
export const addDistributor = (payload) => {
  return {
    type: ADD_DISTRIBUTOR,
    payload,
  }
}
//update Distributors
export const updateDistributor = (payload) => {
  return {
    type: UPDATE_DISTRIBUTOR,
    payload,
  }
}
