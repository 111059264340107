import { tableFields, fields, formFields } from '../schema'
import { getBrands, deleteBrand, addBrand, editBrand } from '../../../modules/Brand/store/actions'
import { getCompanies } from '../../../modules/Company/store/actions'
// eslint-disable-next-line no-unused-vars
import { statics } from '../statics'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const brands = {
  view: {
    get: getBrands,
    delete: deleteBrand,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.company'], 'company.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addBrand,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        arabic_labels['form.company'],
        'company',
        'default',
        { query: getCompanies },
        null,
        null,
        null,
        null,
        null,
        false,
      ),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
    ],
  },
  edit: {
    update: editBrand,
    get: getBrands,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.company'],
        dataIndex: 'company',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCompanies },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            'company',
          ),
        ],
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
    ],
  },
}
