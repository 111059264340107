import { tableFields, fields, formFields } from '../schema'
import {
  getBuyers,
  deleteBuyer,
  addBuyer,
  updateBuyer,
  updateBuyerHandshake,
} from '../../../modules/Buyer/store/actions'
import { getCities } from '../../../modules/City/store/actions'
import { getDistricts } from '../../../modules/District/store/actions'
import { getZones } from '../../../modules/Zone/store/actions'
import { getBuyerCategories } from '../../../modules/BuyerCategory/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'
const logo = require('../../../img/logo-new.png')

export const buyers = {
  view: {
    get: getBuyers,
    delete: deleteBuyer,
    fields: [
      tableFields.image(arabic_labels['form.logo'], 'images[0]', value => value || logo),
      tableFields.text(arabic_labels['form.accountPhoneNumber'], 'accountPhoneNumber'),
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.manager'], 'manager_name.ar'),
      tableFields.text(arabic_labels['form.institution.activity'], 'activity', value =>
        Array.isArray(value) ? value.join('-') : value,
      ),
      tableFields.text(arabic_labels['form.institution.phone'], 'phone'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addBuyer,
    fields: [
      fields.text({
        title: arabic_labels['form.accountPhoneNumber'],
        dataIndex: 'accountPhoneNumber',
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال رقم التليفون المحمول' : true),
      }),
      fields.object({
        title: arabic_labels['form.institution'],
        dataIndex: 'name',
        fields: [
          fields.text({
            title: arabic_labels['form.institution'],
            dataIndex: 'ar',
          }),
        ],
        validate: fieldValue => (!path(['ar'], fieldValue) ? 'برجاء إدخال إسم المنشأة' : true),
      }),
      fields.object({
        title: arabic_labels['form.manager'],
        dataIndex: 'manager_name',
        fields: [
          fields.text({
            title: arabic_labels['form.manager'],
            dataIndex: 'ar',
          }),
        ],
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال إسم مدير المنشأة' : true),
      }),
      formFields.select(
        arabic_labels['form.institution.activity'],
        'activity',
        'multiple',
        {
          list: [
            { key: 'كشك', value: 'كشك' },
            { key: 'سوبر ماركت', value: 'سوبر ماركت' },
            { key: 'سلالسل', value: 'سلالسل' },
            { key: 'مطاعم', value: 'مطاعم' },
            { key: 'تاجر جملة', value: 'تاجر جملة' },
            { key: 'تاجر قطاعي', value: 'تاجر قطاعي' },
          ],
        },
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        null,
        null,
        fieldValue => (!fieldValue ? 'برجاء إدخال نشاط المنشأة' : true),
      ),
      fields.text({
        title: arabic_labels['form.email'],
        dataIndex: 'email',
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال البريد الألكتروني' : true),
      }),
      fields.object({
        title: 'العنوان',
        dataIndex: 'address',
        fields: [
          formFields.select(
            'المدينه',
            'city',
            'default',
            { query: getCities },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المدينه' }],
          ),
          formFields.select(
            'الحي',
            'district',
            'default',
            { query: getDistricts },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار الحي' }],
            'address.city',
            'city',
          ),
          formFields.select(
            'المنطقه',
            'zone',
            'default',
            { query: getZones },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار المنطقه' }],
            'address.district',
            'district',
          ),
          fields.text({ title: 'الشارع', dataIndex: 'street' }),
          fields.text({ title: 'رقم المبني', dataIndex: 'buildingNumber' }),
        ],
        validate: fieldValue =>
          !path(['city'], fieldValue) ||
          !path(['district'], fieldValue) ||
          !path(['zone'], fieldValue)
            ? 'لا بد من إختيار المدينه و الحي و المنطقه'
            : true,
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.text({
        title: arabic_labels['form.institution.phone'],
        dataIndex: 'phone',
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال رقم تليفون المنشأة' : true),
      }),
      fields.text({
        title: arabic_labels['form.password'],
        dataIndex: 'password',
        inputType: 'password',
        validate: fieldValue => (!fieldValue ? 'برجاء ادخال كلمة السر' : true),
      }),
      fields.number({
        title: arabic_labels['form.points'],
        dataIndex: 'points',
        min:0
      }),
    ],
  },
  roleEdit: {
    update: updateBuyerHandshake,
    get: getBuyers,
    fields: [
      formFields.select(
        arabic_labels['form.buyerCategory'],
        'buyerCategory',
        'default',
        { query: getBuyerCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
      ),
    ],
  },
  edit: {
    update: updateBuyer,
    get: getBuyers,
    fields: [
      fields.text({
        title: arabic_labels['form.accountPhoneNumber'],
        dataIndex: 'accountPhoneNumber',
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال رقم التليفون المحمول' : true),
      }),
      fields.object({
        title: arabic_labels['form.institution'],
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: fieldValue => (!path(['ar'], fieldValue) ? 'برجاء إدخال إسم المنشأة' : true),
      }),
      fields.object({
        title: arabic_labels['form.manager'],
        dataIndex: 'manager_name',
        fields: [fields.text({ title: '', dataIndex: 'ar' })],
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال إسم مدير المنشأة' : true),
      }),
      formFields.select(
        arabic_labels['form.institution.activity'],
        'activity',
        'multiple',
        {
          list: [
            { key: 'كشك', value: 'كشك' },
            { key: 'سوبر ماركت', value: 'سوبر ماركت' },
            { key: 'سلالسل', value: 'سلالسل' },
            { key: 'مطاعم', value: 'مطاعم' },
            { key: 'تاجر جملة', value: 'تاجر جملة' },
            { key: 'تاجر قطاعي', value: 'تاجر قطاعي' },
          ],
        },
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        null,
        null,
        fieldValue => (!fieldValue ? 'برجاء إدخال نشاط المنشأة' : true),
      ),
      fields.text({
        title: arabic_labels['form.email'],
        dataIndex: 'email',
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال البريد الألكتروني' : true),
      }),
      fields.object({
        title: 'العنوان',
        dataIndex: 'address',
        fields: [
          formFields.select(
            'المدينه',
            'city',
            'default',
            { query: getCities },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المدينه' }],
          ),
          formFields.select(
            'الحي',
            'district',
            'default',
            { query: getDistricts },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار الحي' }],
            'address.city',
            'city',
          ),
          formFields.select(
            'المنطقه',
            'zone',
            'default',
            { query: getZones },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار المنطقه' }],
            'address.district',
            'district',
          ),
          fields.text({ title: 'الشارع', dataIndex: 'street' }),
          fields.text({ title: 'رقم المبني', dataIndex: 'buildingNumber' }),
        ],
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.text({
        title: arabic_labels['form.institution.phone'],
        dataIndex: 'phone',
        validate: fieldValue => (!fieldValue ? 'برجاء إدخال رقم تليفون المنشأة' : true),
      }),
      fields.text({
        title: arabic_labels['form.password'],
        dataIndex: 'password',
        inputType: 'password',
        validate: fieldValue => (!fieldValue ? 'برجاء ادخال كلمة السر' : true),
      }),
      fields.number({
        title: arabic_labels['form.points'],
        dataIndex: 'points',
        min:0
      }),
    ],
  },
}
