import {
  GET_SUPERADMINS,
  GET_SUPERADMINS_SUCCESS,
  GET_SUPERADMINS_FAILURE,
  DELETE_SUPERADMIN,
  ADD_SUPERADMIN,
  UPDATE_SUPERADMIN,
} from '../../../../config/constants/ActionTypes'

//Get Superadmins Request
export const getSuperadmins = (payload) => {
  return {
    type: GET_SUPERADMINS,
    payload,
  }
}

//Get Superadmins Success
export const getSuperadminsSuccess = (superadmins) => {
  return {
    type: GET_SUPERADMINS_SUCCESS,
    payload: { superadmins },
  }
}

//Get Superadmins Failure
export const getSuperadminsFailure = (error) => {
  return {
    type: GET_SUPERADMINS_FAILURE,
    payload: { error },
  }
}

//Delete Superadmin
export const deleteSuperadmin = (payload) => {
  return {
    type: DELETE_SUPERADMIN,
    payload,
  }
}

//Add Superadmins
export const addSuperadmin = (payload) => {
  return {
    type: ADD_SUPERADMIN,
    payload,
  }
}

//update Superadmin
export const updateSuperadmin = (payload) => {
  return {
    type: UPDATE_SUPERADMIN,
    payload,
  }
}
