import { tableFields, fields, formFields } from '../schema'
import {
  getStaffs,
  getStaff,
  deleteStaff,
  addStaff,
  updateStaff,
} from '../../../modules/Staff/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const staff = {
  view: {
    get: getStaffs,
    delete: deleteStaff,
    fields: [
      tableFields.text(arabic_labels['form.firstname'], 'firstname.ar'),
      tableFields.text(arabic_labels['lastname.name'], 'lastname.ar'),
      tableFields.text(arabic_labels['form.sellerCategory'], 'sellerCategory'),
      tableFields.text(arabic_labels['form.sellerRef'], 'sellerRef.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addStaff,
    fields: [
      fields.object({
        title: arabic_labels['form.firstname'],
        dataIndex: 'firstname',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'لا بد من ادخال الاسم الاول' : true),
      }),
      fields.object({
        title: arabic_labels['lastname.name'],
        dataIndex: 'lastname',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) =>
          !path(['ar'], fieldValue)
            ? ' لا بد من ادخال' + arabic_labels['lastname.name']
            : true,
      }),
      fields.text({
        title: arabic_labels['form.password'],
        inputType: 'password',
        dataIndex: 'password',
        validate: (fieldValue) => (!fieldValue ? 'لا بد من ادخال كلمة السر' : true),
      }),
      fields.text({
        title: arabic_labels['form.email'],
        dataIndex: 'email',
        validate: (fieldValue) => (!fieldValue ? 'لا بد من ادخال البريد الإلكتروني' : true),
      }),
      formFields.select(
        arabic_labels['form.sellerCategory'],
        'sellerCategory',
        'default',
        {
          list: [
            { key: arabic_labels['form.company'], value: 'companies/getCompanies' },
            { key: arabic_labels['form.distributor'], value: 'distributors/getDistributors' },
          ],
        },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار نوع البائعين' }],
        null,
        null,
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار نوع البائعين' : true),
      ),
      formFields.select(
        arabic_labels['form.sellerRef'],
        'sellerRef',
        'default',
        { dynamicSource: 'sellerCategory' },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار البائع' }],
        'sellerCategory',
        'sellerCategory',
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار البائع' : true),
      ),
    ],
  },
  edit: {
    update: updateStaff,
    get: getStaff,
    fields: [
      fields.object({
        title: arabic_labels['form.firstname'],
        dataIndex: 'firstname',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'لا بد من ادخال الاسم الاول' : true),
      }),
      fields.object({
        title: arabic_labels['lastname.name'],
        dataIndex: 'lastname',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) =>
          !path(['ar'], fieldValue)
            ? ' لا بد من ادخال' + arabic_labels['lastname.name']
            : true,
      }),
      fields.text({
        title: arabic_labels['form.email'],
        dataIndex: 'email',
        validate: (fieldValue) => (!fieldValue ? 'لا بد من ادخال البريد الإلكتروني' : true),
      }),
      formFields.select(
        arabic_labels['form.sellerCategory'],
        'sellerCategory',
        'default',
        {
          list: [
            { key: arabic_labels['form.company'], value: 'companies/getCompanies' },
            { key: arabic_labels['form.distributor'], value: 'distributors/getDistributors' },
          ],
        },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار البلد' }],
        null,
        null,
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار نوع البائعين' : true),
      ),
      formFields.select(
        arabic_labels['form.sellerRef'],
        'sellerRef',
        'default',
        { dynamicSource: 'sellerCategory' },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار المدينه' }],
        'sellerCategory',
        'sellerCategory',
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار البائع' : true),
      ),
    ],
  },
}
