import {
  EXPORT_DATA,
  EXPORT_DATA_SUCCESS,
  EXPORT_DATA_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Get Exported Data Request
export const getExportedData = (payload) => {
  return {
    type: EXPORT_DATA,
    payload,
  }
}

//Get Exported Data Success
export const getExportedDataSuccess = (dataSheet) => {
  return {
    type: EXPORT_DATA_SUCCESS,
    payload: { dataSheet },
  }
}

//Get Exported Data Failure
export const getExportedDataFailure = (error) => {
  return {
    type: EXPORT_DATA_FAILURE,
    payload: { error },
  }
}
