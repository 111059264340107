import {
  GET_SIZES,
  GET_SIZES_SUCCESS,
  GET_SIZES_FAILURE,
  DELETE_SIZE,
  ADD_SIZE,
  ADD_SIZE_SUCCESS,
  ADD_SIZE_FAILURE,
  EDIT_SIZE,
  EDIT_SIZE_SUCCESS,
  EDIT_SIZE_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Get Sizes Request
export const getSizes = (payload) => {
  return {
    type: GET_SIZES,
    payload,
  }
}

//Get Sizes Success
export const getSizesSuccess = (sizes) => {
  return {
    type: GET_SIZES_SUCCESS,
    payload: { sizes },
  }
}

//Get Sizes Failure
export const getSizesFailure = (error) => {
  return {
    type: GET_SIZES_FAILURE,
    payload: { error },
  }
}

// //Get Size Request
// export const getSize = (payload) => {
//   return {
//     type: GET_SIZE,
//     payload,
//   }
// }

// //Get Size Success
// export const getSizeSuccess = (size) => {
//   return {
//     type: GET_SIZE_SUCCESS,
//     payload: { size },
//   }
// }

// //Get Size Failure
// export const getSizeFailure = (error) => {
//   return {
//     type: GET_SIZE_FAILURE,
//     payload: { error },
//   }
// }

//Add Size Request
export const addSize = (payload) => {
  return {
    type: ADD_SIZE,
    payload,
  }
}

//Add Size Success
export const addSizeSuccess = (message) => {
  return {
    type: ADD_SIZE_SUCCESS,
    payload: { message },
  }
}

//Add Size Failure
export const addSizeFailure = (error) => {
  return {
    type: ADD_SIZE_FAILURE,
    payload: { error },
  }
}

//Edit Size Request
export const editSize = (payload) => {
  return {
    type: EDIT_SIZE,
    payload,
  }
}

//Edit Size Success
export const editSizeSuccess = (message) => {
  return {
    type: EDIT_SIZE_SUCCESS,
    payload: { message },
  }
}

//Edit Size Failure
export const editSizeFailure = (error) => {
  return {
    type: EDIT_SIZE_FAILURE,
    payload: { error },
  }
}

//Delete Sizes
export const deleteSize = (payload) => {
  return {
    type: DELETE_SIZE,
    payload,
  }
}
