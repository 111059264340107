import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { isEmpty, path, pluck } from 'ramda'
import { getStaffType } from '../../../../services/commonServices'
import { uploadImages } from '../../../../config/Client/actions/Upload'
import { getCompanyDistributors } from '../../../Company/store/actions'

const AddRepForm = ({ dispatch, state }) => {
  const { reps } = config
  const history = useHistory()
  const location = useLocation()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState(false)

  const query = queryString.parse(location.search)

  useEffect(() => {
    // set initial values
    setValues(isEmpty(query) ? undefined : query)
    // verify user access privileges
    if (path(['auth', 'user', 'staff'], state)) {
      if (getStaffType(path(['auth', 'user', 'staff'], state)) === 'company') {
        dispatch(
          getCompanyDistributors({
            query: { _id: path(['auth', 'user', 'staff', 'sellerRef', '_id'], state) },
            onSuccess: accessVerificationOnSuccess,
            onFailure: accessVerificationOnFailure,
          })
        )
      }
    }
  }, []) // eslint-disable-line

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(reps.add.create({ body: values, onSuccess, onFailure }))
  }

  const accessVerificationOnSuccess = (result) => {
    if (!pluck('_id', result).includes(query.distributor))
      history.push(`/companies/view/${path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)}`)
  }

  const accessVerificationOnFailure = (error) => {
    console.log('error: ', error)
    history.push(`/companies/view/${path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)}`)
  }

  const onSuccess = (id, imageFiles) => {
    if (imageFiles && imageFiles.length > 0) {
      dispatch(
        uploadImages({
          imageArray: imageFiles,
          moduleName: 'reps',
          _id: id,
          onSuccess: onUploadSuccess,
          onFailure,
        })
      )
    } else {
      setSubmitting(false)
      message.success('تمت الاضافة بنجاح')
      history.push(`/reps${location.search}`)
    }
  }

  const onUploadSuccess = (imageLinkArray, values, id) => {
    dispatch(
      reps.edit.update({
        body: { images: imageLinkArray, _id: id },
        onSuccess: onEditSuccess,
        onFailure,
      })
    )
  }

  const onEditSuccess = () => {
    setSubmitting(false)
    message.success('تمت الاضافة بنجاح')
    history.push(`/reps${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    if (error.toString().search(/status code 409/) < 0) message.error('حدث خطأ')
    else message.error('حدث خطاء: سبق تسجيل هذا الرقم')
  }
  return (
    <CrudForm
      title="أضف مندوب جديد"
      loading={false}
      fields={config.reps.add.fields}
      onSubmit={onSubmit}
      buttonText="أضف"
      submitting={submitting}
      initialValues={values}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(AddRepForm)
