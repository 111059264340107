import { tableFields, fields, formFields } from '../schema'
import {
  getCompanies,
  deleteCompany,
  addCompany,
  updateCompany,
  getCompany,
} from '../../../modules/Company/store/actions'
import ZonesTreeSelect from '../../../modules/Company/components/ZonesTreeSelect'
import { getSections } from '../../../modules/Section/store/actions'
import { getCities } from '../../../modules/City/store/actions'
import { getDistricts } from '../../../modules/District/store/actions'
import { getZones } from '../../../modules/Zone/store/actions'
import { getSubscriptions } from '../../../modules/Subscription/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'
const logo = require('../../../img/logo-new.png')

export const companies = {
  view: {
    get: getCompanies,
    delete: deleteCompany,
    fields: [
      // tableFields.action.details(),
      tableFields.image(arabic_labels['form.logo'], 'images[0]', (value) => value || logo),
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.email'], 'email'),
      tableFields.text(arabic_labels['form.phone'], 'phone'),
      tableFields.text(arabic_labels['form.section'], 'section[0].name.ar'),
      tableFields.text(arabic_labels['form.subscription'], 'subscription.name.ar'),
      tableFields.action.goTo(arabic_labels['form.details'], 'company', '/companies/view/{key}'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addCompany,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.text({
        title: 'البريد الألكتروني',
        dataIndex: 'email',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال البريد الألكتروني' : true),
      }),
      fields.text({
        title: 'رقم محمول المسئول',
        dataIndex: 'phone',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال  رقم محمول المسئول' : true),
      }),
      formFields.select(
        'الصناعه',
        'section',
        'multiple',
        { query: getSections },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الصناعه' }],
        null,
        null,
        {
          key: 'section',
        },
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الصناعه' : true),
      ),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.object({
        title: 'العنوان',
        dataIndex: 'address',
        fields: [
          formFields.select(
            'نوع العنوان',
            'type',
            'default',
            {
              list: [
                { key: 'شركة', value: 'شركة' },
                { key: 'مخزن', value: 'مخزن' },
                { key: 'مصنع', value: 'مصنع' },
                { key: 'مكتب', value: 'مكتب' },
              ],
            },
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            null,
            (fieldValue) => (!fieldValue ? 'برجاء إدخال نوع العنوان' : true),
          ),
          formFields.select(
            'المدينه',
            'city',
            'default',
            { query: getCities },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المدينه' }],
            null,
            null,
            null,
            null,
            (fieldValue) => (!fieldValue ? 'لا بد من إختيار المدينه' : true),
          ),
          formFields.select(
            'الحي',
            'district',
            'default',
            { query: getDistricts },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار الحي' }],
            'address.city',
            'city',
          ),
          formFields.select(
            'المنطقه',
            'zone',
            'default',
            { query: getZones },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار المنطقه' }],
            'address.district',
            'district',
          ),
          fields.text({ title: 'الشارع', dataIndex: 'street' }),
          fields.text({ title: 'رقم المبني', dataIndex: 'buildingNumber' }),
          fields.text({
            title: 'تليفون العنوان',
            dataIndex: 'phone',
            validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تليفون العنوان' : true),
          }),
          fields.text({ title: 'خرائط جوجل', dataIndex: 'googleMapLocation' }),
        ],
        validate: (fieldValue) =>
          !path(['city'], fieldValue) ||
            !path(['phone'], fieldValue)
            ? 'لا بد من إختيار المدينه و إدخال تليفون العنوان'
            : true,
      }),
      formFields.select(
        'الأشتراك',
        'subscription',
        'default',
        { query: getSubscriptions },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'subscription',
        },
      ),
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال المنطقة' : true),
      }),
      fields.checkbox({ title: 'فعال', dataIndex: 'status' }),
      fields.checkbox({ title: 'نظام النقاط', dataIndex: 'pointsActivated' }),
    ],
  },
  edit: {
    update: updateCompany,
    get: getCompany,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.text({
        title: 'البريد الألكتروني',
        dataIndex: 'email',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال البريد الألكتروني' : true),
      }),
      fields.text({
        title: 'رقم محمول المسئول',
        dataIndex: 'phone',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال  رقم محمول المسئول' : true),
      }),
      formFields.select(
        'الصناعه',
        'section',
        'multiple',
        { query: getSections },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الصناعه' }],
        null,
        null,
        {
          key: 'section',
        },
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الصناعه' : true),
      ),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.object({
        title: 'العنوان',
        dataIndex: 'address',
        fields: [
          formFields.select(
            'نوع العنوان',
            'type',
            'default',
            {
              list: [
                { key: 'شركة', value: 'شركة' },
                { key: 'مخزن', value: 'مخزن' },
                { key: 'مصنع', value: 'مصنع' },
                { key: 'مكتب', value: 'مكتب' },
              ],
            },
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            null,
            (fieldValue) => (!fieldValue ? 'برجاء إدخال نوع العنوان' : true),
          ),
          formFields.select(
            '',
            'city',
            'default',
            { query: getCities },
            (city) => city._id,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المدينه' }],
            null,
            null,
            null,
            null,
            (fieldValue) => (!fieldValue ? 'لا بد من إختيار المدينه' : true),
          ),
          formFields.select(
            '',
            'district',
            'default',
            { api: 'districts' },
            (district) => district._id,
            ['_id'],
            null,
            null,
            null,
            false,
          ),
          formFields.select(
            'المنطقه',
            'zone',
            'default',
            { api: 'zones' },
            (zone) => zone._id,
            ['_id'],
            null,
            null,
            null,
            false,
          ),
          fields.text({ title: 'الشارع', dataIndex: 'street' }),
          fields.text({ title: 'رقم المبني', dataIndex: 'buildingNumber' }),
          fields.text({
            title: 'تليفون العنوان',
            dataIndex: 'phone',
            validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تليفون العنوان' : true),
          }),
          fields.text({ title: 'خرائط جوجل', dataIndex: 'googleMapLocation' }),
        ],
        dependencyObject: { district: 'city', zone: 'district' },
      }),
      formFields.select(
        'الأشتراك',
        'subscription',
        'default',
        { query: getSubscriptions },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'subscription',
        },
      ),
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
      }),
      fields.checkbox({ title: 'فعال', dataIndex: 'status' }),
      fields.checkbox({ title: 'نظام النقاط', dataIndex: 'pointsActivated' }),
    ],
  },
}
