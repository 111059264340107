import { tableFields } from '../schema'
import { pathOr } from 'ramda'
import { getOrders } from '../../../modules/Order/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { getStatusFromStatusNumber } from '../../../services/commonServices'
import UpdateOrderStatus from '../../../modules/Order/components/UpdateStatus'

export const orders = {
  NoAdd: true,
  NoSearch: false,
  view: {
    get: getOrders,
    fields: [
      tableFields.text(arabic_labels['form.sellerRef'], 'seller.name'),
      tableFields.text(arabic_labels['form.buyer'], 'user.name'),
      tableFields.text(arabic_labels['form.status'], 'status', (statusNumber) => arabic_labels[pathOr('-', ['arabicStatus'], getStatusFromStatusNumber(statusNumber))]),
      tableFields.text(arabic_labels['form.total'], 'total', (total) => Number(total).toFixed(2)),
      tableFields.text(arabic_labels['form.discountedTotal'], 'discountedTotal', (discountedTotal) => Number(discountedTotal).toFixed(2)),
      tableFields.text(arabic_labels['form.rep'], 'rep.name'),
      tableFields.action.custom(arabic_labels['form.status'], 'statusAction', UpdateOrderStatus),
      tableFields.action.goTo(arabic_labels['form.details'], 'order', '/orders/view/{key}'),
    ],
  },
}
