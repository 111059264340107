/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_COMPANIES,
  DELETE_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANY,
  GET_COMPANY_DISTRIBUTORS,
} from '../../../../config/constants/ActionTypes'
import { getCompaniesFailure, getCompaniesSuccess, getCompanies } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'
import { path } from 'ramda'

const getCompaniesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'post',
    url: config.url + 'companies/getCompanies',
    params: { ...query, isDeleted: false },
    headers: { lite },
  })
}

function* getCompaniesSaga({ payload }) {
  try {
    const response = yield call(getCompaniesRequest, payload)
    yield put(getCompaniesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getCompaniesFailure(error))
    payload.onFailure(error)
  }
}

const getCompanyDistributorsRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'companies/companyDistributors',
    params: { ...query, isDeleted: false },
  })
}

function* getCompanyDistributorsSaga({ payload }) {
  try {
    const response = yield call(getCompanyDistributorsRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

const getCompanyRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'companies/allCompanyInfo',
    params: { ...query, isDeleted: false },
  })
}

function* getCompanySaga({ payload }) {
  try {
    const response = yield call(getCompanyRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

const deleteCompanyRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'companies/deleteCompany',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
  })
}

function* deleteCompanySaga({ payload }) {
  try {
    const response = yield call(deleteCompanyRequest, payload)
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addCompanyRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'companies',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addCompanySaga({ payload }) {
  try {
    const response = yield call(addCompanyRequest, payload)
    delete payload._id
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateCompanyRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'companies',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateCompanySaga({ payload }) {
  try {
    const response = yield call(updateCompanyRequest, payload)
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getCompanies(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_COMPANIES, getCompaniesSaga)],
    [yield takeEvery(GET_COMPANY_DISTRIBUTORS, getCompanyDistributorsSaga)],
    [yield takeEvery(DELETE_COMPANY, deleteCompanySaga)],
    [yield takeEvery(ADD_COMPANY, addCompanySaga)],
    [yield takeEvery(UPDATE_COMPANY, updateCompanySaga)],
    [yield takeEvery(GET_COMPANY, getCompanySaga)],
  )
}
