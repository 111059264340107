import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Upload from "./Upload";
import Auth from "../../../modules/Auth/store/reducers";
import Company from "../../../modules/Company/store/reducers";
import Brand from "../../../modules/Brand/store/reducers";
import Section from "../../../modules/Section/store/reducers";
import Category from "../../../modules/Category/store/reducers";
import SubCategory from "../../../modules/SubCategory/store/reducers";
import Size from "../../../modules/Size/store/reducers";
import Type from "../../../modules/Type/store/reducers";
import PackageUnit from "../../../modules/PackageUnit/store/reducers";
import SubType from "../../../modules/SubType/store/reducers";
import Tag from "../../../modules/Tag/store/reducers";
import City from "../../../modules/City/store/reducers";
import District from "../../../modules/District/store/reducers";
import Country from "../../../modules/Country/store/reducers";
import Zone from "../../../modules/Zone/store/reducers";
import BuyerCategory from "../../../modules/BuyerCategory/store/reducers";
import Subscription from "../../../modules/Subscription/store/reducers";
import Role from "../../../modules/Role/store/reducers";
import Superadmin from "../../../modules/Superadmin/store/reducers";
import Package from "../../../modules/Package/store/reducers";
import Distributor from "../../../modules/Distributor/store/reducers";
import Buyer from "../../../modules/Buyer/store/reducers";
import Staff from "../../../modules/Staff/store/reducers";
import Rep from "../../../modules/Rep/store/reducers";
import Order from "../../../modules/Order/store/reducers";
import CompanyProduct from "../../../modules/CompanyProduct/store/reducers";
import PriceOffer from "../../../modules/PriceOffer/store/reducers";
import QuantityOffer from "../../../modules/QuantityOffer/store/reducers";
import BundleOffer from "../../../modules/BundleOffer/store/reducers";
import PendingBuyers from "../../../modules/PendingBuyers/store/reducers";
import PriceList from "../../../modules/PriceList/store/reducers";
import Ad from "../../../modules/Ad/store/reducers";
import OrdersReport from "../../../modules/OrdersReportView/store/reducers";
import Setting from "../../../modules/Setting/store/reducers";

const reducers = combineReducers({
  routing: routerReducer,
  auth: Auth,
  company: Company,
  brand: Brand,
  section: Section,
  category: Category,
  subCategory: SubCategory,
  size: Size,
  type: Type,
  packageUnit: PackageUnit,
  city: City,
  district: District,
  country: Country,
  zone: Zone,
  buyerCategory: BuyerCategory,
  subType: SubType,
  tag: Tag,
  subscription: Subscription,
  role: Role,
  superadmin: Superadmin,
  package: Package,
  distributor: Distributor,
  buyer: Buyer,
  staff: Staff,
  rep: Rep,
  order: Order,
  companyProduct: CompanyProduct,
  priceOffer: PriceOffer,
  quantityOffer: QuantityOffer,
  bundleOffer: BundleOffer,
  upload: Upload,
  pendingBuyers: PendingBuyers,
  priceList: PriceList,
  ad: Ad,
  ordersReport: OrdersReport,
  setting: Setting,
});

export default reducers;
