import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_ORDERS,
  GET_ORDER,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER,
  CANCEL_ORDER,
  REJECT_ORDER,
} from '../../../../config/constants/ActionTypes'
import { getOrdersSuccess, getOrdersFailure, getOrderSuccess, getOrderFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getOrdersRequest = async ({ query }) => {
  const zones = query.zones
  delete query.zones
  return await axios({
    method: 'post',
    url: config.url + 'orders/getOrders',
    params: { ...query, isDeleted: false },
    data: {zones},
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getOrdersSaga({ payload }) {
  try {
    const response = yield call(getOrdersRequest, payload)
    yield put(getOrdersSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getOrdersFailure(error))
    payload.onFailure(error)
  }
}

const getOrderRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'orders/order',
    params: { ...query, isDeleted: false },
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getOrderSaga({ payload }) {
  try {
    const response = yield call(getOrderRequest, payload)
    yield put(getOrderSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getOrderFailure(error))
    payload.onFailure(error)
  }
}

const updateOrderStatusRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'orders/nextStep',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateOrderStatusSaga({ payload }) {
  try {
    const response = yield call(updateOrderStatusRequest, payload) // eslint-disable-line
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateOrderRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'orders',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateOrderSaga({ payload }) {
  try {
    const response = yield call(updateOrderRequest, payload) // eslint-disable-line
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const cancelOrderRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'orders/cancel',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* cancelOrderSaga({ payload }) {
  try {
    const response = yield call(cancelOrderRequest, payload) // eslint-disable-line
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const rejectOrderRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'orders/reject',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* rejectOrderSaga({ payload }) {
  try {
    const response = yield call(rejectOrderRequest, payload) // eslint-disable-line
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_ORDERS, getOrdersSaga)],
    [yield takeEvery(GET_ORDER, getOrderSaga)],
    [yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatusSaga)],
    [yield takeEvery(UPDATE_ORDER, updateOrderSaga)],
    [yield takeEvery(CANCEL_ORDER, cancelOrderSaga)],
    [yield takeEvery(REJECT_ORDER, rejectOrderSaga)]
  )
}
