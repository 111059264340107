import { all, call, put, takeEvery } from 'redux-saga/effects'
import { EXPORT_DATA } from '../../../../config/constants/ActionTypes'
import { getExportedDataSuccess, getExportedDataFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getExportedDatRequest = async ({ query }) => {
  const zones = query.zones
  delete query.zones
  return await axios({
    method: 'post',
    url: config.url + 'exportData/exportData',
    params: { ...query, isDeleted: false },
    data: {zones},
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getExportedDataSaga({ payload }) {
  try {
    const response = yield call(getExportedDatRequest, payload)
    yield put(getExportedDataSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getExportedDataFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(EXPORT_DATA, getExportedDataSaga)])
}
