import { tableFields, fields, formFields } from '../schema'
import {
  getCompanyProducts,
  deleteCompanyProduct,
  addCompanyProduct,
  addCompanyProducts,
  updateCompanyProduct,
} from '../../../modules/CompanyProduct/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { getBuyerCategories } from '../../../modules/BuyerCategory/store/actions'
import { getCountries } from '../../../modules/Country/store/actions'
import { getTags } from '../../../modules/Tag/store/actions'
import { getSubTypes } from '../../../modules/SubType/store/actions'
import { getTypes } from '../../../modules/Type/store/actions'
import { getSubCategories } from '../../../modules/SubCategory/store/actions'
import { getCategories } from '../../../modules/Category/store/actions'
import { getSections } from '../../../modules/Section/store/actions'
import { getBrands } from '../../../modules/Brand/store/actions'
import { getCompanies } from '../../../modules/Company/store/actions'
import { getPackages } from '../../../modules/Package/store/actions'
import { getPackageUnits } from '../../../modules/PackageUnit/store/actions'
import { getSizes } from '../../../modules/Size/store/actions'
import { path } from 'ramda'
const logo = require('../../../img/logo-new.png')

export const companyProducts = {
  view: {
    get: getCompanyProducts,
    delete: deleteCompanyProduct,
    add: addCompanyProducts,
    fields: [
      tableFields.image(
        arabic_labels['form.images'],
        'images[0]',
        (value) => value || logo
      ),
      tableFields.text(arabic_labels['form.productName'], 'name.ar'),
      tableFields.text(arabic_labels['form.company'], 'company.name.ar'),
      tableFields.text(arabic_labels['form.category'], 'category.name.ar'),
      tableFields.text(arabic_labels['form.erpCode'], 'erpCode'),
      tableFields.text(arabic_labels['form.brand'], 'brand.name.ar'),
      tableFields.text(arabic_labels['form.points'], 'points'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addCompanyProduct,
    fields: [
      formFields.select(
        arabic_labels['form.company'],
        'company',
        'default',
        { query: getCompanies },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [
          {
            required: true,
            message: 'لا بد من إختيار ' + arabic_labels['form.company'],
          },
        ],
        null,
        null,
        {
          key: 'company',
        },
        null,
        (fieldValue) =>
          !fieldValue
            ? 'لا بد من إختيار ' + arabic_labels['form.company']
            : true
      ),
      formFields.select(
        arabic_labels['form.section'],
        'section',
        'default',
        { query: getSections },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الصناعه' }],
        null,
        null,
        {
          key: 'section',
        },
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الصناعه' : true)
      ),
      formFields.select(
        arabic_labels['form.category'],
        'category',
        'default',
        { query: getCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار القسم' }],
        'section',
        'section',
        {
          key: 'category',
        },
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار القسم' : true)
      ),
      formFields.select(
        arabic_labels['form.subCategory'],
        'subCategory',
        'default',
        { query: getSubCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار القسم الفرعي' }],
        'category',
        'category',
        {
          key: 'subCategory',
        },
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار القسم الفرعي' : true)
      ),
      formFields.select(
        arabic_labels['form.type'],
        'type',
        'default',
        { query: getTypes },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار المجموعه الرئيسيه' }],
        'subCategory',
        'subCategory',
        {
          key: 'type',
        },
        null,
        (fieldValue) =>
          !fieldValue ? 'لا بد من إختيار المجموعه الرئيسيه' : true
      ),
      formFields.select(
        arabic_labels['form.subType'],
        'subType',
        'default',
        { query: getSubTypes },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار المجموعه الفرعية' }],
        'type',
        'type',
        {
          key: 'subType',
        },
        null,
        (fieldValue) =>
          !fieldValue ? 'لا بد من إختيار المجموعه الفرعية' : true
      ),
      formFields.select(
        arabic_labels['form.tags'],
        'tags',
        'multiple',
        { query: getTags },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        'subType',
        'subType',
        {
          key: 'tag',
        },
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الأوصاف' : true)
      ),
      fields.object({
        title: arabic_labels['form.productName'],
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) =>
          !path(['ar'], fieldValue) ? 'لا بد من إدخال إسم الصنف' : true,
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      formFields.select(
        arabic_labels['form.country'],
        'country',
        'default',
        { query: getCountries },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار البلد' }],
        null,
        null,
        {
          key: 'country',
        },
        null,
        (fieldValue) =>
          !fieldValue
            ? 'لا بد من إختيار ' + arabic_labels['form.country']
            : true
      ),
      formFields.select(
        arabic_labels['form.brand'],
        'brand',
        'default',
        { query: getBrands },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الماركة' }],
        'company',
        'company',
        {
          key: 'brand',
        },
        null,
        (fieldValue) =>
          !fieldValue ? 'لا بد من إختيار ' + arabic_labels['form.brand'] : true
      ),
     fields.double({
        title: arabic_labels['form.barcode'],
        dataIndex: 'barcode',
        key: 'barcode',
        firstField: fields.text({
          title: arabic_labels['form.packageBarcode'],
          dataIndex: 'package',
          key:'package',
          validate: (fieldValue) =>
            !fieldValue ? 'لا بد من إدخال باركود العلبه' : true,
        }),
        secondField: fields.text({
          title: arabic_labels['form.unitBarcode'],
          dataIndex: 'unit',
          key: 'unit',
          validate: (fieldValue) =>
            !fieldValue ? 'لا بد من إدخال باركود الوحده' : true,
        }),
        validate: (fieldValue) =>
          !path(['package'], fieldValue) || !path(['unit'], fieldValue)
            ? '  لا بد من إدخال باركود الوحده و العلبه'
            : true,
      }), 
      fields.text({
        title: arabic_labels['form.erpCode'],
        dataIndex: 'erpCode',
        key: 'erpCode',
        validate: (fieldValue) =>
          !fieldValue ? 'لا بد من إدخال اي ار بي كود' : true,
      }),
      fields.list({
        title: arabic_labels['sidebar.buyerCategories'],
        dataIndex: 'buyerCategories',
        field: fields.object({
          fields: [
            fields.object({
              dataIndex: 'saleUnit',
              fields: [
                formFields.select(
                  arabic_labels['form.package'],
                  'package',
                  'default',
                  { query: getPackages },
                  null,
                  ['_id'],
                  null,
                  null,
                  null,
                  false,
                  null,
                  null,
                  null,
                  {
                    key: 'package'
                  }
                ),
                fields.number({
                  title: arabic_labels['form.quantity'],
                  min: 1,
                  dataIndex: 'packageQuantity',
                }),
                fields.number({
                  title: arabic_labels['form.price'],
                  dataIndex: 'price',
                  min: 0,
                  allowFractions: true,
                }),
              ],
              validate: (fieldValue) =>
                !path(['package'], fieldValue) ||
                !path(['packageQuantity'], fieldValue) ||
                !path(['price'], fieldValue)
                  ? 'لابد من إدخال تفاصيل وحدة البيع'
                  : true,
            }),
            formFields.select(
              arabic_labels['form.buyerCategory'],
              'buyerCategory',
              'default',
              { query: getBuyerCategories },
              null,
              ['_id'],
              null,
              null,
              null,
              false,
              null,
              null,
              null,
              {
                key: 'buyerCategory',
              },
              null,
              (fieldValue) =>
                !fieldValue
                  ? 'لابد من إدخال' + arabic_labels['form.buyerCategory']
                  : true
            ),
          ],
        }),
        validate: (fieldValue) =>
          path(['0', 'saleUnit', 'package'], fieldValue) ||
          path(['0', 'saleUnit', 'packageQuantity'], fieldValue) ||
          path(['0', 'saleUnit', 'price'], fieldValue) ||
          path(['0', 'buyerCartegory'], fieldValue)
            ? true
            : 'لا بد من إدخال تفاصيل القطاعات',
      }),
      formFields.select(
        arabic_labels['form.packageUnit'],
        'packageUnit',
        'default',
        { query: getPackageUnits },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار وحدة البيع الفرعيه' }],
        null,
        null,
        {
          key: 'packageUnit',
        },
        null,
        (fieldValue) =>
          !fieldValue
            ? 'لابد من إدخال' + arabic_labels['form.packageUnit']
            : true
      ),
      fields.double({
        title: arabic_labels['form.size'],
        dataIndex: 'sizes',
        firstField: formFields.select(
          arabic_labels['form.sizeunit'],
          'size',
          'default',
          { query: getSizes },
          null,
          ['_id'],
          null,
          null,
          null,
          false,
          [{ required: true, message: 'لا بد من إختيار وحدة الوزن' }],
          null,
          null,
          {
            key: 'size',
          },
          null,
          (fieldValue) =>
            !fieldValue
              ? 'لابد من إدخال' + arabic_labels['form.sizeunit']
              : true
        ),
        secondField: fields.number({
          title: arabic_labels['form.sizequantity'],
          dataIndex: 'quantity',
          min: 1,
          validate: (fieldValue) =>
            !fieldValue ? 'لا بد من إدخال الوزن' : true,
        }),
        validate: (fieldValue) =>
          !path(['size'], fieldValue) || !path(['quantity'], fieldValue)
            ? 'لا بد من إدخال ' + arabic_labels['form.size']
            : true,
      }),
      fields.object({
        title: arabic_labels['form.description'],
        dataIndex: 'description',
        fields: [
          fields.textArea({
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) =>
          !path(['ar'], fieldValue) ? 'لا بد من إدخال الوصف' : true,
      }),
      fields.number({
        title: arabic_labels['form.points'],
        dataIndex: 'points',
        dependentField: 'company',
        min:0
      }),
      fields.checkbox({
        title: arabic_labels['form.featured'],
        dataIndex: 'featured',
      }),
    ],
  },
  edit: {
    update: updateCompanyProduct,
    get: getCompanyProducts,
    fields: [
      fields.object({
        title: arabic_labels['form.company'],
        dataIndex: 'company',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCompanies },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار الشركة' }],
            null,
            null,
            {
              key: 'company',
            },
            'company'
          ),
        ],
        validate: (fieldValue) =>
          !path(['_id'], fieldValue) ? 'لا بد من إختيار الشركة' : true,
      }),
      fields.object({
        title: arabic_labels['form.section'],
        dataIndex: 'section',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getSections },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار الصناعه' }],
            null,
            null,
            {
              key: 'section',
            }
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.category'],
        dataIndex: 'category',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCategories },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار القسم' }],
            'section._id',
            'section',
            {
              key: 'category',
            }
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.subCategory'],
        dataIndex: 'subCategory',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getSubCategories },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار القسم الفرعي' }],
            'category._id',
            'category',
            {
              key: 'subCategory',
            }
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.type'],
        dataIndex: 'type',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getTypes },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المجموعه الرئيسيه' }],
            'subCategory._id',
            'subCategory',
            {
              key: 'type',
            }
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.subType'],
        dataIndex: 'subType',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getSubTypes },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار المجموعه الفرعية' }],
            'type._id',
            'type',
            {
              key: 'subType',
            }
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.tags'],
        dataIndex: 'tags',
        fields: [
          formFields.select(
            '',
            '_id',
            'multiple',
            { query: getTags },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار الأوصاف' }],
            'subType._id',
            'subType',
            {
              key: 'tag',
            }
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.productName'],
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) =>
          !path(['ar'], fieldValue) ? 'لا بد من إدخال إسم الصنف' : true,
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.object({
        title: arabic_labels['form.country'],
        dataIndex: 'country',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCountries },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار البلد' }],
            null,
            null,
            {
              key: 'country',
            }
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.brand'],
        dataIndex: 'brand',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getBrands },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار الماركة' }],
            'company._id',
            'company',
            {
              key: 'brand',
            }
          ),
        ],
      }),
      fields.double({
        title: arabic_labels['form.barcode'],
        dataIndex: 'barcode',
        firstField: fields.text({
          title: arabic_labels['form.packageBarcode'],
          dataIndex: 'package',
          validate: (fieldValue) =>
            !fieldValue ? 'لا بد من إدخال باركود العلبه' : true,
        }),
        secondField: fields.text({
          title: arabic_labels['form.unitBarcode'],
          dataIndex: 'unit',
          validate: (fieldValue) =>
            !fieldValue ? 'لا بد من إدخال باركود الوحده' : true,
        }),
      }),
      fields.text({
        title: arabic_labels['form.erpCode'],
        dataIndex: 'erpCode',
        validate: (fieldValue) =>
          !fieldValue ? 'لا بد من إدخال اي ار بي كود' : true,
      }),
      fields.list({
        title: arabic_labels['sidebar.buyerCategories'],
        dataIndex: 'buyerCategories',
        field: fields.object({
          fields: [
            fields.object({
              dataIndex: 'saleUnit',
              fields: [
                fields.object({
                  title: arabic_labels['form.package'],
                  dataIndex: 'package',
                  fields: [
                    formFields.select(
                      '',
                      '_id',
                      'default',
                      { query: getPackages },
                      null,
                      ['_id'],
                      null,
                      null,
                      null,
                      false,
                      null,
                      null,
                      null,
                      {
                        key: 'package',
                      }
                    ),
                  ],
                }),
                fields.number({
                  title: arabic_labels['form.quantity'],
                  min: 1,
                  dataIndex: 'packageQuantity',
                }),
                fields.number({
                  title: arabic_labels['form.price'],
                  dataIndex: 'price',
                  min: 0,
                  allowFractions: true,
                }),
              ],
            }),
            fields.object({
              title: arabic_labels['form.buyerCategory'],
              dataIndex: 'buyerCategory',
              fields: [
                formFields.select(
                  '',
                  '_id',
                  'default',
                  { query: getBuyerCategories },
                  null,
                  ['_id'],
                  null,
                  null,
                  null,
                  false,
                  null,
                  null,
                  null,
                  {
                    key: 'buyerCategory',
                  }
                ),
              ],
            }),
          ],
          validate: (fieldValue) =>
            !fieldValue ? 'لا بد من إدخال القطاعات' : true,
        }),
      }),
      fields.object({
        title: arabic_labels['form.packageUnit'],
        dataIndex: 'packageUnit',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getPackageUnits },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار وحدة البيع الفرعيه' }],
            null,
            null,
            {
              key: 'packageUnit',
            }
          ),
        ],
      }),
      fields.double({
        title: arabic_labels['form.size'],
        dataIndex: 'sizes',
        firstField: formFields.select(
          arabic_labels['form.sizeunit'],
          'size',
          'default',
          { query: getSizes },
          null,
          ['_id'],
          null,
          null,
          null,
          false,
          [{ required: true, message: 'لا بد من إختيار وحدة الوزن' }],
          null,
          null,
          {
            key: 'size',
          }
        ),
        secondField: fields.number({
          title: arabic_labels['form.sizequantity'],
          dataIndex: 'quantity',
          min: 1,
          validate: (fieldValue) =>
            !fieldValue ? 'لا بد من إدخال الوزن' : true,
        }),
      }),
      fields.object({
        title: arabic_labels['form.description'],
        dataIndex: 'description',
        fields: [fields.textArea({ title: '', dataIndex: 'ar' })],
        validate: (fieldValue) => (!fieldValue ? 'لا بد من الوصف' : true),
      }),
      fields.number({
        title: arabic_labels['form.points'],
        dataIndex: 'points',
        dependentField: 'company',
        min:0
      }),
      fields.checkbox({
        title: arabic_labels['form.featured'],
        dataIndex: 'featured',
      }),
    ],
  },
}
