import { tableFields, fields, formFields } from '../schema'
import { getZones, deleteZone, addZone, updateZone } from '../../../modules/Zone/store/actions'
import { getCities } from '../../../modules/City/store/actions'
import { getCountries } from '../../../modules/Country/store/actions'
import { getDistricts } from '../../../modules/District/store/actions'
import { path } from 'ramda'
import arabic_labels from '../../constants/arabic_labels.json'

export const zones = {
  view: {
    get: getZones,
    delete: deleteZone,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.city'], 'city.name'),
      tableFields.text(arabic_labels['form.country'], 'country.name'),
      tableFields.text(arabic_labels['form.district'], 'district.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addZone,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        'البلد',
        'country',
        'default',
        { query: getCountries },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
      ),
      formFields.select(
        'المدينة',
        'city',
        'default',
        { query: getCities },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: false, message: 'لا بد من إختيار المدينة' }],
        'country',
        'country',
      ),
      formFields.select(
        'الحي',
        'district',
        'default',
        { query: getDistricts },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الحي' }],
        'city',
        'city',
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الحي' : true),
      ),
    ],
  },
  edit: {
    update: updateZone,
    get: getZones,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.country'],
        dataIndex: 'country',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCountries },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.city'],
        dataIndex: 'city',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCities },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: false, message: 'لا بد من إختيار المدينة' }],
            'country._id',
            'country',
          ),
        ],
      }),
      fields.object({
        title: arabic_labels['form.district'],
        dataIndex: 'district',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getDistricts },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            [{ required: true, message: 'لا بد من إختيار الحي' }],
            'city._id',
            'city',
          ),
        ],
      }),
    ],
  },
}
