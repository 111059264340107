/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
import React from 'react'
import { Menu, Icon } from 'antd'
import styled from 'styled-components'
import { history } from '../../../../pages/Router'
import logo from '../../../../img/logo-new.png'
import logo2 from '../../../../img/logo-new-dark.png'
import { Link } from 'react-router-dom'

import { sidebar } from '../../../../config/Structure/sidebar'
import { connect } from 'react-redux'
import { getStaffType } from '../../../../services/commonServices'
import { path } from 'ramda'
import { GlobalContext } from '../../../../../src/GlobalState'

const Sidebar = (props) => {
  const { state } = GlobalContext()

  const children = sidebar.map((item) => {
    if (!item.availableTo.includes(getStaffType(path(['state', 'auth', 'user', 'staff'], props)))) return null
    const SidebarIcon = item.icon
    return (

    <Menu.Item
       key={item.name} 
        onClick={() =>{ history.push(item.link)  
      props.setCollapsed(true)
      }}
       >
        <Icon>
          {' '}
          <SidebarIcon />{' '}
        </Icon>
        <span>{item.name}</span>

      </Menu.Item>
    )
  })

  return (
  <SidebarStyle>
      <UserMenuItemName className="testee">
                <Link type='link' to={`/companies`}>

  {state.selectedTheme === 'light' ?  <img  src={logo} alt='logo' /> :  <img  className="testee" src={logo2} alt='logo' /> }
</Link>
</UserMenuItemName>

      <Menu className='fade-in' theme='dark' mode='inline'>
        {children}
      </Menu>
    </SidebarStyle>
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(Sidebar)


const SidebarStyle = styled.div`
@media (max-width: 767px){

  aside.Siderwidth.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger .testee img {
    display: none;
}
}

  .ant-menu {
    height: 60vh;
    overflow: auto;
  }

  li.ant-menu-item {
    height:55px !important;

    @media (max-width: 767px){

      padding: 5px !important;
      height: 40px !important;

      }
  }

  .ant-menu-vertical li {
    line-height: 55px  !important;
    margin:0;

    @media (max-width: 767px){

      line-height: 35px  !important;

      }

}


  .ant-menu-submenu-title {
    height:55px !important;
  }


  li.ant-menu-item:last-child {
    padding-bottom: 40px !important;
    margin-bottom: 50px;

}

  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 14px 10px;
    overflow: hidden;
    font-size: 16px;
    line-height: normal;
    text-overflow: ellipsis;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: inherit;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
    margin: 10px 0px;
    padding: 0;
    margin: 0;
  }

  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 10px;
  }

  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    text-overflow: clip;
    margin: 0 !important;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }

  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    border: 0;
  }

  li.ant-menu-submenu.ant-menu-submenu-inline,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    text-transform: uppercase;
    color: var(--color-font-secondary);
    font-family: 'Cairo-Regular', sans-serif;
  }


.ant-menu-item:hover   {
    color: var(--color-font-secondary);
    transition: 0.3s;

  }

  .ant-menu-item:hover  i.anticon  ,   .ant-menu-item:focus  i.anticon{

    color: var(--color-font-secondary);

  }

  .ant-menu-item:hover span , .ant-menu-item:focus span  {

    color: var(--color-font-secondary);

  }


 


  .ant-menu-item i,
  .ant-menu-submenu-title .anticon {
    font-size: 24px !important;
    padding-right: 10px;
    padding-left: 10px;
    @media (max-width: 767px){
      font-size: 20px !important;
     
    }
  }

  img {
    margin-bottom: 150px;
    width: 67px;
    margin: 46px auto;
    display: block;

    @media (max-width: 767px){
      
      width: 40px;
      margin: 20px auto;
    }
  }

  li:before,
  .ant-menu-submenu-vertical:before,
  .ant-menu-submenu-title:before,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:before {
    position: absolute;
    left: 0;
    width: 80%;
    height: 1px;
    background: rgba(255, 255, 255, 0.21);
    content: '';
    bottom: 0;
    padding: 0 14px;
    margin: 0px auto;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-menu {
    background-color: var(--color-primary);
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    color: var(--color-font-white) !important;
    background-color: var(--color-secondary)  !important;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected span  ,   .ant-menu.ant-menu-dark .ant-menu-item-selected i.anticon{
    color: var(--color-font-white) !important;
  }

  .ant-menu-inline-collapsed {
    width: 100px;

    @media (max-width: 767px){
      width: 50px;

  }
  }

  
  @media (max-width: 767px){

  .ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content{

  }

}
`

const UserMenuItemName = styled.div`
 
`
