import {
  GET_ORDERS_REPORT,
  GET_ORDERS_REPORT_SUCCESS,
  GET_ORDERS_REPORT_FAILURE,
  GET_ORDERS_CONSTANTS,
  GET_ORDERS_CONSTANTS_SUCCESS,
  GET_ORDERS_CONSTANTS_FAILURE,
  GET_UNITS_REPORT_FAILURE,
  GET_UNITS_REPORT_SUCCESS,
  GET_UNITS_REPORT,
} from '../../../../config/constants/ActionTypes'

//Get Orders Request
export const getOrdersReport = (payload) => {
  return {
    type: GET_ORDERS_REPORT,
    payload,
  }
}

//Get Orders Constants Request
export const getOrdersConstants = (payload) => {
  return {
    type: GET_ORDERS_CONSTANTS,
    payload,
  }
}
//Get orders Success
export const getOrdersReportSuccess = (orders) => {
  return {
    type: GET_ORDERS_REPORT_SUCCESS,
    payload: { orders },
  }
}

//Get orders constants Success
export const getOrdersConstantsSuccess = (orders) => {
  return {
    type: GET_ORDERS_CONSTANTS_SUCCESS,
    payload: { orders },
  }
}
//Get orders Failure
export const getOrdersReportFailure = (error) => {
  return {
    type: GET_ORDERS_REPORT_FAILURE,
    payload: { error },
  }
}

//Get orders constants Failure
export const getOrdersConstantsFailure = (error) => {
  return {
    type: GET_ORDERS_CONSTANTS_FAILURE,
    payload: { error },
  }
}
//Get Orders Request
export const getUnitsReport = (payload) => {
  return {
    type: GET_UNITS_REPORT,
    payload,
  }
}

//Get orders Success
export const getUnitsReportSuccess = (orders) => {
  return {
    type: GET_UNITS_REPORT_SUCCESS,
    payload: { orders },
  }
}

//Get orders Failure
export const getUnitsReportFailure = (error) => {
  return {
    type: GET_UNITS_REPORT_FAILURE,
    payload: { error },
  }
}
