import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_PENDING_BUYERS,
  ACCEPT_BUYER,
  REJECT_BUYER,
} from '../../../../config/constants/ActionTypes'
import { getPendingBuyers, getPendingBuyersSuccess, getPendingBuyersFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getPendingBuyersRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'distributors/distributorPendingBuyers',
    params: { ...query, isDeleted: false },
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getPendingBuyersSaga({ payload }) {
  try {
    const response = yield call(getPendingBuyersRequest, payload)
    yield put(getPendingBuyersSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getPendingBuyersFailure(error))
    payload.onFailure(error)
  }
}

const acceptBuyerRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'distributors/accept',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* acceptBuyerSaga({ payload }) {
  try {
    const response = yield call(acceptBuyerRequest, payload) // eslint-disable-line
    delete payload.body
    yield put(getPendingBuyers(payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const rejectBuyerRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'distributors/reject',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* rejectBuyerSaga({ payload }) {
  try {
    const response = yield call(rejectBuyerRequest, payload) // eslint-disable-line
    delete payload.body
    yield put(getPendingBuyers(payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_PENDING_BUYERS, getPendingBuyersSaga)],
    [yield takeEvery(ACCEPT_BUYER, acceptBuyerSaga)],
    [yield takeEvery(REJECT_BUYER, rejectBuyerSaga)],
  )
}
