import {
  GET_SUB_TYPES_SUCCESS,
  GET_SUB_TYPES_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_TYPES_SUCCESS: {
      return {
        ...state,
        subTypes: action.payload.subTypes,
      }
    }

    case GET_SUB_TYPES_FAILURE: {
      return {
        ...state,
        getSubTypesError: action.payload.error,
      }
    }

    default:
      return state
  }
}
