/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_BUNDLE_OFFERS,
  DELETE_BUNDLE_OFFER,
  ADD_BUNDLE_OFFER,
  UPDATE_BUNDLE_OFFER,
  GET_BUNDLE_OFFER,
} from '../../../../config/constants/ActionTypes'
import { getBundleOffersFailure, getBundleOffersSuccess } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getBundleOffersRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'offers/allBundleOffers',
    params: { ...query },
  })
}

function* getBundleOffersSaga({ payload }) {
  try {
    const response = yield call(getBundleOffersRequest, payload)
    yield put(getBundleOffersSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getBundleOffersFailure(error))
    payload.onFailure(error)
  }
}

const getBundleOfferRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'offers/singleBundleOffer',
    params: { id: query._id },
  })
}

function* getBundleOfferSaga({ payload }) {
  try {
    const response = yield call(getBundleOfferRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

const deleteBundleOfferRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'offers/deleteBundleOffer',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { bundle_offer_id: _id },
  })
}

function* deleteBundleOfferSaga({ payload }) {
  try {
    const response = yield call(deleteBundleOfferRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addBundleOfferRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'offers/bundleOffers',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addBundleOfferSaga({ payload }) {
  try {
    const response = yield call(addBundleOfferRequest, payload) // eslint-disable-line
    delete payload._id
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateBundleOfferRequest = async ({ body }) => {
  let offer_id = body._id
  delete body._id
  return await axios({
    method: 'put',
    url: config.url + 'offers/updateBundleOffers',
    params: {
      offer_id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateBundleOfferSaga({ payload }) {
  try {
    const response = yield call(updateBundleOfferRequest, payload)
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      payload.onSuccess()
      // yield put(getBundleOffers(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_BUNDLE_OFFERS, getBundleOffersSaga)],
    [yield takeEvery(DELETE_BUNDLE_OFFER, deleteBundleOfferSaga)],
    [yield takeEvery(ADD_BUNDLE_OFFER, addBundleOfferSaga)],
    [yield takeEvery(UPDATE_BUNDLE_OFFER, updateBundleOfferSaga)],
    [yield takeEvery(GET_BUNDLE_OFFER, getBundleOfferSaga)],
  )
}
