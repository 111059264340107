/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import arabic_labels from '../../../../config/constants/arabic_labels.json'
import { tableFields } from '../../../../config/Structure/schema'
import { connect } from 'react-redux'
import { getBuyerCategories } from '../../../BuyerCategory/store/actions'
import { getPriceLists } from '../../../PriceList/store/actions'
import definitions from '../../../../config/definitions'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Row, Col, message, Select } from 'antd'
import TableView from '../../../View/components/Table'
import PaginationView from '../../../View/components/Pagination'
import SearchInput from '../../../Form/components/SearchInput'
import { search } from '../../../CompanyProduct/store/actions'
import { pathOr, prop } from 'ramda'
const logo = require('../../../../img/logo-new.png')
const { Option } = Select

const PriceListTable = ({ onRefetch, dispatch, auth }) => {
  const sellerCategory = auth?.user?.staff?.sellerCategory
  const history = useHistory()
  const [fields] = useState([
    tableFields.image(arabic_labels['form.images'], 'images[0]', (value) => value || logo),
    tableFields.text('المنتج', 'tag.name.ar'),
    tableFields.text('الشركة', 'company.name.ar'),
  ])
  const [actionsFields] = useState(
    sellerCategory === 'Distributor' ? [] : [tableFields.action.edit()],
  )
  const [extraFields, setExtraFields] = useState()
  const [renderedFields, setRenderedFields] = useState([])
  const [timer, setTimer] = useState(null)
  const [records, setRecords] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [pageSize] = useState(definitions.defaultPageSize)
  const [loading, setLoading] = useState(false)
  const [fetchingColumns, setFetchingColumns] = useState(true)
  const [children, setChildren] = useState([])
  const location = useLocation()
  const query = queryString.parse(location.search, { arrayFormat: 'comma' })
  const parsedPageNumber = parseInt(query.page) || 1
  const variables = { ...query, limit: pageSize }
  Object.entries(query).forEach(([key, value]) => {
    if (value === 'true') query[key] = true
    if (value === 'false') query[key] = false
  })

  useEffect(() => {
    getBuyerCategoriesColumns()
  }, [])

  useEffect(() => {
    setLoading(true)
    getRecords(parsedPageNumber)
  }, [location.search]) // eslint-disable-line

  useEffect(() => {
    let allfields = JSON.parse(JSON.stringify(fields))
    if (extraFields) allfields.push(extraFields)
    allfields = [...allfields, ...actionsFields]
    setRenderedFields(allfields)
  }, [extraFields]) // eslint-disable-line

  const searchFor = (val) => {
    query['searchTerm'] = val
    history.push(`${location.pathname}?${queryString.stringify(query)}`)
  }

  const debounce = (value) => {
    if (timer) {
      clearTimeout(timer)
    }
    setTimer(
      setTimeout(() => {
        searchFor(value)
        setTimer(clearTimeout(timer))
      }, 800),
    )
  }

  const getRecords = (page) => {
    if (query?.searchTerm) {
      dispatch(
        search({
          query: {
            ...query,
            searchItem: query?.searchTerm.trim(),
            type: 'priceList',
            limit: pageSize,
            skip: page,
          },
          onSuccess: onFetchSuccess,
          onFailure: onFetchFailure,
        }),
      )
    } else {
      delete variables.searchTerm
      dispatch(
        getPriceLists({
          query: { ...variables, skip: page },
          onSuccess: onFetchSuccess,
          onFailure: onFetchFailure,
        }),
      )
    }
  }

  const onChange = (value) => {
    value = value.split(',')
    value = tableFields.text(value[1], value[0])
    setExtraFields(value)
  }

  const getBuyerCategoriesColumns = () => {
    dispatch(getBuyerCategories({ onSuccess, onFailure }))
  }

  const onSuccess = ({ data, count }) => {
    let result = data.filter((item) => item !== null)
    setChildren(
      result.map((datum) => (
        <Option key={datum?._id} value={`${datum?._id},${datum?.name?.ar}`}>
          {pathOr(prop('name', datum), ['name', 'ar'], datum)}
        </Option>
      )),
    )
    setFetchingColumns(false)
  }

  const onFailure = (error) => {
    setFetchingColumns(false)
    message.error('حدث خطأ')
  }

  const onFetchSuccess = ({ data, count }) => {
    setLoading(false)
    setRecords(data.filter((item) => item !== null))
    setTotalRecords(count)
  }

  const onFetchFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }

  let styles = ``
  fields.forEach((field, index) => {
    styles += `
      td:nth-of-type(${index + 1}):before {
        content: "${field.title}"; 
      }
    `
  })

  return (
    <OrderHolder styles={styles}>
      <Row gutter={24}>
        <Col xl={6}>
          <SelectInputStyle>
            <Select
              optionFilterProp='children'
              showSearch={true}
              loading={fetchingColumns}
              disabled={false}
              placeholder={'القطاعات'}
              mode={'default'}
              dropdownMenuStyle={{
                background: 'var(--color-primary)',
                color: 'var(--color-font-secondary)',
              }}
              onChange={onChange}
              name={'القطاعات'}
              filterOption={true}
            >
              {children}
            </Select>
          </SelectInputStyle>
        </Col>
        <Col xl={18}>
          <SearchInput
            disabled={true}
            title={`Filter by product`}
            dataIndex='filter'
            onChange={debounce}
            value={query.searchTerm || ''}
          />
        </Col>
      </Row>
      <Row>
        <TableView
          loading={fetchingColumns || loading}
          data={records}
          headers={renderedFields}
          componentName={'pricelists'}
          //   onDelete={onDelete}
          //   deleteKey={config?.view?.deleteKey}
          //   onRefetch={() => getRecords(parsedPageNumber)}
        />
      </Row>
      <Row>
        <PaginationView totalCount={totalRecords} pageSize={pageSize} getRecords={getRecords} />
      </Row>
    </OrderHolder>
  )
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

export default connect(mapStateToProps)(PriceListTable)

const OrderHolder = styled.div`
  .MuiDialogContent-root {
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    ${(props) => props.styles}
  }
`
const SelectInputStyle = styled.div`
  width: 100px;
  .ant-select-selection .ant-select-selection__choice {
    background: var(--color-primary);
    border: 0;
    border-radius: 2rem;
    font-size: 1rem;
    color: var(--color-font-secondary);
  }

  input.MuiInputBase-input.MuiInput-input,
  .ant-select-dropdown-menu-item {
    color: var(--color-form) !important;
  }

  .ant-select-remove-icon {
    color: var(--color-font-secondary);
  }

  .ant-select-selection {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #8dc3e72b;
    font-weight: 300;
    color: var(--color-font-primary);
    font-size: 1.25rem;
    text-transform: uppercase;
    border-radius: 0;
  }

  .ant-select-selection {
    min-height: 32px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1;
    width: 300px;
  }

  .ant-select-arrow .ant-select-arrow-icon svg {
    display: none;
  }
  i.anticon.anticon-down.ant-select-arrow-icon:before {
    content: '\f107';
    transition: 0.3s;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  i.anticon.anticon-down.ant-select-arrow-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon.anticon-down,
  .anticon-spin {
    font-size: 1.25rem;
    color: var(--color-secondary);
    position: relative;
    bottom: 0.5rem;
  }
`
