import {
  GET_QUANTITY_OFFERS,
  GET_QUANTITY_OFFERS_SUCCESS,
  GET_QUANTITY_OFFERS_FAILURE,
  DELETE_QUANTITY_OFFER,
  ADD_QUANTITY_OFFER,
  UPDATE_QUANTITY_OFFER,
  GET_QUANTITY_OFFER,
} from '../../../../config/constants/ActionTypes'

//Get QuantityOffers Request
export const getQuantityOffers = (payload) => {
  return {
    type: GET_QUANTITY_OFFERS,
    payload,
  }
}

//Get QuantityOffers Success
export const getQuantityOffersSuccess = (quantityOffers) => {
  return {
    type: GET_QUANTITY_OFFERS_SUCCESS,
    payload: { quantityOffers },
  }
}

//Get QuantityOffers Failure
export const getQuantityOffersFailure = (error) => {
  return {
    type: GET_QUANTITY_OFFERS_FAILURE,
    payload: { error },
  }
}

//Get QuantityOffer Request
export const getQuantityOffer = (payload) => {
  return {
    type: GET_QUANTITY_OFFER,
    payload,
  }
}

//Delete QuantityOffer
export const deleteQuantityOffer = (payload) => {
  return {
    type: DELETE_QUANTITY_OFFER,
    payload,
  }
}

//Add QuantityOffer
export const addQuantityOffer = (payload) => {
  return {
    type: ADD_QUANTITY_OFFER,
    payload,
  }
}

//update QuantityOffer
export const updateQuantityOffer = (payload) => {
  return {
    type: UPDATE_QUANTITY_OFFER,
    payload,
  }
}
