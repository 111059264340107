import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_COMPANY_PRODUCTS,
  DELETE_COMPANY_PRODUCT,
  ADD_COMPANY_PRODUCT,
  UPDATE_COMPANY_PRODUCT,
  SEARCH_MODULES,
  ADD_COMPANY_PRODUCTS
} from '../../../../config/constants/ActionTypes'
import { getCompanyProducts, getCompanyProductsSuccess, getCompanyProductsFailure, searchFailure, searchSuccess } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const searchRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'companyProducts/search/pagination',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* searchSaga({ payload }) {
  try {
    const response = yield call(searchRequest, payload)
    yield put(searchSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(searchFailure(error))
    payload.onFailure(error)
  }
}

const getCompanyProductsRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'companyProducts',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getCompanyProductsSaga({ payload }) {
  try {
    const response = yield call(getCompanyProductsRequest, payload)
    yield put(getCompanyProductsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getCompanyProductsFailure(error))
    payload.onFailure(error)
  }
}

const deleteCompanyProductRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'companyProducts',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteCompanyProductSaga({ payload }) {
  try {
    const response = yield call(deleteCompanyProductRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addCompanyProductRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'companyProducts',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addCompanyProductSaga({ payload }) {
  try {
    const response = yield call(addCompanyProductRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const addCompanyProductsRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'companyProducts/addProductsList',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body
  })
}

function* addCompanyProductsSaga({ payload }) {
  try {
    const response = yield call(addCompanyProductsRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateCompanyProductRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'companyProducts',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateCompanyProductSaga({ payload }) {
  try {
    const response = yield call(updateCompanyProductRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      const companyId = { _id: payload.body.company._id}
      delete payload.body
      yield put(getCompanyProducts({...payload, query: companyId}))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_COMPANY_PRODUCTS, getCompanyProductsSaga)],
    [yield takeEvery(DELETE_COMPANY_PRODUCT, deleteCompanyProductSaga)],
    [yield takeEvery(ADD_COMPANY_PRODUCT, addCompanyProductSaga)],
    [yield takeEvery(ADD_COMPANY_PRODUCTS, addCompanyProductsSaga)],
    [yield takeEvery(UPDATE_COMPANY_PRODUCT, updateCompanyProductSaga)],
    [yield takeEvery(SEARCH_MODULES, searchSaga)],
  )
}
