/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { message } from 'antd'
import { useFormContext } from 'react-hook-form'
import { equals, filter } from 'ramda'
import { connect } from 'react-redux'
import { getCompanyProducts } from '../../../CompanyProduct/store/actions'
const PriceLabel = ({ value, onChange, dataIndex, dispatch }) => {
  const [discount, setDiscount] = useState()
  const { getValues, watch } = useFormContext()
  const values = getValues()
  const [valuesState, setValuesState] = useState()
  const [quantity, setQuantity] = useState()
  const previousValuesRef = useRef()
  useEffect(() => {
    previousValuesRef.current = values
  })
  useEffect(() => {
    if (!equals(value, discount)) applyChange(value)
  }, [value]) // eslint-disable-line

  useEffect(() => {
    if (!equals(values, valuesState)) {
      setValuesState(values)
    }
  }, [values])

  useEffect(() => {
    if (!equals(quantity, watch('optional_1'))) {
      setQuantity(watch('optional_1'))
    }
  }, [watch('optional_1')])

  useEffect(() => {
    if (
      dataIndex === 'oldPrice' &&
      values['product_id'] &&
      watch('optional_1') &&
      values['optional_3'] &&
      values['buyer_cat']
    ) {
      // call api
      dispatch(
        getCompanyProducts({
          query: { _id: values['product_id'] },
          onSuccess: onFetchSuccess,
          onFailure: onFetchFailure,
        }),
      )
    }
  }, [valuesState, quantity]) // eslint-disable-line
  const applyChange = (price) => {
    setDiscount(price)
    if (onChange) onChange(price, dataIndex)
  }
  const onFetchSuccess = (records) => {
    let record = records.data[Object.keys(records.data)[0]]
    let buyerCategories = record?.buyerCategories
    const isEqual = (buyerCat) => buyerCat?.buyerCategory._id === values['buyer_cat']
    let buyerCategory = filter(isEqual, buyerCategories)[0]
    let price
    if (values['optional_3'] === 'unit') {
      price =
        (Number(buyerCategory?.saleUnit?.price) /
          Number(buyerCategory?.saleUnit?.packageQuantity)) *
        Number(watch('optional_1'))
    } else {
      price = Number(buyerCategory?.saleUnit?.price) * Number(watch('optional_1'))
    }
    applyChange(price)
  }
  const onFetchFailure = (error) => {
    message.error('حدث خطأ')
  }
  return (
    <div>
      <label style={{ fontSize: '20px',  color: 'var(--color-form)'  }}>{discount}</label>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state,
})
export default connect(mapStateToProps)(PriceLabel)
