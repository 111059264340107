import { types } from './schema'
// import AutoComplete from '../../modules/Form/components/AutoComplete'
import listInput from '../../modules/Form/components/ListInput'
import DoubleInput from '../../modules/Form/components/DoubleInput'
import TripleInput from '../../modules/Form/components/TripleInput'
// import Dynamic from '../../modules/Form/components/DynamicInput'
import CheckBox from '../../modules/Form/components/Checkbox'
import DatePicker from '../../modules/Form/components/DatePicker'
import NumberInput from '../../modules/Form/components/Number'
import ObjectInput2 from '../../modules/Form/components/Object'
import Text from '../../modules/Form/components/Text'
import TextArea from '../../modules/Form/components/TextArea'
import Select from '../../modules/Form/components/Select2'
import VerticalTabs from '../../modules/View/components/VerticalTabs'
import UploadImage from '../../modules/Form/components/UploadImage'
import SelectAsync from '../../modules/Form/components/SelectAsync'
import Tags from '../../modules/Form/components/Select'
import SelectInput from '../../modules/Form/components/SelectInput'

const mapper = {
  [types.list]: listInput,
  [types.double]: DoubleInput,
  [types.triple]: TripleInput,
  // [types.dynamicKeyValue]: Dynamic,
  [types.checkbox]: CheckBox,
  [types.datePicker]: DatePicker,
  // [types.autoComplete]: AutoComplete,
  [types.number]: NumberInput,
  [types.antdSelect]: SelectInput,
  [types.object]: ObjectInput2,
  [types.text]: Text,
  [types.textArea]: TextArea,
  [types.select]: Select,
  [types.selectAsync]: SelectAsync,
  [types.verticalTabs]: VerticalTabs,
  [types.images]: UploadImage,
  [types.tags]: Tags,
}

export default mapper
