import {
  GET_SUB_TYPES,
  GET_SUB_TYPES_SUCCESS,
  GET_SUB_TYPES_FAILURE,
  DELETE_SUB_TYPE,
  ADD_SUB_TYPE,
  UPDATE_SUB_TYPE,
} from '../../../../config/constants/ActionTypes'

//Get SubTypes Request
export const getSubTypes = (payload) => {
  return {
    type: GET_SUB_TYPES,
    payload,
  }
}

//Get SubTypes Success
export const getSubTypesSuccess = (subTypes) => {
  return {
    type: GET_SUB_TYPES_SUCCESS,
    payload: { subTypes },
  }
}

//Get SubTypes Failure
export const getSubTypesFailure = (error) => {
  return {
    type: GET_SUB_TYPES_FAILURE,
    payload: { error },
  }
}

//Delete SubType
export const deleteSubType = (payload) => {
  return {
    type: DELETE_SUB_TYPE,
    payload,
  }
}

//Add SubTypes
export const addSubType = (payload) => {
  return {
    type: ADD_SUB_TYPE,
    payload,
  }
}

//update SubType
export const updateSubType = (payload) => {
  return {
    type: UPDATE_SUB_TYPE,
    payload,
  }
}
