import { tableFields, fields, formFields } from '../schema'
import {
  getBundleOffers,
  getBundleOffer,
  deleteBundleOffer,
  addBundleOffer,
  updateBundleOffer,
} from '../../../modules/BundleOffer/store/actions'
import ZonesTreeSelect from '../../../modules/BundleOffer/components/ZonesTreeSelect'
import arabic_labels from '../../constants/arabic_labels.json'
import { getCompanies } from '../../../modules/Company/store/actions'
import { path } from 'ramda'
import { getCompanyProducts } from '../../../modules/CompanyProduct/store/actions'
import { getProductBuyerCategories } from '../../../modules/BuyerCategory/store/actions'

export const bundleOffers = {
  view: {
    get: getBundleOffers,
    delete: deleteBundleOffer,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name'),
      tableFields.text(arabic_labels['form.quantity'], 'optional_1'),
      tableFields.text(arabic_labels['form.package'], 'optional_3'),
      tableFields.text(arabic_labels['form.price'], 'optional_2'),
      tableFields.text(arabic_labels['form.startDate'], 'starting_date'),
      tableFields.text(arabic_labels['form.endDate'], 'end_date'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
    deleteKey: 'offer_id',
  },
  add: {
    create: addBundleOffer,
    fields: [
      fields.text({
        title: arabic_labels['form.name'],
        dataIndex: 'name',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        'الشركة',
        'company_id',
        'default',
        { query: getCompanies },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الشركة' }],
        null,
        null,
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الشركة' : true),
      ),
      formFields.select(
        'المنتج',
        'product_id',
        'default',
        { query: getCompanyProducts },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار المنتج' }],
        'company_id',
        'company',
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار المنتج' : true),
      ),
      formFields.select(
        'القطاع',
        'buyer_cat',
        'default',
        { query: getProductBuyerCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار القطاع' }],
        'product_id',
        '_id',
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار القطاع' : true),
      ),
      fields.number({
        title: arabic_labels['form.quantity'],
        dataIndex: 'optional_1',
        min: 1,
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الكمية' : true),
      }),
      fields.datePicker({
        title: 'تاريخ البداية',
        dataIndex: 'starting_date',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تاريخ البداية' : true),
      }),
      fields.datePicker({
        title: 'تاريخ النهاية',
        dataIndex: 'end_date',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تاريخ النهاية' : true),
      }),
      formFields.select(arabic_labels['form.package'], 'optional_3', 'default', {
        list: [{ key: 'كرتونة', value: 'carton' }, { key: 'عبوة', value: 'unit' }],
      }),
      fields.list({
        title: 'منتجات هدية',
        dataIndex: 'bundles',
        field: fields.object({
          title: '',
          dataIndex: '',
          fields: [
            fields.number({
              title: 'كمية',
              dataIndex: 'productQuantity',
              min: 1,
              validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال  كمية' : true),
            }),
            formFields.select(arabic_labels['form.package'], 'packageType', 'default', {
              list: [{ key: 'كرتونة', value: 'carton' }, { key: 'عبوة', value: 'unit' }],
            }),
            formFields.select(
              'المنتج',
              'addOnProduct',
              'default',
              { query: getCompanyProducts },
              null,
              ['_id'],
              null,
              null,
              null,
              false,
              [{ required: true, message: 'لا بد من إختيار المنتج' }],
              'company_id',
              'company',
            ),
          ],
        }),
        validate: (fieldValue) =>
          fieldValue?.map((item) =>
            !!path(['addOnProduct'], item)
          )?.reduce((total, current) => total && current)
            ? true
            : 'لا بد من إختيار منتج الهدية',
      }),
      fields.number({
        title: arabic_labels['form.price'],
        min: 0,
        allowFractions: true,
        dataIndex: 'optional_2',
        validate: (fieldValue) =>
          !fieldValue ? 'برجاء إدخال ' + arabic_labels['form.price'] : true,
      }),
      fields.number({
        title: arabic_labels['form.points'],
        dataIndex: 'points',
        dependentField:'company_id',
        min:0
      }),
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال المنطقة' : true),
      }),
    ],
  },
  edit: {
    update: updateBundleOffer,
    get: getBundleOffer,
    fields: [
      fields.text({
        title: arabic_labels['form.name'],
        dataIndex: 'name',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        'الشركة',
        'company_id',
        'default',
        { query: getCompanies },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الشركة' }],
      ),
      formFields.select(
        'المنتج',
        'product_id',
        'default',
        { query: getCompanyProducts },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار المنتج' }],
        'company_id',
        'company',
      ),
      formFields.select(
        'القطاع',
        'buyer_cat',
        'default',
        { query: getProductBuyerCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار القطاع' }],
        'product_id',
        '_id',
      ),
      fields.number({
        title: arabic_labels['form.quantity'],
        min: 1,
        dataIndex: 'optional_1',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الكمية' : true),
      }),
      fields.datePicker({
        title: 'تاريخ البداية',
        dataIndex: 'starting_date',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تاريخ البداية' : true),
      }),
      fields.datePicker({
        title: 'تاريخ النهاية',
        dataIndex: 'end_date',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تاريخ النهاية' : true),
      }),
      formFields.select(arabic_labels['form.package'], 'optional_3', 'default', {
        list: [{ key: 'كرتونة', value: 'carton' }, { key: 'عبوة', value: 'unit' }],
      }),
      fields.list({
        title: 'منتجات هدية',
        dataIndex: 'bundles',
        field: fields.object({
          title: '',
          dataIndex: '',
          fields: [
            fields.number({
              title: 'كمية',
              min: 1,
              dataIndex: 'productQuantity',
              validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال  كمية' : true),
            }),
            formFields.select(arabic_labels['form.package'], 'packageType', 'default', {
              list: [{ key: 'كرتونة', value: 'carton' }, { key: 'عبوة', value: 'unit' }],
            }),
            formFields.select(
              'المنتج',
              'addOnProduct',
              'default',
              { query: getCompanyProducts },
              null,
              ['_id'],
              null,
              null,
              null,
              false,
              [{ required: true, message: 'لا بد من إختيار المنتج' }],
              'company_id',
              'company',
            ),
          ],
        }),
      }),
      fields.number({
        title: arabic_labels['form.price'],
        dataIndex: 'optional_2',
        min: 0,
        allowFractions: true,
        validate: (fieldValue) =>
          !fieldValue ? 'برجاء إدخال  ' + arabic_labels['form.price'] : true,
      }),
      fields.number({
        title: arabic_labels['form.points'],
        dataIndex: 'points',
        dependentField:'company_id',
        min:0
      }),
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
      }),
    ],
  },
}
