import {
  GET_PRICELISTS,
  GET_PRICELISTS_SUCCESS,
  GET_PRICELISTS_FAILURE,
  GET_PRICELIST,
  UPDATE_PRICELIST,
  SEARCH_MODULES,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Search Modules Request
export const search = (payload) => {
  return {
    type: SEARCH_MODULES,
    payload,
  }
}

//Search Modules Success
export const searchSuccess = (items) => {
  return {
    type: SEARCH_SUCCESS,
    payload: { items },
  }
}

//Search Modules Failure
export const searchFailure = (error) => {
  return {
    type: SEARCH_FAILURE,
    payload: { error },
  }
}

//Get PriceList Request
export const getPriceList = (payload) => {
  return {
    type: GET_PRICELIST,
    payload,
  }
}

//Get PriceLists Request
export const getPriceLists = (payload) => {
  return {
    type: GET_PRICELISTS,
    payload,
  }
}

//Get PriceLists Success
export const getPriceListsSuccess = (priceLists) => {
  return {
    type: GET_PRICELISTS_SUCCESS,
    payload: { priceLists },
  }
}

//Get PriceLists Failure
export const getPriceListsFailure = (error) => {
  return {
    type: GET_PRICELISTS_FAILURE,
    payload: { error },
  }
}

//update PriceList
export const updatePriceList = (payload) => {
  return {
    type: UPDATE_PRICELIST,
    payload,
  }
}
