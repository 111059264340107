import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const BuyersTable = () => {
  return (
    <div>
      <TableHolder>
      <View componentName='buyers' config={config.buyers} />

</TableHolder>
    </div>
  )
}

export default BuyersTable


const TableHolder  = styled.div`
@media (max-width:767px){

td:nth-child(7), td:nth-child(8)  {
  display: inline-block;
  text-align: center;
}
}
`