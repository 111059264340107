import {
  GET_TYPES,
  GET_TYPES_SUCCESS,
  GET_TYPES_FAILURE,
  DELETE_TYPE,
  ADD_TYPE,
  UPDATE_TYPE,
} from '../../../../config/constants/ActionTypes'

//Get types Request
export const getTypes = (payload) => {
  return {
    type: GET_TYPES,
    payload,
  }
}

//Get types Success
export const getTypesSuccess = (types) => {
  return {
    type: GET_TYPES_SUCCESS,
    payload: { types },
  }
}

//Get types Failure
export const getTypesFailure = (error) => {
  return {
    type: GET_TYPES_FAILURE,
    payload: { error },
  }
}

//Delete Type
export const deleteType = (payload) => {
  return {
    type: DELETE_TYPE,
    payload,
  }
}

//Add Type
export const addType = (payload) => {
  return {
    type: ADD_TYPE,
    payload,
  }
}

//update Type
export const updateType = (payload) => {
  return {
    type: UPDATE_TYPE,
    payload,
  }
}
