import React, { Fragment, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '../Dialog'

export default function ImageList(props) {
  const [list, setList] = useState(props.list)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(false)

  useEffect(() => {
    if (Array.isArray(props.list)) setList(props.list)
  }, [props.list])

  const onRemove = (index) => {
    setList([...list.filter((item, i) => index !== i)])
    if (props.onChange) props.onChange([...list.filter((item, i) => index !== i)])
  }

  return (
    <Fragment>
      <Dialog title='Image' open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <img src={selectedImage} alt='selected' />
      </Dialog>
      <Grid container spacing={1}>
        {list.map((listItem, index) => (
          <Grid item key={listItem + Math.random()}>
            <ImageCard
              src={listItem}
              onRemove={() => onRemove(index)}
              setDialogOpen={setDialogOpen}
              setSelectedImage={setSelectedImage}
              readOnly={props.readOnly}
            />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  )
}

const ImageCard = (props) => {
  const outerClasses = useOuterStyles(props)
  const containerClasses = useContainerStyles(props)
  const contentClasses = useContentStyles(props)
  const iconsClasses = useIconsStyles()

  const viewImage = () => {
    props.setSelectedImage(props.src)
    props.setDialogOpen(true)
  }

  return (
    <div className={outerClasses.root}>
      <div className={containerClasses.root}>
        <div className={contentClasses.root}>
          <IconButton onClick={viewImage} size='small'>
            <VisibilityIcon className={iconsClasses.root} />
          </IconButton>
          {!props.readOnly && (
            <IconButton onClick={() => (props.onRemove ? props.onRemove() : null)} size='small'>
              <DeleteIcon className={iconsClasses.root} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  )
}

const useOuterStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    background: 'transparent',
    fontFamily: 'inherit',
    border: `1px solid ${theme.palette.grey[100]}`,
    width: 100,
    height: 100,
    position: 'relative',
    padding: 2,
  },
}))

const useContainerStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
    backgroundImage: (props) =>`url(${props.src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      '& > div': {
        display: 'flex',
        opacity: 0.7,
      },
    },
  },
}))

const useContentStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    transition: 'opacity 1s ease-out',
    opacity: 0,
    background: theme.palette.common.black,
  },
}))

const useIconsStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}))
