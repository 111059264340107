import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_PRICELISTS,
  GET_PRICELIST,
  UPDATE_PRICELIST,
} from '../../../../config/constants/ActionTypes'
import {
  getPriceListsSuccess,
  getPriceListsFailure,
} from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getPricelistsRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'pricelists',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getPricelistsSaga({ payload }) {
  try {
    const response = yield call(getPricelistsRequest, payload)
    yield put(getPriceListsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getPriceListsFailure(error))
    payload.onFailure(error)
  }
}

const getPricelistRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'pricelists/single',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getPricelistSaga({ payload }) {
  try {
    const response = yield call(getPricelistRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

const updatePriceListRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'pricelists/single',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updatePriceListSaga({ payload }) {
  try {
    const response = yield call(updatePriceListRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PRICELISTS, getPricelistsSaga),
    yield takeEvery(GET_PRICELIST, getPricelistSaga),
    yield takeEvery(UPDATE_PRICELIST, updatePriceListSaga),
  ])
}
