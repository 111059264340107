/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Row, Col, message, Button, Select, Icon } from 'antd'
import TableView from '../Table'
import PaginationView from '../Pagination'
import SearchInput from '../../../Form/components/SearchInput'
import definitions from '../../../../config/definitions'
import AddIconButton from '../../../Form/components/AddIconButton'
import { connect } from 'react-redux'
import { search } from '../../../CompanyProduct/store/actions'
import styled from 'styled-components'
import { getStaffType, hasPermissionToDelete } from '../../../../services/commonServices'
import { path, omit, pathOr, prop } from 'ramda'
import * as rootConfig from '../../../../config/Structure/index'
import  UploadFile  from '../../../Form/components/UploadFile'
import { getCompanies } from '../../../Company/store/actions';
import axios from 'axios'
import configUrl from '../../../../config/Client/sagas/config'
import LinearProgress from '@material-ui/core/LinearProgress'
import CustomButton from '../../../Form/components/Button'
import { canDownloadContent } from '../../helperFunctions'

const { Option } = Select;
const View = ({ componentName, config, dispatch, deleteKey, state, customParams, setComponentLoading, readFile, downloadExcelFile, getCompanyId, checkSheetErrors, getImages, refetchData, setReload, uploadLoader, exportConfig }) => {
  const location = useLocation()
  const history = useHistory()
  let query = queryString.parse(location.search, { arrayFormat: 'comma' })
  const parsedPageNumber = parseInt(query.page) || 1
  Object.entries(query).forEach(([key, value]) => {
    if (value === 'true') query[key] = true
    if (value === 'false') query[key] = false
  })

  const [fetchedData, setFetchedData] = useState()
  const [staff, setStaff] = useState()
  const [deleting, setDeleting] = useState(false)
  const [timer, setTimer] = useState(null)
  const [records, setRecords] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [pageSize] = useState(definitions.defaultPageSize)
  const [loading, setLoading] = useState(uploadLoader || false)
  const variables = { ...query, limit: pageSize, ...customParams }
  const [companyId, setCompanyId] = useState('');
  const isSuperAdmin = path(['auth', 'user', 'staff', 'isSuperAdmin'], state);
  const [companies, setCompanies] = useState();
  const sellerRef = state.auth.user?.staff?.sellerRef;
  const [ids, setIds] = useState([]);
  const [addValid, setAddValid] = useState(false);
  const [validFile, setValidFile] = useState();
  const compIdQuery = queryString.parse(location.search);
  const [addValidQuery, setAddValidQuery] = useState(false);
  const [clearFile, setClearFile] = useState(false);

  // Loader for the download csv file
  const [csvLoading, setCsvLoading] = useState(false);
  const [fileLink, setFileLink] = useState(); // S3 link for downloading the CSV file

  useEffect(() => {
    if (refetchData) {
      getRecords(1)
      setClearFile(true)
    }
  }, [refetchData])



  const getAllCompanies = () => {
    dispatch(
      getCompanies({
        onSuccess: ({ data, count }) => {
          let result = data.filter((item) => item !== null);
          setCompanies(
            result.map((datum, key) => (
              <Option
                key={datum?._id}
                value={`${datum?._id}`}
                onChange={(e) => {}}
              >
                {pathOr(prop('name', datum), ['name', 'ar'], datum)}
              </Option>
            ))
          );
        },
        onFailure: () => message.error('حدث خطأ'),
      })
    );
  };

  const clearUrl = () => {
    history.push(`/companyProducts`);
  }

  useEffect(() => {
    getAllCompanies();
  }, [companyId]);

  useEffect(() => {
    if(sellerRef && sellerRef?._id && !isSuperAdmin){
      setCompanyId(sellerRef?._id)
      generateExcelTemplate(sellerRef?._id)
      setAddValid(false)
    }
    if(compIdQuery && compIdQuery?.company && compIdQuery?.company !== ''){
      setCompanyId(compIdQuery?.company)
      if (getCompanyId) getCompanyId(compIdQuery?.company)
      generateExcelTemplate(compIdQuery?.company)
      setAddValidQuery(false)  
    }

  }, [sellerRef]);

  useEffect(() => {
    if(getStaffType(path(['auth', 'user', 'staff'], state)) === 'company' &&
    sellerRef &&
    sellerRef !== null){
      setCompanyId(sellerRef?._id)
    }
    if (getStaffType(path(['auth', 'user', 'staff'], state)) === 'company' &&
    sellerRef &&
    sellerRef !== null) {
      setCompanyId(sellerRef?._id)
    }
  }, [companyId,sellerRef,path(['auth', 'user', 'staff', 'sellerRef?', '_id'], state)]);

  const onReset = (value, id) => {
    if (value === 'sellers' && `${id}` !== '') {
      setCompanyId('');
    }
  }

  const generateExcelTemplate = (id) => {
    let query = {}
    if(id && id !== ''){
      query = { company: id }
    }
    if(query !== {} && id !== '' && id.length > 0){
      axios({
        method:'GET',
        url: `${configUrl.url}companyProducts/generateExcelTemplate`,      
        params: {
          ...query
        }
      }).then((res) => {
        setAddValid(res.data.fileGenerated)
        setAddValidQuery(res.data.fileGenerated)
        return res.data.message
      });
    }
  }


  
  let fields = config.view.fields
  if (!hasPermissionToDelete(getStaffType(path(['auth', 'user', 'staff'], state)), componentName)) {
    fields = fields.filter((field) => field.type !== 'deleteAction')
  }
  useEffect(() => {
    setStaff(path(['auth', 'user', 'staff'], state))
    if ((query.distributor || query['distributors.distributor']) && getStaffType(path(['auth', 'user', 'staff'], state)) === 'company') {
      dispatch(
        rootConfig.config.distributors.edit.get({
          query: { _id: query.distributor || query['distributors.distributor'] },
          onSuccess: onFetchSuccess,
          onFailure: onFetchFailure,
        }),
      )
    }
    if (query['seller.id'] && path(['auth', 'user', 'staff', 'sellerRef', '_id'], state) !== query['seller.id']) {
      dispatch(
        rootConfig.config.distributors.edit.get({
          query: { _id: query['seller.id'] },
          onSuccess: onFetchSuccess,
          onFailure: onFetchFailure,
        }),
      )
    }
  }, []) // eslint-disable-line

  const onFetchSuccess = (data) => {
    setFetchedData(data)
  }

  useEffect(
    (_) => {
      if (staff && fetchedData && getStaffType(staff) !== 'superAdmin' && path(['data', '0', 'company', '_id'], fetchedData) !== path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)) {
        history.push(`/companies/view/${path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)}`)
      }
    },
    [fetchedData],
  )

  const onFetchFailure = () => {
    history.push(`/companies/view/${path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)}`)
  }

  useEffect(() => {
    if(checkSheetErrors && checkSheetErrors !== []){
    }
    setLoading(true)
    if (setComponentLoading) setComponentLoading(true)
    getRecords(parsedPageNumber)
    if (canDownloadContent(path(['auth', 'user', 'staff'], state), componentName)) getAllRecords()
  }, [location.search, customParams,checkSheetErrors]) // eslint-disable-line

  const getRecords = (page) => {
    if (query?.searchTerm) {
      let type
      if (componentName.slice(-3) === 'ies') {
        type = componentName.slice(0, -3) + 'y'
      } else {
        type = componentName.slice(0, -1)
      }
      if (componentName === 'ads')
        // ads keyword is prevented by adblock extensions in browsers
        type = 'commercial'
      if (type === 'order' || type === 'staff') query = { ...query, ...customParams }
if(type==='order' || type === 'staff'){
  dispatch(config.view.get({ query: { ...variables, skip: page }, onSuccess, onFailure }))
}else{
      dispatch(
        search({
          query: {
            ...query,
            searchItem: query?.searchTerm.trim(),
            type,
            limit: pageSize,
            skip: page,
          },
          onSuccess,
          onFailure,
        }),
      )
      }
      } else {
        delete variables.searchTerm
        dispatch(config.view.get({ query: { ...variables, skip: page }, onSuccess, onFailure }))
      }
  }

  const onDelete = (_id) => {
    setDeleting(true)
    dispatch(
      config.view.delete({
        query: {
          ...variables,
          skip: parsedPageNumber,
        },
        _id,
        distributorId: query['distributors.distributor'],
        staffType: getStaffType(path(['auth', 'user', 'staff'], state)),
        getRecords,
        onSuccess,
        onFailure,
      }),
    )
  }

  const onSuccess = ({ data, count }) => {
    setLoading(false)
    if (setComponentLoading) setComponentLoading(false)
    setDeleting(false)
    setRecords(data.filter((item) => item !== null))
    setTotalRecords(count)
    if (setReload) setReload(false)
  }

  const onFailure = (error) => {
    setLoading(false)
    if (setComponentLoading) setComponentLoading(false)
    setDeleting(false)
    message.error('حدث خطأ')
  }

  const searchFor = (val) => {
    query['searchTerm'] = val
    query['page'] = 1
    history.push(`${location.pathname}?${queryString.stringify(query)}`)
  }

  const debounce = (value) => {
    if (timer) {
      clearTimeout(timer)
    }
    setTimer(
      setTimeout(() => {
        searchFor(value)
        setTimer(clearTimeout(timer))
      }, 800),
    )
  }

  let styles = ``
  fields.forEach((field, index) => {
    styles += `
      td:nth-of-type(${index + 1}):before {
        content: "${field.title}"; 
      }
    `
  })

  // Get all records (ex: Companies, distributors, reps)
  const getAllRecords = () => {
    setCsvLoading(true)
    dispatch(exportConfig.view.get({ query: { ...query, ...customParams, moduleName: componentName }, onSuccess: onExportSuccess, onFailure: onExportFailure }))
  }

  const onExportSuccess = (res) => {
    setFileLink(res.link)
    setCsvLoading(false)
  }

  const onExportFailure = () => {
    message.error('حدث خطأ')
  }

  return (
    <OrderHolder styles={styles}>
      {
        uploadLoader && <Row gutter={24}>
          <LinearProgress />
          <VerticalSpace />
        </Row>
      }
      <Row gutter={24}>
        {
          canDownloadContent(staff, componentName) && (
            <Col xl={2}>
              <CustomButton
                readOnly={csvLoading}
                color='primary' 
                variant='outlined'
                onClick={() => document.getElementById('download-link').click()}
              >
                <Icon type={csvLoading? 'loading' : 'download'} />
                <a href={fileLink} id='download-link'></a>
              </CustomButton>
            </Col>
          )
        }
        <Col xl={6}>{!config.NoAdd && <AddIconButton disabled={!config.add} uri={`/${componentName}/new?${queryString.stringify(omit(['page'], query))}`} text='أضف' />}</Col>
        {!config.NoSearch && (
          <Col xl={canDownloadContent(staff, componentName) ? 16 : 18}>
            <SearchInput title={`Filter by ${config.view.filterParameter}`} dataIndex='filter' onChange={debounce} value={query.searchTerm || ''} />
          </Col>
        )}
      </Row>
      {componentName ==='companyProducts' && <Row gutter={8} type='flex' justify='center'>
        <Col style={{marginTop: '1rem',marginLeft: '1.1rem',fontSize: '1.6rem'}} xl={4}>
          <SelectInputStyle hidden={(isSuperAdmin && query?.company && query?.company !== '') || !isSuperAdmin ? true : false}>
            <Select
              value={companyId || 'الشركات'}
              optionFilterProp='children'
              showSearch={true}
              disabled={false}
              placeholder={'الشركات'}
              dropdownMenuStyle={{
                background: 'var(--color-primary)',
                color: 'var(--color-font-secondary)',
              }}
              name={'الشركات'}
              filterOption={true}
              onSelect={(value) => {
                setCompanyId(value);
                message.loading('انتظر قليلا حتى يتم تجهيز النموذج' ,3)
                getCompanyId(value)
                generateExcelTemplate(value)
                setAddValid(false)
              }}
            >
              {companies}
            </Select>
            <Icon
              hidden={companyId === '' ? true : false}
              type='close-circle'
              style={{ fontSize: '18px', marginRight: '3px' }}
              onClick={() => onReset('sellers', companyId)}
            />
          </SelectInputStyle>
        </Col> 
        <Col style={{marginTop: '0.8rem',marginLeft: '1.1rem',fontSize: '2rem'}} xl={3}>
          <Button style={{color: '#4a7fbd',border: '2px solid #4a7fbd'}} size={'large'} disabled={companyId === '' || addValid === false  ? true : false} 
                  onClick={() => {downloadExcelFile()}}>
            <Icon
              type='download'
              style={{ fontSize: '18px', marginRight: '3px' }}/>
            <span style={{color: '#4a7fbd',marginRight: '3px'}}> نموذج الملف</span>
          </Button>
        </Col>  
        <Col style={{marginTop: '1rem', marginLeft: '-0.9rem',fontSize: '1.2rem'}} xl={4}>
          <UploadFile accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name={'+ أضف منتجات'} onChange={(val) => {
            readFile(val,val?.type); 
            if(val?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
              setValidFile(false)
            }else{setValidFile(true)}}} 
            validFile={companyId !== '' && validFile}
            readOnly={companyId === '' || addValid === false  ? true : false} inputReadOnly={(compIdQuery?.company === '' || addValidQuery === false) || (companyId === '' || addValid === false)  ? true : false}
            onClick={clearUrl}
            clearFile={clearFile}
            setClearFile={setClearFile}
            />
        </Col> 
        <Col style={{marginTop: '1.3rem',fontSize: '1.6rem'}} xl={5}>
            <StyledInput>
              <Icon type='upload'/>
              <label htmlFor="img"> أضف صور المنتجات</label>
              <input 
                style={{display: 'none'}}
                size={'default'}
                id={'img'}
                type='file'
                accept='image/*'
                onChange={(event) => getImages(event)}
                multiple={true}
                webkitdirectory={true ? 1 : 0} />
            </StyledInput>
        </Col>
      </Row>  
      }
      {componentName ==='companyProducts' && <Row>
        <TabContent style={{marginTop: '1rem'}}>
          <div>
            <h3>ملاحظات هامة:</h3>
            <div style={{color: 'green',fontSize: '1rem'}}>
              <p>
                1- يجب تحميل  نموذج الملف واستخدامه لإضافة المنتجات حتى تتم الإضافة بشكل صحيح.
              </p>
              <p>
                2- يجب اختيار الشركة لتحميل نموذج الملف.
              </p>
            </div>
          </div>
        </TabContent>
      </Row>}
      <Row>
        <TableView
          loading={deleting || loading}
          data={records}
          headers={fields}
          componentName={componentName}
          onDelete={onDelete}
          deleteKey={config?.view?.deleteKey}
          onRefetch={() => getRecords(parsedPageNumber)}
        />
      </Row>
      <Row>{!config.NoPagination && <PaginationView totalCount={totalRecords} pageSize={pageSize} getRecords={getRecords} />}</Row>
    </OrderHolder>
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(View)

const OrderHolder = styled.div`
  .MuiDialogContent-root {
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    ${(props) => props.styles}
  }
`;

const SelectInputStyle = styled.div`
  .ant-select-selection .ant-select-selection__choice {
    background: var(--color-primary);
    border: 0;
    border-radius: 2rem;
    font-size: 1rem;
    color: var(--color-font-secondary);
  }

  input.MuiInputBase-input.MuiInput-input,
  .ant-select-dropdown-menu-item {
    color: var(--color-form) !important;
  }

  .ant-select-remove-icon {
    color: var(--color-font-secondary);
  }

  .ant-select-selection {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #8dc3e72b;
    font-weight: 120;
    color: var(--color-font-primary);
    font-size: 1.25rem;
    text-transform: uppercase;
    border-radius: 0;
  }

  .ant-select-selection {
    min-height: 32px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1;
    width: 200px;
  }

  .ant-select-arrow .ant-select-arrow-icon svg {
    display: none;
  }
  i.anticon.anticon-down.ant-select-arrow-icon:before {
    content: '\f107';
    transition: 0.3s;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  i.anticon.anticon-down.ant-select-arrow-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon.anticon-down,
  .anticon-spin {
    font-size: 1.25rem;
    color: var(--color-secondary);
    position: relative;
    bottom: 0.5rem;
  }
`;
const TabContent = styled.div`
  background: var(--color-primary);
  border-radius: 0.5rem;
  border: 0;
  color: var(--color-font-secondary);
  text-transform: uppercase;
  padding: 1rem;
  width: 50%
  }
`;

const StyledInput = styled(Button)`
  background: white;
  color: #4a7fbd;
  width: 12rem;
  height: 2.6rem;
  text-align: center;
  font-size: 17px;
  border: 2px solid #4a7fbd;
  border-radius: 4px;
  button:active {
    border: 2px solid #4a7fbd;
  }
`;

const VerticalSpace = styled.div`
  height: 1rem;
`