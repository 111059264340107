import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_DISTRIBUTORS,
  DELETE_DISTRIBUTOR,
  ADD_DISTRIBUTOR,
  UPDATE_DISTRIBUTOR,
  GET_DISTRIBUTOR,
} from '../../../../config/constants/ActionTypes'
import { getDistributors, getDistributorsSuccess, getDistributorsFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getDistributorsRequest = async ({ query }) => {
  return await axios({
    method: 'post',
    url: config.url + 'distributors/getDistributors',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getDistributorsSaga({ payload }) {
  try {
    const response = yield call(getDistributorsRequest, payload)
    yield put(getDistributorsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getDistributorsFailure(error))
    payload.onFailure(error)
  }
}

const getDistributorRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'distributors/distributor',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getDistributorSaga({ payload }) {
  try {
    const response = yield call(getDistributorRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}


const deleteDistributorRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'distributors',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteDistributorSaga({ payload }) {
  try {
    const response = yield call(deleteDistributorRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addDistributorRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'distributors',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addDistributorSaga({ payload }) {
  try {
    const response = yield call(addDistributorRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateDistributorRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'distributors',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateDistributorSaga({ payload }) {
  try {
    const response = yield call(updateDistributorRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getDistributors(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_DISTRIBUTORS, getDistributorsSaga)],
    [yield takeEvery(DELETE_DISTRIBUTOR, deleteDistributorSaga)],
    [yield takeEvery(ADD_DISTRIBUTOR, addDistributorSaga)],
    [yield takeEvery(UPDATE_DISTRIBUTOR, updateDistributorSaga)],
    [yield takeEvery(GET_DISTRIBUTOR, getDistributorSaga)],
  )
}
