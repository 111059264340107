import {
  GET_STAFF,
  GET_STAFFS,
  GET_STAFFS_SUCCESS,
  GET_STAFFS_FAILURE,
  DELETE_STAFF,
  ADD_STAFF,
  UPDATE_STAFF,
} from '../../../../config/constants/ActionTypes'

//Get staff Request
export const getStaff = (payload) => {
  return {
    type: GET_STAFF,
    payload,
  }
}

//Get staffs Request
export const getStaffs = (payload) => {
  return {
    type: GET_STAFFS,
    payload,
  }
}

//Get types Success
export const getStaffsSuccess = (types) => {
  return {
    type: GET_STAFFS_SUCCESS,
    payload: { types },
  }
}

//Get types Failure
export const getStaffsFailure = (error) => {
  return {
    type: GET_STAFFS_FAILURE,
    payload: { error },
  }
}

//Delete Staff
export const deleteStaff = (payload) => {
  return {
    type: DELETE_STAFF,
    payload,
  }
}

//Add Staff
export const addStaff = (payload) => {
  return {
    type: ADD_STAFF,
    payload,
  }
}

//update Staff
export const updateStaff = (payload) => {
  return {
    type: UPDATE_STAFF,
    payload,
  }
}
