import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_ZONES,
  GET_ZONES_NAMES,
  DELETE_ZONE,
  ADD_ZONE,
  UPDATE_ZONE,
  GET_DISTRIBUTOR_ZONES,
} from '../../../../config/constants/ActionTypes'
import { getZones, getZonesSuccess, getZonesFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getZonesRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'zones',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getZonesSaga({ payload }) {
  try {
    const response = yield call(getZonesRequest, payload)
    yield put(getZonesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getZonesFailure(error))
    payload.onFailure(error)
  }
}

const getZonesNamesRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'zones/names',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getZonesNamesSaga({ payload }) {
  try {
    const response = yield call(getZonesNamesRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}
function* getDistributorZonesSaga({ payload }) {
  try {
    const response = yield call(getDistributorZonesRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}
const getDistributorZonesRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'distributors/distributorZones',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}
const deleteZoneRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'zones',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteZoneSaga({ payload }) {
  try {
    const response = yield call(deleteZoneRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addZoneRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'zones',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addZoneSaga({ payload }) {
  try {
    const response = yield call(addZoneRequest, payload) // eslint-disable-line
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateZoneRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'zones',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateZoneSaga({ payload }) {
  try {
    const response = yield call(updateZoneRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getZones(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_ZONES, getZonesSaga)],
    [yield takeEvery(GET_ZONES_NAMES, getZonesNamesSaga)],
    [yield takeEvery(GET_DISTRIBUTOR_ZONES, getDistributorZonesSaga)],
    [yield takeEvery(DELETE_ZONE, deleteZoneSaga)],
    [yield takeEvery(ADD_ZONE, addZoneSaga)],
    [yield takeEvery(UPDATE_ZONE, updateZoneSaga)]
  )
}
