import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { isEmpty } from 'ramda'

const AddStaffForm = (props) => {
  const history = useHistory()
  const location = useLocation()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState(false)

  const query = queryString.parse(location.search)

  useEffect(() => {
    setValues(isEmpty(query) ? undefined : query)
  }, []) // eslint-disable-line

  const onSubmit = async (values) => {
    setSubmitting(true)
    values['sellerCategory'] =
      values['sellerCategory'].includes('distributors') ? 'Distributor' : 'Company'
    props.dispatch(config.staff.add.create({ body: values, onSuccess, onFailure }))
  }

  const onSuccess = () => {
    setSubmitting(false)
    message.success('تم الإضافة بنجاح')
    const redirectQuery = queryString.parse(location.search)
    let finalQuery = queryString.stringify(redirectQuery)
      history.push(`/staffs?${finalQuery}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    if (error.response.status === 409)
      message.error('هذا العامل موجود بالفعل!')
    else message.error('حدث خطأ')
  }

  return (
    <CrudForm
      title='أضف عامل جديد '
      loading={false}
      fields={config.staff.add.fields}
      onSubmit={onSubmit}
      buttonText='أضف'
      submitting={submitting}
      initialValues={values}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(AddStaffForm)
