import {
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAILURE,
  GET_BRAND,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAILURE,
  DELETE_BRAND,
  ADD_BRAND,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAILURE,
  EDIT_BRAND,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Get Brands Request
export const getBrands = (payload) => {
  return {
    type: GET_BRANDS,
    payload,
  }
}

//Get Brands Success
export const getBrandsSuccess = (brands) => {
  return {
    type: GET_BRANDS_SUCCESS,
    payload: { brands },
  }
}

//Get Brands Failure
export const getBrandsFailure = (error) => {
  return {
    type: GET_BRANDS_FAILURE,
    payload: { error },
  }
}

//Get Brand Request
export const getBrand = (payload) => {
  return {
    type: GET_BRAND,
    payload,
  }
}

//Get Brand Success
export const getBrandSuccess = (brand) => {
  return {
    type: GET_BRAND_SUCCESS,
    payload: { brand },
  }
}

//Get Brand Failure
export const getBrandFailure = (error) => {
  return {
    type: GET_BRAND_FAILURE,
    payload: { error },
  }
}

//Add Brand Request
export const addBrand = (payload) => {
  return {
    type: ADD_BRAND,
    payload,
  }
}

//Add Brand Success
export const addBrandSuccess = (brand) => {
  return {
    type: ADD_BRAND_SUCCESS,
    payload: { brand },
  }
}

//Add Brand Failure
export const addBrandFailure = (error) => {
  return {
    type: ADD_BRAND_FAILURE,
    payload: { error },
  }
}

//Edit Brand Request
export const editBrand = (payload) => {
  return {
    type: EDIT_BRAND,
    payload,
  }
}

//Edit Brand Success
export const editBrandSuccess = (brand) => {
  return {
    type: EDIT_BRAND_SUCCESS,
    payload: { brand },
  }
}

//Edit Brand Failure
export const editBrandFailure = (error) => {
  return {
    type: EDIT_BRAND_FAILURE,
    payload: { error },
  }
}

//Delete Brands
export const deleteBrand = (payload) => {
  return {
    type: DELETE_BRAND,
    payload,
  }
}
