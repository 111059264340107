import {
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAILURE,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAILURE,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAILURE,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_BRANDS_SUCCESS: {
      return {
        ...state,
        brands: action.payload.brands,
      }
    }

    case GET_BRANDS_FAILURE: {
      return {
        ...state,
        getBrandsError: action.payload.error,
      }
    }

    case GET_BRAND_SUCCESS: {
      return {
        ...state,
        brands: action.payload.brand,
      }
    }

    case GET_BRAND_FAILURE: {
      return {
        ...state,
        getBrandsError: action.payload.error,
      }
    }

    case ADD_BRAND_SUCCESS: {
      return {
        ...state,
        brand: action.payload.brand,
      }
    }

    case ADD_BRAND_FAILURE: {
      return {
        ...state,
        addBrandError: action.payload.error,
      }
    }

    case EDIT_BRAND_SUCCESS: {
      return {
        ...state,
        brand: action.payload.brand,
      }
    }

    case EDIT_BRAND_FAILURE: {
      return {
        ...state,
        addBrandError: action.payload.error,
      }
    }

    default:
      return state
  }
}
