import {
  GET_QUANTITY_OFFERS_SUCCESS,
  GET_QUANTITY_OFFERS_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_QUANTITY_OFFERS_SUCCESS: {
      return {
        ...state,
        quantityOffers: action.payload.quantityOffers,
      }
    }

    case GET_QUANTITY_OFFERS_FAILURE: {
      return {
        ...state,
        getQuantityOffersError: action.payload.error,
      }
    }

    default:
      return state
  }
}
