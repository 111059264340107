import {
  GET_PACKAGE_UNITS_SUCCESS,
  GET_PACKAGE_UNITS_FAILURE,
  // GET_PACKAGE_UNIT_SUCCESS,
  // GET_PACKAGE_UNIT_FAILURE,
  ADD_PACKAGE_UNIT_SUCCESS,
  ADD_PACKAGE_UNIT_FAILURE,
  EDIT_PACKAGE_UNIT_SUCCESS,
  EDIT_PACKAGE_UNIT_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PACKAGE_UNITS_SUCCESS: {
      return {
        ...state,
        packageUnits: action.payload.packageUnits,
      }
    }

    case GET_PACKAGE_UNITS_FAILURE: {
      return {
        ...state,
        getPackageUnitsError: action.payload.error,
      }
    }

    // case GET_PACKAGE_UNIT_SUCCESS: {
    //   return {
    //     ...state,
    //     packageUnits: action.payload.packageUnit,
    //   }
    // }

    // case GET_PACKAGE_UNIT_FAILURE: {
    //   return {
    //     ...state,
    //     getPackageUnitsError: action.payload.error,
    //   }
    // }

    case ADD_PACKAGE_UNIT_SUCCESS: {
      return {
        ...state,
        message: action.payload.message,
      }
    }

    case ADD_PACKAGE_UNIT_FAILURE: {
      return {
        ...state,
        addPackageUnitError: action.payload.error,
      }
    }

    case EDIT_PACKAGE_UNIT_SUCCESS: {
      return {
        ...state,
        message: action.payload.message,
      }
    }

    case EDIT_PACKAGE_UNIT_FAILURE: {
      return {
        ...state,
        addPackageUnitError: action.payload.error,
      }
    }

    default:
      return state
  }
}
