import React, { useState } from 'react'
import styled from 'styled-components'
import { Divider, Row, Menu, Dropdown, Icon, Col } from 'antd'
import { GlobalContext } from '../../../../GlobalState'
import { history } from '../../../../pages/Router'
import UserMenu from '../UserMenu'
import profileImage from '../../../../img/usermenu-profile.jpg'

const TopBar = (props) => {
  const { state } = GlobalContext()
  const [location, setLocation] = useState(history.location.pathname.split('/')[1] ? history.location.pathname.split('/')[1].toUpperCase() : 'ADMIN')

  history.listen((location) => {
    const title = location.pathname.split('/')[1] ? location.pathname.split('/')[1].toUpperCase() : 'ADMIN'
    setLocation(title)
  })

  const menu = (
    <TopbarMenuStyles className='Topbar-Menu'>
      <Menu>
        <UserMenu />
      </Menu>
    </TopbarMenuStyles>
  )

  return (
    <TopBarStyle>
      <TopBarBg>
        <Row>
          <Col md={8} xs={24}>
            <TopBarInfo>{location}</TopBarInfo>
          </Col>
          <Col md={16} xs={24}>
            <TopBarUser>
              <img src={profileImage} alt='profile' />
              <span className='username' >{state.staff ? state.staff.name : ''}</span>
              <Divider type='vertical' />
              <Dropdown overlay={menu} trigger={['click']}>
                <Icon type='setting' />
              </Dropdown>
            </TopBarUser>
          </Col>
        </Row>
      </TopBarBg>
    </TopBarStyle>
  )
}

const TopbarMenuStyles = styled.div`
  .ant-menu {
    border-radius: 10px !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 0;
    padding-left: 0;
    background: transparent;
    border-radius: 0px;
    border: 0;
    margin: 0 !important;
    border: 0;
    outline: 0;
    box-shadow: none;
  }

`

const TopBarStyle = styled.div`
  .ant-divider.ant-divider-vertical {
    background: #5d616a;
    height: 45px;
    margin-left: 15px;
    margin-right: 6px;
    margin-top: -6px;
    
    @media (max-width:767px){
      height: 30px;

    }

  }

  i.anticon.anticon-setting.ant-dropdown-trigger:hover {
    transform: rotate(180deg);
    transition: 0.5s;
    /* margin: 1px; */
}
`

const TopBarBg = styled.div`


  padding: 3px 70px;
  z-index: 10;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--color-primary);
@media (max-width:767px){
  padding:10px 15px;
  
}

`

const TopBarInfo = styled.h2`
  font-size: 25px;
  margin-top: 15px;
  color: var(--color-font-secondary);
  text-transform: uppercase;
  font-weight: 500;
  text-align: left;

  @media (max-width:767px){
    font-size: 18px;
    margin-top:0;
    text-align: center;
  }
`

const TopBarUser = styled.div`
  text-align: right;
  margin-top: 10px;
  @media (max-width:767px){
    margin-top: 0;
    margin: 0 auto;
    display: block;
    text-align: center;


  }
  span.username {
    color: var(--color-font-secondary);
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 300;
    padding: 0;
    margin-right: 18px;

    @media (max-width:767px){
      margin-right: 10px;

    }

  }

  img {
    width: 40px;
    margin-right: 0;
    height: 40px;
    border-radius: 50px;
    vertical-align: top;
  }

  i {
    display: inline-block;
    margin-left: 25px;
    font-size: 25px;
    color: var(--color-font-secondary);
    cursor: pointer;
    @media (max-width:767px){
      margin-left: 0;
      margin-top: 7px;

    }
  }
  i:hover {
    transition: 0.4s;
    color: var(--color-secondary);
  }
`

export default TopBar
