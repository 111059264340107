import { all, call, put, takeEvery } from 'redux-saga/effects'
import { GET_TAGS, DELETE_TAG, ADD_TAG, UPDATE_TAG } from '../../../../config/constants/ActionTypes'
import { getTags, getTagsSuccess, getTagsFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getTagsRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'tags/paginated',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
    headers: { lite },
  })
}

function* getTagsSaga({ payload }) {
  try {
    const response = yield call(getTagsRequest, payload)
    yield put(getTagsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getTagsFailure(error))
    payload.onFailure(error)
  }
}

const deleteTagRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'tags',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteTagSaga({ payload }) {
  try {
    const response = yield call(deleteTagRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addTagRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'tags',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addTagSaga({ payload }) {
  try {
    const response = yield call(addTagRequest, payload)
    payload.onSuccess(path(['data', '_id'], response))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateTagRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'tags',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateTagSaga({ payload }) {
  try {
    const response = yield call(updateTagRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getTags(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_TAGS, getTagsSaga)],
    [yield takeEvery(DELETE_TAG, deleteTagSaga)],
    [yield takeEvery(ADD_TAG, addTagSaga)],
    [yield takeEvery(UPDATE_TAG, updateTagSaga)],
  )
}
