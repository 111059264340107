import { all, call, put, takeEvery } from 'redux-saga/effects'
import { GET_SIZES, DELETE_SIZE, ADD_SIZE, EDIT_SIZE} from '../../../../config/constants/ActionTypes'
import { 
  getSizesFailure,
  getSizesSuccess, 
  addSizeSuccess,
  addSizeFailure,
  editSizeSuccess,
  editSizeFailure,
} from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getSizesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'sizes/',
    params: { ...query, isDeleted: false },
    headers: {
      lite,
      token: `${localStorage.getItem('token')}`,
    },
  })
}

function* getSizesSaga({ payload }) {
  try {
    const response = yield call(getSizesRequest, payload)
    yield put(getSizesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getSizesFailure(error))
    payload.onFailure(error)
  }
}

// const getBrandRequest = async ({ query }) => {
//   return await axios({
//     method: 'get',
//     url: config.url + 'brands/brand',
//     params: { _id: query._id },
//     headers: { token: `${localStorage.getItem('token')}` } 
//   })
// }

// function* getBrandSaga({ payload }) {
//   try {
//     const response = yield call(getBrandRequest, payload)
//     yield put(getBrandSuccess(response.data))
//     payload.onSuccess(response.data)
//   } catch (error) {
//     yield put(getBrandFailure(error))
//     payload.onFailure(error)
//   }
// }

const deleteSizeRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'sizes/',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteSizeSaga({ payload }) {
  try {
    yield call(deleteSizeRequest, payload)
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addSizeRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'sizes/',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addSizeSaga({ payload }) {
  try {
    const response = yield call(addSizeRequest, payload)
    yield put(addSizeSuccess(response.data))
    payload.onSuccess(path(['data', '_id'], response))
  } catch (error) {
    yield put(addSizeFailure(error))
    payload.onFailure(error)
  }
}

const editSizeRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'sizes/',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* editSizeSaga({ payload }) {
  try {
    const response = yield call(editSizeRequest, payload)
    yield put(editSizeSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(editSizeFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    // [yield takeEvery(GET_SIZE, getBrandSaga)],
    [yield takeEvery(GET_SIZES, getSizesSaga)],
    [yield takeEvery(DELETE_SIZE, deleteSizeSaga)],
    [yield takeEvery(ADD_SIZE, addSizeSaga)],
    [yield takeEvery(EDIT_SIZE, editSizeSaga)],
  )
}
