import React from "react";
import { Router as BRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { path, isEmpty } from "ramda";
import { getStaffType } from "../../services/commonServices";

import Login from "../Login";

import AddCompany from "../AddCompany";
import ViewCompanies from "../ViewCompanies";
import EditCompany from "../EditCompany";
import ViewCompany from "../ViewCompany";

import AddBrand from "../AddBrand";
import ViewBrands from "../ViewBrands";
import EditBrand from "../EditBrand";

import AddCompanyProduct from "../AddCompanyProduct";
import ViewCompanyProducts from "../ViewCompanyProducts";
import EditCompanyProduct from "../EditCompanyProduct";

import AddRep from "../AddRep";
import ViewReps from "../ViewReps";
import EditRep from "../EditRep";
import ViewRep from "../ViewRep";

import AddBuyer from "../AddBuyer";
import ViewBuyers from "../ViewBuyers";
import EditBuyer from "../EditBuyer";
import RoleEditBuyer from "../RoleEditBuyer";

import ViewPendingBuyers from "../ViewPendingBuyers";

import AddDistributor from "../AddDistributor";
import ViewDistributors from "../ViewDistributors";
import EditDistributor from "../EditDistributor";
import ViewDistributor from "../ViewDistributor";

import AddPackage from "../AddPackage";
import ViewPackages from "../ViewPackages";
import EditPackage from "../EditPackage";

import AddSize from "../AddSize";
import ViewSizes from "../ViewSizes";
import EditSize from "../EditSize";

import AddPackageUnit from "../AddPackageUnit";
import ViewPackageUnits from "../ViewPackageUnits";
import EditPackageUnit from "../EditPackageUnit";

import AddSubscription from "../AddSubscription";
import ViewSubscriptions from "../ViewSubscriptions";
import EditSubscription from "../EditSubscription";

import AddSection from "../AddSection";
import ViewSections from "../ViewSections";
import EditSection from "../EditSection";

import AddCategory from "../AddCategory";
import ViewCategories from "../ViewCategories";
import EditCategory from "../EditCategory";

import AddBuyerCategory from "../AddBuyerCategory";
import ViewBuyerCategories from "../ViewBuyerCategories";
import EditBuyerCategory from "../EditBuyerCategory";

import AddDistrict from "../AddDistrict";
import ViewDistricts from "../ViewDistricts";
import EditDistrict from "../EditDistrict";

import AddZone from "../AddZone";
import ViewZones from "../ViewZones";
import EditZone from "../EditZone";

import AddSubCategory from "../AddSubCategory";
import ViewSubCategories from "../ViewSubCategories";
import EditSubCategory from "../EditSubCategory";

import ViewTypes from "../ViewTypes";
import AddType from "../AddType";
import EditType from "../EditType";

import AddSubType from "../AddSubType";
import ViewSubTypes from "../ViewSubTypes";
import EditSubType from "../EditSubType";

import AddSuperadmin from "../AddSuperadmin";
import ViewSuperadmins from "../ViewSuperadmins";
import EditSuperadmin from "../EditSuperadmin";

import ViewTags from "../ViewTags";
import AddTag from "../AddTag";
import EditTag from "../EditTag";

import ViewRoles from "../ViewRoles";
import AddRole from "../AddRole";
import EditRole from "../EditRole";

import ViewStaff from "../ViewStaff";
import AddStaff from "../AddStaff";
import EditStaff from "../EditStaff";

import ViewPriceOffers from "../ViewPriceOffers";
import AddPriceOffer from "../AddPriceOffer";
import EditPriceOffer from "../EditPriceOffer";

import ViewQuantityOffers from "../ViewQuantityOffers";
import AddQuantityOffer from "../AddQuantityOffer";
import EditQuantityOffer from "../EditQuantityOffer";

import ViewBundleOffers from "../ViewBundleOffers";
import AddBundleOffer from "../AddBundleOffer";
import EditBundleOffer from "../EditBundleOffer";

import ViewOrders from "../ViewOrders";
import ViewOrder from "../ViewOrder";

import ViewPricelists from "../ViewPricelists";
import EditPricelist from "../EditPricelist";

import ViewAds from "../ViewAds";
import AddAd from "../AddAd";
import EditAd from "../EditAd";

import OrdersReportView from "../ViewOrdersReport";
import UnitsReportView from "../ViewUnitsReport";

import ViewSettings from "../ViewSettings";

import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

const SuperAdminRouteComponent = (options) => {
  const token = localStorage.getItem("token");
  if (!token) return <Redirect to="/login" />;

  if (getStaffType(path(["auth", "user", "staff"], options)) === "company") {
    return (
      <Redirect
        to={`/companies/view/${path(
          ["auth", "user", "staff", "sellerRef", "_id"],
          options
        )}`}
      />
    );
  }
  if (
    getStaffType(path(["auth", "user", "staff"], options)) === "distributor"
  ) {
    return (
      <Redirect
        to={`/distributors/view/${path(
          ["auth", "user", "staff", "sellerRef", "_id"],
          options
        )}`}
      />
    );
  }
  if (getStaffType(path(["auth", "user", "staff"], options)) === "rep") {
    return (
      <Redirect
        to={`/reps/view/${path(["auth", "user", "staff", "_id"], options)}`}
      />
    );
  }

  return <Route {...options} />;
};

const PrivateRouteComponent = (options) => {
  const token = localStorage.getItem("token");
  if (!token) return <Redirect to="/login" />;

  const query = queryString.parse(options.location.search);
  const params = options.computedMatch.params;
  const staffType = getStaffType(path(["auth", "user", "staff"], options));
  const staff = path(["auth", "user", "staff"], options);

  if (options.before) {
    const path = options.before({
      query,
      params,
      staffType,
      staff,
      location: options.location,
    });
    if ((!options.path.startsWith('/staffs') || isEmpty(query)) && path)
      return <Redirect to={path} />;
  }

  return <Route {...options} />;
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

const SuperAdminRoute = connect(mapStateToProps)(SuperAdminRouteComponent);

const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);

const LoginRoute = (options) => {
  const token = localStorage.getItem("token");
  if (token) return <Redirect to="/companies" />;

  return <Route {...options} />;
};

//redirected route (no component passed)
const RedirectedRoute = (options) => {
  const token = localStorage.getItem("token");
  if (token) return <Redirect to="/companies" />;
  return <Redirect to="/login" />;
};

export default function Router() {
  return (
    <BRouter history={history}>
      <Switch>
        <LoginRoute exact path="/login" component={Login} />

        <PrivateRoute
          exact
          path="/pendingBuyers"
          component={ViewPendingBuyers}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <SuperAdminRoute exact path="/companies" component={ViewCompanies} />
        <SuperAdminRoute exact path="/companies/new" component={AddCompany} />
        <PrivateRoute
          exact
          path="/companies/edit/:id"
          component={EditCompany}
          before={({ params, staff, staffType }) => {
            if (staffType === "company" && params.id !== staff?.sellerRef?._id)
              return `/companies/view/${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/companies/view/:id"
          component={ViewCompany}
          before={({ params, staff, staffType }) => {
            if (staffType === "company" && params.id !== staff?.sellerRef?._id)
              return `/companies/view/${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/brands"
          component={ViewBrands}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/brands?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/brands/new"
          component={AddBrand}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/brands/new?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/brands/edit/:id"
          component={EditBrand}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/brands/edit/${params.id}?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/companyProducts"
          component={ViewCompanyProducts}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/companyProducts?company=${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["company"], query) !== staff.sellerRef.company
            )
              return `/companyProducts?company=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/companyProducts/new"
          component={AddCompanyProduct}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/companyProducts/mew?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/companyProducts/edit/:id"
          component={EditCompanyProduct}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/companyProducts/edit/${params.id}?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/reps"
          component={ViewReps}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "company" && !path(["distributor"], query))
              return `/companies/view/${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["distributor"], query) !== staff?.sellerRef?._id
            )
              return `/reps?distributor=${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/reps/new"
          component={AddRep}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "company" && !path(["distributor"], query))
              return `/companies/view/${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["distributor"], query) !== staff?.sellerRef?._id
            )
              return `/reps/new?distributor=${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/reps/edit/:id"
          component={EditRep}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "company" && !path(["distributor"], query))
              return `/companies/view/${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["distributor"], query) !== staff?.sellerRef?._id
            )
              return `/reps/edit/${params.id}?distributor=${staff?.sellerRef?._id}`;
            if (staffType === "rep" && params.id !== staff._id)
              return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/reps/view/:id"
          component={ViewRep}
          before={({ params, staff, staffType }) => {
            if (staffType === "rep" && params.id !== staff._id)
              return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/buyers"
          component={ViewBuyers}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              !path(["distributors.distributor"], query)
            )
              return `/companies/view/${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["distributors.distributor"], query) !==
                staff?.sellerRef?._id
            )
              return `/buyers?distributors.distributor=${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <SuperAdminRoute exact path="/buyers/new" component={AddBuyer} />

        <PrivateRoute
          exact
          path="/buyers/edit/:id"
          component={EditBuyer}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "company") {
              if (!path(["distributors.distributor"], query))
                return `/companies/view/${staff?.sellerRef?._id}`;
              return `/buyers/edit/restricted/${
                params.id
              }?${queryString.stringify(query)}`;
            }
            if (staffType === "distributor") {
              if (!path(["distributors.distributor"], query))
                return `/distributors/view/${staff?.sellerRef?._id}`;
              return `/buyers/edit/restricted/${
                params.id
              }?${queryString.stringify(query)}`;
            }
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/buyers/edit/restricted/:id"
          component={RoleEditBuyer}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              !path(["distributors.distributor"], query)
            )
              return `/companies/view/${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              !path(["distributors.distributor"], query)
            )
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
            if (staffType === "superAdmin") {
              return `/buyers/edit/${params.id}?${queryString.stringify(
                query
              )}`;
            }
          }}
        />

        <PrivateRoute
          exact
          path="/distributors"
          component={ViewDistributors}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/distributors?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/distributors/new"
          component={AddDistributor}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/distributors/new?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/distributors/edit/:id"
          component={EditDistributor}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/distributors/edit/${params.id}?company=${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              params.id !== staff?.sellerRef?._id
            )
              return `/distributors/edit/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/distributors/view/:id"
          component={ViewDistributor}
          before={({ params, staff, staffType }) => {
            if (
              staffType === "distributor" &&
              params.id !== staff?.sellerRef?._id
            )
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/pricelists"
          component={ViewPricelists}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/pricelists?company=${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["company"], query) !== staff?.sellerRef?.company
            )
              return `/pricelists?company=${staff?.sellerRef?.company}`;
          }}
        />

        <PrivateRoute
          exact
          path="/pricelists/edit/:id"
          component={EditPricelist}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/pricelists/edit/${params.id}?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/companies?company=${staff?.sellerRef?.company}`;
          }}
        />
        <PrivateRoute
          exact
          path="/ads/new"
          component={AddAd}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/ads/new?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/ads/edit/:id"
          component={EditAd}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/ads/edit/${params.id}?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/ads"
          component={ViewAds}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company"], query) !== staff?.sellerRef?._id
            )
              return `/ads?company=${staff?.sellerRef?._id}`;
            if (staffType === "distributor")
              return `/distributors/view/${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <SuperAdminRoute exact path="/packages" component={ViewPackages} />
        <SuperAdminRoute exact path="/packages/new" component={AddPackage} />
        <SuperAdminRoute
          exact
          path="/packages/edit/:id"
          component={EditPackage}
        />

        <SuperAdminRoute exact path="/sizes" component={ViewSizes} />
        <SuperAdminRoute exact path="/sizes/new" component={AddSize} />
        <SuperAdminRoute exact path="/sizes/edit/:id" component={EditSize} />

        <SuperAdminRoute
          exact
          path="/packageUnits"
          component={ViewPackageUnits}
        />
        <SuperAdminRoute
          exact
          path="/packageUnits/new"
          component={AddPackageUnit}
        />
        <SuperAdminRoute
          exact
          path="/packageUnits/edit/:id"
          component={EditPackageUnit}
        />

        <SuperAdminRoute
          exact
          path="/subscriptions"
          component={ViewSubscriptions}
        />
        <SuperAdminRoute
          exact
          path="/subscriptions/new"
          component={AddSubscription}
        />
        <SuperAdminRoute
          exact
          path="/subscriptions/edit/:id"
          component={EditSubscription}
        />

        <SuperAdminRoute exact path="/sections" component={ViewSections} />
        <SuperAdminRoute exact path="/sections/new" component={AddSection} />
        <SuperAdminRoute
          exact
          path="/sections/edit/:id"
          component={EditSection}
        />

        <SuperAdminRoute exact path="/categories" component={ViewCategories} />
        <SuperAdminRoute exact path="/categories/new" component={AddCategory} />
        <SuperAdminRoute
          exact
          path="/categories/edit/:id"
          component={EditCategory}
        />

        <SuperAdminRoute
          exact
          path="/buyerCategories"
          component={ViewBuyerCategories}
        />
        <SuperAdminRoute
          exact
          path="/buyerCategories/new"
          component={AddBuyerCategory}
        />
        <SuperAdminRoute
          exact
          path="/buyerCategories/edit/:id"
          component={EditBuyerCategory}
        />

        <SuperAdminRoute exact path="/districts" component={ViewDistricts} />
        <SuperAdminRoute exact path="/districts/new" component={AddDistrict} />
        <SuperAdminRoute
          exact
          path="/districts/edit/:id"
          component={EditDistrict}
        />

        <SuperAdminRoute exact path="/zones" component={ViewZones} />
        <SuperAdminRoute exact path="/zones/new" component={AddZone} />
        <SuperAdminRoute exact path="/zones/edit/:id" component={EditZone} />

        <SuperAdminRoute
          exact
          path="/subCategories"
          component={ViewSubCategories}
        />
        <SuperAdminRoute
          exact
          path="/subCategories/new"
          component={AddSubCategory}
        />
        <SuperAdminRoute
          exact
          path="/subCategories/edit/:id"
          component={EditSubCategory}
        />

        <SuperAdminRoute exact path="/types" component={ViewTypes} />
        <SuperAdminRoute exact path="/types/new" component={AddType} />
        <SuperAdminRoute exact path="/types/edit/:id" component={EditType} />

        <SuperAdminRoute exact path="/subTypes" component={ViewSubTypes} />
        <SuperAdminRoute exact path="/subTypes/new" component={AddSubType} />
        <SuperAdminRoute
          exact
          path="/subTypes/edit/:id"
          component={EditSubType}
        />

        <SuperAdminRoute exact path="/tags" component={ViewTags} />
        <SuperAdminRoute exact path="/tags/new" component={AddTag} />
        <SuperAdminRoute exact path="/tags/edit/:id" component={EditTag} />
        <PrivateRoute
          exact
          path="/orders-report"
          component={OrdersReportView}
        />
        {/* <SuperAdminRoute
          exact
          path="/units-report"
          component={UnitsReportView}
        /> */}

        <PrivateRoute
          exact
          path="/units-report"
          component={UnitsReportView}
        />

        <PrivateRoute
          exact
          path="/roles"
          component={ViewRoles}
          before={({ params, staff, staffType }) => {
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/roles/new"
          component={AddRole}
          before={({ params, staff, staffType }) => {
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/roles/edit/:id"
          component={EditRole}
          before={({ params, staff, staffType }) => {
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <SuperAdminRoute
          exact
          path="/superadmins"
          component={ViewSuperadmins}
        />
        <SuperAdminRoute
          exact
          path="/superadmins/new"
          component={AddSuperadmin}
        />
        <SuperAdminRoute
          exact
          path="/superadmins/edit/:id"
          component={EditSuperadmin}
        />

        <PrivateRoute
          exact
          path="/staffs"
          component={ViewStaff}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === 'company' && isEmpty(query)
            )
              return `/staffs?sellerCategory=companies/getCompanies&sellerRef=${staff?.sellerRef?._id}`;
            if (
              staffType === "company" &&
              path(["sellerRef"], query) &&
              path(["seller"], query) === "distributor"
            )
              return `/staffs?sellerCategory=distributors/getDistributors&sellerRef=${path(["sellerRef"], query)}`;
            if (
              staffType === "company" &&
              path(["sellerRef"], query) === staff?.sellerRef?._id &&
              path(["seller"], query) !== "distributor"
            )
              return `/staffs?sellerCategory=companies/getCompanies&sellerRef=${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["sellerRef"], query) !== staff?.sellerRef?._id
            )
              return `/staffs?sellerCategory=distributors/getDistributors&sellerRef=${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/staffs/new"
          component={AddStaff}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              (path(["sellerCategory"], query) === "companies/getCompanies" ||
                path(["sellerRef"], query) !== staff?.sellerRef?._id) &&
              path(["seller"], query) !== "distributor"
            ) {
              return `/staffs/new?sellerCategory=companies/getCompanies&sellerRef=${staff?.sellerRef?._id}`;
            }
            if (
              staffType === "company" &&
              path(["sellerRef"], query) !== staff?.sellerRef?._id &&
              path(["sellerCategory"], query) === "distributors/getDistributors"
            ) {
              return `/staffs/new?sellerCategory=distributors/getDistributors&sellerRef=${path(["sellerRef"], query)}`;
            }
            if (
              staffType === "distributor" &&
              (path(["sellerCategory"], query) !== "distributors" ||
                path(["sellerRef"], query) !== staff?.sellerRef?._id)
            ) {
              return `/staffs/new?sellerCategory=distributors/getDistributors&sellerRef=${staff?.sellerRef?._id}`;
            }
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/staffs/edit/:id"
          component={EditStaff}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              (path(["sellerCategory"], query) !== "companies/getCompanies" ||
                path(["sellerRef"], query) !== staff?.sellerRef?._id)
            ) {
              return `/staffs/edit/${params.id}?sellerRef=${staff?.sellerRef?._id}&sellerCategory=companies/getCompanies`;
            }
            if (
              staffType === "distributor" &&
              (path(["sellerCategory"], query) !== "distributors" ||
                path(["sellerRef"], query) !== staff?.sellerRef?._id)
            ) {
              return `/staffs/edit/${params.id}?sellerRef=${staff?.sellerRef?._id}&sellerCategory=distributors/getDistributors`;
            }
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <SuperAdminRoute exact path="/settings" component={ViewSettings} />

        <PrivateRoute
          exact
          path="/priceOffers"
          component={ViewPriceOffers}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company_id"], query) !== staff?.sellerRef?._id
            )
              return `/priceOffers?company_id=${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["company_id"], query) !== staff?.sellerRef?.company
            )
              return `/priceOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/priceOffers/new"
          component={AddPriceOffer}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "distributor")
              return `/priceOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/priceOffers/edit/:id"
          component={EditPriceOffer}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "distributor")
              return `/priceOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/quantityOffers"
          component={ViewQuantityOffers}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company_id"], query) !== staff?.sellerRef?._id
            )
              return `/quantityOffers?company_id=${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["company_id"], query) !== staff?.sellerRef?.company
            )
              return `/quantityOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/quantityOffers/new"
          component={AddQuantityOffer}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "distributor")
              return `/quantityOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/quantityOffers/edit/:id"
          component={EditQuantityOffer}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "distributor")
              return `/quantityOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/bundleOffers"
          component={ViewBundleOffers}
          before={({ params, staff, staffType, query }) => {
            if (
              staffType === "company" &&
              path(["company_id"], query) !== staff?.sellerRef?._id
            )
              return `/bundleOffers?company_id=${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["company_id"], query) !== staff?.sellerRef?.company
            )
              return `/bundleOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/bundleOffers/new"
          component={AddBundleOffer}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "distributor")
              return `/bundleOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />
        <PrivateRoute
          exact
          path="/bundleOffers/edit/:id"
          component={EditBundleOffer}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "distributor")
              return `/bundleOffers?company_id=${staff?.sellerRef?.company}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        <PrivateRoute
          exact
          path="/orders"
          component={ViewOrders}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "company" && !path(["seller.id"], query))
              return `/orders?seller.id=${staff?.sellerRef?._id}`;
            if (
              staffType === "distributor" &&
              path(["seller.id"], query) !== staff?.sellerRef?._id
            )
              return `/orders?seller.id=${staff?.sellerRef?._id}`;
            if (staffType === "rep") return `/reps/view/${staff._id}`;
          }}
        />

        {/* <PrivateRoute
          exact
          path="/units-report"
          component={UnitsReportView}
          before={({ params, staff, staffType, query }) => {
            if (staffType === "company" && !path(["seller.id"], query))
              return `/units-report`;
          }}
        /> */}

        <PrivateRoute exact path="/orders/view/:id" component={ViewOrder} />

        <RedirectedRoute exact path="/*" />
      </Switch>
    </BRouter>
  );
}
