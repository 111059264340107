import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_BUYER_CATEGORIES,
  DELETE_BUYER_CATEGORY,
  ADD_BUYER_CATEGORY,
  UPDATE_BUYER_CATEGORY,
  GET_PRODUCT_BUYER_CATEGORIES,
} from '../../../../config/constants/ActionTypes'
import { getBuyerCategories, getBuyerCategoriesSuccess, getBuyerCategoriesFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getBuyerCategoriesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'buyerCategories',
    params: {
      ...query,
      isDeleted: false,
    },
    headers: {
      lite,
      token: `${localStorage.getItem('token')}`,
    },
  })
}

function* getBuyerCategoriesSaga({ payload }) {
  try {
    const response = yield call(getBuyerCategoriesRequest, payload)
    yield put(getBuyerCategoriesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getBuyerCategoriesFailure(error))
    payload.onFailure(error)
  }
}

const getProductBuyerCategoriesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'companyProducts/buyerCategories',
    params: {
      ...query,
      isDeleted: false,
    },
    headers: {
      token: `${localStorage.getItem('token')}`,
    },
  })
}

function* getProductBuyerCategoriesSaga({ payload }) {
  try {
    const response = yield call(getProductBuyerCategoriesRequest, payload)
    yield put(getBuyerCategoriesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getBuyerCategoriesFailure(error))
    payload.onFailure(error)
  }
}

const deleteBuyerCategoryRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'buyerCategories',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteBuyerCategorySaga({ payload }) {
  try {
    const response = yield call(deleteBuyerCategoryRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addBuyerCategoryRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'buyerCategories',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addBuyerCategorySaga({ payload }) {
  try {
    const response = yield call(addBuyerCategoryRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateBuyerCategoryRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'buyerCategories',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateBuyerCategorySaga({ payload }) {
  try {
    const response = yield call(updateBuyerCategoryRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getBuyerCategories(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_BUYER_CATEGORIES, getBuyerCategoriesSaga)],
    [yield takeEvery(DELETE_BUYER_CATEGORY, deleteBuyerCategorySaga)],
    [yield takeEvery(ADD_BUYER_CATEGORY, addBuyerCategorySaga)],
    [yield takeEvery(UPDATE_BUYER_CATEGORY, updateBuyerCategorySaga)],
    [yield takeEvery(GET_PRODUCT_BUYER_CATEGORIES, getProductBuyerCategoriesSaga)],
  )
}
