import { tableFields, fields, formFields } from '../schema'
import {
  getSuperadmins,
  deleteSuperadmin,
  addSuperadmin,
  updateSuperadmin,
} from '../../../modules/Superadmin/store/actions'
import { getRoles } from '../../../modules/Role/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const superadmins = {
  view: {
    get: getSuperadmins,
    delete: deleteSuperadmin,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.email'], 'email'),
      tableFields.text(arabic_labels['form.role'], 'role.name.ar'),
      tableFields.text(arabic_labels['form.job'], 'job'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addSuperadmin,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.text({
        title: arabic_labels['form.email'],
        dataIndex: 'email',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال البريد الالكتروني' : true),
      }),
      fields.text({
        title: arabic_labels['form.password'],
        dataIndex: 'password',
        inputType: 'password',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال  كلمة السر' : true),
      }),
      fields.list({
        title: arabic_labels['form.phoneNumber'],
        dataIndex: 'phone',
        field: fields.text({
          title: '',
          dataIndex: 'phone',
          validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الهاتف' : true),
        }),
      }),
      fields.text({
        title: arabic_labels['form.job'],
        dataIndex: 'job',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الوظيفة ' : true),
      }),
      formFields.select(
        'المهمة',
        'role',
        'default',
        { query: getRoles },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'role',
        },
      ),
    ],
  },
  edit: {
    update: updateSuperadmin,
    get: getSuperadmins,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.text({
        title: arabic_labels['form.email'],
        dataIndex: 'email',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال البريد الالكتروني' : true),
      }),
      fields.list({
        title: arabic_labels['form.phoneNumber'],
        dataIndex: 'phone',
        field: fields.text({
          title: '',
          dataIndex: 'phone',
          validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الهاتف' : true),
        }),
      }),
      fields.text({
        title: arabic_labels['form.job'],
        dataIndex: 'job',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الوظيفة ' : true),
      }),
      fields.object({
        title: arabic_labels['form.role'],
        dataIndex: 'role',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getRoles },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            {
              key: 'role',
            },
          ),
        ],
      }),
    ],
  },
}
