/* eslint-disable */
import React, { forwardRef, useState, useEffect, Fragment, useCallback } from 'react'
import { equals } from 'ramda'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'


const MyUpload = forwardRef(({
  value,
  multiple,
  dataIndex,
  onMounted,
  onChange,
  readOnly,
  name,
  accept,
  inputReadOnly,
  validFile,
  webkitdirectory, 
  clearFile,
  setClearFile,
}, ref) => {
  const [state, setState] = useState(multiple ? [] : undefined)
  const [randomId, seRandomId] = useState()
  const classes = useStyles()
  
  useEffect(() => {
    if (value && typeof value === 'string' && !equals(value, state)) applyChange(value)
  }, [value]) // eslint-disable-line

  useEffect(() => {
    seRandomId(Math.random())
    if (onMounted) onMounted(dataIndex)
  }, []) // eslint-disable-line

  const applyChange = useCallback((val) => {
    let newValue
    if (Array.isArray(val)) {
      if (!multiple) newValue = val.pop()
      else newValue = val
    } else {
      if (!multiple) newValue = val
      else newValue = [val]
    }
    setState(newValue)
    if (onChange) onChange(val, dataIndex)
  }, [dataIndex, multiple, onChange])

  const onFileAdded = useCallback((e) => {
    const fileList = e.target.files
    if (multiple) {
      const files = []
      fileList.forEach((file) => files.push(file))
      applyChange(files)
    } else {
      applyChange(fileList[0])
    }
    e.target.value = null
  }, [applyChange, multiple])

  useEffect(() => {
    setState(multiple ? [] : undefined)
    setClearFile(false)
  }, [clearFile])

  return (
    <Fragment>
      <input
        className={classes.input}
        id={randomId}
        multiple={multiple}
        type='file'
        onChange={onFileAdded}
        accept={accept}
        disabled={inputReadOnly}
        webkitdirectory={webkitdirectory}
      />
      <label htmlFor={randomId}>
        <Button variant='contained' color='primary' component='span' disabled={readOnly}>
          {name ? name : 'Upload'}
        </Button>
      </label>
      {validFile && validFile === true ? <ul className={classes.list}>
        {
          multiple
        ? state.map((file) => <li>{typeof file === 'string' ? <a target='_blank' rel='noopener noreferrer' href={file}>{file.split('/').slice(-2, -1)}</a> : file?.name}</li>)
          : <li>{typeof state === 'string' ? <a target='_blank' rel='noopener noreferrer' href={state}>{state.split('/').slice(-1)}</a> : state?.name}</li>
        }
      </ul> : ''}
      
    </Fragment>
  )
})

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  list: {
    textDecoration: 'none',
    listStyleType: 'none',
    marginLeft: -40,
    marginTop: 5,
    '& li': {
      color: theme.palette.info.main,
      '& a': {
        color: theme.palette.info.main,
      }
    }
  }
}))

export default MyUpload
