import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const BundleOffersTable = () => {
  return (
    <div>
            <TableHolder>
            <View componentName='bundleOffers' config={config.bundleOffers} />
            </TableHolder>

    </div>
  )
}

export default BundleOffersTable

const TableHolder  = styled.div`
@media (max-width:767px){

td:nth-child(7), td:nth-child(8) {
  display: inline-block;
  text-align: center;
}
}
`
