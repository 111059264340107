import {
  GET_SIZES_SUCCESS,
  GET_SIZES_FAILURE,
  // GET_SIZE_SUCCESS,
  // GET_SIZE_FAILURE,
  ADD_SIZE_SUCCESS,
  ADD_SIZE_FAILURE,
  EDIT_SIZE_SUCCESS,
  EDIT_SIZE_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_SIZES_SUCCESS: {
      return {
        ...state,
        sizes: action.payload.sizes,
      }
    }

    case GET_SIZES_FAILURE: {
      return {
        ...state,
        getSizesError: action.payload.error,
      }
    }

    // case GET_SIZE_SUCCESS: {
    //   return {
    //     ...state,
    //     sizes: action.payload.size,
    //   }
    // }

    // case GET_SIZE_FAILURE: {
    //   return {
    //     ...state,
    //     getSizesError: action.payload.error,
    //   }
    // }

    case ADD_SIZE_SUCCESS: {
      return {
        ...state,
        message: action.payload.message,
      }
    }

    case ADD_SIZE_FAILURE: {
      return {
        ...state,
        addSizeError: action.payload.error,
      }
    }

    case EDIT_SIZE_SUCCESS: {
      return {
        ...state,
        message: action.payload.message,
      }
    }

    case EDIT_SIZE_FAILURE: {
      return {
        ...state,
        addSizeError: action.payload.error,
      }
    }

    default:
      return state
  }
}
