// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU'
export const WINDOW_WIDTH = 'WINDOW-WIDTH'
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE'

//Contact Module const
export const FETCH_START = 'fetch_start'
export const FETCH_SUCCESS = 'fetch_success'
export const FETCH_ERROR = 'fetch_error'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER'
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER'
export const SET_LOADING = 'SET_LOADING'

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNIN_USER = 'SIGNIN_USER'
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS'
export const SIGNOUT_USER = 'SIGNOUT_USER'
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS'
export const INIT_URL = 'INIT_URL'
export const USER_DATA = 'user_data'
export const USER_TOKEN_SET = 'user_token_set'
export const UPDATE_SELLER = 'update_seller'

// Company const
export const ADD_COMPANY = 'ADD_COMPANY'
export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE'
export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_COMPANY_DISTRIBUTORS = 'GET_COMPANY_DISTRIBUTORS'
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'

// Brand const
export const ADD_BRAND = 'ADD_BRAND'
export const ADD_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS'
export const ADD_BRAND_FAILURE = 'ADD_BRAND_FAILURE'
export const EDIT_BRAND = 'EDIT_BRAND'
export const EDIT_BRAND_SUCCESS = 'EDIT_BRAND_SUCCESS'
export const EDIT_BRAND_FAILURE = 'EDIT_BRAND_FAILURE'
export const GET_BRANDS = 'GET_ BRANDS'
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS'
export const GET_BRANDS_FAILURE = 'GET_BRANDS_FAILURE'
export const GET_BRAND = 'GET_BRAND'
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS'
export const GET_BRAND_FAILURE = 'GET_BRAND_FAILURE'
export const DELETE_BRAND = 'DELETE_BRAND'

// Section const
export const ADD_SECTION = 'ADD_SECTION'
export const GET_SECTION = 'GET_SECTION'
export const GET_SECTIONS = 'GET_SECTIONS'
export const GET_SECTIONS_SUCCESS = 'GET_SECTIONS_SUCCESS'
export const GET_SECTIONS_FAILURE = 'GET_SECTIONS_FAILURE'
export const UPDATE_SECTION = 'UPDATE_SECTION'
export const DELETE_SECTION = 'DELETE_SECTION'

//Category const
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

//SubCategory const
export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY'
export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES'
export const GET_SUB_CATEGORIES_SUCCESS = 'GET_SUB_CATEGORIES_SUCCESS'
export const GET_SUB_CATEGORIES_FAILURE = 'GET_SUB_CATEGORIES_FAILURE'
export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY'
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY'

// Size const
export const ADD_SIZE = 'ADD_SIZE'
export const ADD_SIZE_SUCCESS = 'ADD_SIZE_SUCCESS'
export const ADD_SIZE_FAILURE = 'ADD_SIZE_FAILURE'
export const EDIT_SIZE = 'EDIT_SIZE'
export const EDIT_SIZE_SUCCESS = 'EDIT_SIZE_SUCCESS'
export const EDIT_SIZE_FAILURE = 'EDIT_SIZE_FAILURE'
export const GET_SIZE = 'GET_SIZE'
export const GET_SIZE_SUCCESS = 'GET_SIZE_SUCCESS'
export const GET_SIZE_FAILURE = 'GET_SIZE_FAILURE'
export const GET_SIZES = 'GET_SIZES'
export const GET_SIZES_SUCCESS = 'GET_SIZES_SUCCESS'
export const GET_SIZES_FAILURE = 'GET_SIZES_FAILURE'
export const DELETE_SIZE = 'DELETE_SIZE'

//Type const
export const ADD_TYPE = 'ADD_TYPE'
export const GET_TYPES = 'GET_TYPES'
export const GET_TYPES_SUCCESS = 'GET_TYPES_SUCCESS'
export const GET_TYPES_FAILURE = 'GET_TYPES_FAILURE'
export const UPDATE_TYPE = 'UPDATE_TYPE'
export const DELETE_TYPE = 'DELETE_TYPE'

// PackageUnit const
export const ADD_PACKAGE_UNIT = 'ADD_PACKAGE_UNIT'
export const ADD_PACKAGE_UNIT_SUCCESS = 'ADD_PACKAGE_UNIT_SUCCESS'
export const ADD_PACKAGE_UNIT_FAILURE = 'ADD_PACKAGE_UNIT_FAILURE'
export const EDIT_PACKAGE_UNIT = 'EDIT_PACKAGE_UNIT'
export const EDIT_PACKAGE_UNIT_SUCCESS = 'EDIT_PACKAGE_UNIT_SUCCESS'
export const EDIT_PACKAGE_UNIT_FAILURE = 'EDIT_PACKAGE_UNIT_FAILURE'
export const GET_PACKAGE_UNITS = 'GET_PACKAGE_UNITS'
export const GET_PACKAGE_UNITS_SUCCESS = 'GET_PACKAGE_UNITS_SUCCESS'
export const GET_PACKAGE_UNITS_FAILURE = 'GET_PACKAGE_UNITS_FAILURE'
export const DELETE_PACKAGE_UNIT = 'DELETE_PACKAGE_UNIT'

//District const
export const ADD_DISTRICT = 'ADD_DISTRICT'
export const GET_DISTRICTS = 'GET_DISTRICTS'
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS'
export const GET_DISTRICTS_FAILURE = 'GET_DISTRICTS_FAILURE'
export const UPDATE_DISTRICT = 'UPDATE_DISTRICT'
export const DELETE_DISTRICT = 'DELETE_DISTRICT'

//City const
export const GET_CITIES = 'GET_CITIES'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE'

//Zone const
export const ADD_ZONE = 'ADD_ZONE'
export const GET_ZONES = 'GET_ZONES'
export const GET_ZONES_NAMES = 'GET_ZONES_NAMES'
export const GET_ZONES_SUCCESS = 'GET_ZONES_SUCCESS'
export const GET_ZONES_FAILURE = 'GET_ZONES_FAILURE'
export const GET_DISTRIBUTOR_ZONES = 'GET_DISTRIBUTOR_ZONES'
export const UPDATE_ZONE = 'UPDATE_ZONE'
export const DELETE_ZONE = 'DELETE_ZONE'

//Country const
export const GET_COUNTIES = 'GET_COUNTIES'
export const GET_COUNTIES_SUCCESS = 'GET_COUNTIES_SUCCESS'
export const GET_COUNTIES_FAILURE = 'GET_COUNTIES_FAILURE'

//BuyerCategory const
export const ADD_BUYER_CATEGORY = 'ADD_BUYER_CATEGORY'
export const GET_PRODUCT_BUYER_CATEGORIES = 'GET_PRODUCT_BUYER_CATEGORIES'
export const GET_BUYER_CATEGORIES = 'GET_BUYER_CATEGORIES'
export const GET_BUYER_CATEGORIES_SUCCESS = 'GET_BUYER_CATEGORIES_SUCCESS'
export const GET_BUYER_CATEGORIES_FAILURE = 'GET_BUYER_CATEGORIES_FAILURE'
export const UPDATE_BUYER_CATEGORY = 'UPDATE_BUYER_CATEGORY'
export const DELETE_BUYER_CATEGORY = 'DELETE_BUYER_CATEGORY'

//Subtype const
export const ADD_SUB_TYPE = 'ADD_SUB_TYPE'
export const GET_SUB_TYPES = 'GET_SUB_TYPES'
export const GET_SUB_TYPES_SUCCESS = 'GET_SUB_TYPES_SUCCESS'
export const GET_SUB_TYPES_FAILURE = 'GET_SUB_TYPES_FAILURE'
export const UPDATE_SUB_TYPE = 'UPDATE_SUB_TYPE'
export const DELETE_SUB_TYPE = 'DELETE_SUB_TYPE'

//Tag const
export const ADD_TAG = 'ADD_TAG'
export const GET_TAGS = 'GET_TAGS'
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS'
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE'
export const UPDATE_TAG = 'UPDATE_TAG'
export const DELETE_TAG = 'DELETE_TAG'

//Subscription const
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION'
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS'
export const GET_SUBSCRIPTIONS_FAILURE = 'GET_SUBSCRIPTIONS_FAILURE'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'

//Role const
export const ADD_ROLE = 'ADD_ROLE'
export const GET_ROLES = 'GET_ROLES'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'

//Superadmin const
export const ADD_SUPERADMIN = 'ADD_SUPERADMIN'
export const GET_SUPERADMINS = 'GET_SUPERADMINS'
export const GET_SUPERADMINS_SUCCESS = 'GET_SUPERADMINS_SUCCESS'
export const GET_SUPERADMINS_FAILURE = 'GET_SUPERADMINS_FAILURE'
export const UPDATE_SUPERADMIN = 'UPDATE_SUPERADMIN'
export const DELETE_SUPERADMIN = 'DELETE_SUPERADMIN'

//Package const
export const ADD_PACKAGE = 'ADD_PACKAGE'
export const GET_PACKAGES = 'GET_PACKAGES'
export const GET_ALL_PACKAGES_ID = 'GET_ALL_PACKAGES_ID'
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS'
export const GET_PACKAGES_FAILURE = 'GET_PACKAGES_FAILURE'
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE'
export const DELETE_PACKAGE = 'DELETE_PACKAGE'

//Distributor const
export const ADD_DISTRIBUTOR = 'ADD_DISTRIBUTOR'
export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS'
export const GET_DISTRIBUTORS_SUCCESS = 'GET_DISTRIBUTORS_SUCCESS'
export const GET_DISTRIBUTORS_FAILURE = 'GET_DISTRIBUTORS_FAILURE'
export const UPDATE_DISTRIBUTOR = 'UPDATE_DISTRIBUTOR'
export const DELETE_DISTRIBUTOR = 'DELETE_DISTRIBUTOR'
export const GET_DISTRIBUTOR = 'GET_DISTRIBUTOR'

//Buyer const
export const ADD_BUYER = 'ADD_BUYER'
export const GET_BUYERS = 'GET_BUYERS'
export const GET_BUYERS_SUCCESS = 'GET_BUYERS_SUCCESS'
export const GET_BUYERS_FAILURE = 'GET_BUYERS_FAILURE'
export const UPDATE_BUYER = 'UPDATE_BUYER'
export const UPDATE_BUYER_HANDSHAKE = 'UPDATE_BUYER_HANDSHAKE'
export const DELETE_BUYER = 'DELETE_BUYER'

//PendingBuyers const
export const GET_PENDING_BUYERS = 'GET_PENDING_BUYERS'
export const GET_PENDING_BUYERS_SUCCESS = 'GET_PENDING_BUYERS_SUCCESS'
export const GET_PENDING_BUYERS_FAILURE = 'GET_PENDING_BUYERS_FAILURE'
export const ACCEPT_BUYER = 'ACCEPT_BUYER'
export const REJECT_BUYER = 'REJECT_BUYER'

//Staff const
export const ADD_STAFF = 'ADD_STAFF'
export const GET_STAFF = 'GET_STAFF'
export const GET_STAFFS = 'GET_STAFFS'
export const GET_STAFFS_SUCCESS = 'GET_STAFFS_SUCCESS'
export const GET_STAFFS_FAILURE = 'GET_STAFFS_FAILURE'
export const UPDATE_STAFF = 'UPDATE_STAFF'
export const DELETE_STAFF = 'DELETE_STAFF'

//Rep const
export const ADD_REP = 'ADD_REP'
export const GET_REPS = 'GET_REPS'
export const GET_REPS_SUCCESS = 'GET_REPS_SUCCESS'
export const GET_REPS_FAILURE = 'GET_REPS_FAILURE'
export const UPDATE_REP = 'UPDATE_REP'
export const DELETE_REP = 'DELETE_REP'
export const GET_REP = 'GET_REP'

// Order const
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE'
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const REJECT_ORDER = 'REJECT_ORDER'
export const CANCEL_ORDER = 'CANCEL_ORDER'
//CompanyProduct const
export const ADD_COMPANY_PRODUCT = 'ADD_COMPANY_PRODUCT'
export const ADD_COMPANY_PRODUCTS = 'ADD_COMPANY_PRODUCTS'
export const GET_COMPANY_PRODUCTS = 'GET_COMPANY_PRODUCTS'
export const GET_COMPANY_PRODUCTS_SUCCESS = 'GET_COMPANY_PRODUCTS_SUCCESS'
export const GET_COMPANY_PRODUCTS_FAILURE = 'GET_COMPANY_PRODUCTS_FAILURE'
export const UPDATE_COMPANY_PRODUCT = 'UPDATE_COMPANY_PRODUCT'
export const DELETE_COMPANY_PRODUCT = 'DELETE_COMPANY_PRODUCT'

//PriceOffer const
export const ADD_PRICE_OFFER = 'ADD_PRICE_OFFER'
export const GET_PRICE_OFFER = 'GET_PRICE_OFFER'
export const GET_PRICE_OFFERS = 'GET_PRICE_OFFERS'
export const GET_PRICE_OFFERS_SUCCESS = 'GET_PRICE_OFFERS_SUCCESS'
export const GET_PRICE_OFFERS_FAILURE = 'GET_PRICE_OFFERS_FAILURE'
export const UPDATE_PRICE_OFFER = 'UPDATE_PRICE_OFFER'
export const DELETE_PRICE_OFFER = 'DELETE_PRICE_OFFER'

//Quantity Offer const
export const ADD_QUANTITY_OFFER = 'ADD_QUANTITY_OFFER'
export const GET_QUANTITY_OFFER = 'GET_QUANTITY_OFFER'
export const GET_QUANTITY_OFFERS = 'GET_QUANTITY_OFFERS'
export const GET_QUANTITY_OFFERS_SUCCESS = 'GET_QUANTITY_OFFERS_SUCCESS'
export const GET_QUANTITY_OFFERS_FAILURE = 'GET_QUANTITY_OFFERS_FAILURE'
export const UPDATE_QUANTITY_OFFER = 'UPDATE_QUANTITY_OFFER'
export const DELETE_QUANTITY_OFFER = 'DELETE_QUANTITY_OFFER'

//Quantity Offer const
export const ADD_BUNDLE_OFFER = 'ADD_BUNDLE_OFFER'
export const GET_BUNDLE_OFFER = 'GET_BUNDLE_OFFER'
export const GET_BUNDLE_OFFERS = 'GET_BUNDLE_OFFERS'
export const GET_BUNDLE_OFFERS_SUCCESS = 'GET_BUNDLE_OFFERS_SUCCESS'
export const GET_BUNDLE_OFFERS_FAILURE = 'GET_BUNDLE_OFFERS_FAILURE'
export const UPDATE_BUNDLE_OFFER = 'UPDATE_BUNDLE_OFFER'
export const DELETE_BUNDLE_OFFER = 'DELETE_BUNDLE_OFFER'

//Search const
export const SEARCH_MODULES = 'SEARCH_MODULES'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SEARCH_FAILURE'

//UploadImages const
export const UPLOAD_IMAGES = 'UPLOAD_IMAGES'
export const UPLOAD_IMAGES_SUCCESS = 'UPLOAD_IMAGES_FAILURE'
export const UPLOAD_IMAGES_FAILURE = 'UPLOAD_IMAGES_FAILURE'

//PriceLists const
export const GET_PRICELISTS = 'GET_PRICELISTS'
export const GET_PRICELIST = 'GET_PRICELIST'
export const UPDATE_PRICELIST = 'UPDATE_PRICELIST'
export const GET_PRICELISTS_SUCCESS = 'GET_PRICELISTS_SUCCESS'
export const GET_PRICELISTS_FAILURE = 'GET_PRICELISTS_FAILURE'

export const ADD_AD = 'ADD_AD'
export const ADD_AD_SUCCESS = 'ADD_AD_SUCCESS'
export const ADD_AD_FAILURE = 'ADD_AD_FAILURE'
export const EDIT_AD = 'EDIT_AD'
export const EDIT_AD_SUCCESS = 'EDIT_AD_SUCCESS'
export const EDIT_AD_FAILURE = 'EDIT_AD_FAILURE'
export const GET_ADS = 'GET_ADS'
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS'
export const GET_ADS_FAILURE = 'GET_ADS_FAILURE'
export const GET_AD = 'GET_AD'
export const GET_AD_SUCCESS = 'GET_AD_SUCCESS'
export const GET_AD_FAILURE = 'GET_AD_FAILURE'
export const DELETE_AD = 'DELETE_AD'

export const GET_ORDERS_REPORT = 'GET_ORDERS_REPORT'
export const GET_ORDERS_REPORT_SUCCESS = 'GET_ORDERS_REPORT_SUCCESS'
export const GET_ORDERS_REPORT_FAILURE = 'GET_ORDERS_REPORT_FAILURE'
export const GET_ORDERS_CONSTANTS = 'GET_ORDERS_CONSTANTS'
export const GET_ORDERS_CONSTANTS_SUCCESS = 'GET_ORDERS_CONSTANTS_SUCCESS'
export const GET_ORDERS_CONSTANTS_FAILURE = 'GET_ORDERS_CONSTANTS_FAILURE'

export const GET_UNITS_REPORT = 'GET_UNITS_REPORT'
export const GET_UNITS_REPORT_SUCCESS = 'GET_UNITS_REPORT_SUCCESS'
export const GET_UNITS_REPORT_FAILURE = 'GET_UNITS_REPORT_FAILURE'



export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE'
export const GET_SETTING = 'GET_SETTING'
export const UPDATE_SETTING = 'UPDATE_SETTING'



// Export Data const
export const EXPORT_DATA = 'EXPORT_DATA'
export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_FAILURE'
export const EXPORT_DATA_FAILURE = 'EXPORT_DATA_FAILURE'