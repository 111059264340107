import React from 'react'
import { ThemeProvider, useTheme } from '@material-ui/core/styles'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import { createGlobalStyle } from 'styled-components'
import { GlobalContext } from './GlobalState'

const GlobalStyleComponent = () => {
  const { state } = GlobalContext()

  return (
    <GlobalStyle theme={state.theme[state.selectedTheme]} />
  )
}

export const GlobalTheme = (props) => {
  const { state } = GlobalContext()
  const theme = useTheme()

  const themeOverrides = {
    overrides: {
      MuiInput: {
        root: {
          // height: '2.5rem',
          fontSize: '1.3rem',
          fontWeight: 300,
          '&:hover:before': {
            borderWidth: '1px !important',
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: '#bfbfbf',
        },
        shrink: {
          color: ['#11192d', '!important'],
        }
      },
      MuiFormHelperText: {
        root: {
          color: '#11192d',
        },
      },
    }
  }

  
  const lightTheme = createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#4a7fbd',
      },
      secondary: {
        main: '#2b5264',
      },
      info: {
        main: '#11192d',
      },
      background: {
        paper: '#fff',
        default: '#fafafa',
      },
      text: {
        primary: '#000',
        secondary: '#fff',
      },
    },
    typography: {
      fontFamily: 'Cairo-Regular',
    },
    shadows: [...theme.shadows, '0 4px 25px 0 rgba(0,0,0,0.1)', '0 4px 25px 0 rgba(0,0,0,0.1)'],
    ...themeOverrides,
  })
  
  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#4a7fbd',
      },
      secondary: {
        main: '#292e3a',
      },
      info: {
        main: '#fff',
      },
      background: {
        paper: '#191b2a',
        default: '#292e3a',
      },
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
    },
    typography: {
      fontFamily: 'Cairo-Regular',
    },
    shadows: [
      ...theme.shadows,
      '',
      ' , '
    ],
    ...themeOverrides,
  })

  return (
    <ThemeProvider theme={state.selectedTheme === 'light' ? responsiveFontSizes(lightTheme): responsiveFontSizes(darkTheme)}>
      {props.children}
    </ThemeProvider>
  )
}



const GlobalStyle = createGlobalStyle`
  :root {
    // theme colors
    --color-primary: ${props => props.theme.colorPrimary};
    --color-body: ${props => props.theme.colorBody};
    --color-secondary: ${props => props.theme.colorSecondary};
    --color-ternary: ${props => props.theme.colorTernary};
    --color-font-primary: ${props => props.theme.colorFontPrimary};
    --color-font-secondary: ${props => props.theme.colorFontSecondary};
    --color-font-white: ${props => props.theme.colorWhite};
    --color-custom: ${props => props.theme.colorCustom};
    --color-form: ${props => props.theme.colorForm};
    --color-not-primary: ${props => props.theme.colorFontNotPrimary};

    --color-font-table: ${props => props.theme.TableHead};
    --color-background: ${props => props.theme.colorBackground};
    --shadow: ${props => props.theme.shadow};
    font-size: 16px;
  }

 

  body {
    /* background: var(--color-background) !important; */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family:'Cairo-Regular';
    /* font-weight: 500;
    font-weight: 400; */
  }
  
  /* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  } */

  * {
    scrollbar-color: var(--color-secondary) transparent;
    scrollbar-width: thin;
    ::-webkit-scrollbar {
      width: .25rem;
      height: .25rem;
      @media (max-width:767px){
        width: 0px;
        height: 1px;

      }
    }

    ::-webkit-scrollbar-button {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--color-secondary); 
    }
  }
  li.ant-menu-item::before {
    background: transparent;
}
  /* // select input
  li.ant-select-dropdown-menu-item {
    text-transform: uppercase;
    color: var(--color-font-secondary);
    font-size: 20px;
    font-weight: 300;
    font-family:  'Cairo-Regular';
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #ffffff2e;
    height: 60px;
    padding: 8px;
    line-height: 40px;
    text-align: center;
  }

  .ant-select-disabled .ant-select-selection {
    background: transparent !important;
  }
 
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-selected {
    color: var(--color-font-secondary);
    background-color: var(--color-secondary) !important;
    z-index: 99999999999999999 !important;
    outline: 0;
  }
  
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none !important;
  }

  

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: var(--color-secondary) !important;
  }

  .ant-select.ant-select-enabled {
    min-width: 100% !important;
  }

  .ant-select-selection--multiple .ant-select-selection__choice {
    border-radius: 10px;
    margin-right: 10px;
    background: var(--color-primary);

    border: 0;
    color: var(--color-font-secondary);
    font-weight: 500;
    z-index: 99999999999999999 !important;
  } */


  .ant-select-selection {
    margin: 10px 0;
}

.ant-select-selection--multiple .ant-select-selection__choice , .ant-select-selection-selected-value{
  float:right !important;
  font-size: 16px;
  margin-right:20px;

}



`

export default GlobalStyleComponent

