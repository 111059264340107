import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_DISTRICTS,
  DELETE_DISTRICT,
  ADD_DISTRICT,
  UPDATE_DISTRICT,
} from '../../../../config/constants/ActionTypes'
import { getDistricts, getDistrictsSuccess, getDistrictsFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getDistrictsRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'districts',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getDistrictsSaga({ payload }) {
  try {
    const response = yield call(getDistrictsRequest, payload)
    yield put(getDistrictsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getDistrictsFailure(error))
    payload.onFailure(error)
  }
}

const deleteDistrictRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'districts',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteDistrictSaga({ payload }) {
  try {
    const response = yield call(deleteDistrictRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addDistrictRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'districts',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addDistrictSaga({ payload }) {
  try {
    const response = yield call(addDistrictRequest, payload) // eslint-disable-line
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateDistrictRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'districts',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateDistrictSaga({ payload }) {
  try {
    const response = yield call(updateDistrictRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getDistricts(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_DISTRICTS, getDistrictsSaga)],
    [yield takeEvery(DELETE_DISTRICT, deleteDistrictSaga)],
    [yield takeEvery(ADD_DISTRICT, addDistrictSaga)],
    [yield takeEvery(UPDATE_DISTRICT, updateDistrictSaga)],
  )
}
