import React, {
  forwardRef,
  useState,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import { equals } from "ramda";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import { useFormContext } from "react-hook-form";
import { offerPointsActive, productPointsActive } from "../../index";

const NumberInput = forwardRef(
  (
    {
      value,
      dataIndex,
      title,
      helperText,
      readOnly,
      onChange,
      onMounted,
      min,
      max,
      allowFractions,
      dependentField,
      anotherDependentField,
    },
    ref
  ) => {
    const [state, setState] = useState({ value: 0 }); // showing an empty field by default until the user clicks the the field
    const { getValues } = useFormContext();
    const valuesRecord = getValues();
    const [readProp, setReadProp] = useState(false);

    useEffect(() => {
      if ((value || value === 0) && !equals(value, state)) {
        if (typeof value !== "number") applyChange(min ? min : 0);
        else applyChange(value);
      }
    }, [value]); // eslint-disable-line

    useEffect(() => {
      if (
        dataIndex === "points" &&
        title === "عدد النقاط" &&
        (dependentField === "company_id" || dependentField === "company")
      ) {
        setReadProp(true);
      }

      if (
        offerPointsActive === true &&
        dataIndex === "points" &&
        title === "عدد النقاط" &&
        dependentField === "company_id"
      ) {
        setReadProp(false);
      }
      if (
        productPointsActive === true &&
        dataIndex === "points" &&
        title === "عدد النقاط" &&
        dependentField === "company"
      ) {
        setReadProp(false);
      }
    }, [dataIndex, dependentField, title, valuesRecord]);

    const applyChange = useCallback(
      (val) => {
        setState(val);
        if (onChange) onChange(val, dataIndex);
      },
      [onChange, dataIndex]
    );

    const onChildChange = useCallback(
      (e) => {
        if (e.target.value[0] === "0")
          applyChange(Number(e.target.value.slice(1)));
        else applyChange(Number(e.target.value));
      },
      [applyChange]
    );

    const onBlur = useCallback(() => {
      if (allowFractions) {
        if (isNaN(Number(state))) applyChange(min || 0);
      } else {
        if (isNaN(Math.floor(Number(state)))) applyChange(min || 0);
        else applyChange(Math.floor(Number(state)));
      }
      if ((min || min === 0) && !isNaN(min) && min > state) applyChange(min);
      if ((max || max === 0) && !isNaN(max) && max < state) applyChange(max);
    }, [applyChange, min, max, allowFractions, state]);

    return (
      <Fragment>
        <FormControl fullWidth margin="dense">
          <InputLabel>{title}</InputLabel>
          <Input
            placeholder={title}
            inputProps={{ "aria-label": title, name: dataIndex, min: "1" }}
            disabled={readOnly || readProp}
            ref={ref}
            value={state}
            onChange={onChildChange}
            onBlur={onBlur}
            fullWidth
            type="number"
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </Fragment>
    );
  }
);

export default NumberInput;
