import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_CITIES,
} from '../../../../config/constants/ActionTypes'
import { getCitiesFailure, getCitiesSuccess } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getCitiesRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'cities/',
    params: { ...query, isDeleted: false },
  })
}

function* getCitiesSaga({ payload }) {
  try {
    const response = yield call(getCitiesRequest, payload)
    yield put(getCitiesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getCitiesFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_CITIES, getCitiesSaga)],
  )
}
