let config = {}
if (process.env.NODE_ENV === 'development') {
  config = {
    url: 'http://localhost:8000/',
  }
} else {
  config = {
    url: 'https://api.dev.repzone.qpix.io/',
  }
}

export const s3Configuration = (moduleName, _id) => ({
  bucketName: process.env.REACT_APP_AWS_BUCKET, //'repzone-static',
  dirName: `${moduleName}/${_id}`,
  region: 'eu-west-1',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, //'AKIAWAUBRLMZU7FSKJPG',
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, //'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',
})

export default config
