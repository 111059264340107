import React, { forwardRef, useState, useEffect, Fragment } from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import { equals } from 'ramda'
import moment from 'moment'
import 'moment/locale/ar'

moment.locale('en')

const DatePicker = forwardRef((props, ref) => {
  const [state, setState] = useState(null)
  const dateTimePickerClasses = useDateTimePickerStyles()
  useEffect(() => {
    if (!equals(props.value, state)) onChange(moment(Number(props.value)))
  }, [props.value]) // eslint-disable-line

  useEffect(() => {
    if (props.onMounted) props.onMounted(props.dataIndex)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (props.locale === 'ar') moment.locale('ar')
    if (props.locale === 'en') moment.locale('en')
  }, [props.locale]) // eslint-disable-line

  const onChange = (val) => {
    if(val&&!isNaN(val))
      setState(val)
    if (props.onChange) props.onChange(moment(val).valueOf().toString(), props.dataIndex)
  }

  return (
    <Fragment>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={props.locale || 'en'}>
        <DateTimePicker
          classes={dateTimePickerClasses}
          inputProps={{ 'aria-label': props.title, name: props.dataIndex }}
          margin='dense'
          format='DD MMM YYYY H:m'
          ref={ref}
          disabled={props.readOnly}
          value={state}
          onChange={onChange}
          label={props.title}
          helperText={props.helperText}
          minDate={props.minDate}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  )
})

const useDateTimePickerStyles = makeStyles(thmeme => ({
  root: {
    width: '100%'
  }
}))

export default DatePicker
