/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import { Row, Col, Icon, Select, Button, message } from 'antd'
import { connect } from 'react-redux'
import componentMapper from '../../../../config/Structure/componentMapper'
import { getObject } from '../../../../services/commonServices' 
import { config } from '../../../../config/Structure'
import  CustomButton from '../../../Form/components/Button'
import { getStaffType, hasPermissionToDelete } from '../../../../services/commonServices'
import TableView from '../../../View/components/Table'
import { CSVLink } from 'react-csv'
import { getUnitsReport } from '../../store/actions'
import { getCompanies } from '../../../Company/store/actions';
import { pathOr, path, prop } from 'ramda'
import StatisticsCard from '../StatisticsCard'

const DatePicker = componentMapper['datePicker']
const { Option } = Select;

function UnitsReport ({ dispatch, state: { ordersReport, ...state } }) {
  const isSuperAdmin = path(['auth', 'user', 'staff', 'isSuperAdmin'], state);
  const [local, setLocal] = React.useState({
    ATV: 0,
    UPT: 0,
    AUV: 0,
    PC: 0,
    cost: 0,
    count: 0,
    constLoading: false,
    data: [],
  })

  let fields = config.unitsReport.view.fields
  if (!hasPermissionToDelete(getStaffType(path(['auth', 'user', 'staff'], state)), 'units-report')) {
      fields = fields.filter((field) => field.type !== 'deleteAction')
  }

  const date = new Date()
  let startWeek = new Date().setDate(date.getDate() - 7)
  let endWeek = date.getTime()
  let startMonth = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  let endMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 999)
  let startYear = new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  let endYear = new Date(new Date().getFullYear(), 11, 31).setHours(23, 59, 59, 999)
  
  const [startDate, setStartDate] = React.useState(`${startMonth}`)
  const [endDate, setEndDate] = React.useState(`${endMonth}`)
  const [csvLoading,setCsvLoading] = React.useState(true);
  const [csvData, setCsvData] = React.useState([]) 
  const [loading, setLoading] = React.useState(false)
  const initialCompanyId = path(['auth', 'user', 'staff', 'sellerRef', '_id'], state)
  const [companyId, setCompanyId] = React.useState(initialCompanyId)
  const [cityId, setCityId] = React.useState('');
  const [district, setDistrict] = React.useState('');
  const [zoneId, setZoneId] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [distributors, setDistributors] = React.useState('')
  const [companies, setCompanies] = React.useState();
  const [districts, setDistricts] = React.useState([]);
  const [zones, setZones] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  const [totalRecords, setTotalRecords] = React.useState(0)
  
  const orderStatuses = [
    { value: 0, label: 'قيد الانتظار' },
    { value: 1, label: 'قيد المعالجة' },
    { value: 2, label: 'جاهز للشحن' },
    { value: 3, label: 'تم الشحن' },
    { value: 4, label: 'تم التوصيل ' },
    { value: 5, label: 'تم الإلغاء' },
    { value: 6, label: 'مرفوض' },
    { value: 7, label: 'تم الاستلام' },
    { value: 8, label: 'في الطريق' },
  ];
 
  const getAllRecords = ({ query: newFilter = {} }) => {
    const query = {
      startDate,
      endDate,
      sellers: distributors,
      city: cityId,
      districts: district,
      zone: zoneId,
      ...newFilter,
    }

    setLoading(true)

    setLocal({
      ...local,
      constLoading: true,
    })
    dispatch(getUnitsReport({ query,
      onSuccess: (res,count) => {
        setLocal({
          ...res,
          loading: true,
        })
        setLoading(false)
        setTotalRecords(count)
      }, 
      onFailure: () => message.error('حدث خطأ')
    }));
  };

  React.useEffect(() => {
    setCompanyId(initialCompanyId)
  }, [initialCompanyId])

  React.useEffect(() => {
    if (companyId) {
      getDistributors(companyId)
    }
    setStatus('')
  }, [companyId])

  React.useEffect(() => {
    if (cityId && cityId !== null) {
      getObject('districts/city', {
        city: cityId,
      }).then((res) => {
        if (res.data) {
          setDistricts(
            res?.data?.map((datum, key) => (
              <Option key={datum?._id} value={`${datum?._id}`}>
                {pathOr(prop('name', datum), ['name', 'ar'], datum)}
              </Option>
            ))
          );
        }
      });
      setStatus('')
     
    }
    if (district && district !== null) {
      getObject('zones/district', {
        district: district,
      }).then((res) => {
        if (res.data) {
          setZones(
            res?.data?.map((datum, key) => (
              <Option key={datum?._id} value={`${datum?._id}`}>
                {pathOr(prop('name', datum), ['name', 'ar'], datum)}
              </Option>
            ))
          );
        }
      });
    }
  },[cityId, district])

  React.useEffect(() => {
    if (isSuperAdmin) {
      getAllCompanies()
      getAllRecords({})
    }
    getAllCities()
  }, [isSuperAdmin]);

  const getDistributors = () => {
      let compIDS = []
      getObject('companies/companyDistributors', { _id: companyId }).then(
        (res) => {
          res.data.map((data, key) => {
            compIDS = [...compIDS, data._id];
          });
          setDistributors(compIDS)
          getAllRecords({
            query: {
              sellers: compIDS,
            }
          })
        }
      )
  }

  const updateStartDate = (value) => {
    if (value !== startDate) {
      getAllRecords({
        query: {
          startDate: value,
        }
      })
      setStartDate(value)
    }
  }
  const updateEndDate = (value) => {
    if (value !== endDate) {
      getAllRecords({
        query: {
          endDate: value,
        }
      })
      setEndDate(value)
    }
  }

  const getAllCompanies = () => {
    dispatch(
      getCompanies({
        onSuccess: ({ data, count }) => {
          let result = data.filter((item) => item !== null);
          setCompanies(
            result.map((datum, key) => (
              <Option
                key={datum?._id}
                value={`${datum?._id}`}
                onChange={(e) => {}}
              >
                {pathOr(prop('name', datum), ['name', 'ar'], datum)}
              </Option>
            ))
          );
        },
        onFailure: () => message.error('حدث خطأ'),
      })
    );
  };

  const getAllCities = () => {
    getObject('cities', {}).then((res) => {
      if (res.data) {
        let cities = res.data;
        setCities(
          cities?.map((datum, key) => (
            <Option key={datum?._id} value={`${datum?._id}`}>
              {pathOr(prop('name', datum), ['name', 'ar'], datum)}
            </Option>
          ))
        );
      }
    });
  };

  const filteredByKey = (obj, keyFilter) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => key !== keyFilter)
  );
  
  const onReset = (value, id) => {
    let objfilter;
    if (value === 'sellers' && `${id}` !== '') {
      setDistributors('');
      setCompanyId('');
      setCityId('');
      setDistrict('');
      setDistricts([]);
      setZoneId('');
      setStatus('')
      setZones([]);
      getAllRecords({query : {
        startDate,
        endDate,
        sellers: [],
        city: [],
        districts: [],
        zone: []
      }})
    }
    if (value === 'city' && `${id}` !== '') {
      setCityId('');
      setDistrict('');
      setDistricts([]);
      setZoneId('');
      setZones([]);
      setStatus('')
      getAllRecords( {query : {
        startDate,
        endDate, 
        city: [],
        districts: [],
        zone: []
      }})
    }
    if (value === 'districts' && `${id}` !== '') {
      setDistrict('');
      setZoneId('');
      setZones([]);
      setStatus('')
      getAllRecords( {query : {
        startDate,
        endDate,
        districts: [],
        zone: []
      }})
    }
    if (value === 'zone' && `${id}` !== '') {
      setZoneId('');
      setStatus('')
      getAllRecords( {query : {
        startDate,
        endDate,
        zone: []
      }})
    }
    if (value === 'status' && `${id}` !== '') {
      setStatus('')
      objfilter = filteredByKey( `${value}`);
      getAllRecords(objfilter);
    }
  };

  React.useEffect(() => {
    let data = []
    if (ordersReport.units)
      data = pathOr([], ['units', 'data'], ordersReport).map((row) =>
        rowSelector(row, pathOr([], ['units'], ordersReport))
      )
      setCsvData([...data])
  }, [ordersReport])

const rowSelector = (row, KPIs) => {
  return {
    Location: pathOr('_', ['zone', 'name'], row),
    'Delivery Date': row.deliveryDate || '_',
    'Order Date': row.createdAt || '_',
    'Order ID': row.orderId || '_',
    'Product name': pathOr('_', ['product', 'name'], row),
    Transactions: row.total || '_',
    'Buyer name': pathOr('_', ['user', 'name'], row),
    'Buyer phone': pathOr('_', ['user', 'phone'], row),
    'Buyer address': pathOr('_', ['user', 'shipping', 'address1'], row),
    'Big Units sold': row.quantity[0].quantity || '0',
    'Small Units sold': row.quantity[1].quantity || '0',
    'Rep number': pathOr('_', ['rep', 'phone'], row),
    'Rep name': pathOr('_', ['rep', 'name'], row),
    'Units count': parseFloat(pathOr('_', ['count'], KPIs)).toFixed(2),
    'Orders total cost': parseFloat(pathOr('_', ['cost'], KPIs)).toFixed(2),
    'ATV': parseFloat(pathOr('_', ['ATV'], KPIs)).toFixed(2),
    'PC': parseFloat(pathOr('_', ['PC'], KPIs)).toFixed(2),
    'AUV': parseFloat(pathOr('0', ['AUV'], KPIs)).toFixed(2),
    'UPT': parseFloat(pathOr('_', ['UPT'], KPIs)).toFixed(2),
  }
}

  return (
    <div>
      <TableHolder style={{ direction: 'rtl' }}>
        <Row gutter={8} type='flex' justify='center'>
          <Col xl={3} >
            <Padding>
              <StyledButton 
              color='primary' 
              onClick={() => {getAllRecords({query : {
                startDate: startWeek,
                endDate: endWeek
              }})
                setStartDate(startWeek)
                setEndDate(endWeek)
                setStatus('')
              }}

              variant='outlined'>
                {'هذا الاسبوع'}
              </StyledButton>
            </Padding>
          </Col>
          <Col xl={3}>
            <Padding>
              <StyledButton 
              color='primary' 
              onClick={() => {getAllRecords({query : {
                startDate: startMonth,
                endDate: endMonth
              }})
              setStartDate(startMonth)
              setEndDate(endMonth)
              setStatus('')
              }
              }      
              variant='outlined'>
                {'هذا الشهر'}
              </StyledButton>
            </Padding>
          </Col>
          <Col xl={3} offset={1}>
            <Padding>
              <StyledButton color='primary'
                  onClick={() => {getAllRecords({query : {
                    startDate: startYear,
                    endDate: endYear
                  }})
                  setStartDate(startYear)
                  setEndDate(endYear)
                  setStatus('')
                  }}
                  variant='outlined'>
                {'هذا العام'}
              </StyledButton>
            </Padding>
          </Col>
          <Col xl={3} offset={1}>
            <div>
              <DatePicker
                locale='ar'
                title='من تاريخ'
                onChange={(value) => {
                  if (!isNaN(value)) {
                    updateStartDate(value)
                  }
                }}
                value={startDate}
              />
            </div>
          </Col>  
          <Col xl={3} offset={1}>
            <div>
              <DatePicker
                locale='ar'
                title='الي تاريخ'
                onChange={(value) => {
                  if (!isNaN(value)) {
                    updateEndDate(value)
                  }
                }}
                value={endDate}
              />
            </div>
          </Col>
          <Col xl={2}>
            <CustomButton 
            readOnly={local.count === 0 || csvLoading === true ? true: false} color='primary' variant='outlined'
            >
              <CSVLink data={csvData}>
              <Icon 
              type={csvLoading === true && local.constLoading === true ? 'loading' : 'download'}
              />
              </CSVLink>
            </CustomButton>
          </Col>
        <Col hidden={!isSuperAdmin}>
            <SelectInputStyle>
              <Select
                value={companyId || 'الشركات'}
                optionFilterProp='children'
                showSearch={true}
                disabled={false}
                placeholder={'الشركات'}
                dropdownMenuStyle={{
                  background: 'var(--color-primary)',
                  color: 'var(--color-font-secondary)',
                }}
                name={'الشركات'}
                filterOption={true}
                onSelect={(value) => {
                  setCompanyId(value);
                }}
              >
                {companies}
              </Select>
              <Icon
                hidden={(companyId === '' || companyId === initialCompanyId)}
                type='close-circle'
                style={{ fontSize: '18px', marginRight: '7px' }}
                onClick={() => onReset('sellers', companyId)}
              />
            </SelectInputStyle>
          </Col>
           <Col>
            <SelectInputStyle>
              <Select
                value={cityId || 'المدينه'}
                optionFilterProp='children'
                showSearch={true}
                disabled={false}
                placeholder={'المدينه'}
                dropdownMenuStyle={{
                  background: 'var(--color-primary)',
                  color: 'var(--color-font-secondary)',
                }}
                name={'المدينه'}
                filterOption={true}
                onSelect={(value) => {
                  setCityId(value)
                  getAllRecords({query : {
                    city: value,
                  }})
                }}
              >
                {cities}
              </Select>
              <Icon
                hidden={cityId === ''}
                type='close-circle'
                style={{ fontSize: '18px', marginRight: '7px' }}
                onClick={() => onReset('city', cityId)}
              />
            </SelectInputStyle>
          </Col>
          <Col>
            <SelectInputStyle>
              <Select
                value={district || 'الحى'}
                optionFilterProp='children'
                showSearch={true}
                disabled={false}
                placeholder={'الحى'}
                dropdownMenuStyle={{
                  background: 'var(--color-primary)',
                  color: 'var(--color-font-secondary)',
                }}
                name={'الحى'}
                filterOption={true}
                onSelect={(value) => {
                  setDistrict(value);
                  getAllRecords({query : {
                    districts: value,
                  }})
                }}
              >
                {districts}
              </Select>
              <Icon
                hidden={district === ''}
                type='close-circle'
                style={{ fontSize: '18px', marginRight: '7px' }}
                onClick={() => {
                  onReset('districts', district);
                }}
              />
            </SelectInputStyle>
          </Col>
          <Col>
            <SelectInputStyle>
              <Select
                value={zoneId || 'المنطقه'}
                optionFilterProp='children'
                showSearch={true}
                disabled={false}
                placeholder={'المنطقه'}
                dropdownMenuStyle={{
                  background: 'var(--color-primary)',
                  color: 'var(--color-font-secondary)',
                }}
                name={'المنطقة'}
                filterOption={true}
                onSelect={(value) => {
                  setZoneId(value);
                  getAllRecords({query : {
                    zone: value,
                  }})
                }}
              >
                {zones}
              </Select>
              <Icon
                hidden={zoneId === ''}
                type='close-circle'
                style={{ fontSize: '18px', marginRight: '7px' }}
                onClick={() => onReset('zone', zoneId)}
              />
            </SelectInputStyle>
          </Col>
          <Col>
            <SelectInputStyle>
              <Select
                value={status || status === 0 ? status : 'الحاله'}
                optionFilterProp='children'
                showSearch={true}
                disabled={false}
                placeholder={'الحاله'}
                dropdownMenuStyle={{
                  background: 'var(--color-primary)',
                  color: 'var(--color-font-secondary)',
                }}
                name={'الحاله'}
                onSelect={(value) => {
                  setStatus(value)
                  getAllRecords({query : {
                    status: value,
                  }})
                }}
              >
                {orderStatuses.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
              <Icon
                hidden={status === ''}
                type='close-circle'
                style={{ fontSize: '18px', marginRight: '7px' }}
                onClick={() => onReset('status', status)}
              />
            </SelectInputStyle> 
          </Col> 
        </Row>  
          <Row style={{ paddingTop: '2.5%' }}>
            <Col span={24}> 
              <PaddingConstants>
              <Row gutter={24} type='flex' justify='center'>
                <Col xl={6}>   
                  <StatisticsCard loading={local.constLoading} title={'Units count'} value={local.count} />
                </Col>
                <Col xl={6}>
                  <StatisticsCard loading={local.constLoading} title={'Orders total cost'} value={local.cost} />
                </Col>

                <Col xl={6}>
                  <StatisticsCard loading={local.constLoading} title={'ATV'} value={local.ATV} />
                </Col>
              </Row>
              </PaddingConstants>  
              <Row gutter={24} type='flex' justify='center'>
                <Col xl={6}>
                  <StatisticsCard loading={local.constLoading} title={'UPT'} value={local.UPT} />
                </Col>
                <Col xl={6}>
                  <StatisticsCard loading={local.constLoading} title={'AUV'} value={local.AUV} />
                </Col>
                <Col xl={6}>
                  <StatisticsCard loading={local.constLoading} title={'PC'} value={local.PC} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
          <TableView
            loading={loading}
            data={local.data}
            headers={fields}
            componentName={'units-report'}
            deleteKey={config?.view?.deleteKey}
            onRefetch={() => getAllRecords()}
          />
          </Row>
        </TableHolder>
      </div>
  )
}
const mapStateToProps = (state) => ({
  state,
})
export default connect(mapStateToProps)(UnitsReport)

const TableHolder = styled.div``

const Padding = styled.div`
  padding-top: 20%;
`
const PaddingConstants = styled.div`
padding: 1.25% 0;
`
const StyledButton = styled(Button)`
  background: white;
  color: #4a7fbd;
  width: 100%;
  text-align:center;
  font-size: 1.2em;
  border: 2px solid #4a7fbd;
  border-radius: 3px;
  button:active {
    border: 2px solid #4a7fbd;
  }
`
const SelectInputStyle = styled.div`
  .ant-select-selection .ant-select-selection__choice {
    background: var(--color-primary);
    border: 0;
    border-radius: 2rem;
    font-size: 1rem;
    color: var(--color-font-secondary);
  }
  input.MuiInputBase-input.MuiInput-input,
  .ant-select-dropdown-menu-item {
    color: var(--color-form) !important;
  }
  .ant-select-remove-icon {
    color: var(--color-font-secondary);
  }
  .ant-select-selection {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #8dc3e72b;
    font-weight: 120;
    color: var(--color-font-primary);
    font-size: 1.25rem;
    text-transform: uppercase;
    border-radius: 0;
  }
  .ant-select-selection {
    min-height: 32px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1;
    width: 200px;
  }
  .ant-select-arrow .ant-select-arrow-icon svg {
    display: none;
  }
  i.anticon.anticon-down.ant-select-arrow-icon:before {
    content: '\f107';
    transition: 0.3s;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  i.anticon.anticon-down.ant-select-arrow-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon.anticon-down,
  .anticon-spin {
    font-size: 1.25rem;
    color: var(--color-secondary);
    position: relative;
    bottom: 0.5rem;
  }

  
`;