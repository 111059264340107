import React from 'react'
import { isEmpty } from 'ramda'
import { Table, Popconfirm, Icon } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CheckboxInput from '../../../Form/components/CheckboxInput'
import { useLocation } from 'react-router-dom'

const TableView = ({
  scroll,
  data,
  headers,
  componentName,
  onDelete,
  loading,
  deleteKey,
  onRefetch,
}) => {
  const columns = []

  const location = useLocation()
  headers.forEach((element) => {
    const el = {
      ...element,
      render: (text, record) => {
        switch (element.type) {
          case 'text':
            if (typeof text === 'boolean') {
              return <CheckboxInput readOnly={true} value={text} />
            }
            if (!text || isEmpty(text)) return <p>{text}</p>
            if (element.selector) return <p>{element.selector(text)}</p>
            return <p>{text}</p>
          case 'editAction':
            return (
              <Link
                type='link'
                to={`/${componentName}/edit/${record._id || record.offer_id || record.id}${
                  location.search
                }`}
              >

                <Icon type='edit' />
                {/* <span class="table-m-action"> تعديل </span>  */}

              </Link>
            )
          case 'deleteAction':
            return (
              <Popconfirm
                title='هل أنت متأكد؟'
                icon={<Icon type='exclamation-circle-o' style={{ color: 'red' }} />}
                okText='نعم'
                cancelText='لا'
                onConfirm={() => {
                  return onDelete(deleteKey ? record[deleteKey] : record._id)
                }}
              >    

                <Icon type='delete' />
                {/* <span class="table-m-action"> حذف </span>  */}
              </Popconfirm>
            )
          case 'goToAction':
            return (
              <Link type='link' to={element.to.replace(/{key}/g, record._id)}>
                <Icon type='arrow-left' />
                {/* <span class="table-m-action"> تفاصيل </span>  */}
              </Link>
            )
          case 'image':
            return (
              <span>
                <TableImage>

                <img
                  src={
                    element.selector
                      ? element.selector(text)
                      : `https://res.cloudinary.com/qpixio/image/fetch/c_fill,g_north,h_1121,w_726/${text}`
                  }
                  alt={element.title}
                />
               </TableImage>

              </span>
            )
          case 'custom':
            const Comp = element.component
            return <Comp text={text} record={record} onChange={onRefetch} />
          default:
            return <p>{text}</p>
        }
      },
    }
    columns.push(el)
  })

  return (
    <div>
      <TableStyle scroll={scroll}>
        <Table
          loading={{ spinning: loading, indicator: loadingIcon, delay: 10 }}
          columns={columns}
          dataSource={data}
          pagination={(componentName === 'units-report' || componentName === 'orders-report' )}
          scroll={scroll ? { y: '39vh' } : undefined}
        >
          {' '}
        </Table>
      </TableStyle>
    </div>
  )
}

const loadingIcon = (
  <Icon type='loading' style={{ fontSize: 50, color: 'var(--color-secondary' }} spin />
)

const TableStyle = styled.div`
@media (max-width:767px){
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0;
    
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0;
}
th {
  background: var(--color-font-table) !important;
}


.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: transparent;
}


tr.ant-table-row.ant-table-row-level-0 {
  margin: 10px auto;
  display: block;
  text-align: center;
}

}

.ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0px 12px;
    overflow-wrap: break-word;

    @media (max-width:767px){
      padding: 0px 2px;

    }
  }

  .ant-table-thead > tr > th {
    background: transparent;
    padding-bottom: 40px !important;
    font-weight: 300;
    color: var(--color-font-primary);

    text-transform: uppercase;
    font-size: 22px;
    border: 0;
    text-align: center;

    @media (max-width: 767px) {
      padding-bottom: 0 !important;
      color: var (--color-font-black);
      font-size: 14px;

    }
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
    border: 0 !important;
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar-corner {
    border: 0 !important;
    background: transparent;
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar-button {
    border: 0 !important;
    background: transparent;
  }

  .ant-table-header,
  .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    scrollbar-color: var(--color-secondary) transparent;
    scrollbar-width: thin;
    background: transparent;
    ::-webkit-scrollbar {
      width: 0.25rem;
    }

    ::-webkit-scrollbar-button {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--color-secondary);
    }
  }

  .ant-table-thead > tr,
  .ant-table-thead > tr > th
  {
    padding: 12px 16px;
    overflow-wrap: break-word;

    @media (max-width:767px){
      padding: 0 !important;

    }
  }


  .ant-table-thead > tr > td
  {
 

    @media (max-width:767px){
      padding: 0px 5px !important;

    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0px 12px;
    overflow-wrap: break-word;
  }

  .ant-table-scroll {
  }

  .ant-table-placeholder {
    background: transparent;
    border: 0px;
  }

  .ant-empty {
    color: var(--color-background);
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-bottom: 0;
  }

  th:first-child {
    border: 0;
  }

  a,
  td {
    color: var(--color-font-primary);
    font-size: 17px;
    height: 84px;
    font-weight: 300;
    text-align: center;

    @media (max-width:767px){
     
      height:45px;

    }

    
  }

  td p {
    margin: 0px;
    font-size: 17px;
    
    @media (max-width:768px){
     
      font-size:14px;

    }
    @media (max-width:767px){
     
      font-size:14px;

    }
  }
.ant-table-column-title{
    font-size: 19px;
    @media (max-width:768px){
     
      font-size:15px !important;
      color: var(--color-font-white);

    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {

    @media (min-width:768px){
   
      background: var(--color-primary) !important;
      font-weight: 200;
      transition: 0.1 !important;
      color: var(--color-font-secondary);
  
  
     
    }

  

  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #82b3d626;

    @media (max-width:767px){
    padding:0px 7px;
    }
  }

  i {
    font-size: 17px;
    color: #4a7fbd;

    @media (max-width:767px){
      font-size: 17px;

    }
  }

  white-space: pre-wrap;

  .ant-table-wrapper {
    padding: 50px 0px;
    @media (max-width:768px){
     
      padding: 25px 0px;

    }
  }

  .ant-table-wrapper {
    min-height: ${(props) => (props.scroll ? '40vh' : 'unset')};
  }





@media (max-width:767px){
  .ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
}
span.ant-table-header-column {
  padding: 6px;
  
}

}


@media (max-width:768px){
  .ant-table-wrapper {
    overflow-y: scroll;
    padding: 10px 0px;
    min-height: 10vh;
    scrollbar-width: thin;
    background: transparent;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  @media (max-width: 768px) {
    .ant-table-wrapper {
      overflow-y: scroll;

      scrollbar-width: thin;
      background: transparent;
      ::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
`


const TableImage = styled.div`
img{
   height:70px; 
   display:inline;
   padding:5px;
@media (max-width:767px){
  height:70px; 
  padding:2px;

}

}


`
export default TableView
