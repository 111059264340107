import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const RepsTable = () => {
  return (
    <div>

      <TableHolder>
      <View componentName='reps' config={config.reps} exportConfig={config.exportData} />

            </TableHolder>
    </div>
  )
}

export default RepsTable


const TableHolder  = styled.div`
@media (max-width:767px){
  td:nth-child(6), td:nth-child(7) , td:nth-child(8) {
    display: inline-block;
    text-align: center;
  }
}

`