import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_QUANTITY_OFFERS,
  DELETE_QUANTITY_OFFER,
  ADD_QUANTITY_OFFER,
  UPDATE_QUANTITY_OFFER,
  GET_QUANTITY_OFFER,
} from '../../../../config/constants/ActionTypes'
import { getQuantityOffersFailure, getQuantityOffersSuccess } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getQuantityOffersRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'offers/allQuantityOffers',
    params: { ...query },
  })
}

function* getQuantityOffersSaga({ payload }) {
  try {
    const response = yield call(getQuantityOffersRequest, payload)
    yield put(getQuantityOffersSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getQuantityOffersFailure(error))
    payload.onFailure(error)
  }
}

const getQuantityOfferRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'offers/singlePriceOffer',
    params: { id: query._id },
  })
}

function* getQuantityOfferSaga({ payload }) {
  try {
    const response = yield call(getQuantityOfferRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

const deleteQuantityOfferRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'offers/deleteQuantityOffer',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { offer_id: _id },
  })
}

function* deleteQuantityOfferSaga({ payload }) {
  try {
    const response = yield call(deleteQuantityOfferRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addQuantityOfferRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'offers/quantityOffers',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addQuantityOfferSaga({ payload }) {
  try {
    const response = yield call(addQuantityOfferRequest, payload) // eslint-disable-line
    delete payload._id
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateQuantityOfferRequest = async ({ body }) => {
  let offer_id = body._id
  delete body._id
  return await axios({
    method: 'put',
    url: config.url + 'offers/updateQuantityOffers',
    params: {
      offer_id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateQuantityOfferSaga({ payload }) {
  try {
    const response = yield call(updateQuantityOfferRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      payload.onSuccess()
      // yield put(getQuantityOffers(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_QUANTITY_OFFERS, getQuantityOffersSaga)],
    [yield takeEvery(DELETE_QUANTITY_OFFER, deleteQuantityOfferSaga)],
    [yield takeEvery(ADD_QUANTITY_OFFER, addQuantityOfferSaga)],
    [yield takeEvery(UPDATE_QUANTITY_OFFER, updateQuantityOfferSaga)],
    [yield takeEvery(GET_QUANTITY_OFFER, getQuantityOfferSaga)]
  )
}
