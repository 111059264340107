import React, { forwardRef, useState, useEffect } from 'react'
import { Input } from 'antd'
import { equals } from 'ramda'
import styled from 'styled-components'

const LoginInput = forwardRef((props, ref) => {
  const [state, setState] = useState(props.value)

  useEffect(() => {
    if (!equals(props.value, state)) setState(props.value)
  }, [props.value]) // eslint-disable-line

  const onChange = (e) => {
    setState(e.target.value)
    if (props.onChange) props.onChange(e.target.value, props.dataIndex)
  }

  return (
    <div>
      {
        props.type === 'password'
          ? <PasswordInput 
              disabled={props.readOnly}
              name={props.dataIndex}
              value={state}
              ref={ref}
              onChange={onChange}
            />
          : <TextInput
              disabled={props.readOnly}
              name={props.dataIndex}
              value={state}
              ref={ref}
              onChange={onChange}
            />
      }
    </div>
  )
})


const style = `
  text-align: left !important;
  width: 232px;
  height: 40px;
  border-radius: 4px;
  background: #a9aaaf !important;
  clear: both;
  border: 0;
  font-size: 17px;
  font-weight: 300;
  @media (max-width:767px){
    width:100%;
      }


`

const PasswordInput = styled(Input.Password)`
  width: 232px;

  @media (max-width:767px){
    width:100%;
      }
  input {
    ${style}
  }
  
`

const TextInput = styled(Input)`
  ${style}
`

export default LoginInput
