import React, { createContext, useContext, useReducer, useEffect } from 'react'

const StateContext = createContext()

const initialState = {
  staff: null,
  token: null,
  theme: {
    light: {
      colorPrimary: '#fff',
      colorSecondary: '#4a7fbd',
      colorTernary: '#11192d',
      colorFontPrimary: '#000000',
      colorFontNotPrimary: '#000',
      colorFontSecondary: '#4a7fbd',
      colorBackground: '#ffffff',
      colorBody: '#eff1f5',
      colorWhite: '#fff',
      shadow: '3px 3px 2px 0 rgba(9, 11, 23, 0.48);',
      colorForm: '#8f8d8d ',
      colorCustom: '#11192d',
      TableHead:'#4a7fbe'

    },
    dark: {
      colorPrimary: '#292e3a',
      colorSecondary: '#4a7fbd',
      colorTernary: '#11192d',
      colorFontPrimary: '#ffffff',
      colorFontNotPrimary: '#000',
      colorFontSecondary: '#ffffff',
      colorBackground: '#191b2a',
      shadow: '3px 3px 2px 0 rgba(9, 11, 23, 0.48);',
      colorBody: '#3636392e',
      colorWhite: '#fff',
      colorForm: '#fff',
      colorCustom: '#fff',
      TableHead:'#292f3a'


    }
  },
  selectedTheme: 'light',
  dependency: null,
}

export const GlobalStateProvider = ({ children }) => {
  
  const reducer = (state, action) => {
    switch (action.type) {
      case 'LOGIN':
        localStorage.setItem('staff', JSON.stringify(action.payload.staff))
        return { ...state, token: action.payload.token, staff: action.payload.staff }
      case 'AUTO_LOGIN':
        return { ...state, token: action.payload.token, staff: action.payload.staff }
      case 'LOGOUT':
        localStorage.removeItem('staff')
        localStorage.removeItem('token')
        return { ...initialState, selectedTheme: state.selectedTheme }
      case 'SET_DEPENDENCY':
        return { ...state, dependency: action.payload.dependency }
      case 'TOGGLE_THEME':
        const selectedTheme = state.selectedTheme === 'light' ? 'dark' : 'light'
        localStorage.setItem('selectedTheme', selectedTheme)
        return { ...state, selectedTheme }
      case 'SET_THEME':
        return { ...state, selectedTheme: action.payload.selectedTheme }
      default:
        return state
    }
  }
      
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const selectedTheme = localStorage.getItem('selectedTheme')
    if (selectedTheme) dispatch({ type: 'SET_THEME', payload: { selectedTheme } })
    const token = localStorage.getItem('token')
    const staff = localStorage.getItem('staff')
    if (token && staff) dispatch({ type: 'AUTO_LOGIN', payload: { token, staff: JSON.parse(staff) } })
  }, [])

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}

export const GlobalContext = () => useContext(StateContext)