import React, { useState, useEffect, forwardRef } from 'react'
import { equals } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

/* 
props:
  - title -> string
  - dataIndex -> string
  - multiple -> boolean
  - value -> string if multiple is false, array[string] if multiple is true
  - optionsQuery -> query to get the options list from, whether the result is in an items array or directly an array
  - readOnly -> boolean
  - onChange -> fn(value, dataIndex)
  - onMounted -> fn(dataIndex)
*/

const SelectInput = forwardRef((props, ref) => {
  const [state, setState] = useState(props.multiple ? [] : '')
  // eslint-disable-next-line no-unused-vars
  const [tempState, setTempState] = useState(props.multiple ? [] : '')
  // const { data: fetchedOptions } = useQuery(props.optionsQuery)
  const [options, setOptions] = useState([])
  const [open, setOpen] = useState(false)
  const selectClasses = useSelectStyles()

  useEffect(() => {
    if (props.value && !equals(props.value, state)) {
      if (options.length) onChange(props.value)
      else setTempState(props.value)
    }
  }, [props.value]) // eslint-disable-line

  // useEffect(() => {
  //   if (fetchedOptions) {
  //     if (Array.isArray(fetchedOptions[Object.keys(fetchedOptions)[0]])) {
  //       setOptions(fetchedOptions[Object.keys(fetchedOptions)[0]])
  //       setState(tempState)
  //     } else if (Array.isArray(fetchedOptions[Object.keys(fetchedOptions)[0]].items)) {
  //       setOptions(fetchedOptions[Object.keys(fetchedOptions)[0]].items)
  //       setState(tempState)
  //     }
  //   }
  // }, [fetchedOptions, tempState])

  useEffect(() => {
    if (props.onMounted) props.onMounted(props.dataIndex)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (props.options && Array.isArray(props.options)) setOptions(props.options)
  }, [props.options]) // eslint-disable-line

  const onChange = (val) => {
    setState(val)
    if (props.onChange) props.onChange(val, props.dataIndex)
  }

  return (
    <div ref={ref}>
      <FormControl fullWidth>
        <InputLabel>{props.title}</InputLabel>
        <Select
          multiple={props.multiple}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={state}
          onChange={(e) => onChange(e.target.value)}
          classes={selectClasses}
          fullWidth
          disabled={props.readOnly}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
})

const useSelectStyles = makeStyles((theme) => ({
  root: {},
  select: {},
}))

export default SelectInput
