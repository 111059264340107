import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  DELETE_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_COMPANY_DISTRIBUTORS,
} from '../../../../config/constants/ActionTypes'

//Get Companies Request
export const getCompanies = (payload) => {
  return {
    type: GET_COMPANIES,
    payload,
  }
}

//Get Company Distributors Request
export const getCompanyDistributors = (payload) => {
  return {
    type: GET_COMPANY_DISTRIBUTORS,
    payload,
  }
}

//Get Companies Success
export const getCompaniesSuccess = (companies) => {
  return {
    type: GET_COMPANIES_SUCCESS,
    payload: { companies },
  }
}

//Get Companies Failure
export const getCompaniesFailure = (error) => {
  return {
    type: GET_COMPANIES_FAILURE,
    payload: { error },
  }
}

//Get Company Request
export const getCompany = (payload) => {
  return {
    type: GET_COMPANY,
    payload,
  }
}

//Get Companies Success
export const getCompanySuccess = (company) => {
  return {
    type: GET_COMPANY_SUCCESS,
    payload: { company },
  }
}

//Get Companies Failure
export const getCompanyFailure = (error) => {
  return {
    type: GET_COMPANY_FAILURE,
    payload: { error },
  }
}

//Delete Company
export const deleteCompany = (payload) => {
  return {
    type: DELETE_COMPANY,
    payload,
  }
}

//Add Company
export const addCompany = (payload) => {
  return {
    type: ADD_COMPANY,
    payload,
  }
}

//update Company
export const updateCompany = (payload) => {
  return {
    type: UPDATE_COMPANY,
    payload,
  }
}
