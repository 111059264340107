import {
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: action.payload.orders,
      }
    }

    case GET_ORDERS_FAILURE: {
      return {
        ...state,
        getOrdersError: action.payload.error,
      }
    }

    case GET_ORDER_SUCCESS: {
      return {
        ...state,
        order: action.payload.order,
      }
    }

    case GET_ORDER_FAILURE: {
      return {
        ...state,
        getOrderError: action.payload.error,
      }
    }

    default:
      return state
  }
}
