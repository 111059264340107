import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const PackagesTable = () => {
  return (
    <OrderHolder>
            <TableHolder>
      <View componentName='packages' config={config.packages} />
      </TableHolder>
    </OrderHolder>
  )
}

export default PackagesTable


const OrderHolder = styled.div`
.MuiDialogContent-root {
  margin: 0 auto;
}


`

const TableHolder  = styled.div`
@media (max-width:767px){

  td:nth-child(3), td:nth-child(4) {
    display: inline-block;
    text-align: center;
  }
}

`