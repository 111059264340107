import {
  GET_COUNTIES_SUCCESS,
  GET_COUNTIES_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_COUNTIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload.countries,
      }
    }

    case GET_COUNTIES_FAILURE: {
      return {
        ...state,
        getCountriesError: action.payload.error,
      }
    }

    default:
      return state
  }
}
