import {
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANY_FAILURE,
  GET_COMPANY_SUCCESS,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: action.payload.companies,
      }
    }

    case GET_COMPANIES_FAILURE: {
      return {
        ...state,
        getCompaniesError: action.payload.error,
      }
    }

    case GET_COMPANY_SUCCESS: {
      return {
        ...state,
        company: action.payload.company,
      }
    }

    case GET_COMPANY_FAILURE: {
      return {
        ...state,
        getCompanyError: action.payload.error,
      }
    }

    default:
      return state
  }
}
