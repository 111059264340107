import {
  GET_PACKAGES,
  GET_ALL_PACKAGES_ID,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
  DELETE_PACKAGE,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
} from '../../../../config/constants/ActionTypes'

//Get Package Request
export const getPackages = (payload) => {
  return {
    type: GET_PACKAGES,
    payload,
  }
}

//Get All Packages Id Request
export const getAllPackagesId = (payload) => {
  return {
    type: GET_ALL_PACKAGES_ID,
    payload,
  }
}

//Get Packages Success
export const getPackagesSuccess = (packages) => {
  return {
    type: GET_PACKAGES_SUCCESS,
    payload: { packages },
  }
}

//Get Packages Failure
export const getPackagesFailure = (error) => {
  return {
    type: GET_PACKAGES_FAILURE,
    payload: { error },
  }
}

//Delete Package
export const deletePackage = (payload) => {
  return {
    type: DELETE_PACKAGE,
    payload,
  }
}

//Add Package
export const addPackage = (payload) => {
  return {
    type: ADD_PACKAGE,
    payload,
  }
}

//update Packages
export const updatePackage = (payload) => {
  return {
    type: UPDATE_PACKAGE,
    payload,
  }
}
