import { tableFields, fields, formFields } from '../schema'
import { getAds, deleteAd, addAd, editAd } from '../../../modules/Ad/store/actions'
import { getCompanies } from '../../../modules/Company/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'

export const ads = {
  view: {
    get: getAds,
    delete: deleteAd,
    fields: [
      tableFields.text(arabic_labels['form.title'], 'title'),
      tableFields.text(arabic_labels['form.company'], 'company.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addAd,
    fields: [
      fields.text({
        title: 'العنوان',
        dataIndex: 'title',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال العنوان' : true),
      }),
      fields.text({
        title: 'عنوان ثانوي',
        dataIndex: 'subtitle',
      }),
      formFields.select(
        arabic_labels['form.company'],
        'company',
        'default',
        { query: getCompanies },
        null,
        null,
        null,
        null,
        null,
        false,
      ),
      fields.checkbox({ title: arabic_labels['form.isActive'], dataIndex: 'isActive' }),
      fields.text({
        title: 'رابط الاعلان',
        dataIndex: 'url',
        validate: (fieldValue) => (!fieldValue) ? 'برجاء إدخال رابط الاعلان' : true
      }),
      fields.text({
        title: 'عنوان الزر ',
        dataIndex: 'button',
      }),
      fields.images({
        title: arabic_labels['form.image'],
        dataIndex: 'image',
        multiple: false,
        dimensions: {width: 1280 , height:720 , aspectWidth : 16 , aspectHeight: 9},
        validate: (fieldValue) => (!fieldValue) ? 'برجاء إدخال صورة الاعلان' : true

      }),
    ],
  },
  edit: {
    update: editAd,
    get: getAds,
    fields: [
      fields.text({
        title: 'العنوان',
        dataIndex: 'title',
        validate: (fieldValue) => (!fieldValue) ? 'برجاء إدخال العنوان' : true,
      }),
      fields.text({
        title: 'عنوان ثانوي',
        dataIndex: 'subtitle',
      }),
      fields.object({
        title: arabic_labels['form.company'],
        dataIndex: 'company',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getCompanies },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            'company',
          ),
        ],
      }),
      fields.checkbox({ title: arabic_labels['form.isActive'], dataIndex: 'isActive' }),
      fields.text({
        title: 'رابط الاعلان',
        dataIndex: 'url',
        validate: (fieldValue) => (!fieldValue) ? 'برجاء إدخال رابط الاعلان' : true
      }),
      fields.text({
        title: 'عنوان الزر ',
        dataIndex: 'button',
      }),
      fields.images({
        title: arabic_labels['form.image'],
        dataIndex: 'image',
        multiple: false,
        dimensions: {width: 1280 , height:720 , aspectWidth : 16 , aspectHeight: 9},
        validate: (fieldValue) => (!fieldValue) ? 'برجاء إدخال صورة الاعلان' : true
      }),
    ],
  },
}
