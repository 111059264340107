/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useReducer
} from 'react';
import { isEmpty } from 'ramda';
import { useForm, Controller, FormContext } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { message } from 'antd';
import componentMapper from '../../config/Structure/componentMapper';
import Button from './components/Button';
import styled from 'styled-components';
import { types } from '../../config/Structure/schema';
import { getObject } from '../../services/commonServices';

export let offerPointsActive = false;
export let productPointsActive = false;

const productPointsReducer = (state,action) => {
  switch(action.type) {
    case 'getProductPoints':
      return {state: productPointsActive}
    case 'setProductPoints':
      productPointsActive = true
      return {state: productPointsActive}
    case 'resetProductPoints':
      productPointsActive = false
      return {state: productPointsActive}

    default:
      return state
  }
}
const offerPointsReducer = (state,action) => {
  switch(action.type) {
    case 'getOfferPoints':
      return {state: offerPointsActive}
    case 'setOfferPoints':
      offerPointsActive = true
      return {state: offerPointsActive}
    case 'resetOfferPoints':
      offerPointsActive = false
      return {state: offerPointsActive}

    default:
      return state
  }
}

const CrudForm = (props) => {
  const [stateProductPoints, dispatchProduct] = useReducer(productPointsReducer,productPointsActive)
  const [stateOfferPoints, dispatchOffer] = useReducer(offerPointsReducer,offerPointsActive)
  const [isSubmitting, setSubmitting] = useState(false);
  const [children, setChildren] = useState([]);
  const [hasTabs, setHasTabs] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    errors,
    getValues,
    watch,
  } = useForm();
  const labelClasses = useLabelStyles(props);
  const titleClasses = useTitleStyles();
  const valuesRecord = getValues();
  const company_id = valuesRecord['company_id'];
  const companyId = valuesRecord['company'];

  useEffect(() => {
  if (props.setCompanyId) props.setCompanyId(companyId)
  }, [companyId])

  useEffect(() => {
    if (companyId?._id || companyId) {
      const id = companyId._id?
        companyId._id
        : companyId;
      getObject('companies/allCompanyInfo', { _id: id })
        .then((res) => {
          if (res.data[0].pointsActivated === true  && companyId && companyId !== null) {
            dispatchProduct({type: 'setProductPoints'})
          } else if (companyId && companyId !== null){
            dispatchProduct({type: 'resetProductPoints'})
          }
        })
        .catch(() => {});
    }
  }, [companyId, companyId?._id,stateProductPoints]);
  useEffect(() => {
    if (company_id) {
      const id = company_id
        ? company_id
        : '';
      getObject('companies/allCompanyInfo', { _id: id })
        .then((res) => {    
          if (res.data[0].pointsActivated === true && company_id && company_id !== null
            && valuesRecord['optional_3'] === 'carton' && watch('optional_3')
            ) {
            dispatchOffer({type: 'setOfferPoints'})
          } else if ((valuesRecord['optional_3'] !== 'carton' && watch('optional_3')) || (company_id && company_id !== null)){
            dispatchOffer({type: 'resetOfferPoints'})
          }
        })
        .catch(() => {});
    }
  }, [company_id,watch('optional_3'),valuesRecord['optional_3'],stateOfferPoints]);
  
  useEffect(() => {
    setSubmitting(props.submitting);
  }, [props.submitting]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      for (let field of Object.values(errors)) {
        message.error(field.message);
      }
    }
  }, [errors]);

  const mapFields = useCallback(
    (fields) => {
      return fields.map((field) => {
        const Comp =
          field.type === 'custom' ? field.field : componentMapper[field.type];

        if (field.type === types.verticalTabs) {
          setHasTabs(true);
          const tabs = field.tabs.map((tab) => {
            const name = tab.name;
            const content = mapFields(tab.fields);
            return { name, content };
          });
          return (
            <Comp
              key={field.key}
              {...field}
              tabs={tabs}
              inModal={props.inModal}
            />
          );
        }

        return (
          <Fragment key={field.key}>
            <Grid container spacing={props.inModal ? 0 : 2}>
              <Grid item xs={12} sm={props.inModal ? 12 : 2}>
                <Box className='crud-title2' classes={labelClasses}>
                  {field.title}
                </Box>
              </Grid>
              <Grid item xs={12} sm={props.inModal ? 12 : 9}>
                <Controller
                  as={
                    <Comp
                      {...field}
                      readOnly={isSubmitting || field.readOnly}
                      hideTitle
                      inModal={props.inModal}
                    />
                  }
                  name={field.dataIndex}
                  rules={{
                    validate: (fieldValue) =>
                      field.validate
                        ? field.validate(fieldValue, getValues())
                        : true,
                  }}
                  control={control}
                  valueName={'value'}
                />
              </Grid>
            </Grid>
            <br />
            <br />
          </Fragment>
        );
      });
    },
    [isSubmitting, labelClasses]
  );

  const applyInitialValues = useCallback(
    (fields, initialValues) => {
      const applySelector = (child, initialValue) => {
        if (!initialValue) {
          return initialValue;
        }
        let value;
        switch (child.type) {
          case 'object':
            value = {};
            child.fields.forEach((field) => {
              value[field.dataIndex] = applySelector(
                field,
                initialValue[field.dataIndex]
              );
            });
            break;
          case 'list':
            value = [];
            initialValue.forEach((initialVal) => {
              value.push(applySelector(child.field, initialVal));
            });
            break;
          case 'double':
            value = {};
            value[child.firstField.dataIndex] = applySelector(
              child.firstField,
              initialValue[child.firstField.dataIndex]
            );
            value[child.secondField.dataIndex] = applySelector(
              child.secondField,
              initialValue[child.secondField.dataIndex]
            );
            break;
          case 'triple':
            value = {};
            value[child.firstField.dataIndex] = applySelector(
              child.firstField,
              initialValue[child.firstField.dataIndex]
            );
            value[child.secondField.dataIndex] = applySelector(
              child.secondField,
              initialValue[child.secondField.dataIndex]
            );
            value[child.thirdField.dataIndex] = applySelector(
              child.thirdField,
              initialValue[child.thirdField.dataIndex]
            );
            break;
          default:
            value = child.selector
              ? child.selector(initialValue)
              : initialValue;
            break;
        }
        return value;
      };

      fields.map((field) => {
        if (field.type === types.verticalTabs) {
          return field.tabs.map((tab) =>
            applyInitialValues(tab.fields, initialValues)
          );
        } else {
          const initialValue =
            initialValues && !isEmpty(initialValues)
              ? applySelector(field, initialValues[field.dataIndex])
              : undefined;
          return setTimeout(() => {
            setValue(field.dataIndex, initialValue);
          }, 0);
        }
      });
    },
    [setValue]
  );

  useEffect(() => {
    setChildren(mapFields(props.fields));
  }, [mapFields, setChildren, props.fields]);

  useEffect(() => {
    applyInitialValues(props.fields, props.initialValues);
  }, [props.initialValues, props.fields, applyInitialValues]);

  const submit = (data) => {
    if (isSubmitting) return false;
    setSubmitting(true);
    props.onSubmit(data);
    productPointsActive === true
      ? (productPointsActive = false)
      : (productPointsActive = false);
    offerPointsActive === true
      ? (offerPointsActive = false)
      : (offerPointsActive = false);
  };

  return (
    <Fragment>
      <FormHolder>
        {props.title && (
          <TitleStyle>
            <Typography className='crud-title' classes={titleClasses}>
              {props.title}
            </Typography>
          </TitleStyle>
        )}
        <FormContext getValues={getValues} watch={watch}>
          <form onSubmit={handleSubmit(submit)}>
            <Container
              maxWidth={hasTabs ? undefined : 'lg'}
              style={{ direction: 'rtl' }}
            >
              <Grid container justify='center' direction='column' spacing={3}>
                {children}
                <Grid item xs={12}>
                  <Button
                    style={{
                      width: props.inModal ? '4rem' : '7rem',
                      float: 'right',
                      right: props.inModal ? '2rem' : '5rem',
                      marginTop: '1rem',
                      height: props.inModal ? '3rem' : '3.3rem',
                      fontSize: props.inModal ? '1.6rem' : '1.9rem',
                    }}
                    loading={isSubmitting}
                    variant='contained'
                    onClick={handleSubmit(submit)}
                  >
                    {props.buttonText}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        </FormContext>
      </FormHolder>
    </Fragment>
  );
};

const useLabelStyles = makeStyles((theme) => ({
  root: {
    textAlign: (props) => (props.inModal ? 'left' : 'right'),
    marginRight: 5,
    textTransform: 'uppercase',
    color: theme.palette.info.main,
    fontSize: '1.1rem',
  },
}));

const useTitleStyles = makeStyles((theme) => ({
  root: {
    fontSize: '27px',
    textAlign: 'center',
    textTransform: 'upperCase',
    margin: '60px 0',
    color: theme.palette.info.main,
  },
}));

export default CrudForm;

const TitleStyle = styled.div`
  .crud-title2 {
    font-size: 1.1rem;
  }

  @media (max-width: 767px) {
    p {
      font-size: 16px;
      padding: 0;
      margin: 25px 0;
    }
    .crud-title2 {
      font-size: 15px !important;
    }
  }
`;

const FormHolder = styled.div`
  .crud-title2 {
    font-size: 1.1rem;

    @media (max-width: 767px) {
      font-size: 13px !important;
    }
  }
`;
