import {
  GET_COMPANY_PRODUCTS_SUCCESS,
  GET_COMPANY_PRODUCTS_FAILURE,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_COMPANY_PRODUCTS_SUCCESS: {
      return {
        ...state,
        companyProducts: action.payload.companyProducts,
      }
    }

    case GET_COMPANY_PRODUCTS_FAILURE: {
      return {
        ...state,
        getCompanyProductsError: action.payload.error,
      }
    }

    case SEARCH_SUCCESS: {
      return {
        ...state,
        searchItems: action.payload.items,
      }
    }

    case SEARCH_FAILURE: {
      return {
        ...state,
        getSearchError: action.payload.error,
      }
    }

    default:
      return state
  }
}
