import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const CompaniesTable = () => {
  return (
    <div>
      <TableHolder>

      <View componentName='companies' config={config.companies} exportConfig={config.exportData} />
      </TableHolder>
    </div>
  )
}

export default CompaniesTable


const TableHolder  = styled.div`
@media (max-width:767px){

td:nth-child(8), td:nth-child(9), td:nth-child(7) {
  display: inline-block;
  text-align: center;
}

}
`