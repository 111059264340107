import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_SUPERADMINS,
  DELETE_SUPERADMIN,
  ADD_SUPERADMIN,
  UPDATE_SUPERADMIN,
} from '../../../../config/constants/ActionTypes'
import { getSuperadmins, getSuperadminsSuccess, getSuperadminsFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getSuperadminsRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'superadmins',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
  })
}

function* getSuperadminsSaga({ payload }) {
  try {
    const response = yield call(getSuperadminsRequest, payload)
    yield put(getSuperadminsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getSuperadminsFailure(error))
    payload.onFailure(error)
  }
}

const deleteSuperadminRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'superadmins',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteSuperadminSaga({ payload }) {
  try {
    const response = yield call(deleteSuperadminRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addSuperadminRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'superadmins',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addSuperadminSaga({ payload }) {
  try {
    const response = yield call(addSuperadminRequest, payload) // eslint-disable-line
    payload.onSuccess()
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateSuperadminRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'superadmins',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateSuperadminSaga({ payload }) {
  try {
    const response = yield call(updateSuperadminRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getSuperadmins(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_SUPERADMINS, getSuperadminsSaga)],
    [yield takeEvery(DELETE_SUPERADMIN, deleteSuperadminSaga)],
    [yield takeEvery(ADD_SUPERADMIN, addSuperadminSaga)],
    [yield takeEvery(UPDATE_SUPERADMIN, updateSuperadminSaga)],
  )
}
