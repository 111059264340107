import { tableFields, fields, formFields } from '../schema'
import PriceLabel from '../../../modules/QuantityOffer/components/PriceLabel'
import DiscountLabel from '../../../modules/QuantityOffer/components/DiscountLabel'
import ZonesTreeSelect from '../../../modules/QuantityOffer/components/ZonesTreeSelect'
import {
  getQuantityOffers,
  getQuantityOffer,
  deleteQuantityOffer,
  addQuantityOffer,
  updateQuantityOffer,
} from '../../../modules/QuantityOffer/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { getCompanies } from '../../../modules/Company/store/actions'
import { getCompanyProducts } from '../../../modules/CompanyProduct/store/actions'
import { getProductBuyerCategories } from '../../../modules/BuyerCategory/store/actions'

export const quantityOffers = {
  view: {
    get: getQuantityOffers,
    delete: deleteQuantityOffer,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name'),
      tableFields.text(arabic_labels['form.quantity'], 'optional_1'),
      tableFields.text(arabic_labels['form.package'], 'optional_3'),
      tableFields.text(arabic_labels['form.freeQuantity'], 'optional_2'),
      tableFields.text(arabic_labels['form.freePackage'], 'optional_4'),
      tableFields.text(arabic_labels['form.startDate'], 'starting_date'),
      tableFields.text(arabic_labels['form.endDate'], 'end_date'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
    deleteKey: 'id',
  },
  add: {
    create: addQuantityOffer,
    fields: [
      fields.text({
        title: arabic_labels['form.name'],
        dataIndex: 'name',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        'الشركة',
        'company_id',
        'default',
        { query: getCompanies },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الشركة' }],
        null,
        null,
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار الشركة' : true),
      ),
      formFields.select(
        'المنتج',
        'product_id',
        'default',
        { query: getCompanyProducts },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار المنتج' }],
        'company_id',
        'company',
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار المنتج' : true),
      ),
      formFields.select(
        'القطاع',
        'buyer_cat',
        'default',
        { query: getProductBuyerCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار القطاع' }],
        'product_id',
        '_id',
        null,
        null,
        (fieldValue) => (!fieldValue ? 'لا بد من إختيار القطاع' : true),
      ),
      fields.number({
        title: arabic_labels['form.quantity'],
        min: 1,
        dataIndex: 'optional_1',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الكمية' : true),
      }),
      fields.datePicker({
        title: 'تاريخ البداية',
        dataIndex: 'starting_date',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تاريخ البداية' : true),
      }),
      fields.datePicker({
        title: 'تاريخ النهاية',
        dataIndex: 'end_date',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تاريخ النهاية' : true),
      }),
      formFields.select(arabic_labels['form.package'], 'optional_3', 'default', {
        list: [{ key: 'كرتونة', value: 'carton' }, { key: 'عبوة', value: 'unit' }],
      }),
      fields.number({
        title: arabic_labels['form.freeQuantity'],
        min: 1,
        dataIndex: 'optional_2',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال العدد المجاني' : true),
      }),
      formFields.select(arabic_labels['form.freePackage'], 'optional_4', 'default', {
        list: [{ key: 'كرتونة', value: 'carton' }, { key: 'عبوة', value: 'unit' }],
      }),
      fields.custom({
        title: 'السعر قبل الخصم',
        dataIndex: 'oldPrice',
        field: PriceLabel,
      }),
      fields.custom({
        title: 'السعر بعد الخصم',
        dataIndex: 'newPrice',
        field: DiscountLabel,
      }),
      fields.number({
        title: arabic_labels['form.points'],
        dataIndex: 'points',
        dependentField:'company_id',
        min:0
      }),
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال المنطقة' : true),
      }),
    ],
  },
  edit: {
    update: updateQuantityOffer,
    get: getQuantityOffer,
    fields: [
      fields.text({
        title: arabic_labels['form.name'],
        dataIndex: 'name',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        'الشركة',
        'company_id',
        'default',
        { query: getCompanies },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار الشركة' }],
      ),
      formFields.select(
        'المنتج',
        'product_id',
        'default',
        { query: getCompanyProducts },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار المنتج' }],
        'company_id',
        'company',
      ),
      formFields.select(
        'القطاع',
        'buyer_cat',
        'default',
        { query: getProductBuyerCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        [{ required: true, message: 'لا بد من إختيار القطاع' }],
        'product_id',
        '_id',
      ),
      fields.number({
        title: arabic_labels['form.quantity'],
        min: 1,
        dataIndex: 'optional_1',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الكمية' : true),
      }),
      fields.datePicker({
        title: 'تاريخ البداية',
        dataIndex: 'starting_date',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تاريخ البداية' : true),
      }),
      fields.datePicker({
        title: 'تاريخ النهاية',
        dataIndex: 'end_date',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال تاريخ النهاية' : true),
      }),
      formFields.select(arabic_labels['form.package'], 'optional_3', 'default', {
        list: [{ key: 'كرتونة', value: 'carton' }, { key: 'عبوة', value: 'unit' }],
      }),
      fields.number({
        title: arabic_labels['form.freeQuantity'],
        min: 1,
        dataIndex: 'optional_2',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال العدد المجاني' : true),
      }),
      formFields.select(arabic_labels['form.freePackage'], 'optional_4', 'default', {
        list: [{ key: 'كرتونة', value: 'carton' }, { key: 'عبوة', value: 'unit' }],
      }),
      fields.custom({
        title: 'السعر قبل الخصم',
        dataIndex: 'oldPrice',
        field: PriceLabel,
      }),
      fields.custom({
        title: 'السعر بعد الخصم',
        dataIndex: 'newPrice',
        field: DiscountLabel,
      }),
      fields.number({
        title: arabic_labels['form.points'],
        dataIndex: 'points',
        dependentField:'company_id',
        min:0
      }),
      fields.custom({
        title: arabic_labels['form.zone'],
        dataIndex: 'zones',
        field: ZonesTreeSelect,
      }),
    ],
  },
}
