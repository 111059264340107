import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_ROLES,
  DELETE_ROLE,
  ADD_ROLE,
  UPDATE_ROLE,
} from '../../../../config/constants/ActionTypes'
import { getRolesFailure, getRolesSuccess, getRoles } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getRolesRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'roles',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
  })
}

function* getRolesSaga({ payload }) {
  try {
    const response = yield call(getRolesRequest, payload)
    yield put(getRolesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getRolesFailure(error))
    payload.onFailure(error)
  }
}

const deleteRoleRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'roles',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteRoleSaga({ payload }) {
  try {
    const response = yield call(deleteRoleRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addRoleRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'roles',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addRoleSaga({ payload }) {
  try {
    const response = yield call(addRoleRequest, payload)
    payload.onSuccess(path(['data', '_id'], response))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateRoleRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'roles',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateRoleSaga({ payload }) {
  try {
    const response = yield call(updateRoleRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getRoles(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_ROLES, getRolesSaga)],
    [yield takeEvery(DELETE_ROLE, deleteRoleSaga)],
    [yield takeEvery(ADD_ROLE, addRoleSaga)],
    [yield takeEvery(UPDATE_ROLE, updateRoleSaga)],
  )
}
