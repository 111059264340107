import { tableFields, fields, formFields } from '../schema'
import {
  getCategories,
  deleteCategory,
  addCategory,
  updateCategory,
} from '../../../modules/Category/store/actions'
import { getSections } from '../../../modules/Section/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const categories = {
  view: {
    get: getCategories,
    delete: deleteCategory,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.section'], 'section.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addCategory,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.textArea({
        title: arabic_labels['form.description'],
        dataIndex: 'description.ar',
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      formFields.select(
        'الفئة',
        'section',
        'default',
        { query: getSections },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'section',
        },
      ),
    ],
  },
  edit: {
    update: updateCategory,
    get: getCategories,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.description'],
        dataIndex: 'description',
        fields: [fields.textArea({ title: '', dataIndex: 'ar' })],
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.object({
        title: arabic_labels['form.section'],
        dataIndex: 'section',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getSections },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            {
              key: 'section',
            },
          ),
        ],
      }),
    ],
  },
}
