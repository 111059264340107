import { Statistic, Card, Icon } from 'antd'
import React from 'react'

export default function StatisticsCard({ loading, title, value }) {
  return (
    <div className='site-statistic-demo-card'>
      <Card style={{ direction: 'ltr', textAlign: 'center', borderRadius: '7%', padding: '6%' }}>
        {loading ? (
          <Icon type='loading' style={{ fontSize: 20, color: 'var(--color-secondary' }} spin />
        ) : (
          <Statistic
            title={title}
            value={value || 0}
            precision={2}
            valueStyle={{ color: '#4a7fbd', textAlign: 'center' }}
          />
        )}
      </Card>
    </div>
  )
}
