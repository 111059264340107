import React from 'react'
import { Icon } from 'antd'
import styled from 'styled-components'
import { FaSun, FaMoon } from 'react-icons/fa'
import { GlobalContext } from '../../../../GlobalState'
import avatar from '../../../../img/usermenu-profile.jpg'
import { connect } from 'react-redux'
import { userSignOut } from '../../../Auth/store/actions'

const UserMenu = (props) => {
  const { state, dispatch } = GlobalContext()

  return (
    <div>
      <UserMenuStyle>
        <img src={avatar} alt='profile' />
        <h1>{state.staff ? state.staff.name : ''}</h1>
        <UserMenuItem>
          <ul>
            <li
              onClick={() => {
                dispatch({ type: 'TOGGLE_THEME' })
              }}
            >
              <UserMenuItemName className="hvr-grow">
                {' '}
                {state.selectedTheme === 'light' ? 'المظهر الليلي' : 'المظهر النهاري'}{' '}
              </UserMenuItemName>
              <Icon> {state.selectedTheme === 'light' ? <FaMoon /> : <FaSun />} </Icon>

            </li>
          </ul>
        </UserMenuItem>
        <UserMenuItem>
          <ul>
            <li
              onClick={() => {
                localStorage.removeItem('token')
                props.dispatch(userSignOut())
              }}
            >
              <UserMenuItemName> تسجيل خروج </UserMenuItemName>  <Icon type='logout' />
            </li>
          </ul>
        </UserMenuItem>
      </UserMenuStyle>
    </div>
  )
}

const UserMenuStyle = styled.div`
  text-align: center;
  color: var(--color-font-secondary);
  margin-top: 28px;
  width: 249px;
  box-shadow: 0 4px 25px 0 rgba(0,0,0,0.1);
  position: relative;
  border-radius: 8px;
  right: 72px;
  padding: 24px;


  @media (max-width: 767px) {
    width: 180px;
    left: unset;
    right: 19px;
    margin: 30px 0px;

}


  background-color: var(--color-primary);
  .ant-dropdown-menu {
    border-radius: 0;
    background: transparent;

  }

  h1 {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 37px;
    margin-bottom: 40px;
    color: var(--color-font-secondary);

    @media (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 10px;
    }

  }

  p {
    font-size: 23px;
    margin-top: 56px;
    margin-bottom: 40px;
  }

  img {
    width: 92px;
    height: 92px;
    border-radius: 8px;
    box-shadow: 0 4px 25px 0 rgba(0,0,0,0.1);
    margin-top: 42px;

    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
`

const UserMenuItem = styled.div`
  li {
    list-style: none;
    border-bottom: 1px solid #ffffff17;
    margin-bottom: 0;
    line-height: 45px;
    cursor: pointer;
  }

  a {
    color: var(--color-font-secondary);
  }

  :hover {
    color: var(--color-secondary);
    transition: 0.3s;
  }

  ul {
    padding: 0;
  }

  i {
    font-size: 18px;
  }
`

const UserMenuItemName = styled.span`
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 15px;
  width: 50px;

  @media (max-width: 767px) {
    font-size: 16px;
    margin-right: 4px;
  }
`

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(UserMenu)
