import { tableFields, fields, formFields } from '../schema'
import {
  getPackages,
  deletePackage,
  addPackage,
  updatePackage,
} from '../../../modules/Package/store/actions'
import { getPackageUnits } from '../../../modules/PackageUnit/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'

export const packages = {
  view: {
    get: getPackages,
    delete: deletePackage,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name'),
      tableFields.text(arabic_labels['form.packageUnit'], 'packageUnit.name'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addPackage,
    fields: [
      fields.text({
        title: arabic_labels['form.name'],
        dataIndex: 'name',
        validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الاسم' : true),
      }),
      formFields.select(
        'وحدة البيع الفرعيه',
        'packageUnit',
        'default',
        { query: getPackageUnits },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'packageUnit'
        }
      ),
    ],
  },
  edit: {
    update: updatePackage,
    get: getPackages,
    fields: [
      fields.text({ title: arabic_labels['form.name'], dataIndex: 'name',
      validate: (fieldValue) => (!fieldValue ? 'برجاء إدخال الاسم' : true),
    }),
      fields.object({
        title: arabic_labels['form.packageUnit'],
        dataIndex: 'packageUnit',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getPackageUnits },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            {
              key: 'packageUnit'
            }
          ),
        ],
      }),
    ],
  },
}
