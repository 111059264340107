import {
  GET_REPS,
  GET_REPS_SUCCESS,
  GET_REPS_FAILURE,
  DELETE_REP,
  ADD_REP,
  UPDATE_REP,
  GET_REP,
} from '../../../../config/constants/ActionTypes'

//Get Reps Request
export const getReps = (payload) => {
  return {
    type: GET_REPS,
    payload,
  }
}

//Get Rep Request
export const getRep = (payload) => {
  return {
    type: GET_REP,
    payload,
  }
}

//Get Reps Success
export const getRepsSuccess = (reps) => {
  return {
    type: GET_REPS_SUCCESS,
    payload: { reps },
  }
}

//Get Reps Failure
export const getRepsFailure = (error) => {
  return {
    type: GET_REPS_FAILURE,
    payload: { error },
  }
}

//Delete Rep
export const deleteRep = (payload) => {
  return {
    type: DELETE_REP,
    payload,
  }
}

//Add Rep
export const addRep = (payload) => {
  return {
    type: ADD_REP,
    payload,
  }
}

//update Reps
export const updateRep = (payload) => {
  return {
    type: UPDATE_REP,
    payload,
  }
}
