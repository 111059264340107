import React, { useState, useEffect, forwardRef, Fragment , useCallback} from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import StopRoundedIcon from '@material-ui/icons/StopRounded'
import CropSquareRoundedIcon from '@material-ui/icons/CropSquareRounded'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import { equals, isNil } from 'ramda'

const CheckboxInput = forwardRef(({
  value,
  onMounted,
  onChange,
  dataIndex,
  readOnly,
  title,
  helperText,
  style,
}, ref) => {
  const [state, setState] = useState(false)
  const formControlLabelClasses = useFormControlLabelStyles()
  const checkBoxClasses = useCheckBoxStyles()
  const iconClasses = useIconStyles()

  useEffect(() => {
    if (!equals(value, state)) applyChange(!isNil(value))
  }, [value]) // eslint-disable-line

  useEffect(() => {
    if (onMounted) onMounted(dataIndex)
  }, [onMounted, dataIndex])

  const applyChange = useCallback((val) => {
    setState(val)
    if (onChange) onChange(val, dataIndex)
  }, [onChange, dataIndex])

  const onChildChange = useCallback((e) => {
    applyChange(e.target.checked)
  }, [applyChange])

  return (
    <Fragment>
      <FormControlLabel
        style={style}
        classes={formControlLabelClasses}
        control={
          <Checkbox
            classes={checkBoxClasses}
            checked={state}
            onChange={onChildChange}
            ref={ref}
            disabled={readOnly}
            icon={<CropSquareRoundedIcon className={iconClasses.root} />}
            checkedIcon={<StopRoundedIcon className={iconClasses.root} />}
            color='primary'
          />
        }
        label={title}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </Fragment>
  )
})

const useFormControlLabelStyles = makeStyles( theme => ({
  root: {
    width: '100%',
    margin: 0,
    paddingTop: 10,
  },
  label: {
    marginLeft: 5,
    paddingTop: 3,
    fontFamily: 'inherit',
    color: theme.palette.text.primary,
  }
}))

const useCheckBoxStyles = makeStyles(theme => ({
  root: {
    textTransform: 'uppercase',
    fontFamily: 'Cairo-Regular',
    fontWeight: 300,
    color: theme.palette.primary.main,
    // boxShadow: theme.shadows[3],
    borderRadius: 0,
    padding: 0,
  }

}))

const useIconStyles = makeStyles( theme => ({
  root: {
    borderRadius: 3,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
  }
}))

export default CheckboxInput
