/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import queryString from 'query-string'

const EditBuyerForm = ({ dispatch }) => {
  const { buyers } = config
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const query = queryString.parse(location.search, { arrayFormat: 'comma' })
  Object.entries(query).forEach(([key, value]) => {
    if (value === 'true') query[key] = true
    if (value === 'false') query[key] = false
  })

  useEffect(() => {
    setLoading(true)
    dispatch(
      config.buyers.roleEdit.get({
        query: { _id: id },
        onSuccess: onFetchSuccess,
        onFailure: onFetchFailure,
      }),
    )
  }, [])

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(buyers.roleEdit.update({
      query: {
        _id: query['distributors.distributor'],
      },
      body: {
      ...values,
      buyer: {
          _id: id,
          buyerCategory: values?.buyerCategory,
        },
      },
      onSuccess,
      onFailure
    }))
  }

  const onSuccess = () => {
    setSubmitting(false)
    message.success('تم التعديل بنجاح')
    history.push(`/buyers${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  const onFetchSuccess = (records) => {
    const fetchedRecord = records.data[Object.keys(records.data)[0]]
    const handshake = fetchedRecord.distributors.find(item => item.distributor._id === query['distributors.distributor'])
    const record = {
      buyerCategory: handshake?.buyerCategory
    }
    setLoading(false)
    setData(record)
  }

  const onFetchFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }

  return (
    <CrudForm
      title='تعديل المشتري'
      loading={loading}
      fields={config.buyers.roleEdit.fields}
      onSubmit={onSubmit}
      buttonText='تعديل'
      initialValues={data}
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(EditBuyerForm)
