import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_AD,
  GET_ADS,
  DELETE_AD,
  ADD_AD,
  EDIT_AD,
} from '../../../../config/constants/ActionTypes'
import {
  getAdsFailure,
  getAdsSuccess,
  getAdFailure,
  getAdSuccess,
  addAdSuccess,
  addAdFailure,
  editAdFailure,
} from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getAdsRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'commercials/',
    params: { ...query, isDeleted: false },
    headers: {
      token: `${localStorage.getItem('token')}`,
    },
  })
}

function* getAdsSaga({ payload }) {
  try {
    const response = yield call(getAdsRequest, payload)
    yield put(getAdsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getAdsFailure(error))
    payload.onFailure(error)
  }
}

const getAdRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'commercials/commercial',
    params: { _id: query._id},
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getAdSaga({ payload }) {
  try {
    const response = yield call(getAdRequest, payload)
    yield put(getAdSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getAdFailure(error))
    payload.onFailure(error)
  }
}

const deleteAdRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'commercials/',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteAdSaga({ payload }) {
  try {
    yield call(deleteAdRequest, payload)
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addAdRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'commercials/',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body , image: null },
  })
}

function* addAdSaga({ payload }) {
  try {
    const response = yield call(addAdRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'image'], payload))
    yield put(addAdSuccess(response.data.ad))
  } catch (error) {
    yield put(addAdFailure(error))
    payload.onFailure(error)
  }
}

const editAdRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'commercials/',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* editAdSaga({ payload }) {
  try {
    const response = yield call(editAdRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'image'], payload))
  } catch (error) {
    yield put(editAdFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_AD, getAdSaga)],
    [yield takeEvery(GET_ADS, getAdsSaga)],
    [yield takeEvery(DELETE_AD, deleteAdSaga)],
    [yield takeEvery(ADD_AD, addAdSaga)],
    [yield takeEvery(EDIT_AD, editAdSaga)],
  )
}
