import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { pluck } from 'ramda'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import { uploadImages } from '../../../../config/Client/actions/Upload'
import queryString from 'query-string'
import { path } from 'ramda'

const EditCompanyProductForm = ({ dispatch }) => {
  const { companyProducts } = config
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const query = queryString.parse(location.search)

  useEffect(() => {
    setLoading(true)
    dispatch(
      config.companyProducts.edit.get({
        query: { _id: id },
        onSuccess: onFetchSuccess,
        onFailure: onFetchFailure,
      }),
    )
  }, []) // eslint-disable-line

  const onSubmit = (values) => {
    setSubmitting(true)
    values.tags = values?.tags?._id
    dispatch(uploadImages({
      values,
      imageArray: values.images,
      moduleName: 'companyProducts',
      _id: id,
      onSuccess: onUploadSuccess,
      onFailure,
    }))
  }

  const onUploadSuccess = (imageLinkArray, values) => {
    values.images = imageLinkArray
    dispatch(companyProducts.edit.update({ body: { ...values, _id: id }, onSuccess, onFailure }))
  }

  const onSuccess = () => {
    setSubmitting(false)
    message.success('تم التعديل بنجاح')
    history.push(`/companyProducts${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  const onFetchSuccess = (records) => {
    setLoading(false)
    const fetchedRecord = records.data[0]
    const record = {
      ...fetchedRecord,
      tags: { _id: pluck('_id', fetchedRecord.tags) },
      sizes: {
        size: fetchedRecord?.sizes?.size?._id,
        quantity: fetchedRecord?.sizes?.quantity,
      },
    }
    if (query['company'] && path(['company', '_id'], record) !== query['company']) {
      return history.push('/companies')
    }
    if (record.buyerCategories) record.buyerCategories.forEach(buyerCategory => {
      if (buyerCategory.saleUnit.packageQuantity) buyerCategory.saleUnit.packageQuantity = Number(buyerCategory.saleUnit.packageQuantity)
      if (buyerCategory.saleUnit.price) buyerCategory.saleUnit.price = Number(buyerCategory.saleUnit.price)
    })
    if (record?.sizes?.quantity) record.sizes.quantity = Number(record.sizes.quantity)
    setData(record)
  }

  const onFetchFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }
  return (
    <CrudForm
      title='تعديل منتج الشركة'
      loading={loading}
      fields={config.companyProducts.edit.fields}
      onSubmit={onSubmit}
      buttonText='تعديل'
      initialValues={data}
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(EditCompanyProductForm)
