import { tableFields } from '../schema'
import { getPendingBuyers } from '../../../modules/PendingBuyers/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import RejectBuyerButton from '../../../modules/PendingBuyers/components/RejectBuyerButton'
import AcceptBuyerButton from '../../../modules/PendingBuyers/components/AcceptBuyerButton'
const logo = require('../../../img/logo-new.png')

export const pendingBuyers = {
  view: {
    get: getPendingBuyers,
    fields: [
      tableFields.image(arabic_labels['form.logo'], 'images[0]', (value) => value || logo),
      tableFields.text(arabic_labels['form.accountPhoneNumber'], 'accountPhoneNumber'),
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.manager'], 'manager_name.ar'),
      tableFields.text(arabic_labels['form.institution.activity'], 'activity',(value) =>Array.isArray(value)?value.join('-'):value),
      tableFields.text(arabic_labels['form.institution.phone'], 'phone'),
      tableFields.action.custom(arabic_labels['status.accept'], '', AcceptBuyerButton),
      tableFields.action.custom(arabic_labels['status.reject'], '', RejectBuyerButton),
    ],
  },
}
