import {
  GET_PRICELISTS_SUCCESS,
  GET_PRICELISTS_FAILURE,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PRICELISTS_SUCCESS: {
      return {
        ...state,
        priceLists: action.payload.priceLists,
      }
    }

    case GET_PRICELISTS_FAILURE: {
      return {
        ...state,
        getPriceListsError: action.payload.error,
      }
    }

    case SEARCH_SUCCESS: {
      return {
        ...state,
        searchItems: action.payload.items,
      }
    }

    case SEARCH_FAILURE: {
      return {
        ...state,
        getSearchError: action.payload.error,
      }
    }

    default:
      return state
  }
}
