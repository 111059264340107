import {
  GET_BUYERS,
  GET_BUYERS_SUCCESS,
  GET_BUYERS_FAILURE,
  DELETE_BUYER,
  ADD_BUYER,
  UPDATE_BUYER,
  UPDATE_BUYER_HANDSHAKE,
} from '../../../../config/constants/ActionTypes'

//Get BUYERS Request
export const getBuyers = (payload) => {
  return {
    type: GET_BUYERS,
    payload,
  }
}

//Get Buyers Success
export const getBuyersSuccess = (buyers) => {
  return {
    type: GET_BUYERS_SUCCESS,
    payload: { buyers },
  }
}

//Get Buyers Failure
export const getBuyersFailure = (error) => {
  return {
    type: GET_BUYERS_FAILURE,
    payload: { error },
  }
}

//Delete Buyer
export const deleteBuyer = (payload) => {
  return {
    type: DELETE_BUYER,
    payload,
  }
}

//Add Buyer
export const addBuyer = (payload) => {
  return {
    type: ADD_BUYER,
    payload,
  }
}

//update Buyer
export const updateBuyer = (payload) => {
  return {
    type: UPDATE_BUYER,
    payload,
  }
}

//update Buyer's handshake
export const updateBuyerHandshake = (payload) => {
  return {
    type: UPDATE_BUYER_HANDSHAKE,
    payload,
  }
}
