import React from 'react'
// import { client } from '../../../../config/Client'
import LoginForm from '../LoginForm'
import { connect } from 'react-redux'
import { userSignIn } from '../../store/actions'

const LoginBox = (props) => {
  //   useEffect(() => {
  //     if (prop('getCurrentStaff', currentStaffData)) {
  //       dispatch({
  //         type: 'LOGIN',
  //         payload: {
  //           token: prop('login', loginData),
  //           staff: prop('getCurrentStaff', currentStaffData),
  //         },
  //       })
  //       history.push('/products')
  //     }
  //   }, [currentStaffData]) // eslint-disable-line

  //   useEffect(() => {
  //     if (prop('login', loginData)) {
  //       localStorage.setItem('token', prop('login', loginData))
  //       client.setHeader('authorization', `Bearer ${prop('login', loginData)}`)
  //       getCurrentStaff()
  //     }
  //   }, [loginData]) // eslint-disable-line

  const onLogin = async (email, password, type, accountPhoneNumber, onSuccess, onFailure) => {
    props.userSignIn(email, password, type, accountPhoneNumber, onSuccess, onFailure)
  }

  return <LoginForm onSubmit={onLogin} loading={false} />
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth
  return { token }
}

export default connect(
  mapStateToProps,
  { userSignIn },
)(LoginBox)
