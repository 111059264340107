import {
  GET_ORDERS_REPORT_SUCCESS,
  GET_ORDERS_REPORT_FAILURE,
  GET_ORDERS_CONSTANTS_SUCCESS,
  GET_ORDERS_CONSTANTS_FAILURE,
  GET_UNITS_REPORT_SUCCESS,
  GET_UNITS_REPORT_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ORDERS_REPORT_SUCCESS: {
      return {
        ...state,
        orders: action.payload.orders,
      }
    }

    case GET_ORDERS_REPORT_FAILURE: {
      return {
        ...state,
        getOrdersError: action.payload.error,
      }
    }
    case GET_ORDERS_CONSTANTS_SUCCESS: {
      return {
        ...state,
        orders: action.payload.orders,
      }
    }

    case GET_ORDERS_CONSTANTS_FAILURE: {
      return {
        ...state,
        getOrdersConstantsError: action.payload.error,
      }
    }
    case GET_UNITS_REPORT_SUCCESS: {
      return {
        ...state,
        units: action.payload.orders,
      }
    }

    case GET_UNITS_REPORT_FAILURE: {
      return {
        ...state,
        getUnitsError: action.payload.error,
      }
    }
    default:
      return state
  }
}
