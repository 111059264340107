import {
  GET_REPS_SUCCESS,
  GET_REPS_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_REPS_SUCCESS: {
      return {
        ...state,
        reps: action.payload.reps,
      }
    }

    case GET_REPS_FAILURE: {
      return {
        ...state,
        getRepsError: action.payload.error,
      }
    }

    default:
      return state
  }
}
