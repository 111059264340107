import {
  GET_COUNTIES,
  GET_COUNTIES_SUCCESS,
  GET_COUNTIES_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Get Countries Request
export const getCountries = (payload) => {
  return {
    type: GET_COUNTIES,
    payload,
  }
}

//Get Countries Success
export const getCountriesSuccess = (countries) => {
  return {
    type: GET_COUNTIES_SUCCESS,
    payload: { countries },
  }
}

//Get Countries Failure
export const getCountriesFailure = (error) => {
  return {
    type: GET_COUNTIES_FAILURE,
    payload: { error },
  }
}