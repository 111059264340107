/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { pathOr, path, isEmpty } from 'ramda'
import { message } from 'antd'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { getCompany } from '../../store/actions'
import arabic_labels from '../../../../config/constants/arabic_labels.json'
import Table from '../../../View/components/Table'
import AddButton from '../../../Form/components/AddButton'

const CompanyPage = ({ dispatch }) => {
  const { id } = useParams()
  const [currentActive, setCurrentActive] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    getRecords(id)
  }, []) // eslint-disable-line

  const getRecords = (itemId) => {
    dispatch(getCompany({ query: { _id: itemId }, onSuccess, onFailure }))
  }

  const onSuccess = (data) => {
    setLoading(false)
    setData(data[0])
  }

  const onFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }

  const zonesColumns = [
    {
      title: 'المنطقة',
      dataIndex: 'name.ar',
      key: 'name.ar',
      type: 'text',
    },
  ]

  const citiesColumns = [
    {
      title: 'المدينة',
      dataIndex: 'name',
      key: 'name',
      type: 'text',
    },
  ]

  const districtColumns = [
    {
      title: 'الحي',
      dataIndex: 'name.ar',
      key: 'name.ar',
      type: 'text',
    },
  ]

  if (loading) return <LinearProgress />
  return (
    <div>
      <div className='row'>
        <div className='col-12 col-9-md'>
          <MyTabs className='row'>
            <Tab
              className={`${currentActive === 1 &&
                'active'} col-4  is-vertical-align is-horizontal-align`}
              onClick={() => setCurrentActive(1)}
            >
              {arabic_labels['notification.info']}
            </Tab>
            <Tab
              className={`${currentActive === 2 &&
                'active'} col-4 is-vertical-align is-horizontal-align`}
              onClick={() => setCurrentActive(2)}
            >
              {arabic_labels['form.address']}
            </Tab>
          </MyTabs>
          <TabContent className={`${currentActive !== 1 && 'is-hidden'}`}>
            <div className='row'>
              <div className='col-12 col-4-md part'>
                <p className='title'>{arabic_labels['form.name']}</p>
                <p className='value'>{pathOr('-', ['name', 'ar'], data)}</p>
              </div>
              <br />
              <div className='col-12 col-4-md part'>
                <p className='title'>{arabic_labels['form.email']}</p>
                <p className='value'>{pathOr('-', ['email'], data)}</p>
              </div>
              <br />
              <br />
              <div className='col-12 col-4-md part'>
                <p className='title'>{arabic_labels['appModule.phone']}</p>
                <p className='value'>{pathOr('-', ['phone', '0'], data)}</p>
              </div>
              <br />
            </div>
            <br />
            <div className='row'>
              <div className='col-12 col-8-md part'>
                <p className='title'>{'الصناعة'}</p>
                <p className='value'>
                  {path(['section'], data)?.length > 0
                    ? path(['section'], data)
                      .reduce((acc, el) => `${acc}، ${path(['name', 'ar'], el)}`, '')
                      .slice(2)
                    : '-'}
                </p>
              </div>
              <br />
              <div className='col-12 col-4-md part'>
                <p className='title'>{'الإشتراك'}</p>
                <p className='value'>{pathOr('-', ['subscription', 'name', 'ar'], data)}</p>
              </div>
            </div>
          </TabContent>
          <TabContent className={`${currentActive !== 2 && 'is-hidden'}`}>
            <div className='row'>
              <div className='col-12 col-3-md part'>
                <p className='title'>{'نوع العنوان'}</p>
                <p className='value'>{pathOr('-', ['address', '0', 'type'], data)}</p>
              </div>
              <div className='col-12 col-3-md part'>
                <p className='title'>{'المدينة'}</p>
                <p className='value'>{pathOr('-', ['address', '0', 'city', 'name'], data)}</p>
              </div>
              <div className='col-12 col-3-md part'>
                <p className='title'>{'الحي'}</p>
                <p className='value'>{pathOr('-', ['address', '0', 'district', 'name', 'ar'], data)}</p>
              </div>
              <div className='col-12 col-3-md part'>
                <p className='title'>{'المنطقة'}</p>
                <p className='value'>{pathOr('-', ['address', '0', 'zone', 'name', 'ar'], data)}</p>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12 col-3-md part'>
                <p className='title'>{'الشارع'}</p>
                <p className='value'>{!isEmpty(path(['address', '0', 'street'], data)) ? pathOr('-', ['address', '0', 'street'], data) : '-'}</p>
              </div>
              <div className='col-12 col-3-md part'>
                <p className='title'>{'رقم المبنى'}</p>
                <p className='value'>{!isEmpty(path(['address', '0', 'buildingNumber'], data)) ? pathOr('-', ['address', '0', 'buildingNumber'], data) : '-'}</p>
              </div>
              <div className='col-12 col-3-md part'>
                <p className='title'>{'تليفون العنوان'}</p>
                <p className='value'>{!isEmpty(path(['address', '0', 'phone'], data)) ? pathOr('-', ['address', '0', 'phone'], data) : '-'}</p>
              </div>
              <div className='col-12 col-3-md part'>
                <p className='title'>{'خرائط جوجل'}</p>
                <p className='value'>{!isEmpty(path(['address', '0', 'googleMapLocation'], data)) ? pathOr('-', ['address', '0', 'googleMapLocation'], data) : '-'}</p>
              </div>
            </div>
          </TabContent>
          <TabContent className={`${currentActive !== 3 && 'is-hidden'}`}>
            <div className='row'>
              <div className='col-12 col-12-md part'>
                <p className='title'>{arabic_labels['form.buyer']}</p>
                <p className='value'>
                  <strong className='title'>{arabic_labels['form.name'] + ' '}</strong>
                  {pathOr(' - ', ['user', 'name'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.phoneNumber'] + ' '}</strong>
                  {pathOr(' - ', ['user', '[phone]'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.email'] + ' '}</strong>
                  {pathOr(' - ', ['user', 'email'], data) + ' '}
                </p>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12 part'>
                <p className='title'>{arabic_labels['form.sellerRef']}</p>
                <p className='value'>
                  <strong className='title'>{arabic_labels['form.name'] + ' '}</strong>
                  {pathOr(' - ', ['user', 'name'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.phoneNumber'] + ' '}</strong>
                  {pathOr(' - ', ['user', '[phone]'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.email'] + ' '}</strong>
                  {pathOr(' - ', ['user', 'email'], data) + ' '}
                  <strong className='title'>{arabic_labels['form.sellerType'] + ' '}</strong>
                  {pathOr(' - ', ['user', 'type'], data) + ' '}
                </p>
              </div>
            </div>
          </TabContent>
        </div>
        <div className='col-12 col-3-md'>
          <div className='row'>
            <div className='col-12'>
              <AddButton
                style={{ height: '4.75rem', width: '100%' }}
                text='تعديل'
                onClick={() => history.push(`/companies/edit/${id}`)}
              />
            </div>
          </div>
          <TabContent>
            <div className='row'>
              <div className='col-12 part'>
                <p className='title'>
                  <SpanLink to={`/companyProducts/new?company=${id}`} className='main'>
                    {'إضافة منتج'}
                  </SpanLink>
                  <SpanLink to={`/companyProducts?company=${id}`} className='view-main pull-left'>
                    عرض المنتجات
                  </SpanLink>
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 part'>
                <p className='title'>
                  <SpanLink to={`/distributors/new?company=${id}`} className='main'>
                    {'إضافة موزع'}
                  </SpanLink>
                  <SpanLink to={`/distributors?company=${id}`} className='view-main pull-left'>
                    عرض الموزعين
                  </SpanLink>
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 part'>
                <p className='title'>
                  <SpanLink to={`/brands/new?company=${id}`} className='main'>
                    {'إضافة ماركة'}
                  </SpanLink>
                  <SpanLink to={`/brands?company=${id}`} className='view-main pull-left'>
                    عرض الماركات
                  </SpanLink>
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 part'>
                <p className='title'>
                  <SpanLink
                    to={`/staffs/new?sellerCategory=companies/getCompanies&sellerRef=${id}`}
                    className='main'
                  >
                    {'إضافة عامل'}
                  </SpanLink>
                  <SpanLink to={`/staffs?sellerCategory=companies/getCompanies&sellerRef=${id}`} className='view-main pull-left'>
                    عرض طاقم العمل
                  </SpanLink>
                </p>
              </div>
            </div>
          </TabContent>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-4-md part'>
          <TableStyle columnsCount={citiesColumns.length}>
            <Table
              scroll={true}
              data={path(['zones', 'city'], data)}
              headers={citiesColumns}
              componentName={'order'}
              loading={false}
            />
          </TableStyle>
        </div>
        <div className='col-12 col-4-md part'>
          <TableStyle columnsCount={districtColumns.length}>
            <Table
              scroll={true}
              data={path(['zones', 'district'], data)}
              headers={districtColumns}
              componentName={'order'}
              loading={false}
            />
          </TableStyle>
        </div>
        <div className='col-12 col-4-md part'>
          <TableStyle columnsCount={zonesColumns.length}>
            <Table
              scroll={true}
              data={path(['zones', 'zone'], data)}
              headers={zonesColumns}
              componentName={'order'}
              loading={false}
            />
          </TableStyle>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(CompanyPage)

const MyTabs = styled.div`

    .active  {
      border-bottom: 0;
     color: var(--color-font-white) !important;
        background-color: var(--color-secondary) ;
      :hover  {
        color: var(--color-font-white) !important;
        background-color: var(--color-secondary) ;
      }
  
    }
  
    .false:hover {
      border-bottom: 0;
     color: var(--color-font-white) !important;
        background-color: var(--color-secondary) ;
    }

  }
`

const SpanLink = styled(Link)`
  cursor: pointer;
  color: var(--color-font-secondary);
  :hover {
    color: var(--color-secondary);
  }
`

const Tab = styled.div`
  height: 4.75rem;
  background: var(--color-primary);
  cursor: pointer;
  border-radius: 0.5rem;
  border: 0;
  color: var(--color-font-secondary);
  text-transform: uppercase;
  font-size: 1.3rem;
  @media (max-width: 767px) {
    font-size: 15px;
  }

  :hover {
    color: var(--color-secondary);
  }
`

const TabContent = styled.div`
  background: var(--color-primary);
  border-radius: 0.5rem;
  border: 0;
  color: var(--color-font-secondary);
  text-transform: uppercase;
  padding: 1.5rem;

  .part {
    margin-left: 10px;

    .title {
      color: var(--color-custom);

      font-size: 18px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        line-height: 32px;
      }

      a.main {
        color: var(--color-custom);
      }
    }

    .value {
      color: var(--color-font-secondary);
      font-size: 17px;
      font-weight: 300;
      text-transform: lowercase;
      line-height: 1.2rem;
    }
  }

  hr {
    margin: 0.65rem 1rem;
    height: 1px;
    color: #82b3d626;
    background: #82b3d626;
  }
`

const TableStyle = styled.div`
  overflow-x: auto;
  th, td {
    min-width: 200px;
    /* width: ${(props) => 100 / props.columnsCount}vw; */
  }

`
