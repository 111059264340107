import {
  GET_SETTING,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  UPDATE_SETTING,
} from "../../../../config/constants/ActionTypes";

//Get setting Request
export const getSetting = (payload) => {
  return {
    type: GET_SETTING,
    payload,
  };
};

//Get types Success
export const getSettingsSuccess = (settings) => {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: { settings },
  };
};

//Get types Failure
export const getSettingsFailure = (error) => {
  return {
    type: GET_SETTINGS_FAILURE,
    payload: { error },
  };
};

//update Setting
export const updateSetting = (payload) => {
  return {
    type: UPDATE_SETTING,
    payload,
  };
};
