import config from '../config/Client/sagas/config'
import axios from 'axios'
import get from 'lodash/get'
import { message } from 'antd'
import { path } from 'ramda'

export function handleFormSubmit({
  form,
  action,
  type,
  _id,
  closeModal,
  refreshRoute,
  priceLists,
}) {
  form.validateFields((err, fieldsValue) => {
    if (err) {
      return
    }
    if (fieldsValue.newPass !== fieldsValue.confirmPass) {
      return
    }
    if (type === 'companyProducts') {
      if (fieldsValue.buyerCategories)
        fieldsValue.buyerCategories = toArray(fieldsValue.buyerCategories)
    }
    if (type === 'companies' || type === 'reps') {
      if (fieldsValue.distributors) fieldsValue.distributors = toArray(fieldsValue.distributors)
      if (fieldsValue.reps) fieldsValue.reps = toArray(fieldsValue.reps)
    }
    if (type === 'distributors' || type === 'reps') {
      if (fieldsValue.reps) fieldsValue.reps = toArray(fieldsValue.reps)
      if (fieldsValue.companies) fieldsValue.companies = toArray(fieldsValue.companies)
    }
    if (type === 'priceLists') {
      fieldsValue.priceLists = priceLists
    }
    let payload = {
      type: type,
      value: fieldsValue,
      _id: _id,
      closeModal,
      refreshRoute: refreshRoute,
    }
    action(payload)
    form.resetFields()
  })
}

export function replaceUndefinedsWithNulls(value) {
  for (let index = 0; index < Object.keys(value).length; index++) {
    const key = Object.keys(value)[index]
    if (value[key] === undefined) value[key] = null
  }
}

export function handleSubmitWithStaff({ method, form, type, sellerCategory, context, _id }) {
  form.validateFields((err, fieldsValue) => {
    if (err) {
      return
    }
    if (type === 'companies') {
      if (fieldsValue.distributors) fieldsValue.distributors = toArray(fieldsValue.distributors)
      if (fieldsValue.reps) fieldsValue.reps = toArray(fieldsValue.reps)
    }
    if (type === 'distributors') {
      if (fieldsValue.reps) fieldsValue.reps = toArray(fieldsValue.reps)
      if (fieldsValue.companies) fieldsValue.companies = toArray(fieldsValue.companies)
    }
    replaceUndefinedsWithNulls(fieldsValue)
    axios({
      method,
      url: config.url + type,
      params: {
        _id: method === 'PUT' ? _id : undefined,
        token: localStorage.getItem('token'),
      },
      data: fieldsValue,
    })
      .then((response) => {
        context.props.history.push({
          pathname: '/add/staffs',
          state: {
            sellerCategory,
            sellerRef: response.data._id,
          },
        })
      })
      .catch(function (error) {})
    form.resetFields()
  })
}

export function handleSubmitStaff({ method, form, context, _id }) {
  form.validateFields((err, fieldsValue) => {
    if (err) {
      return
    }
    message.loading('', 0)
    replaceUndefinedsWithNulls(fieldsValue)
    axios({
      method: method,
      url: config.url + 'staffs',
      params: {
        _id: method === 'PUT' ? _id : undefined,
        token: localStorage.getItem('token'),
      },
      data: fieldsValue,
    })
      .then((response) => {
        axios({
          method: 'PUT',
          url: config.url + plural(fieldsValue.sellerCategory) + '/staff',
          params: {
            _id: fieldsValue.sellerRef._id,
            token: localStorage.getItem('token'),
          },
          data: {
            staff: response.data._id,
            role: fieldsValue.role,
            isAdmin: fieldsValue.isAdmin,
          },
        }).then((response2) => {
          message.destroy()
          message.success(get(response, 'data.message'))
          message.success(get(response2, 'data.message'))
          context.props.history.push({
            pathname: '/list/staffs',
          })
        })
      })
      .catch(function (error) {
        message.destroy()
        message.error(get(error, 'response.data.error', 'Something Went Wrong!'))
      })
    // form.resetFields();
  })
}

export function getOldPrice(productId, getFieldValue, array, quantity, packageType) {
  let product = getObjectWithId(productId, array)
  let oldPrice = get(product, 'buyerCategories', []).find(
    (buyerCategory) =>
      getFieldValue === get(buyerCategory, 'buyerCategory._id', 'buyerCategory.buyerCategory')
  )
  oldPrice = get(oldPrice, 'saleUnit.price', 0)

  if (packageType === 'unit') {
    if (
      !isNaN(
        (get(oldPrice[0], 'saleUnit.price') / get(oldPrice[0], 'saleUnit.packageQuantity')) *
          Number(quantity)
      )
    ) {
      return String(
        (get(oldPrice[0], 'saleUnit.price') / get(oldPrice[0], 'saleUnit.packageQuantity')) *
          Number(quantity)
      )
    } else {
      return 'جاري الحساب ...'
    }
  } else {
    if (!isNaN(get(oldPrice[0], 'saleUnit.price') * Number(quantity)))
      return String(get(oldPrice[0], 'saleUnit.price') * Number(quantity))
    else {
      return 'جاري الحساب ...'
    }
  }
}

export function getNewPrice(productId, getFieldValue, array, quantity, packageType, discount) {
  let discountFraction = 1 - Number(discount) / 100
  let product = getObjectWithId(productId, array)
  let oldPrice = get(product, 'buyerCategories', []).find(
    (buyerCategory) =>
      getFieldValue === get(buyerCategory, 'buyerCategory._id', 'buyerCategory.buyerCategory')
  )
  oldPrice = oldPrice.saleUnit.price

  if (packageType === 'unit') {
    if (
      !isNaN(get(oldPrice[0], 'saleUnit.price') / get(oldPrice[0], 'saleUnit.packageQuantity')) *
      Number(quantity) *
      discountFraction
    ) {
      return String(
        (get(oldPrice[0], 'saleUnit.price') / get(oldPrice[0], 'saleUnit.packageQuantity')) *
          Number(quantity) *
          discountFraction
      )
    } else {
      return 'جاري الحساب ...'
    }
  } else {
    if (!isNaN(get(oldPrice[0], 'saleUnit.price') * Number(quantity) * discountFraction))
      return String(get(oldPrice[0], 'saleUnit.price') * Number(quantity) * discountFraction)
    else {
      return 'جاري الحساب ...'
    }
  }
}

export function requestWithToken({
  addedDistributors,
  updatedDistributors,
  deletedDistributors,
  addedCompanies,
  updatedCompanies,
  deletedCompanies,
  addedReps,
  updatedReps,
  deletedReps,
  distributors,
  offer_id,
  zones,
  noChangeInAddOns,
  images,
  companyId,
  companies,
  reps,
  getGeneric,
  productId,
  type,
  method,
  _id,
  form,
  element,
}) {
  return new Promise(function (resolve, reject) {
    form.validateFields((err, fieldsValue) => {
      if (err) {
        console.log(err)
        return
      }
      message.loading('', 0)
      if (type === 'companyProducts') {
        if (fieldsValue.buyerCategories)
          fieldsValue.buyerCategories = toArray(fieldsValue.buyerCategories)
      }
      if (noChangeInAddOns) fieldsValue.addOnProducts = []
      if (companyId) fieldsValue.companyId = companyId
      if (offer_id) fieldsValue.offer_id = offer_id
      if (zones) fieldsValue.zones = zones
      if (images) fieldsValue.images = images
      if (companies) fieldsValue.companies = companies
      if (distributors) fieldsValue.distributors = distributors
      if (reps) fieldsValue.reps = reps
      if (fieldsValue.startDate) {
        fieldsValue.startDate = fieldsValue.startDate.format('YYYY/MM/DD')
      }
      if (fieldsValue.endDate) {
        fieldsValue.endDate = fieldsValue.endDate.format('YYYY/MM/DD')
      }
      if (productId) fieldsValue.productId = productId
      if (addedCompanies) fieldsValue.addedCompanies = addedCompanies
      if (updatedCompanies) fieldsValue.updatedCompanies = updatedCompanies
      if (deletedCompanies) fieldsValue.deletedCompanies = deletedCompanies

      if (addedDistributors) fieldsValue.addedDistributors = addedDistributors
      if (updatedDistributors) fieldsValue.updatedDistributors = updatedDistributors
      if (deletedDistributors) fieldsValue.deletedDistributors = deletedDistributors
      if (addedReps) {
        fieldsValue.addedReps = addedReps
      }
      if (updatedReps) {
        fieldsValue.updatedReps = updatedReps
      }
      if (deletedReps) {
        fieldsValue.deletedReps = deletedReps
      }

      replaceUndefinedsWithNulls(fieldsValue)

      axios({
        method: method,
        url: config.url + type,
        params: {
          _id: method === 'PUT' ? _id : undefined,
          token: localStorage.getItem('token'),
        },
        data: fieldsValue,
      })
        .then((response) => {
          form.resetFields()
          message.destroy()
          message.success(get(response, 'data.message'))
          if (type.includes('companyProducts/')) {
            getGeneric('companyProducts')
          } else {
            getGeneric(type)
          }
          return resolve(response.data)
        })
        .catch(function (error) {
          reject(error)
          handleError(error)
        })
    })
  })
}

export function updateObject({ type, _id, body }) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      url: config.url + type,
      params: {
        _id: _id,
        token: localStorage.getItem('token'),
      },
      data: body,
    })
      .then((response) => {
        message.destroy()
        message.success(get(response, 'data.message'))
        return resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
        handleError(error)
      })
  })
}

export function getObjectWithToken({ type, _id }) {
  return axios({
    method: 'GET',
    url: config.url + type,
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      handleError(error)
    })
}

export function toSingular(string) {
  let result = string.toLowerCase()
  result = capitalizeFirstLetter(result)
  if (string.charAt(string.length - 3) === 'i' && string.charAt(string.length - 2) === 'e') {
    result = result.substring(0, result.length - 3)
    result += 'y'
  } else result = result.substring(0, result.length - 1)
  return result
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
}

export function toArray(obj) {
  var array = []
  for (let index = 0; index < Object.keys(obj).length; index++) {
    const key = Object.keys(obj)[index]
    if (key === index && get(Object.keys(obj[key]), 'length', 0) > 0) array.push(obj[key])
  }
  return array
}

export function addRow({ setFieldsValue, getFieldValue, staffs }) {
  let array = getFieldValue(staffs)
  setFieldsValue({
    [staffs + 'Count']: getFieldValue(staffs + 'Count') + 1,
  })
  array[getFieldValue(staffs + 'Count')] = {}
  setFieldsValue({
    [staffs]: array,
  })
}

export function removeRow({ setFieldsValue, getFieldValue, staffs }) {
  let array = getFieldValue(staffs)
  setFieldsValue({
    [staffs + 'Count']: getFieldValue(staffs + 'Count') - 1,
  })
  array[getFieldValue(staffs + 'Count')] = {}
  setFieldsValue({
    [staffs]: array,
  })
}

export function clearUnselectedZones({ setFieldsValue, getFieldValue, staffs }) {
  for (let i = 0; i < new Array(getFieldValue(staffs + 'Count')).fill(0).length; i++) {
    if (!getFieldValue(staffs + '[' + i + '].zones')) return
    for (let j = 0; j < getFieldValue(staffs + '[' + i + '].zones').length; j++) {
      let found = false
      const staffZone = getFieldValue(staffs + '[' + i + '].zones')[j]
      for (let index = 0; index < getFieldValue('zones.zone').length; index++) {
        const zone = getFieldValue('zones.zone')[index]
        if (staffZone === zone) found = true
      }
      if (!found) {
        let zones = getFieldValue(staffs + '[' + i + '].zones')
        zones.splice(j, 1)
        setFieldsValue({
          [staffs + '[' + i + '].zones']: zones,
        })
      }
    }
  }
}

export function clearUnselectedStaffs({
  setFieldsValue,
  getFieldValue,
  staffs,
  staff,
  reduxStaffs,
}) {
  for (let i = 0; i < new Array(getFieldValue(staffs + 'Count')).fill(0).length; i++) {
    let staffObj = getObjectWithId(getFieldValue(staffs + '[' + i + '].' + staff), reduxStaffs)
    for (let j = 0; j < get(staffObj, 'zones', []).length; j++) {
      let found = false
      const staffZone = staffObj.zones[j]
      if (getFieldValue(staffs + '[' + i + '].zones').length > 0) return
      for (let index = 0; index < get(getFieldValue('zones.zone'), 'length', 0); index++) {
        const zone = getFieldValue('zones.zone')[index]
        if (get(staffZone, '_id', staffZone) === zone) found = true
      }
      if (!found) {
        setFieldsValue({
          [staffs + '[' + i + '].' + staff]: '',
        })
      }
    }
  }
}

export function getObject(objectName, params) {
  return axios(config.url + objectName, {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('token'),
    },
    params,
  })
}

export function onZonesChange({ setFieldsValue, getFieldValue, staffs, staff, reduxStaffs }) {
  let staffsArray = getFieldValue(staffs)
  let zones

  getFieldValue('zones.country')
    ? (zones = getFieldValue('zones.country'))
    : getFieldValue('zones.zone')

  if (!staffsArray) staffsArray = {}
  if (get(zones, 'length') > 0) {
    staffsArray[0] = {}
  } else staffsArray = {}
  setFieldsValue({
    [staffs]: staffsArray,
  })
  if (Object.keys(staffsArray).length === 0)
    setFieldsValue({
      [staffs + 'Count']: 0,
    })
  else {
    if (getFieldValue(staffs + 'Count') === 0)
      setFieldsValue({
        [staffs + 'Count']: 1,
      })
    else
      setFieldsValue({
        [staffs + 'Count']: getFieldValue(staffs + 'Count'),
      })
  }
  clearUnselectedZones({
    setFieldsValue,
    getFieldValue,
    staffs,
  })
  clearUnselectedStaffs({
    setFieldsValue,
    getFieldValue,
    staffs,
    staff,
    reduxStaffs,
  })
}

export function getObjectWithId(id, array) {
  for (let i = 0; i < get(array, 'length', 0); i++) {
    if (array[i].company && array[i].company._id === id) {
      return array[i]
    } else if (id === array[i]._id) {
      return array[i]
    }
  }
}

export function plural(string) {
  let result = string.toLowerCase()
  if (string.charAt(string.length - 1) === 'y') {
    result = result.substring(0, result.length - 1)
    result += 'ie'
  }
  result += 's'
  return result
}

export async function getBuyerCategoryObject(id) {
  let buyerCat = {}
  await getObject('buyerCategories/buyerCategory', { _id: id }).then((res) => {
    buyerCat = res.data
  })
  return buyerCat
}

export async function getProductObject(id) {
  let buyerCat = {}
  await getObject('companyProducts/product', { _id: id }).then((res) => {
    buyerCat = res.data
  })
  return buyerCat
}

export function getObjectsOfIds(arrayofIds, arrayOfObjects) {
  let result = []
  for (let i = 0; i < arrayofIds.length; i++) {
    const id = arrayofIds[i]
    for (let index = 0; index < arrayOfObjects.length; index++) {
      const object = arrayOfObjects[index]
      if (get(object, '_id') === id) {
        result.push(object)
      }
    }
  }
  return result
}

export function deleteImageFromS3(image) {
  return axios.post(config.url + 'images/delete', {
    imageUrl: image,
  })
}

export function removeUndefineds(array) {
  let filtered = array.filter(function (entry) {
    return entry != null
  })
  return filtered
}

export function handleError(error) {
  message.destroy()
  if (error.response && typeof error.response.statusText === 'string') {
    message.error(error.response.data.error)
  } else if (error.request && typeof error.request === 'string') {
    message.error(error.request)
  } else if (error.message && typeof error.message === 'string') {
    message.error('No internet connection!')
  } else {
    message.error('Something Went Wrong!')
  }
}

export function getStatusFromStatusNumber(statusNumber) {
  let mapStatus = {
    '0': {
      status: 'Pending',
      arabicStatus: 'status.pending',
      btnAction: 'Accept/Reject',
      arabicBtnAction: 'status.accept/reject',
      arabicBtnAction1: 'status.accept',
      arabicBtnAction2: 'status.reject',
    },
    '1': {
      status: 'Processing',
      arabicStatus: 'status.processing',
      btnAction: 'Pack',
      arabicBtnAction: 'status.pack',
    },
    '2': {
      status: 'Ready to ship',
      arabicStatus: 'status.readyToShip',
      btnAction: 'Ship',
      arabicBtnAction: 'status.ship',
    },
    '3': {
      status: 'Shipped',
      arabicStatus: 'status.shipped',
      btnAction: 'deliver',
      arabicBtnAction: 'status.deliver',
    },
    '4': {
      status: 'Delivered',
      arabicStatus: 'status.delivered',
      btnAction: 'received',
      arabicBtnAction: 'status.received',
    },
    '5': {
      status: 'Cancelled',
      arabicStatus: 'status.cancelled',
    },
    '6': {
      status: 'Rejected',
      arabicStatus: 'status.rejected',
    },
    '7': {
      status: 'Received',
      arabicStatus: 'status.received',
    },
    '8': {
      status: 'Rep Started',
      arabicStatus: 'status.repStarted',
    },
  }

  return mapStatus[statusNumber]
}

export function getStaffType(staffObject) {
  if (!staffObject) return 'who are you'
  if (staffObject.isSuperAdmin) return 'superAdmin'
  else if (path(['sellerCategory'], staffObject)) {
    if (path(['sellerCategory'], staffObject) === 'Company') return 'company'
    else return 'distributor'
  } else return 'rep'
}

export function hasPermissionToDelete(staffType, componentName) {
  const companyComponents = [
    'distributors',
    'companyProducts',
    'brands',
    'staffs',
    'buyers',
    'roles',
    'priceOffers',
    'quantityOffers',
    'bundleOffers',
    'ads',
  ]
  const distributorComponents = ['reps', 'roles', 'buyers']

  switch (staffType) {
    case 'superAdmin':
      return true
    case 'company':
      return companyComponents.includes(componentName)
    case 'distributor':
      return distributorComponents.includes(componentName)
    case 'rep':
      return false
    default:
      return false
  }
}
