import { fields } from "../schema";
import {
  getSetting,
  updateSetting,
} from "../../../modules/Setting/store/actions";
import arabic_labels from "../../constants/arabic_labels.json";
export const settings = {
  edit: {
    update: updateSetting,
    get: getSetting,
    fields: [
      fields.number({
        title: arabic_labels["form.conversionRate"],
        min: 0,
        allowFractions: true,
        dataIndex: "conversionRate",
      }),
    ],
  },
};
