import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_CATEGORIES,
  DELETE_CATEGORY,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
} from '../../../../config/constants/ActionTypes'
import { getCategories, getCategoriesSuccess, getCategoriesFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getCategoriesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'categories/paginated',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
    headers: { lite },
  })
}

function* getCategoriesSaga({ payload }) {
  try {
    const response = yield call(getCategoriesRequest, payload)
    yield put(getCategoriesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getCategoriesFailure(error))
    payload.onFailure(error)
  }
}

const deleteCategoryRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'categories',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteCategorySaga({ payload }) {
  try {
    const response = yield call(deleteCategoryRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addCategoryRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'categories',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addCategorySaga({ payload }) {
  try {
    const response = yield call(addCategoryRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateCategoryRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'categories',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateCategorySaga({ payload }) {
  try {
    const response = yield call(updateCategoryRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getCategories(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_CATEGORIES, getCategoriesSaga)],
    [yield takeEvery(DELETE_CATEGORY, deleteCategorySaga)],
    [yield takeEvery(ADD_CATEGORY, addCategorySaga)],
    [yield takeEvery(UPDATE_CATEGORY, updateCategorySaga)],
  )
}
