/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment, forwardRef, useCallback } from 'react'
import { equals, path } from 'ramda'
import componentMapper from '../../../../config/Structure/componentMapper'

const ObjectInput = forwardRef(
  ({ value, onChange, readOnly, fields, dataIndex, dependencyObject }, ref) => {
    const [state, setState] = useState({})
    useEffect(() => {
      if (value && !equals(value, state)) {
        applyChange({ ...state, ...value })
      }
    }, [value]) // eslint-disable-line

    const applyChange = useCallback(
      (val) => {
        setState(val)
        if (onChange) onChange(val, dataIndex)
      },
      [onChange, dataIndex],
    )

    const onChildChange = useCallback(
      (val, dataIndex) => {
        applyChange({ ...state, [dataIndex]: val })
      },
      [applyChange, state],
    )

    const children = useCallback(
      () =>
        fields.map((child) => {
          const Comp = componentMapper[child.type]
          // let vari = path([child.dataIndex], dependencyObject) && {
          //   [path([child.dataIndex], dependencyObject)]: state[dependencyObject[child.dataIndex]],
          // }
          return (
            <Comp
              {...child}
              dependencyValue={
                path([child.dataIndex], dependencyObject) &&
                state[path([child.dataIndex], dependencyObject)]
                  ? {
                      [path([child.dataIndex], dependencyObject)]: state[
                        path([child.dataIndex], dependencyObject)
                      ],
                    }
                  : undefined
              }
              readOnly={readOnly || child.readOnly}
              value={state[child.dataIndex]}
              onChange={onChildChange}
            />
          )
        }),
      [fields, onChildChange, readOnly, state],
    )

    return <Fragment ref={ref}>{children()}</Fragment>
  },
)

export default ObjectInput
