import { brands } from './modules/brands'
import { bundleOffers } from './modules/bundleOffers'
import { buyerCategories } from './modules/buyerCategories'
import { buyers } from './modules/buyers'
import { categories } from './modules/categories'
import { companies } from './modules/companies'
import { companyProducts } from './modules/companyProducts'
import { distributors } from './modules/distributors'
import { districts } from './modules/districts'
import { packages } from './modules/packages'
import { packageUnits } from './modules/packageUnits'
import { priceOffers } from './modules/priceOffers'
import { quantityOffers } from './modules/quantityOffers'
import { reps } from './modules/reps'
import { roles } from './modules/roles'
import { sections } from './modules/sections'
import { sizes } from './modules/sizes'
import { staff } from './modules/staff'
import { subCategories } from './modules/subCategories'
import { subscriptions } from './modules/subscriptions'
import { subTypes } from './modules/subTypes'
import { superadmins } from './modules/superadmins'
import { tags } from './modules/tags'
import { types } from './modules/types'
import { zones } from './modules/zones'
import { settings } from './modules/settings'



const mapper = {
  'brand': brands,
  'bundleOffer': bundleOffers,
  'buyerCategory': buyerCategories,
  'buyer': buyers,
  'category': categories,
  'company': companies,
  'companyProduct': companyProducts,
  'distributor': distributors,
  'district': districts,
  // 'order': orders,
  'package': packages,
  'packageUnit': packageUnits,
  'priceOffer': priceOffers,
  'quantityOffer': quantityOffers,
  'rep': reps,
  'role': roles,
  'section': sections,
  'size': sizes,
  'staff': staff,
  'subCategory': subCategories,
  'subscription': subscriptions,
  'subType': subTypes,
  'superadmin': superadmins,
  'tag': tags,
  'type': types,
  'zone': zones,
  'setting': settings,
  
}

export default mapper