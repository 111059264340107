import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { pathOr, path } from 'ramda'
import { message } from 'antd'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getRep } from '../../store/actions'
import { getOrders } from '../../../Order/store/actions'
import arabic_labels from '../../../../config/constants/arabic_labels.json'
import Table from '../../../View/components/Table'
import AddButton from '../../../Form/components/AddButton'
import { getStatusFromStatusNumber } from '../../../../services/commonServices'

const CompanyPage = ({ dispatch }) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [orders, setOrders] = useState([])
  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    getRecords(id)
    getRepOrders(id)
  }, []) // eslint-disable-line

  const getRecords = (itemId) => {
    dispatch(getRep({ query: { _id: itemId }, onSuccess, onFailure }))
  }

  const getRepOrders = (itemId) => {
    dispatch(
      getOrders({
        query: {
          'rep.id': itemId,
          $or: [{ status: '3' }, { status: '4' }, { status: '7' },{ status: '8' }],
        },
        onSuccess: onOrdersSuccess,
        onFailure,
      }),
    )
  }

  const onOrdersSuccess = ({ data }) => {
    setOrders(data)
  }

  const onSuccess = (data) => {
    setLoading(false)
    setData(data['data'][0])
  }

  const onFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }

  const zonesColumns = [
    {
      title: 'المنطقة',
      dataIndex: 'name.ar',
      key: 'name.ar',
      type: 'text',
    },
  ]

  const ordersColumns = [
    {
      title: arabic_labels['form.sellerRef'],
      dataIndex: 'seller.name',
      key: 'seller.name',
      type: 'text',
    },
    {
      title: arabic_labels['form.buyer'],
      dataIndex: 'user.name',
      key: 'user.name',
      type: 'text',
    },
    {
      title: arabic_labels['form.status'],
      dataIndex: 'status',
      key: 'status',
      type: 'text',
      selector: (statusNumber) =>
        arabic_labels[pathOr('-', ['arabicStatus'], getStatusFromStatusNumber(statusNumber))],
    },
    {
      title: arabic_labels['form.total'],
      dataIndex: 'total',
      key: 'total',
      type: 'text',
    },
    {
      title: arabic_labels['form.details'],
      dataIndex: 'order',
      key: 'order',
      to: '/orders/view/{key}',
      type: 'goToAction',
    },
  ]

  if (loading) return <LinearProgress />
  return (
    <div>
      <div className='row'>
        <div className='col-12 col-9-md'>
          <TabContent style={{ height: '4.75rem', padding: '.9rem' }}>
            <div className='row'>
              <div className='col-12 col-3-md part'>
                <p className='title'>{arabic_labels['form.name']}</p>
                <p className='value'>{pathOr('-', ['name', 'ar'], data)}</p>
              </div>
              <br />
              <div className='col-12 col-3-md part'>
                <p className='title'>{arabic_labels['form.phone']}</p>
                <p className='value'>{pathOr('-', ['accountPhoneNumber'], data)}</p>
              </div>
              <br />
              <br />
              <div className='col-12 col-3-md part'>
                <p className='title'>{'الموزع'}</p>
                <p className='value'>{pathOr('-', ['distributor', 'name', 'ar'], data)}</p>
              </div>
              <br />
              <div className='col-12 col-3-md part'>
                <p className='title'>{'قطاع'}</p>
                <p className='value'>{pathOr('-', ['buyerCategory', 'name', 'ar'], data)}</p>
              </div>
              <br />
            </div>
          </TabContent>
        </div>
        <div className='col-12 col-3-md'>
          <div className='row'>
            <div className='col-12'>
              <AddButton
                style={{ height: '4.75rem', width: '100%' }}
                text='تعديل'
                onClick={() => history.push(`/reps/edit/${id}`)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-4-md part'>
          <p style={{ color: 'var(--color-ternary)', fontSize: '1.6rem', textAlign: 'center' }}>
            {'المناطق'}
          </p>
          <TableStyle columnsCount={zonesColumns.length}>
            <Table
              scroll={true}
              data={path(['zones'], data)}
              headers={zonesColumns}
              loading={false}
            />
          </TableStyle>
        </div>
        <div className='col-12 col-8-md part'>
          <p style={{ color: 'var(--color-ternary)', fontSize: '1.6rem', textAlign: 'center' }}>
            {'الطلبات'}
          </p>
          <TableStyle columnsCount={ordersColumns.length}>
            <Table scroll={true} data={orders} headers={ordersColumns} loading={false} />
          </TableStyle>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(CompanyPage)

const TabContent = styled.div`
  background: var(--color-primary);
  border-radius: 0.5rem;
  border: 0;
  color: var(--color-font-secondary);
  text-transform: uppercase;
  padding: 1.5rem;

  .part {
    margin-left: 10px;
    .title {
      color: var(--color-custom);
      font-size: 18px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        line-height: 32px;
      }
    }

    .value {
      color: var(--color-font-secondary);
      font-size: 17px;
      font-weight: 300;
      text-transform: lowercase;

      line-height: 1.2rem;
    }
  }

  hr {
    margin: 0.65rem 1rem;
    height: 1px;
    color: #82b3d626;
    background: #82b3d626;
  }
`

const TableStyle = styled.div`
  overflow-x: auto;
  th, td {
    min-width: 200px;
    /* width: ${(props) => 100 / props.columnsCount}vw; */
  }

`
