import React, { useState } from 'react'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
// import { GlobalContext } from './GlobalState'
import Router from './pages/Router'
import Sidebar from './modules/View/components/Sidebar'
import TopBar from './modules/View/components/TopBar'
import GlobalStyle from './GlobalStyle'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
const { Content, Sider } = Layout

const App = (props) => {
  const [collapsed, setCollapsed] = useState(true)
  const appClasses = useAppStyles()
  // const { state } = GlobalContext()

  return (
    <AppStyle className={appClasses.root}>
      <GlobalStyle />
      <Layout style={{ height: '100vh' }}>
        {props.auth.token ? (
          <Sider  className="Siderwidth"  collapsible collapsed={collapsed} onCollapse={(state) => setCollapsed(state) 
           }>
          
            <Sidebar  setCollapsed={setCollapsed}/>
          </Sider>
        ) : (
          ''
        )}
        {props.auth.token ? (
          <Content style={{ padding: '1.2rem' }}>
            <div className='row'>
              <div className='col-12'>
                <TopBar />
              </div>
              <div className='col-12'>
                <Router />
              </div>
            </div>
          </Content>
        ) : (
          <Content>
            <Row>
              <Col>
                <Router />
              </Col>
            </Row>
          </Content>
        )}
      </Layout>



    </AppStyle>
  )
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

export default connect(mapStateToProps)(App)

const useAppStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
  },
}))

const AppStyle = styled.div`
.Siderwidth{
  width:230px  !important;
  flex: 0 0 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
  @media (max-width:767px){
    width:100% !important;
    flex: 0 0  100% !important;
    max-width:  100% !important;
    min-width:  100% !important;
    
    
  }
}
  .ant-layout {
    background: var(--color-body);
    direction: rtl;
  }

  .ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: var(--color-font-secondary);
    line-height: 48px;
    text-align: center;
    background: var(--color-primary);
    cursor: pointer;
    transition: all 0.2s;
    padding: 0;
    margin: 0;

    @media (max-width: 767px){
      width:50px !important;
    }
  }
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger i.anticon.anticon-left{
float: right;
margin-top: 10px;
padding-right: 30px;
  }

  .ant-layout-sider {
    background: var(--color-primary) 00;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);


    i.anticon.anticon-right svg  , i.anticon.anticon-left svg{
      display: none !important;
      transition: 0.3s;
    }
    i.anticon.anticon-right:before {
      content: "\f03b" !important;
      transition: 0.3s;
    }
  
  
  i.anticon.anticon-left:before {
    content: "\f03b" !important;
    transition: 0.3s;
    transform: rotate(180deg);

  }
  
  i.anticon.anticon-left:before ,   i.anticon.anticon-right:before {
    transition: 0.3s;

    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  }

  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
    flex: 0 0 100px !important;
    min-width: 100px !important;
    width: 100px !important;
    max-width: 100px !important;

    @media (max-width:767px){
       flex: 0 0 50px !important;
    min-width: 50px !important;
    width: 50px !important;
    max-width: 50px !important;

    
    }
  }

  .MuiInputLabel-formControl , .ant-select-selection__placeholder, .ant-select-search__field__placeholder , .ant-select-selection__placeholder, .ant-select-search__field__placeholder{
    right: 0 !important;
    left: inherit;

}

.ant-select-selection--multiple .ant-select-selection__rendered  {
    height: auto;
    margin-bottom: 5px;
    margin-left: 5px;
    line-height: normal;
    font-size: 16px;
    padding: 10px !important;
    background: transparent !important;

}
.ant-select-selection--multiple {
  background: transparent!important;
}

.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li{
  margin-bottom: 10px !important;

}
.MuiButton-label {
  
  font-size: 23px;
}

.MuiFormControlLabel span{
 
  margin-right: 20px; !important;
}
.MuiInputLabel-shrink{
  color: var(--color-font-secondary)  !important;

}

.MuiFormControl-fullWidth {
  margin: 4px 0;
}


.MuiTypography-root.MuiFormControlLabel-label {
  margin-right: 20px !important;
  font-size: 17px !important;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder, .ant-select-selection__placeholder, .ant-select-search__field__placeholder , input.MuiInputBase-input.MuiInput-input{
  font-size: 16px  !important;
  margin-right: 20px; !important;
}


 input.MuiInputBase-input.MuiInput-input , .ant-select-dropdown-menu-item {
  color: var(--color-form)  !important;

}

 .ant-select-selection__placeholder, .ant-select-search__field__placeholder, .ant-select-selection__placeholder, .ant-select-search__field__placeholder, input.MuiInputBase-input.MuiInput-input{
  color: var(--color-form)  !important;

}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  margin: 0;
}




`





