import {
  GET_PACKAGE_UNITS,
  GET_PACKAGE_UNITS_SUCCESS,
  GET_PACKAGE_UNITS_FAILURE,
  DELETE_PACKAGE_UNIT,
  ADD_PACKAGE_UNIT,
  ADD_PACKAGE_UNIT_SUCCESS,
  ADD_PACKAGE_UNIT_FAILURE,
  EDIT_PACKAGE_UNIT,
  EDIT_PACKAGE_UNIT_SUCCESS,
  EDIT_PACKAGE_UNIT_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Get PackageUnits Request
export const getPackageUnits = (payload) => {
  return {
    type: GET_PACKAGE_UNITS,
    payload,
  }
}

//Get PackageUnits Success
export const getPackageUnitsSuccess = (packageUnits) => {
  return {
    type: GET_PACKAGE_UNITS_SUCCESS,
    payload: { packageUnits },
  }
}

//Get PackageUnits Failure
export const getPackageUnitsFailure = (error) => {
  return {
    type: GET_PACKAGE_UNITS_FAILURE,
    payload: { error },
  }
}

// //Get PackageUnit Request
// export const getPackageUnit = (payload) => {
//   return {
//     type: GET_PACKAGE_UNIT,
//     payload,
//   }
// }

// //Get PackageUnit Success
// export const getPackageUnitSuccess = (packageUnit) => {
//   return {
//     type: GET_PACKAGE_UNIT_SUCCESS,
//     payload: { packageUnit },
//   }
// }

// //Get PackageUnit Failure
// export const getPackageUnitFailure = (error) => {
//   return {
//     type: GET_PACKAGE_UNIT_FAILURE,
//     payload: { error },
//   }
// }

//Add PackageUnit Request
export const addPackageUnit = (payload) => {
  return {
    type: ADD_PACKAGE_UNIT,
    payload,
  }
}

//Add PackageUnit Success
export const addPackageUnitSuccess = (message) => {
  return {
    type: ADD_PACKAGE_UNIT_SUCCESS,
    payload: { message },
  }
}

//Add PackageUnit Failure
export const addPackageUnitFailure = (error) => {
  return {
    type: ADD_PACKAGE_UNIT_FAILURE,
    payload: { error },
  }
}

//Edit PackageUnit Request
export const editPackageUnit = (payload) => {
  return {
    type: EDIT_PACKAGE_UNIT,
    payload,
  }
}

//Edit PackageUnit Success
export const editPackageUnitSuccess = (message) => {
  return {
    type: EDIT_PACKAGE_UNIT_SUCCESS,
    payload: { message },
  }
}

//Edit PackageUnit Failure
export const editPackageUnitFailure = (error) => {
  return {
    type: EDIT_PACKAGE_UNIT_FAILURE,
    payload: { error },
  }
}

//Delete PackageUnits
export const deletePackageUnit = (payload) => {
  return {
    type: DELETE_PACKAGE_UNIT,
    payload,
  }
}
