import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { isEmpty } from 'ramda'
import { uploadImages } from '../../../../config/Client/actions/Upload'
// import useAddForm from '../../../Form/components/useFormHelpers/useAddForm'

const AddDistributorForm = ({ dispatch }) => {
  const { distributors } = config
  const history = useHistory()
  const location = useLocation()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState(false)

  const query = queryString.parse(location.search)

  useEffect(() => {
    setValues(isEmpty(query) ? undefined : query)
  }, []) // eslint-disable-line

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(distributors.add.create({ body: values, onSuccess, onFailure }))
  }

  const onSuccess = (id, imageFiles) => {
    if (imageFiles && imageFiles.length > 0) {
      dispatch(uploadImages({
        imageArray: imageFiles,
        moduleName: 'distributors',
        _id: id,
        onSuccess: onUploadSuccess,
        onFailure,
      }))
    } else {
      setSubmitting(false)
      message.success('تمت الاضافة بنجاح')
      history.push(`/distributors${location.search}`)
    }
  }

  const onUploadSuccess = (imageLinkArray, values, id) => {
    dispatch(distributors.edit.update({
      body: { images: imageLinkArray, _id: id },
      onSuccess: onEditSuccess,
      onFailure
    }))
  }

  const onEditSuccess = () => {
    setSubmitting(false)
    message.success('تمت الاضافة بنجاح')
    history.push(`/distributors${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  return (
    <CrudForm
      title='أضف موزع أو فرع'
      loading={false}
      fields={config.distributors.add.fields}
      onSubmit={onSubmit}
      buttonText='أضف'
      submitting={submitting}
      initialValues={values}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(AddDistributorForm)
