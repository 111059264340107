import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_SUB_TYPES,
  DELETE_SUB_TYPE,
  ADD_SUB_TYPE,
  UPDATE_SUB_TYPE,
} from '../../../../config/constants/ActionTypes'
import { getSubTypes, getSubTypesSuccess, getSubTypesFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getSubTypesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'subTypes/paginated',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
    headers: { lite },
  })
}

function* getSubTypesSaga({ payload }) {
  try {
    const response = yield call(getSubTypesRequest, payload)
    yield put(getSubTypesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getSubTypesFailure(error))
    payload.onFailure(error)
  }
}

const deleteSubTypeRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'subTypes',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteSubTypeSaga({ payload }) {
  try {
    const response = yield call(deleteSubTypeRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addSubTypeRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'subTypes',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addSubTypeSaga({ payload }) {
  try {
    const response = yield call(addSubTypeRequest, payload)
    payload.onSuccess(path(['data', '_id'], response))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateSubTypeRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'subTypes',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateSubTypeSaga({ payload }) {
  try {
    const response = yield call(updateSubTypeRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getSubTypes(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_SUB_TYPES, getSubTypesSaga)],
    [yield takeEvery(DELETE_SUB_TYPE, deleteSubTypeSaga)],
    [yield takeEvery(ADD_SUB_TYPE, addSubTypeSaga)],
    [yield takeEvery(UPDATE_SUB_TYPE, updateSubTypeSaga)],
  )
}
