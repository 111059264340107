/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
// import { get } from 'lodash'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'

const EditDistrictForm = ({ dispatch }) => {
  const { districts } = config
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(
      config.districts.edit.get({
        query: { _id: id },
        onSuccess: onFetchSuccess,
        onFailure: onFetchFailure,
      }),
    )
  }, [])

  const onSubmit = (values) => {
    setSubmitting(true)
    dispatch(districts.edit.update({ body: { ...values, _id: id }, onSuccess, onFailure }))
  }
  const onSuccess = () => {
    setSubmitting(false)
    message.success('تم التعديل بنجاح')
    history.push(`/districts${location.search}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  const onFetchSuccess = (records) => {
    setLoading(false)
    setData(records.data[Object.keys(records.data)[0]])
  }

  const onFetchFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }
  return (
    <CrudForm
      title='تعديل القسم أو المركز'
      loading={loading}
      fields={config.districts.edit.fields}
      onSubmit={onSubmit}
      buttonText='تعديل'
      initialValues={data}
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(EditDistrictForm)
