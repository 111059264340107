/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Select, message } from 'antd'
import { equals, path, prop, isEmpty, pathOr, includes } from 'ramda'
import { GlobalContext } from '../../../../GlobalState'
import { connect } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '../../../View/components/Dialog'
import AddNew from './AddNew'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
const { Option } = Select

function useIsMountedRef() {
  const isMountedRef = useRef(null)
  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })
  return isMountedRef
}

const SelectInput = forwardRef((props, ref) => {
  const location = useLocation()
  const history = useHistory()
  const parsedQueryString = queryString.parse(location.search)
  const call = useRef()
  const previousDependantValue = useRef()
  const isMounted = useIsMountedRef()
  const [variables, setVariables] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [vars, setVars] = useState({})
  const [state, setState] = useState(undefined)
  const [_id, setAddNewID] = useState(undefined)
  const [values, setValues] = useState({})
  const [AddingNew, setAddingNew] = useState(false)
  const [dependencyURLValue, setDependencyURLValue] = useState(false)

  const { state: globalState, dispatch } = GlobalContext()
  const { getValues } = useFormContext()
  let query

  if (path(['source', 'query'], props) && typeof path(['source', 'query'], props) === 'function') {
    query = path(['source', 'query'], props)
  } else {
    query = () => []
  }
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const value = getValues()


  const openAddOnFlyModal = () => {
    setDialogOpen(true)
    if (!props?.dependentField) return
    // add dependency value to the url query to set defaults
    if (parsedQueryString[props.dependentKey]) return setDependencyURLValue(true)
    parsedQueryString[props.dependentKey] = path(props.dependentField.split('.'), value)
    history.push(`${location.pathname}?${queryString.stringify(parsedQueryString)}`)
  }

  const closeAddOnFlyModal = () => {
    setDialogOpen(false)
    if (!props?.dependentField || dependencyURLValue) return
    // remove dependency value from query
    if (parsedQueryString[props.dependentKey]) delete parsedQueryString[props.dependentKey]
    history.push(`${location.pathname}?${queryString.stringify(parsedQueryString)}`)
  }

  const onAddNew = (id) => {
    closeAddOnFlyModal()
    if (id) {
      setAddNewID(id)
      fetchData(true)
    }
  }

  useEffect(() => {
    if (AddingNew) {
      if (includes(_id, (data?.data || data).map((datum) => datum?._id))) {
        props.mode === 'default'
          ? props.onChange(_id, props.dataIndex)
          : Array.isArray(state)
            ? setState([...state, _id])
            : setState([_id])
      }
      setAddingNew(false)
    }
  }, [AddingNew]) // eslint-disable-line

  useEffect(() => {
    if (!equals(props.value, state)) onChange(props.value === null ? undefined : props.value)
  }, [props.value]) // eslint-disable-line

  useEffect(() => {
    if (
      props.getFieldValue &&
      props.dependency &&
      !equals({ [props.dependency]: props.getFieldValue(props.dependency) }, variables)
    )
      setVariables({ [props.dependency]: props.getFieldValue(props.dependency) })
  }, [globalState.dependency]) // eslint-disable-line

  useEffect(() => {
    if (!equals(props.dependencyValue, variables)) setVariables(props.dependencyValue)
  }, [props.dependencyValue]) // eslint-disable-line

  useEffect(() => {
    if (props.variables) setVariables(props.variables)
  }, [props.variables]) // eslint-disable-line

  const fetchData = (inAddNew) => {
    if (
      (!props.dependency || (variables && !isEmpty(variables))) &&
      !path(['source', 'list'], props) &&
      (path(['source', 'query'], props) ||
        path(['source', 'api'], props) ||
        path(['source', 'dynamicSource'], props))
    ) {
      if (props.dependentField && !path(props.dependentField.split('.'), value)) {
        return
      }
      setLoading(true)
      if (
        path(['source', 'query'], props) &&
        typeof path(['source', 'query'], props) === 'function'
      ) {
        props.dispatch(
          query({
            query: {
              ...variables,
              ...(props.dependentField && {
                [props.dependentKey]: path(props.dependentField.split('.'), value),
              }),
            },
            lite: true, // request a lite payload containing only the object's name
            onSuccess: inAddNew ? onSuccessAddNew : onSuccess,
            onFailure,
          }),
        )
      }
      if (path(['source', 'api'], props) && typeof path(['source', 'api'], props) === 'string') {
        apiCall(path(['source', 'api'], props), variables)
      }
      if (
        path(['source', 'dynamicSource'], props) &&
        typeof path(['source', 'dynamicSource'], props) === 'string'
      ) {
        apiCall(path(props.dependentField.split('.'), value), variables)
      }
    }
  }
  useEffect(() => {
    if (props.onChange) props.onChange(state, props.dataIndex)
  }, [state])

  useEffect(() => {
    fetchData() // initial data fetch
  }, [])

  useEffect(() => {
    if (equals(value, values) && equals(vars, variables)) {
      return
    }
    setValues(value)
    setVars(variables)

    if (props?.dependentField && previousDependantValue.current !== null &&
      path(props.dependentField.split('.'), value) !== null &&
      previousDependantValue.current !== path(props.dependentField.split('.'), value)) {

      if (previousDependantValue.current) {
        setState(undefined)
        props.onChange(undefined, props.dataIndex)
      }

      previousDependantValue.current = path(props.dependentField.split('.'), value)
      fetchData()
    }
  }, [variables, value])

  const apiCall = (api, variables) => {
    if (call.current) {
      call.current.cancel()
    }
    call['current'] = axios.CancelToken.source()
    axios({
      method: api.includes('getDistributors') || api.includes('getCompanies') ? 'post' : 'get',
      url: config.url + api,
      params: { ...variables, isDeleted: false },
      cancelToken: call?.current.token,
    }).then((res) => onSuccess(res.data))
  }

  const onSuccessAddNew = records => {
    setLoading(false)
    if (isMounted.current) setData(records)
    setAddingNew(true)
  }

  const onSuccess = (records) => {
    setLoading(false)
    if (isMounted.current) setData(records)
  }

  const onFailure = () => {
    setLoading(false)
    message.error('حدث خطأ')
  }

  const onChange = (val) => {
    setState(val)
    dispatch({ type: 'SET_DEPENDENCY', payload: { dependency: val } })
    if (props.onChange) props.onChange(val, props.dataIndex)
  }

  let children = []

  if (data?.length || data?.data?.length) {
    children = (data?.data || data).map((datum) => {
      return (
        <Option key={datum._id} value={datum._id}>
          {pathOr(prop('name', datum), ['name', 'ar'], datum)}
        </Option>
      )
    })
  } else {
    if (path(['source', 'list'], props) && path(['source', 'list', 'length'], props)) {
      children = props.source.list.map((datum) => {
        return (
          <Option key={datum.key ? datum.key : datum} value={datum.value ? datum.value : datum}>
            {datum.key}
          </Option>
        )
      })
    }
  }

  return (
    <Grid container justify={(props?.module?.key) === 'country'? 'left':'center'}>
      <Grid item xs={props.module ? 11 : 12}>
        <SelectInputStyle>
          <Select
            optionFilterProp='children'
            showSearch={true}
            loading={loading}
            disabled={
              (props.dependentField && !path(props.dependentField.split('.'), value)) ||
              props.readOnly ||
              !!parsedQueryString[props.dataIndex] ||
              !!parsedQueryString[props.anotherDataIndex]
            }
            placeholder={props.title}
            mode={props.mode}
            dropdownMenuStyle={{
              background: 'var(--color-primary)',
              color: 'var(--color-font-secondary)',
            }}
            onChange={onChange}
            filterOption={true}
            name={props.dataIndex}
            value={state}
            ref={ref}
          >
            {children}
          </Select>
        </SelectInputStyle>
      </Grid>
      {props.module && props?.module?.key !== 'country' && !parsedQueryString[props.dataIndex] && (
        <Grid item xs={1}>
          <Box display='flex' justifyContent='center' justifyItems='center'>
            <IconButton onClick={() => openAddOnFlyModal()}>
              <AddIcon />
            </IconButton>
            <Dialog
              onClose={() => closeAddOnFlyModal()}
              open={dialogOpen}
              contentHeight={'70vh'}
              title={'اضافة'}
            >
              <AddNew
                onAdd={onAddNew}
                module={path(['module', 'key'], props)}
                beforeSubmit={path(['module', 'beforeSubmit'], props)}
              />
            </Dialog>
          </Box>
        </Grid>
      )}
    </Grid>
  )
})

const SelectInputStyle = styled.div`
  .ant-select-selection--multiple .ant-select-selection__choice {
    background: var(--color-primary);
    border: 0;
    border-radius: 2rem;
    font-size: 1rem;
    color: var(--color-font-secondary);
  }

  input.MuiInputBase-input.MuiInput-input,
  .ant-select-dropdown-menu-item {
    color: var(--color-form) !important;
  }

  .ant-select-remove-icon {
    color: var(--color-font-secondary);
  }

  .ant-select-selection {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #8dc3e72b;
    font-weight: 300;
    color: var(--color-font-primary);
    font-size: 1.25rem;
    text-transform: uppercase;
    border-radius: 0;
  }

  .ant-select-arrow .ant-select-arrow-icon svg {
    display: none;
  }
  i.anticon.anticon-down.ant-select-arrow-icon:before {
    content: '\f107';
    transition: 0.3s;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  i.anticon.anticon-down.ant-select-arrow-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon.anticon-down,
  .anticon-spin {
    font-size: 1.25rem;
    color: var(--color-secondary);
    position: relative;
    bottom: 0.5rem;
  }
`

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(SelectInput)
