import React, { useEffect, useState, forwardRef, useCallback } from 'react'
import { equals, propOr } from 'ramda'
import Grid from '@material-ui/core/Grid'
import componentMapper from '../../../../config/Structure/componentMapper'

const DoubleInput = forwardRef(({
  value,
  firstField,
  secondField,
  dataIndex, 
  readOnly,
  onChange,
  dependent,
}, ref) => {
  const [state, setState] = useState({
    [firstField.dataIndex]: value ? value[firstField.dataIndex] : undefined,
    [secondField.dataIndex]: value ? value[secondField.dataIndex] : undefined,
  })
  const [dependencyValue, setDependencyValue] = useState()

  useEffect(() => {
    if (value && !equals(value, state)) applyChange(value)
  }, [value]) // eslint-disable-line

  const applyChange = useCallback((val) => {
    setState(val)
    if (onChange) onChange(val, dataIndex)
  }, [onChange, dataIndex])

  const handleValue1Change = useCallback((val) => {
    applyChange({ ...state, [firstField.dataIndex]: val })
    if (dependent) setDependencyValue({ [firstField.dataIndex]: val })
  }, [applyChange, dependent, state, firstField.dataIndex])

  const handleValue2Change = useCallback((val) => {
    applyChange({ ...state, [secondField.dataIndex]: val })
  }, [applyChange, state, secondField.dataIndex])

  const FirstField = componentMapper[firstField.type]
  const SecondField = componentMapper[secondField.type]

  return (
    <Grid container alignItems='center' ref={ref} spacing={1}>
      <Grid item xs={6}>
        <FirstField { ...firstField } readOnly={readOnly || firstField.readOnly} onChange={handleValue1Change} value={state[firstField.dataIndex]} />
      </Grid>
      <Grid item xs={6}>
      <SecondField { ...secondField } 
        readOnly={readOnly || secondField.readOnly || (!propOr(false, firstField.dataIndex)(dependencyValue) && dependent)} 
        dependencyValue={dependencyValue} onChange={handleValue2Change} value={state[secondField.dataIndex]} />
      </Grid>
    </Grid>
  )
})

export default DoubleInput
