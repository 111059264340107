import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_COUNTIES,
} from '../../../../config/constants/ActionTypes'
import { getCountriesFailure, getCountriesSuccess } from '../actions'
import config from '../../../../config/Client/sagas/config'
import axios from 'axios'

const getCountriesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'countries/',
    params: { ...query, isDeleted: false },
    headers: { lite },
  })
}

function* getCountriesSaga({ payload }) {
  try {
    const response = yield call(getCountriesRequest, payload)
    yield put(getCountriesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getCountriesFailure(error))
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_COUNTIES, getCountriesSaga)],
  )
}
