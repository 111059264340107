import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const PackageUnitsTable = () => {  

  return(
    <div>


      <TableHolder>
      <View componentName='packageUnits' config={config.packageUnits} />
      </TableHolder>
    </div>
  )
}

export default PackageUnitsTable


const TableHolder  = styled.div`
@media (max-width:767px){
  td:nth-child(2), td:nth-child(3) {
    display: inline-block;
    text-align: center;
  }
}

`