import React, { useState, useEffect, forwardRef } from 'react'
import Input from 'antd/lib/input'
import Icon from 'antd/lib/icon'
import { equals } from 'ramda'
import styled from 'styled-components'

const SearchInput = forwardRef((props, ref) => {
  const [state, setState] = useState(props.value)

  useEffect(() => {
    if (!equals(props.value, state)) onChange(props.value)
  }, [props.value]) // eslint-disable-line

  const onChange = (e) => {
    if (e.target) {

      setState(e.target.value)
      if (props.onChange) props.onChange(e.target.value, props.dataIndex)
    }
  }

  return (
    <SearchInputNabvar>
      <Input 
        value={state}
        prefix={<Icon type='search' />}
        placeholder='بحث'
        name={props.dataIndex}
        onChange={onChange}
        ref={ref}
      /> 
    </SearchInputNabvar>
  )
})

const SearchInputNabvar = styled.div`
  font-size: 2rem;
  height: 4.5rem;
  border: 0px;
  width: 100%;
  background: var(--color-primary);
  // box-shadow: var(--shadow);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 4rem;
  border-radius: .5rem;
  border: 0;
@media (max-width: 767px){
  height: inherit;
  padding: 0 20px;

}

  .ant-input-prefix,
  .ant-input-prefix > i {
    bottom: 0 !important;
    top: 1rem;
    display: block;
    font-size: 2rem;
    color: var(--color-font-secondary);
    text-indent: -1.5rem;

    @media (max-width: 767px){
      bottom: inherit !important;
      top: 14px;
      display: block;
      font-size: 22px;
      color: var(--color-font-secondary);
      text-indent: -1.5rem;
      left: 0;
  
    }

  }

  input {
    background: transparent;
    border: 0;
    color: var(--color-font-secondary);
    border-bottom: 1px solid #8dc3e72b;
    font-size:24px;
    height: 100%;
    border-radius: 0;
    font-weight: 300;
    margin-bottom: -1.8rem !important;
    top: .6rem;
    display: block;
    @media (max-width: 767px){
      top: 0px;
      font-size: 18px;


    }
    ::placeholder {
      color: var(--color-font-secondary);
      font-size:27px;

      @media (max-width: 767px){
       
        font-size: 16px;
  
  
      }
    }

    :focus {
      outline: none;
      -moz-appearance: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      border-color: inherit;
      box-shadow: none;
    }
  }

  
`

export default SearchInput

