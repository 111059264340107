import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const QuantityOffersTable = () => {
  return (
    <div>
            <TableHolder>
            <View componentName='quantityOffers' config={config.quantityOffers} />

            </TableHolder>

    </div>
  )
}

export default QuantityOffersTable
const TableHolder  = styled.div`
@media (max-width:767px){
  td:nth-child(8), td:nth-child(9) {
    display: inline-block;
    text-align: center;
  }
}

`

