import {
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Get Cities Request
export const getCities = (payload) => {
  return {
    type: GET_CITIES,
    payload,
  }
}

//Get Cities Success
export const getCitiesSuccess = (cities) => {
  return {
    type: GET_CITIES_SUCCESS,
    payload: { cities },
  }
}

//Get Cities Failure
export const getCitiesFailure = (error) => {
  return {
    type: GET_CITIES_FAILURE,
    payload: { error },
  }
}