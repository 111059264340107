import { tableFields, fields, formFields } from '../schema'
import { getTypes, deleteType, addType, updateType } from '../../../modules/Type/store/actions'
import { getSubCategories } from '../../../modules/SubCategory/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'
export const types = {
  view: {
    get: getTypes,
    delete: deleteType,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name.ar'),
      tableFields.text(arabic_labels['form.subCategory'], 'subCategory.name.ar'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addType,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.textArea({
        title: arabic_labels['form.description'],
        dataIndex: 'description.ar',
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      formFields.select(
        ' القسم الفرعي',
        'subCategory',
        'default',
        { query: getSubCategories },
        null,
        ['_id'],
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'subCategory',
        },
        null,
        (fieldValue) => (!fieldValue ? 'برجاء إدخال القسم الفرعي' : true),
      ),
    ],
  },
  edit: {
    update: updateType,
    get: getTypes,
    fields: [
      fields.object({
        title: 'الاسم',
        dataIndex: 'name',
        fields: [
          fields.text({
            title: '',
            dataIndex: 'ar',
          }),
        ],
        validate: (fieldValue) => (!path(['ar'], fieldValue) ? 'برجاء إدخال الاسم' : true),
      }),
      fields.object({
        title: arabic_labels['form.description'],
        dataIndex: 'description',
        fields: [fields.textArea({ title: '', dataIndex: 'ar' })],
      }),
      fields.images({
        title: arabic_labels['form.images'],
        dataIndex: 'images',
        multiple: true,
      }),
      fields.object({
        title: arabic_labels['form.subCategory'],
        dataIndex: 'subCategory',
        fields: [
          formFields.select(
            '',
            '_id',
            'default',
            { query: getSubCategories },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            {
              key: 'subCategory',
            },
          ),
        ],
      }),
    ],
  },
}
