import { fields, formFields } from '../schema'
import { getPriceList, updatePriceList } from '../../../modules/PriceList/store/actions'
import { getBuyerCategories } from '../../../modules/BuyerCategory/store/actions'
import { getPackages } from '../../../modules/Package/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'
import { path } from 'ramda'

export const pricelists = {
  edit: {
    update: updatePriceList,
    get: getPriceList,
    fields: [
      fields.object({
        title: arabic_labels['form.name'],
        dataIndex: 'name',
        fields: [fields.text({ dataIndex: 'ar' })],
      }),
      fields.object({
        title: arabic_labels['form.company'],
        dataIndex: 'company',
        fields: [
          fields.object({
            dataIndex: 'name',
            fields: [fields.text({ dataIndex: 'ar' })],
          }),
        ],
      }),
      fields.list({
        title: arabic_labels['sidebar.buyerCategories'],
        dataIndex: 'buyerCategories',
        field: fields.object({
          fields: [
            fields.object({
              dataIndex: 'saleUnit',
              fields: [
                formFields.select(
                  arabic_labels['form.package'],
                  'package',
                  'default',
                  { query: getPackages },
                  null,
                  ['_id'],
                  null,
                  null,
                  null,
                  false,
                ),
                fields.number({
                  title: arabic_labels['form.quantity'],
                  min: 1,
                  dataIndex: 'packageQuantity',
                }),
                fields.number({
                  title: arabic_labels['form.price'],
                  min: 0,
                  allowFractions: true,
                  dataIndex: 'price',
                }),
              ],
              validate: (fieldValue) =>
                !path(['package'], fieldValue) ||
                  !path(['packageQuantity'], fieldValue) ||
                  !path(['price'], fieldValue)
                  ? 'لابد من إدخال تفاصيل وحدة البيع'
                  : true,
            }),
            formFields.select(
              arabic_labels['form.buyerCategory'],
              'buyerCategory',
              'default',
              { query: getBuyerCategories },
              null,
              ['_id'],
              null,
              null,
              null,
              false,
              null,
              null,
              null,
              {
                key: 'buyerCategory',
              },
              null,
              (fieldValue) =>
                !fieldValue ? 'لابد من إدخال' + arabic_labels['form.buyerCategory'] : true,
            ),
          ],
        }),
        validate: (fieldValue) =>
          path(['0', 'saleUnit', 'package'], fieldValue) ||
            path(['0', 'saleUnit', 'packageQuantity'], fieldValue) ||
            path(['0', 'saleUnit', 'price'], fieldValue) ||
            path(['0', 'buyerCartegory'], fieldValue)
            ? true
            : 'لا بد من إدخال تفاصيل القطاعات',
      }),
    ],
  },
}
