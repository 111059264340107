import React from 'react'
import { Pagination } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

const PaginationView = ({ totalCount, pageSize, getRecords }) => {
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const parsedPageNumber = parseInt(query.page) || 1

  const onPageChange = (newPageNumber) => {
    query.page = newPageNumber
    getRecords(newPageNumber)
    history.push({ search: queryString.stringify(query) })
  }

  return (
    <PaginationStyle>
      <Pagination
        current={Number(parsedPageNumber)}
        total={totalCount}
        onChange={onPageChange}
        pageSize={pageSize}
      />
    </PaginationStyle>
  )
}

const PaginationStyle = styled.div`

i.anticon.anticon-left {
  transform: rotate(180deg);
}

i.anticon.anticon-right {
  transform: rotate(180deg);
}

  text-align: center;
  li.ant-pagination-item {
    background: transparent;
    border: 0;

    a {
      color: var(--color-font-primary);
      font-size: 16px;
    }
  }

  .ant-pagination-item-active a {
    color: var(--color-secondary) !important;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    outline: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--color-secondary);
    font-size: 16px;
  }

@media (max-width:767px){
  .ant-pagination {
    margin-top: 25px;
}

}

`

export default PaginationView
