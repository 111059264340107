import React, { useState, useEffect, forwardRef, useCallback } from 'react'
import { equals, isEmpty } from 'ramda'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import componentMapper from '../../../../config/Structure/componentMapper'
import Divider from '@material-ui/core/Divider'

const ListInput = forwardRef(({
  value,
  onChange,
  dataIndex,
  field,
  readOnly,
  hideTitle,
  title,
}, ref) => {
  const [state, setState] = useState([null])
  const labelClasses = useLabelStyles()

  useEffect(() => {
    if (value && !equals(value, state)) applyChange(isEmpty(value) ? [null] : value) 
  }, [value]) // eslint-disable-line

  const applyChange = useCallback((val) => {
    setState(val)
    if (onChange) onChange(val, dataIndex)
  }, [onChange, dataIndex])

  const onChildChange = useCallback((index) => (val) => {
    applyChange(state.map((child, i) => (i !== index) ? child : val))
  }, [state, applyChange])

  const addInput = useCallback((e) => {
    if (readOnly) return
    applyChange([...state, null])
  }, [readOnly, applyChange, state])

  const removeInput = useCallback((index) => (e) => {
    if (readOnly) return
    applyChange(state.filter((value, i) => index !== i))
  }, [readOnly, applyChange, state])


  const children = useCallback( () => {
    const Comp = componentMapper[field.type]

    return state.map((child, index) => {
      return (
        <Grid container key={index}>
          { index !== 0 && <Grid item xs={12}> <Divider style={{margin: '10px 0'}} /> </Grid> }
          <Grid item xs={11}>
            <Comp
              {...field}
              readOnly={readOnly || field.readOnly}
              value={state[index]}
              onChange={onChildChange(index)}
            />
          </Grid>
          <Grid item xs={1}>
            <Grid container direction='column' justify='center' alignItems='center'>
              <Grid item xs={6}>
                { state.length !== 1 && <IconButton onClick={removeInput(index)}>
                  <RemoveIcon color='primary' />
                </IconButton> }
              </Grid>
              <Grid item xs={6}>
                { (index + 1) === state.length && <IconButton onClick={addInput}>
                    <AddIcon color='primary' />
                  </IconButton> }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    })}, [field, addInput, removeInput, onChildChange, state, readOnly])

  return (
    <Box ref={ref}>
      { !hideTitle && <Grid item xs={12}><Box classes={labelClasses}>{title}</Box></Grid> }
      {children()}
    </Box>
  )
})

const useLabelStyles = makeStyles(theme => ({
  root: {
    marginRight: 5,
    textTransform: 'uppercase',
    color: theme.palette.info.main,
    fontSize: '1.1rem',
  }
}))


export default ListInput



