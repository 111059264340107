import React, { useState, useEffect, forwardRef } from 'react'
import { equals } from 'ramda'
// import { makeStyles } from '@material-ui/core/styles'

/* 
props:
  - title -> string
  - dataIndex -> string
  - multiple -> boolean
  - value -> string if multiple is false, array[string] if multiple is true
  - options -> array[string]
  - readOnly -> boolean
  - onChange -> fn(value, dataIndex)
  - onMounted -> fn(dataIndex)

*/

const SelectInput = forwardRef((props, ref) => {
  const [state, setState] = useState(props.multiple ? [] : '')
  // const [options, setOptions] = useState([])
  // const [open, setOpen] = useState(false)
  // const selectClasses = useSelectStyles()

  useEffect(() => {
    if (props.value && !equals(props.value, state)) onChange(props.value)
  }, [props.value]) // eslint-disable-line

  useEffect(() => {
    if (props.onMounted) props.onMounted(props.dataIndex)
  }, []) // eslint-disable-line

  useEffect(() => {
    // if (props.options && Array.isArray(props.options)) setOptions(props.options)
  }, [props.options]) // eslint-disable-line

  const onChange = (val) => {
    setState(val)
    if (props.onChange) props.onChange(val, props.dataIndex)
  }

  return (
    <div ref={ref}>
      
    </div>
  )
})

// const useSelectStyles = makeStyles(theme => ({
//   root: {
//   },
//   select: {
//   }
// }))

export default SelectInput