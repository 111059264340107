import React, { useState, Fragment } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'


/* 
  props:
    tabs: array of tab objects:
      - name: tab name
      - content: tab content
*/


export default function VerticalTabs(props) {
  const theme = useTheme()
  const tabsClasses = useTabsStyles()
  const tabClasses = useTabStyles()
  const [value, setValue] = useState(0)
  const lowerThanSM = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }


  return (
    <Fragment>
      <Grid container justify='center'>
        <Grid item xs={props.inModal ? 12 : 4} sm={props.inModal ? 12 : 2}>
          <Tabs
            orientation={ props.inModal && !lowerThanSM ? 'horizontal' : 'vertical'}
            centered={true}
            value={value}
            indicatorColor='primary'
            onChange={handleChange}
            classes={props.inModal ? null : tabsClasses}
            scrollButtons='auto'
          >
            {
              Array.isArray(props.tabs) && props.tabs.map((tab) => (
                <Tab classes={tabClasses} key={tab.name} label={tab.name}/>
              ))
            }
          </Tabs>
          <br />
        </Grid>
        <Grid item xs={props.inModal ? 12 : 8} sm={props.inModal ? 12 : 10}>
          {
            Array.isArray(props.tabs) && props.tabs.map((tab, index) => (
              <Fragment key={tab.name}>
                <Grid style={{ display: value === index ? 'flex' : 'none' }} container justify='center' direction='column' spacing={8}>
                  {tab.content}
                </Grid>
              </Fragment>
            ))
          }
        </Grid>
      </Grid>
    </Fragment>
  )
}

const useTabsStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxWidth: 265,
  },
}))

const useTabStyles = makeStyles( theme => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: '1.2rem',
    // width: '100%',
  },
}))
