import React from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'

const BuyerCategoriesTable = () => {
  return (
    <div>
                  <TableHolder>
      <View componentName='buyerCategories' config={config.buyerCategories} />
      </TableHolder>
    </div>
  )
}

export default BuyerCategoriesTable


const TableHolder  = styled.div`
@media (max-width:767px){

td:nth-child(2), td:nth-child(3) {
  display: inline-block;
  text-align: center;
}
}
`