import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  DELETE_TAG,
  ADD_TAG,
  UPDATE_TAG,
} from '../../../../config/constants/ActionTypes'

//Get types Request
export const getTags = (payload) => {
  return {
    type: GET_TAGS,
    payload,
  }
}

//Get types Success
export const getTagsSuccess = (types) => {
  return {
    type: GET_TAGS_SUCCESS,
    payload: { types },
  }
}

//Get types Failure
export const getTagsFailure = (error) => {
  return {
    type: GET_TAGS_FAILURE,
    payload: { error },
  }
}

//Delete Tag
export const deleteTag = (payload) => {
  return {
    type: DELETE_TAG,
    payload,
  }
}

//Add Tag
export const addTag = (payload) => {
  return {
    type: ADD_TAG,
    payload,
  }
}

//update Tag
export const updateTag = (payload) => {
  return {
    type: UPDATE_TAG,
    payload,
  }
}
