import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_SUB_CATEGORIES,
  DELETE_SUB_CATEGORY,
  ADD_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
} from '../../../../config/constants/ActionTypes'
import { getSubCategories, getSubCategoriesSuccess, getSubCategoriesFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getSubCategoriesRequest = async ({ query, lite }) => {
  return await axios({
    method: 'get',
    url: config.url + 'subCategories/paginated',
    params: {
      ...query,
      isDeleted: false,
      token: localStorage.getItem('token'),
    },
    headers: { lite },
  })
}

function* getSubCategoriesSaga({ payload }) {
  try {
    const response = yield call(getSubCategoriesRequest, payload)
    yield put(getSubCategoriesSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getSubCategoriesFailure(error))
    payload.onFailure(error)
  }
}

const deleteSubCategoryRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'subCategories',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteSubCategorySaga({ payload }) {
  try {
    const response = yield call(deleteSubCategoryRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addSubCategoryRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'subCategories',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addSubCategorySaga({ payload }) {
  try {
    const response = yield call(addSubCategoryRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateSubCategoryRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'subCategories',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateSubCategorySaga({ payload }) {
  try {
    const response = yield call(updateSubCategoryRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getSubCategories(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_SUB_CATEGORIES, getSubCategoriesSaga)],
    [yield takeEvery(DELETE_SUB_CATEGORY, deleteSubCategorySaga)],
    [yield takeEvery(ADD_SUB_CATEGORY, addSubCategorySaga)],
    [yield takeEvery(UPDATE_SUB_CATEGORY, updateSubCategorySaga)],
  )
}
