import React from 'react'
import Button from 'antd/lib/button'
import styled from 'styled-components'

const AddButton = (props) => {
  return (
    <AddButtonStyle {...props}>
      {props.text}
    </AddButtonStyle>
  )
}

const AddButtonStyle = styled(Button)`
  background-color: var(--color-secondary);
  color: var(--color-font-white);
  box-shadow: 0 4px 25px 0 rgba(0,0,0,0.1);
  border-radius: .5rem;
  font-weight: 500;
  font-size:18px;
  text-transform: uppercase;
  padding: 0px 2rem;
  height: 100%;
  border: 0px;
  padding: 10px;

  @media (max-width: 767px){
    padding: 8px 6px;
    font-size: 14px;
    width: 40% !important;

  }

  :focus {
    background-color: var(--color-font-white) !important;
    color: var(--color-secondary);
   

  }

  :hover {
    background-color: var(--color-font-white) !important;
    color: var(--color-secondary) 
  }
`

export default AddButton
