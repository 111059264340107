import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTION,
  ADD_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from '../../../../config/constants/ActionTypes'
import { getSubscriptions, getSubscriptionsSuccess, getSubscriptionsFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getSubscriptionsRequest = async ({ query }) => {
  return await axios({
    method: 'get',
    url: config.url + 'subscriptions',
    params: {
      ...query,
      isDeleted: false,
    },
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getSubscriptionsSaga({ payload }) {
  try {
    const response = yield call(getSubscriptionsRequest, payload)
    yield put(getSubscriptionsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getSubscriptionsFailure(error))
    payload.onFailure(error)
  }
}

const deleteSubscriptionRequest = async ({ _id }) => {
  return await axios({
    method: 'put',
    url: config.url + 'subscriptions',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
    data: { isDeleted: true },
  })
}

function* deleteSubscriptionSaga({ payload }) {
  try {
     yield call(deleteSubscriptionRequest, payload)
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addSubscriptionRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'subscriptions',
    params: {
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* addSubscriptionSaga({ payload }) {
  try {
    const response = yield call(addSubscriptionRequest, payload)
    payload.onSuccess(path(['data', '_id'], response))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateSubscriptionRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'subscriptions',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateSubscriptionSaga({ payload }) {
  try {
    yield call(updateSubscriptionRequest, payload)
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getSubscriptions(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_SUBSCRIPTIONS, getSubscriptionsSaga)],
    [yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscriptionSaga)],
    [yield takeEvery(ADD_SUBSCRIPTION, addSubscriptionSaga)],
    [yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscriptionSaga)],
  )
}
