import {
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.payload.cities,
      }
    }

    case GET_CITIES_FAILURE: {
      return {
        ...state,
        getCitiesError: action.payload.error,
      }
    }

    default:
      return state
  }
}
