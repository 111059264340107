import { tableFields, fields, formFields } from '../schema'
import { 
  getPackageUnits,
  deletePackageUnit,
  addPackageUnit,
  editPackageUnit,
} from '../../../modules/PackageUnit/store/actions'
import { getSizes } from '../../../modules/Size/store/actions'
import arabic_labels from '../../constants/arabic_labels.json'

export const packageUnits = {
  view: {
    get: getPackageUnits,
    delete: deletePackageUnit,
    fields: [
      tableFields.text(arabic_labels['form.name'], 'name'),
      tableFields.action.edit(),
      tableFields.action.delete(),
    ],
  },
  add: {
    create: addPackageUnit,
    fields: [
      fields.text({ title: arabic_labels['form.name'], dataIndex: 'name', validate: (fieldValue) => (!fieldValue) ? 'لا بد من ادخال الاسم' : true }),
      formFields.select(
        arabic_labels['form.size'],
        'sizes',
        'multiple',
        { query: getSizes },
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        null,
        null,
        {
          key: 'size'
        }
      ),
    ]
  },
  edit: {
    update: editPackageUnit,
    get: getPackageUnits,
    fields: [
      fields.text({ title: arabic_labels['form.name'], dataIndex: 'name' }),
      fields.object({
        title: arabic_labels['form.size'],
        dataIndex: 'sizes',
        fields: [
          formFields.select(
            '',
            '_id',
            'multiple',
            { query: getSizes },
            null,
            ['_id'],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            {
              key: 'size'
            }
          ),
        ],
      }),
    ],
  },
}
