import { GET_TAGS_SUCCESS, GET_TAGS_FAILURE } from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload.tags,
      }
    }

    case GET_TAGS_FAILURE: {
      return {
        ...state,
        getTagsError: action.payload.error,
      }
    }

    default:
      return state
  }
}
