import React, { useState, useEffect } from 'react'
import { path } from 'ramda'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { config } from '../../../../config/Structure'
import CrudForm from '../../../Form'
import { connect } from 'react-redux'
import queryString from 'query-string'

const EditStaffForm = ({ dispatch }) => {
  const { staff } = config
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const query = queryString.parse(location.search)

  useEffect(() => {
    setLoading(true)
    dispatch(
      config.staff.edit.get({
        query: { _id: id },
        onSuccess: onFetchSuccess,
        onFailure: onFetchFailure,
      }),
    )
  }, []) // eslint-disable-line

  const onSubmit = (values) => {
    setSubmitting(true)
    values['sellerCategory'] =
      values['sellerCategory'].includes('distributors') ? 'Distributor' : 'Company'
    dispatch(staff.edit.update({ body: { ...values, _id: id }, onSuccess, onFailure }))
  }
  const onSuccess = () => {
    setSubmitting(false)
    message.success('تم التعديل بنجاح')
    const redirectQuery = queryString.parse(location.search)
    let finalQuery = queryString.stringify(redirectQuery)
    history.push(`/staffs?${finalQuery}`)
  }

  const onFailure = (error) => {
    setSubmitting(false)
    message.error('حدث خطأ')
  }

  const onFetchSuccess = (record) => {
    record['sellerCategory'] =
      record['sellerCategory'] === 'Company' ? 'companies/getCompanies' : 'distributors/getDistributors'
    setLoading(false)
    if (query['sellerRef'] && path(['sellerRef'], record) !== query['sellerRef']) {
      return history.push('/companies')
    }
    setData(record)
  }

  const onFetchFailure = (error) => {
    setLoading(false)
    message.error('حدث خطأ')
  }
  return (
    <CrudForm
      title=' تعديل عامل'
      loading={loading}
      fields={config.staff.edit.fields}
      onSubmit={onSubmit}
      buttonText='تعديل'
      initialValues={data}
      submitting={submitting}
    />
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(EditStaffForm)
