import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_REPS,
  DELETE_REP,
  ADD_REP,
  UPDATE_REP,
  GET_REP,
} from '../../../../config/constants/ActionTypes'
import { getReps, getRepsSuccess, getRepsFailure } from '../actions'
import config from '../../../../config/Client/sagas/config'
import { path } from 'ramda'
import axios from 'axios'

const getRepsRequest = async ({ query }) => {
  return await axios({
    method: 'post',
    url: config.url + 'reps/getReps',
    params: { ...query, isDeleted: false },
    headers: { token: `${localStorage.getItem('token')}` },
  })
}

function* getRepsSaga({ payload }) {
  try {
    const response = yield call(getRepsRequest, payload)
    yield put(getRepsSuccess(response.data))
    payload.onSuccess(response.data)
  } catch (error) {
    yield put(getRepsFailure(error))
    payload.onFailure(error)
  }
}

const getRepRequest = async ({ query }) => {
  return await axios({
    method: 'post',
    url: config.url + 'reps/getReps',
    params: {
      ...query,
      isDeleted: false,
    },
  })
}

function* getRepSaga({ payload }) {
  try {
    const response = yield call(getRepRequest, payload)
    payload.onSuccess(response.data)
  } catch (error) {
    payload.onFailure(error)
  }
}

const deleteRepRequest = async ({ _id }) => {
  return await axios({
    method: 'delete',
    url: config.url + 'reps',
    params: {
      _id,
      token: localStorage.getItem('token'),
    },
  })
}

function* deleteRepSaga({ payload }) {
  try {
    const response = yield call(deleteRepRequest, payload) // eslint-disable-line
    delete payload._id
    payload.getRecords(payload.query.skip)
  } catch (error) {
    payload.onFailure(error)
  }
}

const addRepRequest = async ({ body }) => {
  return await axios({
    method: 'post',
    url: config.url + 'reps',
    params: {
      token: localStorage.getItem('token'),
    },
    data: { ...body, images: [] },
  })
}

function* addRepSaga({ payload }) {
  try {
    const response = yield call(addRepRequest, payload)
    payload.onSuccess(path(['data', '_id'], response), path(['body', 'images'], payload))
  } catch (error) {
    payload.onFailure(error)
  }
}

const updateRepRequest = async ({ body }) => {
  return await axios({
    method: 'put',
    url: config.url + 'reps',
    params: {
      _id: body._id,
      token: localStorage.getItem('token'),
    },
    data: body,
  })
}

function* updateRepSaga({ payload }) {
  try {
    const response = yield call(updateRepRequest, payload) // eslint-disable-line
    if (payload?.OnFlyAddFlag) payload.onSuccess(payload?.body?._id)
    else {
      delete payload.body
      yield put(getReps(payload))
    }
  } catch (error) {
    payload.onFailure(error)
  }
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_REPS, getRepsSaga)],
    [yield takeEvery(DELETE_REP, deleteRepSaga)],
    [yield takeEvery(ADD_REP, addRepSaga)],
    [yield takeEvery(UPDATE_REP, updateRepSaga)],
    [yield takeEvery(GET_REP, getRepSaga)],
  )
}
