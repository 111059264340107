import {
  GET_SUB_CATEGORIES,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_FAILURE,
  DELETE_SUB_CATEGORY,
  ADD_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
} from '../../../../config/constants/ActionTypes'

//Get SubCategories Request
export const getSubCategories = (payload) => {
  return {
    type: GET_SUB_CATEGORIES,
    payload,
  }
}

//Get SubCategories Success
export const getSubCategoriesSuccess = (subCategories) => {
  return {
    type: GET_SUB_CATEGORIES_SUCCESS,
    payload: { subCategories },
  }
}

//Get SubCategories Failure
export const getSubCategoriesFailure = (error) => {
  return {
    type: GET_SUB_CATEGORIES_FAILURE,
    payload: { error },
  }
}

//Delete SubCategory
export const deleteSubCategory = (payload) => {
  return {
    type: DELETE_SUB_CATEGORY,
    payload,
  }
}

//Add SubCategory
export const addSubCategory = (payload) => {
  return {
    type: ADD_SUB_CATEGORY,
    payload,
  }
}

//update SubCategory
export const updateSubCategory = (payload) => {
  return {
    type: UPDATE_SUB_CATEGORY,
    payload,
  }
}
