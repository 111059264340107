import {
  GET_COMPANY_PRODUCTS,
  GET_COMPANY_PRODUCTS_SUCCESS,
  GET_COMPANY_PRODUCTS_FAILURE,
  DELETE_COMPANY_PRODUCT,
  ADD_COMPANY_PRODUCT,
  ADD_COMPANY_PRODUCTS,
  UPDATE_COMPANY_PRODUCT,
  SEARCH_MODULES,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
} from '../../../../config/constants/ActionTypes'

//Search Modules Request
export const search = (payload) => {
  return {
    type: SEARCH_MODULES,
    payload,
  }
}

//Search Modules Success
export const searchSuccess = (items) => {
  return {
    type: SEARCH_SUCCESS,
    payload: { items },
  }
}

//Search Modules Failure
export const searchFailure = (error) => {
  return {
    type: SEARCH_FAILURE,
    payload: { error },
  }
}
//Get CompanyProducts Request
export const getCompanyProducts = (payload) => {
  return {
    type: GET_COMPANY_PRODUCTS,
    payload,
  }
}

//Get CompanyProducts Success
export const getCompanyProductsSuccess = (companyProducts) => {
  return {
    type: GET_COMPANY_PRODUCTS_SUCCESS,
    payload: { companyProducts },
  }
}

//Get CompanyProducts Failure
export const getCompanyProductsFailure = (error) => {
  return {
    type: GET_COMPANY_PRODUCTS_FAILURE,
    payload: { error },
  }
}

//Delete CompanyProduct
export const deleteCompanyProduct = (payload) => {
  return {
    type: DELETE_COMPANY_PRODUCT,
    payload,
  }
}

//Add CompanyProduct
export const addCompanyProduct = (payload) => {
  return {
    type: ADD_COMPANY_PRODUCT,
    payload,
  }
}

//Add CompanyProducts
export const addCompanyProducts = (payload) => {
  return {
    type: ADD_COMPANY_PRODUCTS,
    payload,
  }
}

//update CompanyProducts
export const updateCompanyProduct = (payload) => {
  return {
    type: UPDATE_COMPANY_PRODUCT,
    payload,
  }
}
