import {
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
} from '../../../../config/constants/ActionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PACKAGES_SUCCESS: {
      return {
        ...state,
        packages: action.payload.packages,
      }
    }

    case GET_PACKAGES_FAILURE: {
      return {
        ...state,
        getPackagesError: action.payload.error,
      }
    }

    default:
      return state
  }
}
