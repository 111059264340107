/* eslint-disable */
import React, { useState, useEffect } from 'react'
import View from '../../../View/components/View'
import { config } from '../../../../config/Structure'
import styled from 'styled-components'
import XLSX from 'xlsx';
import { getObject } from '../../../../services/commonServices'
import { message } from 'antd'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver';
import axios from 'axios'
import configUrl from '../../../../config/Client/sagas/config'
import { addCompanyProducts } from '../../../CompanyProduct/store/actions'
import { uploadImages } from '../../../../config/Client/actions/Upload'
import { getCompanyProducts } from '../../../CompanyProduct/store/actions'
import { getBuyerCategories } from '../../../BuyerCategory/store/actions'
import { getAllPackagesId } from '../../../Package/store/actions'
import { useHistory, useLocation } from 'react-router-dom'








const CompanyProductsTable = ({ dispatch, state }) => {
  const [recordData, setRecordData] = useState([])
  const [fetchedData, setFetchedData] = useState(false)
  const [companyId, setCompanyId] = useState('');
  const [checkExcelFile, setCheckExcelFile] = useState(false);
  const [checkSheetErrorsArr, setCheckSheetErrorsArr] = useState();
  const [dataRowOut, setDataRowOut] = useState();
  const [buyerCategoriesIds, setBuyerCategoriesIds] = useState({
    Wholesale: '', Retail: '', قطاعى_وجملة: '', VIC: '', KeyRetail: ''
  });
  const [productsNumber, setProductsNumber] = useState(0);
  const [brandId, setBrandId] = useState('');
  const [countryId, setCountryId] = useState('');
  const [decrementedCount, setDecrementedCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const { companyProducts } = config
  const history = useHistory()
  const location = useLocation()

  const readFile = (file, fileType) => {
    setUploadLoader(true)
    let sheetErrors = [];
    if (!file || fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      message.error('xlsx يجب أن يكون نوع الملف ')
      sheetErrors = [...sheetErrors, 'xlsx يجب أن يكون نوع الملف ']
      return false;
    } else {
      const reader = new FileReader();
      let dataFile;
      reader.onload = (e) => {

        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets['المنتجات'];



        const checkFile = () => {
          if (worksheet?.A2 === undefined || worksheet?.A2 === null) {
            message.error('لا يمكن إضافة ملف فارغ')
            sheetErrors = [...sheetErrors, 'لا يمكن إضافة ملف فارغ']
            setCheckExcelFile(false)
            return false;
          }

          if (worksheet !== undefined &&
            !(worksheet.A1.v === "section" && worksheet.B1.v === "category" && worksheet.C1.v === "subCategory" && worksheet.D1.v === "type" && worksheet.E1.v === "subType" && worksheet.F1.v === "tags" &&
              worksheet.G1.v === "name" && worksheet.H1.v === "country" && worksheet.I1.v === "brand" && worksheet.J1.v === "erpCode" && worksheet.K1.v === "barcode_package" && worksheet.L1.v === "barcode_unit" &&
              worksheet.M1.v === "saleUnit_package" && worksheet.N1.v === "packageUnit" && worksheet.O1.v === "saleUnit_packageQuantity" &&
              worksheet.P1.v === "Wholesale_السعر" && worksheet.Q1.v === "Retail_السعر" && worksheet.R1.v === "السعر_قطاعى_وجملة" && worksheet.S1.v === "VIC_السعر" &&
              worksheet.T1.v === "key_retail_السعر" &&
              worksheet.U1.v === "sizes_size" && worksheet.V1.v === "sizes_quantity" && worksheet.W1.v === "description" && worksheet.X1.v === "points" &&
              worksheet.Y1.v === "featured" && worksheet.Z1.v === "sku")
          ) {
            message.error('أسماء الأعمدة لا تطابق النموذج')
            setCheckExcelFile(false)
            sheetErrors = [...sheetErrors, 'أسماء الأعمدة لا تطابق النموذج']
            return false;
          }
          setCheckExcelFile(true)
          return true;
        }
        dataFile = XLSX.utils.sheet_to_json(worksheet,
          { blankRows: false, defval: '' });

        const checkRequiredFields = (dataFile) => {
          let deletedCount = 0
          dataFile.map((record, key) => {
            if (record.name === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون الاسم`)
            }
            if (record.section === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون الصناعة`)
            }
            if (record.category === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون القسم`)
            }
            if (record.subCategory === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون القسم الفرعى`)
            }
            if (record.type === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون المجموعة الرئيسيه`)
            }
            if (record.subType === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون المجموعة الفرعيه`)
            }
            if (record.tags === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون الأوصاف`)
            }
            if (record.country === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون البلد`)
            }
            if (record.brand === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون الماركة`)
            }
            if (record.barcode_package === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون باركود العلبه`)
            }
            if (record.barcode_unit === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون باركود الوحده`)
            }
            if (record.erpCode === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون اي ار بي كود`)
            }
            if (record.packageUnit === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون وحدة البيع الفرعيه`)
            }
            if (record.sizes_size === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون وحدة الوزن`)
            }
            if (record.sizes_quantity === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون الوزن`)
            }
            if (record.description === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون الوصف`)
            }
            if (record.Wholesale_السعر === '' && record.Retail_السعر === '' &&
              record.السعر_قطاعى_وجملة === '' && record.VIC_السعر === '' &&
              record.key_retail_السعر === '') {
              deletedCount++
              setCheckSheetErrorsArr(false)
              return message.error(`لا يمكن إضافة المنتح ${key + 1} بدون القطاعات`)
            }
            return false
          })
          setDecrementedCount(deletedCount)
        }
        checkRequiredFields(dataFile)
        const allData = dataFile.filter((record) => {
          if (record.name !== '' && record.section !== '' && record.category !== ''
            && record.subCategory !== '' && record.type !== '' && record.subType !== ''
            && record.tags !== '' && record.country !== '' && record.brand !== ''
            && record.barcode_package !== '' && record.barcode_unit !== ''
            && record.erpCode !== '' && record.packageUnit !== ''
            && (record.Wholesale_السعر !== '' || record.Retail_السعر !== '' || record.السعر_قطاعى_وجملة !== ''
              || record.VIC_السعر !== '' || record.key_retail_السعر !== '')
            && record.sizes_size !== '' && record.sizes_quantity !== '' && record.description !== '') {
            return true
          } else {
            return false
          }
        })

        setProductsNumber(dataFile.length)
        if (dataFile && dataFile !== undefined && allData && allData !== undefined && checkFile(worksheet) === true && companyId && companyId !== '') {
          message.loading('انتظر قليلا حتى يتم اضافة المنتجات الجديده' ,8)
          allData.map((record1) => {
            const dataRow = {
              barcode: { package: String(record1.barcode_package), unit: String(record1.barcode_unit) },
              brand: '',
              buyerCategories: [],
              category: '',
              company: companyId,
              country: '',
              description: { ar: record1.description },
              erpCode: String(record1.erpCode).trim(),
              featured: record1.featured,
              name: { ar: record1.name },
              packageUnit: '',
              points: record1.points,
              section: '',
              sizes: { size: '', quantity: String(record1.sizes_quantity) },
              subCategory: '',
              subType: '',
              tags: [],
              type: '',
              sku: String(record1.sku)
            }

            //buyerCategory
            if (record1.saleUnit_package && record1.saleUnit_package !== '') {
              dispatch(getAllPackagesId({
                query: { 'name': record1.saleUnit_package, isDeleted: false },
                onSuccess: (res) => {
                  const saleUnit_packageObj = res[0]
                    if (record1.Wholesale_السعر && record1.Wholesale_السعر !== ''
                      && buyerCategoriesIds && buyerCategoriesIds !== {} && buyerCategoriesIds.Wholesale && buyerCategoriesIds.Wholesale !== ''
                    ) {
                      const buyerCategoryObj = {
                        buyerCategory: buyerCategoriesIds.Wholesale,
                        saleUnit: {
                          package: saleUnit_packageObj._id ? saleUnit_packageObj._id : '',
                          packageQuantity: record1.saleUnit_packageQuantity ? String(record1.saleUnit_packageQuantity) : '',
                          price: record1.Wholesale_السعر && record1.Wholesale_السعر !== '' ? String(record1.Wholesale_السعر) : ''
                        }
                      }
                      if (buyerCategoryObj && buyerCategoryObj !== {}) {
                        dataRow.buyerCategories.push(buyerCategoryObj)
                      }
                    }
                    if (record1.Retail_السعر && record1.Retail_السعر !== ''
                      && buyerCategoriesIds && buyerCategoriesIds !== {} && buyerCategoriesIds.Retail && buyerCategoriesIds.Retail !== ''
                    ) {
                      const buyerCategoryObj = {
                        buyerCategory: buyerCategoriesIds.Retail,
                        saleUnit: {
                          package: saleUnit_packageObj._id ? saleUnit_packageObj._id : '',
                          packageQuantity: record1.saleUnit_packageQuantity ? String(record1.saleUnit_packageQuantity) : '',
                          price: record1.Retail_السعر && record1.Retail_السعر !== '' ? String(record1.Retail_السعر) : ''
                        }
                      }
                      if (buyerCategoryObj && buyerCategoryObj !== {}) {
                        dataRow.buyerCategories.push(buyerCategoryObj)
                      }
                    }
                    if (record1.key_retail_السعر && record1.key_retail_السعر !== ''
                      && buyerCategoriesIds && buyerCategoriesIds !== {} && buyerCategoriesIds.KeyRetail && buyerCategoriesIds.KeyRetail !== ''
                    ) {
                      const buyerCategoryObj = {
                        buyerCategory: buyerCategoriesIds.KeyRetail,
                        saleUnit: {
                          package: saleUnit_packageObj._id ? saleUnit_packageObj._id : '',
                          packageQuantity: record1.saleUnit_packageQuantity ? String(record1.saleUnit_packageQuantity) : '',
                          price: record1.key_retail_السعر && record1.key_retail_السعر !== '' ? String(record1.key_retail_السعر) : ''
                        }
                      }
                      if (buyerCategoryObj && buyerCategoryObj !== {}) {
                        dataRow.buyerCategories.push(buyerCategoryObj)
                      }
                    }
                    if (record1.VIC_السعر && record1.VIC_السعر !== ''
                      && buyerCategoriesIds && buyerCategoriesIds !== {} && buyerCategoriesIds.VIC && buyerCategoriesIds.VIC !== ''
                    ) {
                      const buyerCategoryObj = {
                        buyerCategory: buyerCategoriesIds.VIC,
                        saleUnit: {
                          package: saleUnit_packageObj._id ? saleUnit_packageObj._id : '',
                          packageQuantity: record1.saleUnit_packageQuantity ? String(record1.saleUnit_packageQuantity) : '',
                          price: record1.VIC_السعر && record1.VIC_السعر !== '' ? String(record1.VIC_السعر) : ''
                        }
                      }
                      if (buyerCategoryObj && buyerCategoryObj !== {}) {
                        dataRow.buyerCategories.push(buyerCategoryObj)
                      }
                    }
                    if (record1.السعر_قطاعى_وجملة && record1.السعر_قطاعى_وجملة !== ''
                      && buyerCategoriesIds && buyerCategoriesIds !== {} && buyerCategoriesIds.قطاعى_وجملة && buyerCategoriesIds.قطاعى_وجملة !== ''
                    ) {
                      const buyerCategoryObj = {
                        buyerCategory: buyerCategoriesIds.قطاعى_وجملة,
                        saleUnit: {
                          package: saleUnit_packageObj._id ? saleUnit_packageObj._id : '',
                          packageQuantity: record1.saleUnit_packageQuantity ? String(record1.saleUnit_packageQuantity) : '',
                          price: record1.السعر_قطاعى_وجملة && record1.السعر_قطاعى_وجملة !== '' ? String(record1.السعر_قطاعى_وجملة) : ''
                        }
                      }
                      if (buyerCategoryObj && buyerCategoryObj !== {}) {
                        dataRow.buyerCategories.push(buyerCategoryObj)
                      }
                    }
                },
                onFailure: (err) => { console.log('err', err) }
              }))
            }

            //section
            if (record1.section && record1.section !== '') {
              getObject('sections/allSectionsId', { 'name.ar': record1.section, isDeleted: false })
                .then((res) => {
                  res.data.map((secObj) => {
                    return dataRow.section = secObj._id ? secObj._id : ''
                  })
                  //category
                  if (record1.category && record1.category !== '') {
                    getObject('categories/allCategoriesId', { 'name.ar': record1.category, isDeleted: false })
                      .then((res) => {
                        res.data.map((catObj) => {
                          return dataRow.category = catObj._id ? catObj._id : ''
                        })
                        //subCategory
                        if (record1.subCategory && record1.subCategory !== '') {
                          getObject('subCategories/allSubCategoriesId', { 'name.ar': record1.subCategory, isDeleted: false })
                            .then((res) => {
                              res.data.map((subCatObj) => {
                                return dataRow.subCategory = subCatObj._id ? subCatObj._id : ''
                              })
                              //type
                              if (record1.type && record1.type !== '') {
                                getObject('types/allTypesId', { 'name.ar': record1.type, isDeleted: false })
                                  .then((res) => {
                                    res.data.map((typeObj) => {
                                      return dataRow.type = typeObj._id ? typeObj._id : ''
                                    })
                                    //subType
                                    if (record1.subType && record1.subType !== '') {
                                      getObject('subTypes/allSubTypesId', { 'name.ar': record1.subType, isDeleted: false })
                                        .then((res) => {
                                          res.data.map((subTypeObj) => {
                                            return dataRow.subType = subTypeObj._id ? subTypeObj._id : ''
                                          })
                                          //tag
                                          if (record1.tags && record1.tags !== '') {
                                            getObject('tags/allTagsId', { 'name.ar': record1.tags, isDeleted: false })
                                              .then((res) => {
                                                res.data.map((tagsObj) => {
                                                  return dataRow.tags = tagsObj._id ? [tagsObj._id] : []
                                                })

                                                //country
                                                if (record1.country && record1.country !== '') {
                                                  getObject('countries/allCountriesId', { 'name': record1.country, isDeleted: false })
                                                    .then((res) => {
                                                      res.data.map((countryObj) => {
                                                        setCountryId(countryObj._id ? countryObj._id : '')
                                                        return dataRow.country = countryObj._id ? countryObj._id : ''
                                                      })
                                                      //brand
                                                      if (record1.brand && record1.brand !== '') {
                                                        getObject('brands/allBrandsId', { 'name.ar': record1.brand, isDeleted: false })
                                                          .then((res) => {
                                                            let id = ''
                                                            res.data.map((brandObj, key) => {
                                                              id = brandObj.company
                                                              if (brandObj.company === companyId) setBrandId(brandObj._id ? brandObj._id : '')
                                                              return dataRow.brand = brandObj._id ? brandObj._id : ''
                                                            })
                                                            if (id !== '' && id !== companyId) {
                                                              message.error('يجب أن تكون الماركة مطابقة للشركة  ')
                                                              setCheckSheetErrorsArr(false)
                                                            } else {
                                                              setCheckSheetErrorsArr(true)
                                                            }
                                                            //packageUnit
                                                            if (record1.packageUnit && record1.packageUnit !== '') {
                                                              getObject('packageUnits/allPackageUnitsId', { 'name': record1.packageUnit, isDeleted: false })
                                                                .then((res) => {
                                                                  res.data.map((packageUnitObj) => {
                                                                    return dataRow.packageUnit = packageUnitObj._id ? packageUnitObj._id : ''
                                                                  })
                                                                  //sizes_size
                                                                  if (record1.sizes_size && record1.sizes_size !== '') {
                                                                    getObject('sizes/allSizesId', { 'name.ar': record1.sizes_size, isDeleted: false })
                                                                      .then((res) => {
                                                                        res.data.map((sizes_sizeObj) => {
                                                                          return dataRow.sizes = {
                                                                            size: sizes_sizeObj._id ? sizes_sizeObj._id : '',
                                                                            quantity: record1.sizes_quantity ? String(record1.sizes_quantity) : ''
                                                                          }
                                                                        })
                                                                        setDataRowOut(dataRow)
                                                                      })
                                                                      .catch(() => { })
                                                                  }
                                                                })
                                                                .catch(() => { });
                                                            }
                                                          })
                                                          .catch(() => { });
                                                      }
                                                    })
                                                    .catch(() => { });
                                                }
                                              })
                                              .catch(() => { });
                                          }
                                        })
                                        .catch(() => { });
                                    }
                                  })
                                  .catch(() => { });
                              }
                            })
                            .catch(() => { });
                        }
                      })
                      .catch(() => { });
                  }
                })
                .catch(() => { });
            }

            if (record1.brand === '') {
              message.error('يجب أن تكون الماركة مطابقة للشركة  ')
              setCheckSheetErrorsArr(false)
            } else {
              setCheckSheetErrorsArr(true)
            }
            setFetchedData(true)
            return true;
          })
        }
      }

      reader.onerror = (err) => {
        return { error: err }
      }
      reader.readAsArrayBuffer(file);
    } 
  }

  const onSuccess = ({ data, count }) => {
    let result = data.filter((item) => item !== null)
    let buyerList = {}
    result.map((item) => {
      if(item.name && item._id && item.name.ar === 'Wholesale'){
        buyerList = {...buyerList,'Wholesale':item._id}
      }
      if(item.name && item._id && item.name.ar === 'Retail'){
        buyerList = {...buyerList,'Retail':item._id}
      }
      if(item.name && item._id && item.name.ar === 'قطاعى وجملة'){
        buyerList = {...buyerList,'قطاعى_وجملة':item._id}
      }
      if(item.name && item._id && item.name.ar === 'VIC'){
        buyerList = {...buyerList,'VIC':item._id}
      }
      if(item.name && item._id && item.name.ar === 'key retail'){
        buyerList = {...buyerList,'KeyRetail':item._id}
      }
      return buyerList
    })
    if(buyerList && buyerList !== {}){
      setBuyerCategoriesIds(buyerList)
    }
  }

  const onFailure = (error) => {
    message.error('حدث خطأ')
  }

  useEffect(() => {
    dispatch(getBuyerCategories({ onSuccess, onFailure }))
  }, []) // eslint-disable-line
  
  useEffect(() => {
    if(dataRowOut !== null && dataRowOut !== {} &&
       dataRowOut !== undefined && checkSheetErrorsArr !== undefined &&
       checkSheetErrorsArr === true){
      setRecordData([...recordData, {...dataRowOut, brand: brandId, country: countryId}])
    }
  },[dataRowOut,checkSheetErrorsArr])



  useEffect(() => {
    if (fetchedData && recordData.length === (productsNumber - decrementedCount)) {
      if (recordData !== null && recordData !== [] && recordData.length > 0){
          dispatch(addCompanyProducts({
            body: recordData,
          onSuccess: () => {
            message.success(`تم اضافه المنتجات الجديده`)
            setReload(true)
            setDataRowOut()
            setRecordData([])
            setUploadLoader(false)
          },
          onFailure: (err) => {
            message.error('حدث خطأ')
            setReload(true)
            setDataRowOut()
            setRecordData([])
            setUploadLoader(false)
          }
        }))
      }
    }
  }, [recordData,fetchedData]) // eslint-disable-line

  const downloadExcelFile = (val) => {
    let query = {}
    if(companyId && companyId !== ''){
      query = { company: companyId }
    }
    axios({
      method:'GET',
      url: `${configUrl.url}companyProducts/getExcelTemp`,      
      responseType: 'blob',
      params: {
        ...query
      }
    }).then((res) => {
      if(res?.data.size > 0){
        saveAs(res.data, `Add Products - ${new Date().toLocaleString('en-eg', {
          day: 'numeric',
          month: 'short',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })}.xlsx`);
      }
    });
  }
  const [imgsUpload,setImgsUpload] = useState([])
  const getImages = (event) => {
    message.loading('انتظر قليلا حتى يتم اضافة الصور' ,8)
    setUploadLoader(true)
    let files = event.target.files;
    let directPath = []
    let images = []
    let companyId = ''
    let companyErpCode = ''
    let type = ''
    const onFetchSuccess = (records) => {
      records.data.map((company) => {
        companyId = company._id
        companyErpCode = company.erpCode
        return companyId
      })
      if(!records || records.count === 0){
        message.error('لا يمكن إضافة صور لمنتج غير موجود')
        setUploadLoader(false)
      }

      if(companyId && companyId !== '' && companyErpCode && companyErpCode !== '' 
          && images && images !== []){
        setImgsUpload([...imgsUpload,{
          imgFile: images.filter((img) =>{
            return img.webkitRelativePath.split('/')[directPath] === companyErpCode
         }),id: companyId, erpCode: companyErpCode
        }])
      }
    }

    const onFetchFailure = (error) => {
      message.error('حدث خطأ')
      setUploadLoader(false)
    }

    let erpList = [];
    const fileTypes = ['jpeg', 'jpg', 'png'];
    [...files].forEach((file,index) => {
      let erpCode = file.webkitRelativePath.split('/')[file.webkitRelativePath.split('/').length-2]
      if(fileTypes.includes(file.webkitRelativePath.split('.')[1]) && !erpList.includes(erpCode)){
        erpList = [...erpList,erpCode]
      }
    });
    if(erpList && erpList !== [] && erpList.length > 0) {
      erpList.map((erp) => {
        dispatch(
          getCompanyProducts({
            query: { erpCode: erp},
            onSuccess: onFetchSuccess,
            onFailure: onFetchFailure,
          }),
        )
        return true;
      })
    }
  

    for (let i = 0; i < files.length; i++) {
      directPath = files[i].webkitRelativePath.split('/').length-2
      let temp = files[i].webkitRelativePath.split('.')
      type = temp[temp.length-1]
      if(type === 'jpg' || type === 'png' || type === 'jpeg'){
        images = [...images,files[i]]        
      }
    }
    event.target.value = null;
  }
  const onEditFailure = (error) => {
    message.error('حدث خطأ')
    setUploadLoader(false)
  }

  useEffect(() => {
    const onUploadSuccess = (imageLinkArray, values, id) => {
      const tempComapyId = companyId
      dispatch(companyProducts.edit.update({
        body: { images: imageLinkArray && imageLinkArray !== []? imageLinkArray : '' , _id: id, company: {_id: tempComapyId} },
        onSuccess: () => {
          message.success('تمت اضافة الصور للمنتج بنجاح')
          setReload(true)
          setUploadLoader(false)
        },
        onFailure: onEditFailure
      }))
    }

    const onFailure = (error) => {
      message.error('حدث خطأ')
      setUploadLoader(false)
    }

    if(imgsUpload && imgsUpload !== [] && imgsUpload.length > 0){
      dispatch(uploadImages({
        imageArray: imgsUpload[0].imgFile,
        moduleName: 'companyProducts',
        _id: imgsUpload[0].id,
        onSuccess: onUploadSuccess,
        onFailure,
      }))
    }



  },[imgsUpload,dispatch,companyProducts,history])

  return (
    <div>
      <TableHolder>
        <View componentName='companyProducts' config={config.companyProducts} 
              readFile={(file,fileType) => readFile(file,fileType)} downloadExcelFile={ () => downloadExcelFile()}
              getCompanyId={ (id) => setCompanyId(id)} checkFile={checkExcelFile} checkSheetErrors={checkSheetErrorsArr}
              getImages={getImages}
              refetchData={reload}
              setReload={setReload}
              uploadLoader={uploadLoader}
              />
      </TableHolder>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state,
})

export default connect(mapStateToProps)(CompanyProductsTable)


const TableHolder  = styled.div`


@media (max-width:767px){

  td:nth-child(7), td:nth-child(8) {
    display: inline-block;
    text-align: center;
  }
}
`