import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { config } from "../../../../config/Structure";
import { Row, message } from "antd";
import CrudForm from "../../../Form";

const SettingsTable = ({ dispatch }) => {
  const { settings } = config;
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(
      settings.edit.get({
        query: { _id: "601985a101d1462cd0346a74" },
        onSuccess: onFetchSuccess,
        onFailure: onFetchFailure,
      })
    );
  },[settings,dispatch]);

  const onFetchSuccess = ({ settings }) => {
    setLoading(false);
    setData(settings[Object.keys(settings)[0]]);
  };

  const onFetchFailure = (error) => {
    setLoading(false);
    message.error("حدث خطأ");
  };

  const onSubmit = (values) => {
    setSubmitting(true);
    dispatch(
      settings.edit.update({
        body: {
          conversionRate: values["conversionRate"],
          _id: data["_id"],
        },
        onSuccess,
        onFailure,
      })
    );
  };

  const onSuccess = () => {
    setSubmitting(false);
    message.success("تم التعديل بنجاح");
  };

  const onFailure = (error) => {
    setSubmitting(false);
    message.error("حدث خطأ");
  };

  return (
    <div>
      <Row>
        <TabContent>
          <div>
            <h3>ملاحظة هامة:</h3>
            <p>
              ** معدل التحويل هو معدل تحويل النقاط إلى أموال.
              <br />
              <br />
              <strong>
                فمثلا إذا كان: <br />
              </strong>
              معدل التحويل = 10 -- فإن كل 10 نقاط يساوى 1 جنيه.
            </p>
          </div>
        </TabContent>
      </Row>
      <Row>
        <CrudForm
          title="إعدادات"
          loading={loading}
          fields={config.settings.edit.fields}
          onSubmit={onSubmit}
          buttonText="تعديل"
          submitting={submitting}
          initialValues={data}
        />
      </Row>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(SettingsTable);

const TabContent = styled.div`
  background: var(--color-primary);
  border-radius: 0.5rem;
  border: 0;
  color: var(--color-font-secondary);
  text-transform: uppercase;
  padding: 1.5rem;
  }
`;
