import {
  GET_SECTIONS,
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_FAILURE,
  GET_SECTION,
  DELETE_SECTION,
  ADD_SECTION,
  UPDATE_SECTION,
} from '../../../../config/constants/ActionTypes'

//Get Sections Request
export const getSections = (payload) => {
  return {
    type: GET_SECTIONS,
    payload,
  }
}

//Get Sections Success
export const getSectionsSuccess = (sections) => {
  return {
    type: GET_SECTIONS_SUCCESS,
    payload: { sections },
  }
}

//Get Sections Failure
export const getSectionsFailure = (error) => {
  return {
    type: GET_SECTIONS_FAILURE,
    payload: { error },
  }
}

//Delete Section
export const deleteSection = (payload) => {
  return {
    type: DELETE_SECTION,
    payload,
  }
}

//Add Section
export const addSection = (payload) => {
  return {
    type: ADD_SECTION,
    payload,
  }
}

//update Section
export const updateSection = (payload) => {
  return {
    type: UPDATE_SECTION,
    payload,
  }
}

//Get Section Request
export const getSection = (payload) => {
  return {
    type: GET_SECTION,
    payload,
  }
}
